/**
 * Calculate the expected Scoring Data for a shot for a benchmark
 *
 */
/* eslint-disable */
export function getScoringDataForShot(shot, benchmarkIndex) {
  if (shot.surface === 0) {
    return 0
  }

  // If benchmark = 8, use database
  if (benchmarkIndex == 8) {
    return getTourScoringDataForShot(shot)
  }

  // Otherwise use formulas
  const surface = convertSurface(shot.surface)
  const distance = convertMetersToYardsDouble(
    shot.distanceToHole,
  )

  const scoringData = getShotData(surface, distance, benchmarkIndex)

  // Always have a minimum of 1.001
  return Math.max(scoringData, 1.001)
}

function convertSurface(surface) {
  let newSurface = surface
  if ([6, 7, 8, 9, 10].includes(newSurface)) {
    newSurface -= 4
  }
  return newSurface
}

function getShotData(surface, distance, benchmarkIndex) {
  let expectedScore = 0.0

  // For Rough & Sand over 300yds
  if (distance >= 300 && (surface == 3 || surface == 4)) {
    expectedScore = calculateLongDistanceExpectedScore(
      surface,
      distance,
      benchmarkIndex,
    )
    // For Fairway over 250yds - benchmarks 5 & 6
  } else if (surface == 2 && benchmarkIndex == 5 && distance > 250) {
    expectedScore = calculateStandardExpectedScore(surface, distance, 4) + 0.3
  } else if (surface == 2 && benchmarkIndex == 6 && distance > 250) {
    expectedScore = calculateStandardExpectedScore(surface, distance, 4) + 0.6
    // For miniTour Fairway > 290
  } else if (surface == 2 && benchmarkIndex == 7 && distance > 290) {
    expectedScore = calculateStandardExpectedScore(surface, distance, 2)
    // For Other, take fairway +0.7
  } else if (surface == 5) {
    expectedScore = calculateStandardExpectedScore(2, distance, benchmarkIndex) + 0.7
    // For Fairway over 250yds - benchmarks 5 & 6
  } else if (surface == 1 && benchmarkIndex == 5 && distance > 500) {
    expectedScore = calculateStandardExpectedScore(surface, distance, 4) + 0.3
  } else if (surface == 1 && benchmarkIndex == 6 && distance > 500) {
    expectedScore = calculateStandardExpectedScore(surface, distance, 4) + 0.6
    // Otherwise standard calculation
  } else {
    expectedScore = calculateStandardExpectedScore(
      surface,
      distance,
      benchmarkIndex,
    )
  }

  // Perform additional checks for edge cases
  if (surface == 1) {
    // On tee shot have a minimum of 2.8
    expectedScore = Math.max(expectedScore, 2.8)
  } else if (surface == 6) {
    // Check mini and max values for putting
    expectedScore = Math.max(
      getMinimumPuttStats(distance, benchmarkIndex),
      expectedScore,
    )
    expectedScore = Math.min(
      getMaximumPuttStats(benchmarkIndex),
      expectedScore,
    )
  }

  return expectedScore
}

function calculateStandardExpectedScore(surface, distance, benchmarkIndex) {
  // key: benchmark|surface
  const sgData = {}

  sgData['0|2'] = [
    1.75977965465763,
    0.0397612937058182,
    -0.000722042983046948,
    0.00000675626779380128,
    -0.000000032507288949068,
    0.0000000000782669330993472,
    -0.0000000000000744092153302255,
  ]
  sgData['0|6'] = [0.968569503, 0.199169553, -0.0123192638, 0.000267814034]
  sgData['0|5'] = [
    1.38404513747827,
    0.0799684321150517,
    -0.00135074363544209,
    0.0000104698563426516,
    -0.0000000366013831219249,
    0.0000000000472464502017571,
  ]
  sgData['0|3'] = [
    1.87108248372436,
    0.0412416095961925,
    -0.000745882153382364,
    0.000006902440533044,
    -0.0000000323337121453337,
    0.0000000000747969365596078,
    -0.0000000000000678472899412151,
  ]
  sgData['0|4'] = [
    2.43196136,
    0.00553969292,
    -0.00000261165577,
    0.0000000106552961,
  ]
  sgData['0|1'] = [
    6.15009360308834,
    -0.0696780138958983,
    0.000522756164205673,
    -0.00000174171921797948,
    0.00000000289238761077577,
    -0.00000000000225512836358734,
    0.00000000000000062069820649045,
  ]

  sgData['1|2'] = [
    1.86302333696595,
    0.034036360732771,
    -0.0004943662823787,
    0.00000364091286870118,
    -0.0000000133031705100756,
    0.0000000000239494685461247,
    -0.0000000000000170855822195423,
  ]
  sgData['1|6'] = [1.05876354, 0.176821969, -0.00955647819, 0.000179229879]
  sgData['1|5'] = [
    1.99721699549198,
    0.0532079428079487,
    -0.000899453105512372,
    0.00000725094892603354,
    -0.0000000259318196915717,
    0.0000000000337114974650591,
  ]
  sgData['1|3'] = [
    2.00340183484944,
    0.0401435880389707,
    -0.000724864724723503,
    0.00000685267689479519,
    -0.0000000330230115823502,
    0.0000000000788060044378337,
    -0.0000000000000737074820598909,
  ]
  sgData['1|4'] = [
    2.4483401,
    0.0115971154,
    -0.0000530682634,
    0.000000123133661,
  ]
  sgData['1|1'] = [
    15.6066310152149,
    -0.263579806602505,
    0.00209155117742862,
    -0.00000810949884321164,
    0.0000000166551587693381,
    -0.0000000000173506893257362,
    0.00000000000000721899102361292,
  ]

  sgData['2|2'] = [
    1.8825830761004,
    0.0407237669191911,
    -0.000652522140828539,
    0.00000514894535998778,
    -0.0000000199216204899453,
    0.0000000000376469604468272,
    -0.0000000000000281571982445226,
  ]
  sgData['2|6'] = [1.09270531, 0.172450295, -0.0091235554, 0.000174980646]
  sgData['2|5'] = [
    2.47170295651197,
    0.0417329054113223,
    -0.000683223036891519,
    0.00000519423187485407,
    -0.0000000170847921594145,
    0.0000000000202218052798365,
  ]
  sgData['2|3'] = [
    2.13398972857263,
    0.0388212115705861,
    -0.000733095677809463,
    0.0000071885062824419,
    -0.0000000350704056065995,
    0.0000000000835282772705346,
    -0.0000000000000776277690298045,
  ]
  sgData['2|4'] = [
    2.49031593,
    0.0191191738,
    -0.000124837075,
    0.000000298039958,
  ]
  sgData['2|1'] = [
    11.843482461896,
    -0.184912996411705,
    0.00146356148776042,
    -0.00000556212357827141,
    0.0000000111051890875576,
    -0.0000000000111636259837532,
    0.00000000000000444982559248904,
  ]

  sgData['3|2'] = [
    1.9996902973375,
    0.0422300211803958,
    -0.000723515503114402,
    0.00000612996128310571,
    -0.0000000254995373506732,
    0.0000000000518176865777936,
    -0.000000000000041456878593403,
  ]
  sgData['3|6'] = [1.04186791, 0.22746059, -0.0154582029, 0.000368066837]
  sgData['3|5'] = [
    2.72506059687645,
    0.0215431081299745,
    -0.000135748807750561,
    0.000000302054990458157,
  ]
  sgData['3|3'] = [
    2.17435449214796,
    0.0418392892893786,
    -0.00068545921945305,
    0.00000559384482711637,
    -0.0000000221668805715623,
    0.0000000000419574339791094,
    -0.0000000000000302958582086557,
  ]
  sgData['3|4'] = [
    2.74368652,
    0.00815190307,
    -0.00000820817719,
    0.00000000187846722,
  ]
  sgData['3|1'] = [
    17.9019392682243,
    -0.310843573948604,
    0.00251041309322052,
    -0.00000992763279234479,
    0.0000000207986505667203,
    -0.0000000000220877272084434,
    0.00000000000000935756522585667,
  ]

  sgData['4|2'] = [
    2.0797386510396,
    0.0400584559335995,
    -0.00064825247719493,
    0.00000529585108259046,
    -0.0000000210527636840379,
    0.0000000000405309154586557,
    -0.0000000000000305353147739456,
  ]
  sgData['4|6'] = [1.06339301, 0.22727479, -0.0154170244, 0.000373861128]
  sgData['4|5'] = [
    3.11992909133831,
    0.0129701110743584,
    -0.0000747165621854905,
    0.000000189631483078362,
  ]
  sgData['4|3'] = [
    2.22753403485057,
    0.0486657141354351,
    -0.000868181198033669,
    0.00000759224772680805,
    -0.0000000324068679185792,
    0.0000000000667918140311238,
    -0.0000000000000535052197772101,
  ]
  sgData['4|4'] = [
    2.76854070761147,
    0.0128484501392335,
    -0.0000263658749744384,
    -0.000000128803146601646,
    0.00000000049569744548318,
  ]
  sgData['4|1'] = [
    18.5030461393432,
    -0.322064118679739,
    0.00260971104389088,
    -0.0000103528964228937,
    0.0000000217473824818544,
    -0.0000000000231247499516813,
    0.00000000000000978723196140491,
  ]

  sgData['5|2'] = [
    2.15832185824081,
    0.0388163242130911,
    -0.000591627229500015,
    0.00000416775304886128,
    -0.0000000107738147225022,
    0.000000000000515876816313438,
    0.0000000000000237869293786425,
  ]
  sgData['5|6'] = [0.952823144, 0.327320435, -0.0294689577, 0.00093352983]
  sgData['5|5'] = [
    3.3611858038683,
    0.0142793264023857,
    -0.0000740641528815059,
    0.000000180490110189398,
  ]
  sgData['5|3'] = [
    2.44695275,
    0.0316871708,
    -0.000414170517,
    0.00000287933776,
    -0.0000000089366838,
    0.0000000000101461269,
  ]
  sgData['5|4'] = [
    2.8103677928058,
    0.0216092363844073,
    -0.000138452355315218,
    0.000000392155255891873,
    -0.000000000334029832487598,
  ]
  sgData['5|1'] = [
    28.7528606835232,
    -0.538176813177915,
    0.00443027383550845,
    -0.0000180807332499635,
    0.0000000393151627541465,
    -0.0000000000435552871518292,
    0.0000000000000193432444290033,
  ]

  sgData['6|2'] = [
    2.42359545285305,
    0.0169217102911831,
    0.0000842018758188212,
    -0.00000429006205257832,
    0.0000000409573082521959,
    -0.000000000155325169480747,
    0.000000000000209132375455725,
  ]
  sgData['6|6'] = [0.92846068, 0.4025602, -0.046845, 0.00193521]
  sgData['6|5'] = [3.19173346614189, 0.00736473466054629]
  sgData['6|3'] = [
    2.39232969,
    0.0517158967,
    -0.000878526437,
    0.00000687609098,
    -0.0000000232335163,
    0.0000000000281458789,
  ]
  sgData['6|4'] = [
    3.29586666468574,
    -0.00757144278197146,
    0.000234862661735636,
    -0.00000119749059163348,
    0.00000000184778839576166,
  ]
  sgData['6|1'] = [
    -144.752883,
    3.74234319,
    -0.0387816196,
    0.000214052623,
    -0.000000679996142,
    0.00000000124712734,
    -0.00000000000122687166,
    0.000000000000000501136375,
  ]

  sgData['7|2'] = [
    1.65897001,
    0.068104669,
    -0.0016416138,
    0.0000201620799,
    -0.00000013512065,
    0.000000000506633809,
    -0.000000000000993430211,
    0.000000000000000790014749,
  ]
  sgData['7|6'] = [1.00608516, 0.206503478, -0.0117560563, 0.000222617074]
  sgData['7|5'] = [
    1.69075338,
    0.0929717585,
    -0.00168881322,
    0.0000151103293,
    -0.0000000670849532,
    0.000000000139097155,
    -0.000000000000102914472,
  ]
  sgData['7|3'] = [
    1.96921161,
    0.0508842325,
    -0.000997885634,
    0.00000956061007,
    -0.0000000450674451,
    0.000000000102400594,
    -0.0000000000000894870023,
  ]
  sgData['7|4'] = [
    2.23848179,
    0.0365351113,
    -0.000459410236,
    0.00000273245124,
    -0.00000000723475751,
    0.00000000000713106478,
  ]
  sgData['7|1'] = [
    6.92766627,
    -0.092623942,
    0.000804733524,
    -0.00000328869522,
    0.00000000709853088,
    -0.00000000000778003905,
    0.00000000000000341475856,
  ]

  const shotData = sgData[`${benchmarkIndex}|${surface}`]

  let expectedScore = 0.0
  if(shotData) {
    for (let i = 0; i < shotData.length; i++) {
      const data = shotData[i]
      expectedScore += data * Math.pow(parseFloat(distance), parseFloat(i))
    }
  }

  if (benchmarkIndex > 2 && benchmarkIndex < 7) {
    for (let j = 2; j < benchmarkIndex; j++) {
      let expectedScoreLowerBenchmark = 0.0
      const shotDataLowerBenchmark = sgData[`${j}|${surface}`]
      if(shotDataLowerBenchmark) {
        for (let i = 0; i < shotDataLowerBenchmark.length; i++) {
          const data = shotDataLowerBenchmark[i]
          expectedScoreLowerBenchmark
            += data * Math.pow(parseFloat(distance), parseFloat(i))
        }
        expectedScore = Math.max(expectedScore, expectedScoreLowerBenchmark)
      }
    }
  }

  return expectedScore
}

function getMinimumPuttStats(distance, benchmarkIndex) {
  // key: benchmark: 0.5, 1m
  const sgData = {}

  sgData[0] = [0.999, 0.986]
  sgData[1] = [0.999, 0.978]
  sgData[2] = [0.998, 0.967]
  sgData[3] = [0.997, 0.951]
  sgData[4] = [0.992, 0.94]
  sgData[5] = [0.985, 0.931]
  sgData[6] = [0.975, 0.912]
  sgData[7] = [0.998, 0.967]

  const data = sgData[benchmarkIndex]

  let makePercentage = 1.0
  if (distance <= 0.5) {
    makePercentage = data[0]
  } else {
    makePercentage = data[1]
  }

  const expectedScore = 1 + (1 - makePercentage)

  return expectedScore
}

function getMaximumPuttStats(benchmarkIndex) {
  const sgData = {}

  sgData[0] = 2.4
  sgData[1] = 2.45
  sgData[2] = 2.5
  sgData[3] = 2.55
  sgData[4] = 2.6
  sgData[5] = 2.65
  sgData[6] = 2.7
  sgData[7] = 2.5

  return sgData[benchmarkIndex]
}

function calculateLongDistanceExpectedScore(surface, distance, benchmarkIndex) {
  // key: benchmark|surface
  const sgData = {}

  sgData['0|3'] = [0.00142373651, 3.44575809]
  sgData['0|4'] = [0.00284495596, 3.29302642]

  sgData['1|3'] = [0.00163209425, 3.62074345]
  sgData['1|4'] = [0.00274686713, 3.65187972]

  sgData['2|3'] = [0.00231934482, 3.50839311]
  sgData['2|4'] = [0.00187396605, 4.47562037]

  sgData['3|3'] = [0.00270742278, 3.57554633]
  sgData['3|4'] = [0.00287396605, 4.17562037]

  sgData['4|3'] = [0.00338399395, 3.46960363]
  sgData['4|4'] = [0.00387396605, 3.87562037]

  sgData['5|3'] = [0.00370724, 3.57565313]
  sgData['5|4'] = [0.00487397, 3.57562037]

  sgData['6|3'] = [0.00467607, 3.29435905]
  sgData['6|4'] = [0.00587397, 3.27562037]

  sgData['7|3'] = [0.00231934482, 3.50839311]
  sgData['7|4'] = [0.00187396605, 4.47562037]

  const shotData = sgData[`${benchmarkIndex}|${surface}`]

  const expectedScore = shotData[0] * distance + shotData[1]

  return expectedScore
}

/*
getScoringDataForShot

Parameters:
shot

Return average strokes from shot distance & surface
*/
function getTourScoringDataForShot(shot) {
  let scoringData = 0

  let distance = 0
  let { surface } = shot
  // Parameters.greensideFairwayCode = 6
  if (surface >= 6) surface -= 4

  if (surface !== 6) distance = convertMetersToYards(shot.distanceToHole)
  else distance = convertMetersToFeet(shot.distanceToHole)

  const data = scoringDataTour[distance]
  scoringData = data[surface - 1]

  return scoringData
}

/*
convert meters To Yards
*/
function convertMetersToYards(meters) {
  let yards = meters * 1.0936
  yards = Math.round(yards)
  if (yards <= 0) yards = 1
  else if (yards > 600) yards = 600
  return yards
}

/*
convert meters To Yards Double
*/
function convertMetersToYardsDouble(meters) {
  let yards = meters * 1.0936
  if (yards <= 0) yards = 1
  else if (yards > 600) yards = 600
  return yards
}

/*
convert meters To Feet
*/
function convertMetersToFeet(meters) {
  let feet = meters * 3.2808
  feet = Math.round(feet)
  if (feet <= 0) feet = 1
  else if (feet > 600) feet = 600
  return feet
}

const scoringDataTour = {
  1: [2.03, 1.91, 2.07, 2.16, 2.73, 1.01],
  2: [2.06, 1.94, 2.1, 2.19, 2.76, 1.025],
  3: [2.09, 1.97, 2.13, 2.22, 2.79, 1.04],
  4: [2.12, 2, 2.16, 2.25, 2.82, 1.13],
  5: [2.15, 2.03, 2.19, 2.28, 2.85, 1.23],
  6: [2.18, 2.06, 2.22, 2.31, 2.88, 1.34],
  7: [2.21, 2.09, 2.25, 2.34, 2.91, 1.42],
  8: [2.24, 2.12, 2.28, 2.37, 2.94, 1.5],
  9: [2.27, 2.15, 2.31, 2.4, 2.97, 1.56],
  10: [2.3, 2.18, 2.34, 2.43, 3, 1.61],
  11: [2.322, 2.202, 2.366, 2.44, 3.01, 1.644],
  12: [2.344, 2.224, 2.392, 2.45, 3.02, 1.678],
  13: [2.366, 2.246, 2.418, 2.46, 3.03, 1.712],
  14: [2.388, 2.268, 2.444, 2.47, 3.04, 1.746],
  15: [2.41, 2.29, 2.47, 2.48, 3.05, 1.78],
  16: [2.432, 2.312, 2.494, 2.49, 3.06, 1.798],
  17: [2.454, 2.334, 2.518, 2.5, 3.07, 1.816],
  18: [2.476, 2.356, 2.542, 2.51, 3.08, 1.834],
  19: [2.498, 2.378, 2.566, 2.52, 3.09, 1.852],
  20: [2.52, 2.4, 2.59, 2.53, 3.1, 1.87],
  21: [2.53, 2.41, 2.5995, 2.5445, 3.1145, 1.882],
  22: [2.54, 2.42, 2.609, 2.559, 3.129, 1.894],
  23: [2.55, 2.43, 2.6185, 2.5735, 3.1435, 1.906],
  24: [2.56, 2.44, 2.628, 2.588, 3.158, 1.918],
  25: [2.57, 2.45, 2.6375, 2.6025, 3.1725, 1.93],
  26: [2.58, 2.46, 2.647, 2.617, 3.187, 1.94],
  27: [2.59, 2.47, 2.6565, 2.6315, 3.2015, 1.95],
  28: [2.6, 2.48, 2.666, 2.646, 3.216, 1.96],
  29: [2.61, 2.49, 2.6755, 2.6605, 3.2305, 1.97],
  30: [2.62, 2.5, 2.685, 2.675, 3.245, 1.98],
  31: [2.63, 2.51, 2.6945, 2.6895, 3.2595, 1.988],
  32: [2.64, 2.52, 2.704, 2.704, 3.274, 1.996],
  33: [2.65, 2.53, 2.7135, 2.7185, 3.2885, 2.004],
  34: [2.66, 2.54, 2.723, 2.733, 3.303, 2.012],
  35: [2.67, 2.55, 2.7325, 2.7475, 3.3175, 2.02],
  36: [2.68, 2.56, 2.742, 2.762, 3.332, 2.028],
  37: [2.69, 2.57, 2.7515, 2.7765, 3.3465, 2.036],
  38: [2.7, 2.58, 2.761, 2.791, 3.361, 2.044],
  39: [2.71, 2.59, 2.7705, 2.8055, 3.3755, 2.052],
  40: [2.72, 2.6, 2.78, 2.82, 3.39, 2.06],
  41: [2.725, 2.605, 2.7865, 2.8365, 3.4065, 2.068],
  42: [2.73, 2.61, 2.793, 2.853, 3.423, 2.076],
  43: [2.735, 2.615, 2.7995, 2.8695, 3.4395, 2.084],
  44: [2.74, 2.62, 2.806, 2.886, 3.456, 2.092],
  45: [2.745, 2.625, 2.8125, 2.9025, 3.4725, 2.1],
  46: [2.75, 2.63, 2.819, 2.919, 3.489, 2.108],
  47: [2.755, 2.635, 2.8255, 2.9355, 3.5055, 2.116],
  48: [2.76, 2.64, 2.832, 2.952, 3.522, 2.124],
  49: [2.765, 2.645, 2.8385, 2.9685, 3.5385, 2.132],
  50: [2.77, 2.65, 2.845, 2.985, 3.555, 2.14],
  51: [2.775, 2.655, 2.8515, 3.0015, 3.5715, 2.146],
  52: [2.78, 2.66, 2.858, 3.018, 3.588, 2.152],
  53: [2.785, 2.665, 2.8645, 3.0345, 3.6045, 2.158],
  54: [2.79, 2.67, 2.871, 3.051, 3.621, 2.164],
  55: [2.795, 2.675, 2.8775, 3.0675, 3.6375, 2.17],
  56: [2.8, 2.68, 2.884, 3.084, 3.654, 2.178],
  57: [2.805, 2.685, 2.8905, 3.1005, 3.6705, 2.186],
  58: [2.81, 2.69, 2.897, 3.117, 3.687, 2.194],
  59: [2.815, 2.695, 2.9035, 3.1335, 3.7035, 2.202],
  60: [2.82, 2.7, 2.91, 3.15, 3.72, 2.21],
  61: [2.8225, 2.7025, 2.9125, 3.1545, 3.7245, 2.217],
  62: [2.825, 2.705, 2.915, 3.159, 3.729, 2.224],
  63: [2.8275, 2.7075, 2.9175, 3.1635, 3.7335, 2.231],
  64: [2.83, 2.71, 2.92, 3.168, 3.738, 2.238],
  65: [2.8325, 2.7125, 2.9225, 3.1725, 3.7425, 2.245],
  66: [2.835, 2.715, 2.925, 3.177, 3.747, 2.252],
  67: [2.8375, 2.7175, 2.9275, 3.1815, 3.7515, 2.259],
  68: [2.84, 2.72, 2.93, 3.186, 3.756, 2.266],
  69: [2.8425, 2.7225, 2.9325, 3.1905, 3.7605, 2.273],
  70: [2.845, 2.725, 2.935, 3.195, 3.765, 2.28],
  71: [2.8475, 2.7275, 2.9375, 3.1995, 3.7695, 2.286],
  72: [2.85, 2.73, 2.94, 3.204, 3.774, 2.292],
  73: [2.8525, 2.7325, 2.9425, 3.2085, 3.7785, 2.298],
  74: [2.855, 2.735, 2.945, 3.213, 3.783, 2.304],
  75: [2.8575, 2.7375, 2.9475, 3.2175, 3.7875, 2.31],
  76: [2.86, 2.74, 2.95, 3.222, 3.792, 2.316],
  77: [2.8625, 2.7425, 2.9525, 3.2265, 3.7965, 2.322],
  78: [2.865, 2.745, 2.955, 3.231, 3.801, 2.328],
  79: [2.8675, 2.7475, 2.9575, 3.2355, 3.8055, 2.334],
  80: [2.87, 2.75, 2.96, 3.24, 3.81, 2.34],
  81: [2.8725, 2.7525, 2.963, 3.2395, 3.8095, 2.346],
  82: [2.875, 2.755, 2.966, 3.239, 3.809, 2.352],
  83: [2.8775, 2.7575, 2.969, 3.2385, 3.8085, 2.358],
  84: [2.88, 2.76, 2.972, 3.238, 3.808, 2.364],
  85: [2.8825, 2.7625, 2.975, 3.2375, 3.8075, 2.37],
  86: [2.885, 2.765, 2.978, 3.237, 3.807, 2.376],
  87: [2.8875, 2.7675, 2.981, 3.2365, 3.8065, 2.382],
  88: [2.89, 2.77, 2.984, 3.236, 3.806, 2.388],
  89: [2.8925, 2.7725, 2.987, 3.2355, 3.8055, 2.394],
  90: [2.895, 2.775, 2.99, 3.235, 3.805, 2.4],
  91: [2.8975, 2.7775, 2.993, 3.2345, 3.8045, 2.4],
  92: [2.9, 2.78, 2.996, 3.234, 3.804, 2.4],
  93: [2.9025, 2.7825, 2.999, 3.2335, 3.8035, 2.4],
  94: [2.905, 2.785, 3.002, 3.233, 3.803, 2.4],
  95: [2.9075, 2.7875, 3.005, 3.2325, 3.8025, 2.4],
  96: [2.91, 2.79, 3.008, 3.232, 3.802, 2.4],
  97: [2.9125, 2.7925, 3.011, 3.2315, 3.8015, 2.4],
  98: [2.915, 2.795, 3.014, 3.231, 3.801, 2.4],
  99: [2.9175, 2.7975, 3.017, 3.2305, 3.8005, 2.4],
  100: [2.92, 2.8, 3.02, 3.23, 3.8, 2.4],
  101: [2.9235, 2.8025, 3.023, 3.229, 3.799, 2.4],
  102: [2.927, 2.805, 3.026, 3.228, 3.798, 2.4],
  103: [2.9305, 2.8075, 3.029, 3.227, 3.797, 2.4],
  104: [2.934, 2.81, 3.032, 3.226, 3.796, 2.4],
  105: [2.9375, 2.8125, 3.035, 3.225, 3.795, 2.4],
  106: [2.941, 2.815, 3.038, 3.224, 3.794, 2.4],
  107: [2.9445, 2.8175, 3.041, 3.223, 3.793, 2.4],
  108: [2.948, 2.82, 3.044, 3.222, 3.792, 2.4],
  109: [2.9515, 2.8225, 3.047, 3.221, 3.791, 2.4],
  110: [2.955, 2.825, 3.05, 3.22, 3.79, 2.4],
  111: [2.9585, 2.8275, 3.053, 3.219, 3.789, 2.4],
  112: [2.962, 2.83, 3.056, 3.218, 3.788, 2.4],
  113: [2.9655, 2.8325, 3.059, 3.217, 3.787, 2.4],
  114: [2.969, 2.835, 3.062, 3.216, 3.786, 2.4],
  115: [2.9725, 2.8375, 3.065, 3.215, 3.785, 2.4],
  116: [2.976, 2.84, 3.068, 3.214, 3.784, 2.4],
  117: [2.9795, 2.8425, 3.071, 3.213, 3.783, 2.4],
  118: [2.983, 2.845, 3.074, 3.212, 3.782, 2.4],
  119: [2.9865, 2.8475, 3.077, 3.211, 3.781, 2.4],
  120: [2.99, 2.85, 3.08, 3.21, 3.78, 2.4],
  121: [2.989, 2.853, 3.0835, 3.2105, 3.781, 2.4],
  122: [2.988, 2.856, 3.087, 3.211, 3.782, 2.4],
  123: [2.987, 2.859, 3.0905, 3.2115, 3.783, 2.4],
  124: [2.986, 2.862, 3.094, 3.212, 3.784, 2.4],
  125: [2.985, 2.865, 3.0975, 3.2125, 3.785, 2.4],
  126: [2.984, 2.868, 3.101, 3.213, 3.786, 2.4],
  127: [2.983, 2.871, 3.1045, 3.2135, 3.787, 2.4],
  128: [2.982, 2.874, 3.108, 3.214, 3.788, 2.4],
  129: [2.981, 2.877, 3.1115, 3.2145, 3.789, 2.4],
  130: [2.98, 2.88, 3.115, 3.215, 3.79, 2.4],
  131: [2.979, 2.883, 3.1185, 3.2155, 3.791, 2.4],
  132: [2.978, 2.886, 3.122, 3.216, 3.792, 2.4],
  133: [2.977, 2.889, 3.1255, 3.2165, 3.793, 2.4],
  134: [2.976, 2.892, 3.129, 3.217, 3.794, 2.4],
  135: [2.975, 2.895, 3.1325, 3.2175, 3.795, 2.4],
  136: [2.974, 2.898, 3.136, 3.218, 3.796, 2.4],
  137: [2.973, 2.901, 3.1395, 3.2185, 3.797, 2.4],
  138: [2.972, 2.904, 3.143, 3.219, 3.798, 2.4],
  139: [2.971, 2.907, 3.1465, 3.2195, 3.799, 2.4],
  140: [2.97, 2.91, 3.15, 3.22, 3.8, 2.4],
  141: [2.971, 2.9135, 3.154, 3.223, 3.8005, 2.4],
  142: [2.972, 2.917, 3.158, 3.226, 3.801, 2.4],
  143: [2.973, 2.9205, 3.162, 3.229, 3.8015, 2.4],
  144: [2.974, 2.924, 3.166, 3.232, 3.802, 2.4],
  145: [2.975, 2.9275, 3.17, 3.235, 3.8025, 2.4],
  146: [2.976, 2.931, 3.174, 3.238, 3.803, 2.4],
  147: [2.977, 2.9345, 3.178, 3.241, 3.8035, 2.4],
  148: [2.978, 2.938, 3.182, 3.244, 3.804, 2.4],
  149: [2.979, 2.9415, 3.186, 3.247, 3.8045, 2.4],
  150: [2.98, 2.945, 3.19, 3.25, 3.805, 2.4],
  151: [2.981, 2.9485, 3.194, 3.253, 3.8055, 2.4],
  152: [2.982, 2.952, 3.198, 3.256, 3.806, 2.4],
  153: [2.983, 2.9555, 3.202, 3.259, 3.8065, 2.4],
  154: [2.984, 2.959, 3.206, 3.262, 3.807, 2.4],
  155: [2.985, 2.9625, 3.21, 3.265, 3.8075, 2.4],
  156: [2.986, 2.966, 3.214, 3.268, 3.808, 2.4],
  157: [2.987, 2.9695, 3.218, 3.271, 3.8085, 2.4],
  158: [2.988, 2.973, 3.222, 3.274, 3.809, 2.4],
  159: [2.989, 2.9765, 3.226, 3.277, 3.8095, 2.4],
  160: [2.99, 2.98, 3.23, 3.28, 3.81, 2.4],
  161: [2.993, 2.985, 3.234, 3.286, 3.8105, 2.4],
  162: [2.996, 2.99, 3.238, 3.292, 3.811, 2.4],
  163: [2.999, 2.995, 3.242, 3.298, 3.8115, 2.4],
  164: [3.002, 3, 3.246, 3.304, 3.812, 2.4],
  165: [3.005, 3.005, 3.25, 3.31, 3.8125, 2.4],
  166: [3.008, 3.01, 3.254, 3.316, 3.813, 2.4],
  167: [3.011, 3.015, 3.258, 3.322, 3.8135, 2.4],
  168: [3.014, 3.02, 3.262, 3.328, 3.814, 2.4],
  169: [3.017, 3.025, 3.266, 3.334, 3.8145, 2.4],
  170: [3.02, 3.03, 3.27, 3.34, 3.815, 2.4],
  171: [3.023, 3.035, 3.274, 3.346, 3.8155, 2.4],
  172: [3.026, 3.04, 3.278, 3.352, 3.816, 2.4],
  173: [3.029, 3.045, 3.282, 3.358, 3.8165, 2.4],
  174: [3.032, 3.05, 3.286, 3.364, 3.817, 2.4],
  175: [3.035, 3.055, 3.29, 3.37, 3.8175, 2.4],
  176: [3.038, 3.06, 3.294, 3.376, 3.818, 2.4],
  177: [3.041, 3.065, 3.298, 3.382, 3.8185, 2.4],
  178: [3.044, 3.07, 3.302, 3.388, 3.819, 2.4],
  179: [3.047, 3.075, 3.306, 3.394, 3.8195, 2.4],
  180: [3.05, 3.08, 3.31, 3.4, 3.82, 2.4],
  181: [3.0535, 3.0855, 3.3155, 3.4075, 3.8225, 2.4],
  182: [3.057, 3.091, 3.321, 3.415, 3.825, 2.4],
  183: [3.0605, 3.0965, 3.3265, 3.4225, 3.8275, 2.4],
  184: [3.064, 3.102, 3.332, 3.43, 3.83, 2.4],
  185: [3.0675, 3.1075, 3.3375, 3.4375, 3.8325, 2.4],
  186: [3.071, 3.113, 3.343, 3.445, 3.835, 2.4],
  187: [3.0745, 3.1185, 3.3485, 3.4525, 3.8375, 2.4],
  188: [3.078, 3.124, 3.354, 3.46, 3.84, 2.4],
  189: [3.0815, 3.1295, 3.3595, 3.4675, 3.8425, 2.4],
  190: [3.085, 3.135, 3.365, 3.475, 3.845, 2.4],
  191: [3.0885, 3.1405, 3.3705, 3.4825, 3.8475, 2.4],
  192: [3.092, 3.146, 3.376, 3.49, 3.85, 2.4],
  193: [3.0955, 3.1515, 3.3815, 3.4975, 3.8525, 2.4],
  194: [3.099, 3.157, 3.387, 3.505, 3.855, 2.4],
  195: [3.1025, 3.1625, 3.3925, 3.5125, 3.8575, 2.4],
  196: [3.106, 3.168, 3.398, 3.52, 3.86, 2.4],
  197: [3.1095, 3.1735, 3.4035, 3.5275, 3.8625, 2.4],
  198: [3.113, 3.179, 3.409, 3.535, 3.865, 2.4],
  199: [3.1165, 3.1845, 3.4145, 3.5425, 3.8675, 2.4],
  200: [3.12, 3.19, 3.42, 3.55, 3.87, 2.4],
  201: [3.1225, 3.1965, 3.4255, 3.5575, 3.8725, 2.4],
  202: [3.125, 3.203, 3.431, 3.565, 3.875, 2.4],
  203: [3.1275, 3.2095, 3.4365, 3.5725, 3.8775, 2.4],
  204: [3.13, 3.216, 3.442, 3.58, 3.88, 2.4],
  205: [3.1325, 3.2225, 3.4475, 3.5875, 3.8825, 2.4],
  206: [3.135, 3.229, 3.453, 3.595, 3.885, 2.4],
  207: [3.1375, 3.2355, 3.4585, 3.6025, 3.8875, 2.4],
  208: [3.14, 3.242, 3.464, 3.61, 3.89, 2.4],
  209: [3.1425, 3.2485, 3.4695, 3.6175, 3.8925, 2.4],
  210: [3.145, 3.255, 3.475, 3.625, 3.895, 2.4],
  211: [3.1475, 3.2615, 3.4805, 3.6325, 3.8975, 2.4],
  212: [3.15, 3.268, 3.486, 3.64, 3.9, 2.4],
  213: [3.1525, 3.2745, 3.4915, 3.6475, 3.9025, 2.4],
  214: [3.155, 3.281, 3.497, 3.655, 3.905, 2.4],
  215: [3.1575, 3.2875, 3.5025, 3.6625, 3.9075, 2.4],
  216: [3.16, 3.294, 3.508, 3.67, 3.91, 2.4],
  217: [3.1625, 3.3005, 3.5135, 3.6775, 3.9125, 2.4],
  218: [3.165, 3.307, 3.519, 3.685, 3.915, 2.4],
  219: [3.1675, 3.3135, 3.5245, 3.6925, 3.9175, 2.4],
  220: [3.17, 3.32, 3.53, 3.7, 3.92, 2.4],
  221: [3.174, 3.3265, 3.5355, 3.707, 3.9225, 2.4],
  222: [3.178, 3.333, 3.541, 3.714, 3.925, 2.4],
  223: [3.182, 3.3395, 3.5465, 3.721, 3.9275, 2.4],
  224: [3.186, 3.346, 3.552, 3.728, 3.93, 2.4],
  225: [3.19, 3.3525, 3.5575, 3.735, 3.9325, 2.4],
  226: [3.194, 3.359, 3.563, 3.742, 3.935, 2.4],
  227: [3.198, 3.3655, 3.5685, 3.749, 3.9375, 2.4],
  228: [3.202, 3.372, 3.574, 3.756, 3.94, 2.4],
  229: [3.206, 3.3785, 3.5795, 3.763, 3.9425, 2.4],
  230: [3.21, 3.385, 3.585, 3.77, 3.945, 2.4],
  231: [3.214, 3.3915, 3.5905, 3.777, 3.9475, 2.4],
  232: [3.218, 3.398, 3.596, 3.784, 3.95, 2.4],
  233: [3.222, 3.4045, 3.6015, 3.791, 3.9525, 2.4],
  234: [3.226, 3.411, 3.607, 3.798, 3.955, 2.4],
  235: [3.23, 3.4175, 3.6125, 3.805, 3.9575, 2.4],
  236: [3.234, 3.424, 3.618, 3.812, 3.96, 2.4],
  237: [3.238, 3.4305, 3.6235, 3.819, 3.9625, 2.4],
  238: [3.242, 3.437, 3.629, 3.826, 3.965, 2.4],
  239: [3.246, 3.4435, 3.6345, 3.833, 3.9675, 2.4],
  240: [3.25, 3.45, 3.64, 3.84, 3.97, 2.4],
  241: [3.26, 3.4565, 3.645, 3.8445, 3.973, 2.4],
  242: [3.27, 3.463, 3.65, 3.849, 3.976, 2.4],
  243: [3.28, 3.4695, 3.655, 3.8535, 3.979, 2.4],
  244: [3.29, 3.476, 3.66, 3.858, 3.982, 2.4],
  245: [3.3, 3.4825, 3.665, 3.8625, 3.985, 2.4],
  246: [3.31, 3.489, 3.67, 3.867, 3.988, 2.4],
  247: [3.32, 3.4955, 3.675, 3.8715, 3.991, 2.4],
  248: [3.33, 3.502, 3.68, 3.876, 3.994, 2.4],
  249: [3.34, 3.5085, 3.685, 3.8805, 3.997, 2.4],
  250: [3.35, 3.515, 3.69, 3.885, 4, 2.4],
  251: [3.36, 3.5215, 3.695, 3.8895, 4.003, 2.4],
  252: [3.37, 3.528, 3.7, 3.894, 4.006, 2.4],
  253: [3.38, 3.5345, 3.705, 3.8985, 4.009, 2.4],
  254: [3.39, 3.541, 3.71, 3.903, 4.012, 2.4],
  255: [3.4, 3.5475, 3.715, 3.9075, 4.015, 2.4],
  256: [3.41, 3.554, 3.72, 3.912, 4.018, 2.4],
  257: [3.42, 3.5605, 3.725, 3.9165, 4.021, 2.4],
  258: [3.43, 3.567, 3.73, 3.921, 4.024, 2.4],
  259: [3.44, 3.5735, 3.735, 3.9255, 4.027, 2.4],
  260: [3.45, 3.58, 3.74, 3.93, 4.03, 2.4],
  261: [3.46, 3.5855, 3.7445, 3.9335, 4.0335, 2.4],
  262: [3.47, 3.591, 3.749, 3.937, 4.037, 2.4],
  263: [3.48, 3.5965, 3.7535, 3.9405, 4.0405, 2.4],
  264: [3.49, 3.602, 3.758, 3.944, 4.044, 2.4],
  265: [3.5, 3.6075, 3.7625, 3.9475, 4.0475, 2.4],
  266: [3.51, 3.613, 3.767, 3.951, 4.051, 2.4],
  267: [3.52, 3.6185, 3.7715, 3.9545, 4.0545, 2.4],
  268: [3.53, 3.624, 3.776, 3.958, 4.058, 2.4],
  269: [3.54, 3.6295, 3.7805, 3.9615, 4.0615, 2.4],
  270: [3.55, 3.635, 3.785, 3.965, 4.065, 2.4],
  271: [3.56, 3.6405, 3.7895, 3.9685, 4.0685, 2.4],
  272: [3.57, 3.646, 3.794, 3.972, 4.072, 2.4],
  273: [3.58, 3.6515, 3.7985, 3.9755, 4.0755, 2.4],
  274: [3.59, 3.657, 3.803, 3.979, 4.079, 2.4],
  275: [3.6, 3.6625, 3.8075, 3.9825, 4.0825, 2.4],
  276: [3.61, 3.668, 3.812, 3.986, 4.086, 2.4],
  277: [3.62, 3.6735, 3.8165, 3.9895, 4.0895, 2.4],
  278: [3.63, 3.679, 3.821, 3.993, 4.093, 2.4],
  279: [3.64, 3.6845, 3.8255, 3.9965, 4.0965, 2.4],
  280: [3.65, 3.69, 3.83, 4, 4.1, 2.4],
  281: [3.653, 3.6945, 3.8335, 4.002, 4.105, 2.4],
  282: [3.656, 3.699, 3.837, 4.004, 4.11, 2.4],
  283: [3.659, 3.7035, 3.8405, 4.006, 4.115, 2.4],
  284: [3.662, 3.708, 3.844, 4.008, 4.12, 2.4],
  285: [3.665, 3.7125, 3.8475, 4.01, 4.125, 2.4],
  286: [3.668, 3.717, 3.851, 4.012, 4.13, 2.4],
  287: [3.671, 3.7215, 3.8545, 4.014, 4.135, 2.4],
  288: [3.674, 3.726, 3.858, 4.016, 4.14, 2.4],
  289: [3.677, 3.7305, 3.8615, 4.018, 4.145, 2.4],
  290: [3.68, 3.735, 3.865, 4.02, 4.15, 2.4],
  291: [3.683, 3.7395, 3.8685, 4.022, 4.155, 2.4],
  292: [3.686, 3.744, 3.872, 4.024, 4.16, 2.4],
  293: [3.689, 3.7485, 3.8755, 4.026, 4.165, 2.4],
  294: [3.692, 3.753, 3.879, 4.028, 4.17, 2.4],
  295: [3.695, 3.7575, 3.8825, 4.03, 4.175, 2.4],
  296: [3.698, 3.762, 3.886, 4.032, 4.18, 2.4],
  297: [3.701, 3.7665, 3.8895, 4.034, 4.185, 2.4],
  298: [3.704, 3.771, 3.893, 4.036, 4.19, 2.4],
  299: [3.707, 3.7755, 3.8965, 4.038, 4.195, 2.4],
  300: [3.71, 3.78, 3.9, 4.04, 4.2, 2.4],
  301: [3.714, 3.783, 3.9025, 4.044, 4.2055, 2.4],
  302: [3.718, 3.786, 3.905, 4.048, 4.211, 2.4],
  303: [3.722, 3.789, 3.9075, 4.052, 4.2165, 2.4],
  304: [3.726, 3.792, 3.91, 4.056, 4.222, 2.4],
  305: [3.73, 3.795, 3.9125, 4.06, 4.2275, 2.4],
  306: [3.734, 3.798, 3.915, 4.064, 4.233, 2.4],
  307: [3.738, 3.801, 3.9175, 4.068, 4.2385, 2.4],
  308: [3.742, 3.804, 3.92, 4.072, 4.244, 2.4],
  309: [3.746, 3.807, 3.9225, 4.076, 4.2495, 2.4],
  310: [3.75, 3.81, 3.925, 4.08, 4.255, 2.4],
  311: [3.754, 3.813, 3.9275, 4.084, 4.2605, 2.4],
  312: [3.758, 3.816, 3.93, 4.088, 4.266, 2.4],
  313: [3.762, 3.819, 3.9325, 4.092, 4.2715, 2.4],
  314: [3.766, 3.822, 3.935, 4.096, 4.277, 2.4],
  315: [3.77, 3.825, 3.9375, 4.1, 4.2825, 2.4],
  316: [3.774, 3.828, 3.94, 4.104, 4.288, 2.4],
  317: [3.778, 3.831, 3.9425, 4.108, 4.2935, 2.4],
  318: [3.782, 3.834, 3.945, 4.112, 4.299, 2.4],
  319: [3.786, 3.837, 3.9475, 4.116, 4.3045, 2.4],
  320: [3.79, 3.84, 3.95, 4.12, 4.31, 2.4],
  321: [3.7935, 3.842, 3.9535, 4.127, 4.3165, 2.4],
  322: [3.797, 3.844, 3.957, 4.134, 4.323, 2.4],
  323: [3.8005, 3.846, 3.9605, 4.141, 4.3295, 2.4],
  324: [3.804, 3.848, 3.964, 4.148, 4.336, 2.4],
  325: [3.8075, 3.85, 3.9675, 4.155, 4.3425, 2.4],
  326: [3.811, 3.852, 3.971, 4.162, 4.349, 2.4],
  327: [3.8145, 3.854, 3.9745, 4.169, 4.3555, 2.4],
  328: [3.818, 3.856, 3.978, 4.176, 4.362, 2.4],
  329: [3.8215, 3.858, 3.9815, 4.183, 4.3685, 2.4],
  330: [3.825, 3.86, 3.985, 4.19, 4.375, 2.4],
  331: [3.8285, 3.862, 3.9885, 4.197, 4.3815, 2.4],
  332: [3.832, 3.864, 3.992, 4.204, 4.388, 2.4],
  333: [3.8355, 3.866, 3.9955, 4.211, 4.3945, 2.4],
  334: [3.839, 3.868, 3.999, 4.218, 4.401, 2.4],
  335: [3.8425, 3.87, 4.0025, 4.225, 4.4075, 2.4],
  336: [3.846, 3.872, 4.006, 4.232, 4.414, 2.4],
  337: [3.8495, 3.874, 4.0095, 4.239, 4.4205, 2.4],
  338: [3.853, 3.876, 4.013, 4.246, 4.427, 2.4],
  339: [3.8565, 3.878, 4.0165, 4.253, 4.4335, 2.4],
  340: [3.86, 3.88, 4.02, 4.26, 4.44, 2.4],
  341: [3.863, 3.8835, 4.0245, 4.2675, 4.446, 2.4],
  342: [3.866, 3.887, 4.029, 4.275, 4.452, 2.4],
  343: [3.869, 3.8905, 4.0335, 4.2825, 4.458, 2.4],
  344: [3.872, 3.894, 4.038, 4.29, 4.464, 2.4],
  345: [3.875, 3.8975, 4.0425, 4.2975, 4.47, 2.4],
  346: [3.878, 3.901, 4.047, 4.305, 4.476, 2.4],
  347: [3.881, 3.9045, 4.0515, 4.3125, 4.482, 2.4],
  348: [3.884, 3.908, 4.056, 4.32, 4.488, 2.4],
  349: [3.887, 3.9115, 4.0605, 4.3275, 4.494, 2.4],
  350: [3.89, 3.915, 4.065, 4.335, 4.5, 2.4],
  351: [3.893, 3.9185, 4.0695, 4.3425, 4.506, 2.4],
  352: [3.896, 3.922, 4.074, 4.35, 4.512, 2.4],
  353: [3.899, 3.9255, 4.0785, 4.3575, 4.518, 2.4],
  354: [3.902, 3.929, 4.083, 4.365, 4.524, 2.4],
  355: [3.905, 3.9325, 4.0875, 4.3725, 4.53, 2.4],
  356: [3.908, 3.936, 4.092, 4.38, 4.536, 2.4],
  357: [3.911, 3.9395, 4.0965, 4.3875, 4.542, 2.4],
  358: [3.914, 3.943, 4.101, 4.395, 4.548, 2.4],
  359: [3.917, 3.9465, 4.1055, 4.4025, 4.554, 2.4],
  360: [3.92, 3.95, 4.11, 4.41, 4.56, 2.4],
  361: [3.922, 3.954, 4.115, 4.417, 4.565, 2.4],
  362: [3.924, 3.958, 4.12, 4.424, 4.57, 2.4],
  363: [3.926, 3.962, 4.125, 4.431, 4.575, 2.4],
  364: [3.928, 3.966, 4.13, 4.438, 4.58, 2.4],
  365: [3.93, 3.97, 4.135, 4.445, 4.585, 2.4],
  366: [3.932, 3.974, 4.14, 4.452, 4.59, 2.4],
  367: [3.934, 3.978, 4.145, 4.459, 4.595, 2.4],
  368: [3.936, 3.982, 4.15, 4.466, 4.6, 2.4],
  369: [3.938, 3.986, 4.155, 4.473, 4.605, 2.4],
  370: [3.94, 3.99, 4.16, 4.48, 4.61, 2.4],
  371: [3.942, 3.994, 4.165, 4.487, 4.615, 2.4],
  372: [3.944, 3.998, 4.17, 4.494, 4.62, 2.4],
  373: [3.946, 4.002, 4.175, 4.501, 4.625, 2.4],
  374: [3.948, 4.006, 4.18, 4.508, 4.63, 2.4],
  375: [3.95, 4.01, 4.185, 4.515, 4.635, 2.4],
  376: [3.952, 4.014, 4.19, 4.522, 4.64, 2.4],
  377: [3.954, 4.018, 4.195, 4.529, 4.645, 2.4],
  378: [3.956, 4.022, 4.2, 4.536, 4.65, 2.4],
  379: [3.958, 4.026, 4.205, 4.543, 4.655, 2.4],
  380: [3.96, 4.03, 4.21, 4.55, 4.66, 2.4],
  381: [3.9615, 4.034, 4.2145, 4.557, 4.6645, 2.4],
  382: [3.963, 4.038, 4.219, 4.564, 4.669, 2.4],
  383: [3.9645, 4.042, 4.2235, 4.571, 4.6735, 2.4],
  384: [3.966, 4.046, 4.228, 4.578, 4.678, 2.4],
  385: [3.9675, 4.05, 4.2325, 4.585, 4.6825, 2.4],
  386: [3.969, 4.054, 4.237, 4.592, 4.687, 2.4],
  387: [3.9705, 4.058, 4.2415, 4.599, 4.6915, 2.4],
  388: [3.972, 4.062, 4.246, 4.606, 4.696, 2.4],
  389: [3.9735, 4.066, 4.2505, 4.613, 4.7005, 2.4],
  390: [3.975, 4.07, 4.255, 4.62, 4.705, 2.4],
  391: [3.9765, 4.074, 4.2595, 4.627, 4.7095, 2.4],
  392: [3.978, 4.078, 4.264, 4.634, 4.714, 2.4],
  393: [3.9795, 4.082, 4.2685, 4.641, 4.7185, 2.4],
  394: [3.981, 4.086, 4.273, 4.648, 4.723, 2.4],
  395: [3.9825, 4.09, 4.2775, 4.655, 4.7275, 2.4],
  396: [3.984, 4.094, 4.282, 4.662, 4.732, 2.4],
  397: [3.9855, 4.098, 4.2865, 4.669, 4.7365, 2.4],
  398: [3.987, 4.102, 4.291, 4.676, 4.741, 2.4],
  399: [3.9885, 4.106, 4.2955, 4.683, 4.7455, 2.4],
  400: [3.99, 4.11, 4.3, 4.69, 4.75, 2.4],
  401: [3.9915, 4.112, 4.302, 4.692, 4.752, 2.4],
  402: [3.993, 4.114, 4.304, 4.694, 4.754, 2.4],
  403: [3.9945, 4.116, 4.306, 4.696, 4.756, 2.4],
  404: [3.996, 4.118, 4.308, 4.698, 4.758, 2.4],
  405: [3.9975, 4.12, 4.31, 4.7, 4.76, 2.4],
  406: [3.999, 4.122, 4.312, 4.702, 4.762, 2.4],
  407: [4.0005, 4.124, 4.314, 4.704, 4.764, 2.4],
  408: [4.002, 4.126, 4.316, 4.706, 4.766, 2.4],
  409: [4.0035, 4.128, 4.318, 4.708, 4.768, 2.4],
  410: [4.005, 4.13, 4.32, 4.71, 4.77, 2.4],
  411: [4.0065, 4.132, 4.322, 4.712, 4.772, 2.4],
  412: [4.008, 4.134, 4.324, 4.714, 4.774, 2.4],
  413: [4.0095, 4.136, 4.326, 4.716, 4.776, 2.4],
  414: [4.011, 4.138, 4.328, 4.718, 4.778, 2.4],
  415: [4.0125, 4.14, 4.33, 4.72, 4.78, 2.4],
  416: [4.014, 4.142, 4.332, 4.722, 4.782, 2.4],
  417: [4.0155, 4.144, 4.334, 4.724, 4.784, 2.4],
  418: [4.017, 4.146, 4.336, 4.726, 4.786, 2.4],
  419: [4.0185, 4.148, 4.338, 4.728, 4.788, 2.4],
  420: [4.02, 4.15, 4.34, 4.73, 4.79, 2.4],
  421: [4.023, 4.1525, 4.3425, 4.7325, 4.7925, 2.4],
  422: [4.026, 4.155, 4.345, 4.735, 4.795, 2.4],
  423: [4.029, 4.1575, 4.3475, 4.7375, 4.7975, 2.4],
  424: [4.032, 4.16, 4.35, 4.74, 4.8, 2.4],
  425: [4.035, 4.1625, 4.3525, 4.7425, 4.8025, 2.4],
  426: [4.038, 4.165, 4.355, 4.745, 4.805, 2.4],
  427: [4.041, 4.1675, 4.3575, 4.7475, 4.8075, 2.4],
  428: [4.044, 4.17, 4.36, 4.75, 4.81, 2.4],
  429: [4.047, 4.1725, 4.3625, 4.7525, 4.8125, 2.4],
  430: [4.05, 4.175, 4.365, 4.755, 4.815, 2.4],
  431: [4.053, 4.1775, 4.3675, 4.7575, 4.8175, 2.4],
  432: [4.056, 4.18, 4.37, 4.76, 4.82, 2.4],
  433: [4.059, 4.1825, 4.3725, 4.7625, 4.8225, 2.4],
  434: [4.062, 4.185, 4.375, 4.765, 4.825, 2.4],
  435: [4.065, 4.1875, 4.3775, 4.7675, 4.8275, 2.4],
  436: [4.068, 4.19, 4.38, 4.77, 4.83, 2.4],
  437: [4.071, 4.1925, 4.3825, 4.7725, 4.8325, 2.4],
  438: [4.074, 4.195, 4.385, 4.775, 4.835, 2.4],
  439: [4.077, 4.1975, 4.3875, 4.7775, 4.8375, 2.4],
  440: [4.08, 4.2, 4.39, 4.78, 4.84, 2.4],
  441: [4.0845, 4.2045, 4.3945, 4.7845, 4.8445, 2.4],
  442: [4.089, 4.209, 4.399, 4.789, 4.849, 2.4],
  443: [4.0935, 4.2135, 4.4035, 4.7935, 4.8535, 2.4],
  444: [4.098, 4.218, 4.408, 4.798, 4.858, 2.4],
  445: [4.1025, 4.2225, 4.4125, 4.8025, 4.8625, 2.4],
  446: [4.107, 4.227, 4.417, 4.807, 4.867, 2.4],
  447: [4.1115, 4.2315, 4.4215, 4.8115, 4.8715, 2.4],
  448: [4.116, 4.236, 4.426, 4.816, 4.876, 2.4],
  449: [4.1205, 4.2405, 4.4305, 4.8205, 4.8805, 2.4],
  450: [4.125, 4.245, 4.435, 4.825, 4.885, 2.4],
  451: [4.1295, 4.2495, 4.4395, 4.8295, 4.8895, 2.4],
  452: [4.134, 4.254, 4.444, 4.834, 4.894, 2.4],
  453: [4.1385, 4.2585, 4.4485, 4.8385, 4.8985, 2.4],
  454: [4.143, 4.263, 4.453, 4.843, 4.903, 2.4],
  455: [4.1475, 4.2675, 4.4575, 4.8475, 4.9075, 2.4],
  456: [4.152, 4.272, 4.462, 4.852, 4.912, 2.4],
  457: [4.1565, 4.2765, 4.4665, 4.8565, 4.9165, 2.4],
  458: [4.161, 4.281, 4.471, 4.861, 4.921, 2.4],
  459: [4.1655, 4.2855, 4.4755, 4.8655, 4.9255, 2.4],
  460: [4.17, 4.29, 4.48, 4.87, 4.93, 2.4],
  461: [4.1755, 4.2955, 4.4855, 4.8755, 4.9355, 2.4],
  462: [4.181, 4.301, 4.491, 4.881, 4.941, 2.4],
  463: [4.1865, 4.3065, 4.4965, 4.8865, 4.9465, 2.4],
  464: [4.192, 4.312, 4.502, 4.892, 4.952, 2.4],
  465: [4.1975, 4.3175, 4.5075, 4.8975, 4.9575, 2.4],
  466: [4.203, 4.323, 4.513, 4.903, 4.963, 2.4],
  467: [4.2085, 4.3285, 4.5185, 4.9085, 4.9685, 2.4],
  468: [4.214, 4.334, 4.524, 4.914, 4.974, 2.4],
  469: [4.2195, 4.3395, 4.5295, 4.9195, 4.9795, 2.4],
  470: [4.225, 4.345, 4.535, 4.925, 4.985, 2.4],
  471: [4.2305, 4.3505, 4.5405, 4.9305, 4.9905, 2.4],
  472: [4.236, 4.356, 4.546, 4.936, 4.996, 2.4],
  473: [4.2415, 4.3615, 4.5515, 4.9415, 5.0015, 2.4],
  474: [4.247, 4.367, 4.557, 4.947, 5.007, 2.4],
  475: [4.2525, 4.3725, 4.5625, 4.9525, 5.0125, 2.4],
  476: [4.258, 4.378, 4.568, 4.958, 5.018, 2.4],
  477: [4.2635, 4.3835, 4.5735, 4.9635, 5.0235, 2.4],
  478: [4.269, 4.389, 4.579, 4.969, 5.029, 2.4],
  479: [4.2745, 4.3945, 4.5845, 4.9745, 5.0345, 2.4],
  480: [4.28, 4.4, 4.59, 4.98, 5.04, 2.4],
  481: [4.2865, 4.4065, 4.5965, 4.9865, 5.0465, 2.4],
  482: [4.293, 4.413, 4.603, 4.993, 5.053, 2.4],
  483: [4.2995, 4.4195, 4.6095, 4.9995, 5.0595, 2.4],
  484: [4.306, 4.426, 4.616, 5.006, 5.066, 2.4],
  485: [4.3125, 4.4325, 4.6225, 5.0125, 5.0725, 2.4],
  486: [4.319, 4.439, 4.629, 5.019, 5.079, 2.4],
  487: [4.3255, 4.4455, 4.6355, 5.0255, 5.0855, 2.4],
  488: [4.332, 4.452, 4.642, 5.032, 5.092, 2.4],
  489: [4.3385, 4.4585, 4.6485, 5.0385, 5.0985, 2.4],
  490: [4.345, 4.465, 4.655, 5.045, 5.105, 2.4],
  491: [4.3515, 4.4715, 4.6615, 5.0515, 5.1115, 2.4],
  492: [4.358, 4.478, 4.668, 5.058, 5.118, 2.4],
  493: [4.3645, 4.4845, 4.6745, 5.0645, 5.1245, 2.4],
  494: [4.371, 4.491, 4.681, 5.071, 5.131, 2.4],
  495: [4.3775, 4.4975, 4.6875, 5.0775, 5.1375, 2.4],
  496: [4.384, 4.504, 4.694, 5.084, 5.144, 2.4],
  497: [4.3905, 4.5105, 4.7005, 5.0905, 5.1505, 2.4],
  498: [4.397, 4.517, 4.707, 5.097, 5.157, 2.4],
  499: [4.4035, 4.5235, 4.7135, 5.1035, 5.1635, 2.4],
  500: [4.41, 4.53, 4.72, 5.11, 5.17, 2.4],
  501: [4.4165, 4.5365, 4.7265, 5.1165, 5.1765, 2.4],
  502: [4.423, 4.543, 4.733, 5.123, 5.183, 2.4],
  503: [4.4295, 4.5495, 4.7395, 5.1295, 5.1895, 2.4],
  504: [4.436, 4.556, 4.746, 5.136, 5.196, 2.4],
  505: [4.4425, 4.5625, 4.7525, 5.1425, 5.2025, 2.4],
  506: [4.449, 4.569, 4.759, 5.149, 5.209, 2.4],
  507: [4.4555, 4.5755, 4.7655, 5.1555, 5.2155, 2.4],
  508: [4.462, 4.582, 4.772, 5.162, 5.222, 2.4],
  509: [4.4685, 4.5885, 4.7785, 5.1685, 5.2285, 2.4],
  510: [4.475, 4.595, 4.785, 5.175, 5.235, 2.4],
  511: [4.4815, 4.6015, 4.7915, 5.1815, 5.2415, 2.4],
  512: [4.488, 4.608, 4.798, 5.188, 5.248, 2.4],
  513: [4.4945, 4.6145, 4.8045, 5.1945, 5.2545, 2.4],
  514: [4.501, 4.621, 4.811, 5.201, 5.261, 2.4],
  515: [4.5075, 4.6275, 4.8175, 5.2075, 5.2675, 2.4],
  516: [4.514, 4.634, 4.824, 5.214, 5.274, 2.4],
  517: [4.5205, 4.6405, 4.8305, 5.2205, 5.2805, 2.4],
  518: [4.527, 4.647, 4.837, 5.227, 5.287, 2.4],
  519: [4.5335, 4.6535, 4.8435, 5.2335, 5.2935, 2.4],
  520: [4.54, 4.66, 4.85, 5.24, 5.3, 2.4],
  521: [4.5455, 4.666, 4.856, 5.246, 5.306, 2.4],
  522: [4.551, 4.672, 4.862, 5.252, 5.312, 2.4],
  523: [4.5565, 4.678, 4.868, 5.258, 5.318, 2.4],
  524: [4.562, 4.684, 4.874, 5.264, 5.324, 2.4],
  525: [4.5675, 4.69, 4.88, 5.27, 5.33, 2.4],
  526: [4.573, 4.696, 4.886, 5.276, 5.336, 2.4],
  527: [4.5785, 4.702, 4.892, 5.282, 5.342, 2.4],
  528: [4.584, 4.708, 4.898, 5.288, 5.348, 2.4],
  529: [4.5895, 4.714, 4.904, 5.294, 5.354, 2.4],
  530: [4.595, 4.72, 4.91, 5.3, 5.36, 2.4],
  531: [4.6005, 4.726, 4.916, 5.306, 5.366, 2.4],
  532: [4.606, 4.732, 4.922, 5.312, 5.372, 2.4],
  533: [4.6115, 4.738, 4.928, 5.318, 5.378, 2.4],
  534: [4.617, 4.744, 4.934, 5.324, 5.384, 2.4],
  535: [4.6225, 4.75, 4.94, 5.33, 5.39, 2.4],
  536: [4.628, 4.756, 4.946, 5.336, 5.396, 2.4],
  537: [4.6335, 4.762, 4.952, 5.342, 5.402, 2.4],
  538: [4.639, 4.768, 4.958, 5.348, 5.408, 2.4],
  539: [4.6445, 4.774, 4.964, 5.354, 5.414, 2.4],
  540: [4.65, 4.78, 4.97, 5.36, 5.42, 2.4],
  541: [4.6545, 4.784, 4.974, 5.364, 5.424, 2.4],
  542: [4.659, 4.788, 4.978, 5.368, 5.428, 2.4],
  543: [4.6635, 4.792, 4.982, 5.372, 5.432, 2.4],
  544: [4.668, 4.796, 4.986, 5.376, 5.436, 2.4],
  545: [4.6725, 4.8, 4.99, 5.38, 5.44, 2.4],
  546: [4.677, 4.804, 4.994, 5.384, 5.444, 2.4],
  547: [4.6815, 4.808, 4.998, 5.388, 5.448, 2.4],
  548: [4.686, 4.812, 5.002, 5.392, 5.452, 2.4],
  549: [4.6905, 4.816, 5.006, 5.396, 5.456, 2.4],
  550: [4.695, 4.82, 5.01, 5.4, 5.46, 2.4],
  551: [4.6995, 4.824, 5.014, 5.404, 5.464, 2.4],
  552: [4.704, 4.828, 5.018, 5.408, 5.468, 2.4],
  553: [4.7085, 4.832, 5.022, 5.412, 5.472, 2.4],
  554: [4.713, 4.836, 5.026, 5.416, 5.476, 2.4],
  555: [4.7175, 4.84, 5.03, 5.42, 5.48, 2.4],
  556: [4.722, 4.844, 5.034, 5.424, 5.484, 2.4],
  557: [4.7265, 4.848, 5.038, 5.428, 5.488, 2.4],
  558: [4.731, 4.852, 5.042, 5.432, 5.492, 2.4],
  559: [4.7355, 4.856, 5.046, 5.436, 5.496, 2.4],
  560: [4.74, 4.86, 5.05, 5.44, 5.5, 2.4],
  561: [4.7425, 4.8625, 5.0525, 5.4425, 5.5025, 2.4],
  562: [4.745, 4.865, 5.055, 5.445, 5.505, 2.4],
  563: [4.7475, 4.8675, 5.0575, 5.4475, 5.5075, 2.4],
  564: [4.75, 4.87, 5.06, 5.45, 5.51, 2.4],
  565: [4.7525, 4.8725, 5.0625, 5.4525, 5.5125, 2.4],
  566: [4.755, 4.875, 5.065, 5.455, 5.515, 2.4],
  567: [4.7575, 4.8775, 5.0675, 5.4575, 5.5175, 2.4],
  568: [4.76, 4.88, 5.07, 5.46, 5.52, 2.4],
  569: [4.7625, 4.8825, 5.0725, 5.4625, 5.5225, 2.4],
  570: [4.765, 4.885, 5.075, 5.465, 5.525, 2.4],
  571: [4.7675, 4.8875, 5.0775, 5.4675, 5.5275, 2.4],
  572: [4.77, 4.89, 5.08, 5.47, 5.53, 2.4],
  573: [4.7725, 4.8925, 5.0825, 5.4725, 5.5325, 2.4],
  574: [4.775, 4.895, 5.085, 5.475, 5.535, 2.4],
  575: [4.7775, 4.8975, 5.0875, 5.4775, 5.5375, 2.4],
  576: [4.78, 4.9, 5.09, 5.48, 5.54, 2.4],
  577: [4.7825, 4.9025, 5.0925, 5.4825, 5.5425, 2.4],
  578: [4.785, 4.905, 5.095, 5.485, 5.545, 2.4],
  579: [4.7875, 4.9075, 5.0975, 5.4875, 5.5475, 2.4],
  580: [4.79, 4.91, 5.1, 5.49, 5.55, 2.4],
  581: [4.7915, 4.9115, 5.1015, 5.4915, 5.5515, 2.4],
  582: [4.793, 4.913, 5.103, 5.493, 5.553, 2.4],
  583: [4.7945, 4.9145, 5.1045, 5.4945, 5.5545, 2.4],
  584: [4.796, 4.916, 5.106, 5.496, 5.556, 2.4],
  585: [4.7975, 4.9175, 5.1075, 5.4975, 5.5575, 2.4],
  586: [4.799, 4.919, 5.109, 5.499, 5.559, 2.4],
  587: [4.8005, 4.9205, 5.1105, 5.5005, 5.5605, 2.4],
  588: [4.802, 4.922, 5.112, 5.502, 5.562, 2.4],
  589: [4.8035, 4.9235, 5.1135, 5.5035, 5.5635, 2.4],
  590: [4.805, 4.925, 5.115, 5.505, 5.565, 2.4],
  591: [4.8065, 4.9265, 5.1165, 5.5065, 5.5665, 2.4],
  592: [4.808, 4.928, 5.118, 5.508, 5.568, 2.4],
  593: [4.8095, 4.9295, 5.1195, 5.5095, 5.5695, 2.4],
  594: [4.811, 4.931, 5.121, 5.511, 5.571, 2.4],
  595: [4.8125, 4.9325, 5.1225, 5.5125, 5.5725, 2.4],
  596: [4.814, 4.934, 5.124, 5.514, 5.574, 2.4],
  597: [4.8155, 4.9355, 5.1255, 5.5155, 5.5755, 2.4],
  598: [4.817, 4.937, 5.127, 5.517, 5.577, 2.4],
  599: [4.8185, 4.9385, 5.1285, 5.5185, 5.5785, 2.4],
  600: [4.82, 4.94, 5.13, 5.52, 5.58, 2.4],
}
