import axios from '@axios'
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { SlackAlert } from '@/@core/utils/utils'
import { getStats } from './calculationHelpers'
import getStatsPlayer from '../dashboard/calculationHelpers'
import router from '../../router'
import configuration from '../../views/spider/stats/config'

export default {
  namespaced: true,
  state: {
    dashBench: 'Men -6-3',
    comparaison: false,
    fullData: false,
    players: [],
    groups: [],
    selectedPlayer: null,
    stats: null,
    playerStats: null,
    filteredData: null,
    trends: null,
    filteredTrends: null,
    allData: null,
    units: 'metric',
    loadingData: true,
    strokeGain: null,
    benchmarks: [
      'Men -6-3', 'Men -3-0', 'Men 0-3', 'Men 3-6', 'Men 6-9', 'Men 9-12', 'Men 12-15', 'Men 15-20', 'Men 20-25', 'Men 25-30', 'Men Mini Tours', 'Men Tours',
      'Women -6-3', 'Women -3-0', 'Women 0-3', 'Women 3-6', 'Women 6-9', 'Women 9-12', 'Women 12-15', 'Women 15-20', 'Women 20-25', 'Women 25-30', 'Women Mini Tours', 'Women Tours',
    ],
    playerBenchmarks: [
      'Hcp: +6-+3', 'Hcp: +3-0', 'Hcp: 0-3', 'Hcp: 3-6', 'Hcp: 6-9', 'Hcp: 9-12', 'Hcp: 12-15', 'Mini-Tour', 'PGA Tour',
    ],
    playerBenchmark: 0,
    benchmark: 11,
    selectedGroup: null,
  },
  getters: {},
  mutations: {
    translateBench(state) {
      /* eslint-disable */
      state.benchmarks = [
        this._vm.$t('Men -6-3'),
        this._vm.$t('Men -3-0'),
        this._vm.$t('Men 0-3'),
        this._vm.$t('Men 3-6'),
        this._vm.$t('Men 6-9'),
        this._vm.$t('Men 9-12'),
        this._vm.$t('Men 12-15'),
        this._vm.$t('Men 15-20'),
        this._vm.$t('Men 20-25'),
        this._vm.$t('Men 25-30'),
        this._vm.$t('Men Mini Tours'),
        this._vm.$t('Men Tours'),
        this._vm.$t('Women -6-3'),
        this._vm.$t('Women -3-0'),
        this._vm.$t('Women 0-3'),
        this._vm.$t('Women 3-6'),
        this._vm.$t('Women 6-9'),
        this._vm.$t('Women 9-12'),
        this._vm.$t('Women 12-15'),
        this._vm.$t('Women 15-20'),
        this._vm.$t('Women 20-25'),
        this._vm.$t('Women 25-30'),
        this._vm.$t('Women Mini Tours'),
        this._vm.$t('Women Tours'),
      ]
    },
    setSelectedGroup(state, value) {
      state.selectedGroup = value
    },
    setDashBench(state, value) {
      state.dashBench = value
    },
    comparaisonOn(state) {
      state.comparaison = true
    },
    comparaisonOff(state) {
      state.comparaison = false
    },
    comparaisonToggle(state) {
      state.comparaison = !state.comparaison
    },
    setUnits(state, value) {
      state.units = value === 'metric' ? 'metric' : 'imperial'
    },
    /* eslint-disable no-param-reassign */
    setPlayers(state, obj) {
      state.players = obj.map((item, index) => {
        item.rank = index + 1
        return item
      })
    },
    setGroups(state, obj) {
      state.groups = obj.map(item => {
        if(state.players.length > 0) {
          // Filter players are existing
          const playerIds = state.players.map(player => player.id)
          item.players = item.players.filter(player => playerIds.includes(player))
        }
        item.players_number = item.players.length
        return item
      })
      state.groups.push({
        coach: '',
        id: -1,
        name: '',
        players: [],
      })
    },
    /* eslint-enable no-param-reassign */
    addPlayerToGroup(state, { playerId, groupId }) {
      const updatedGroup = state.groups.find(obj => obj.id === groupId)
      updatedGroup.players.push(playerId)
    },
    removePlayerFromGroup(state, { playerId, groupId }) {
      const updatedGroup = state.groups.find(obj => obj.id === groupId)
      const index = updatedGroup.players.indexOf(+playerId)
      if (index > -1) {
        updatedGroup.players.splice(index, 1)
      }
    },
    updateGroupCoach(state, { groupId, coachName }) {
      const updatedGroup = state.groups.find(obj => obj.id === groupId)
      updatedGroup.coach = coachName
    },
    selectPlayer(state, id) {
      if (state.selectedPlayer === id) {
        state.selectedPlayer = null
      } else {
        state.selectedPlayer = id
      }
    },
    clearData(state) {
      state.players = []
      state.groups = []
      state.selectedPlayer = null
      state.stats = []
    },
    loadingDone(state) {
      state.loadingData = false
    },
    loading(state) {
      state.loadingData = true
    },
    updateStatistics(state) {
      if (state.filteredData) {
        state.stats = state.filteredData.map(round => {
          try {
            const stats = getStats(round.rounds, state.units)
            return {
              player: round.player,
              stats,
            }
          } catch (error) {
            SlackAlert(round.player.id, error);
          }
        })
      } else {
        state.stats = state.allData.map(round => {
          try {
            const stats = getStats(round.rounds, state.units)
            return {
              player: round.player,
              stats,
            }
          } catch (error) {
            SlackAlert(round.player.id, error);
          }
        })
      }
      if (state.filteredTrends) {
        state.trends = state.filteredTrends.map(round => {
          try {
            const stats = getStats(round.rounds, state.units)
            return {
              player: round.player,
              stats,
            }
          } catch (error) {
            SlackAlert(round.player.id, error);
          }
        })
      } else {
        state.trends = state.stats
      }
    },
    updateplayerStats(state, playerId) {
      if (state.filteredData) {
        const data = state.filteredData.find(item => item.player.id === playerId)
        if (data === undefined) {
          state.playerStats = []
        } else {
          state.playerStats = data.rounds.map(r => {
            const final = {
              date: r.dateRound,
              stats: getStatsPlayer([r], state.units, state.playerBenchmark),
            }
            return final
          })
        }
      } else {
        const data = state.allData.find(item => item.player.id === playerId)
        if (data === undefined) {
          state.playerStats = []
        } else {
          state.playerStats = data.rounds.map(r => {
            const final = {
              date: r.dateRound,
              stats: getStatsPlayer([r], state.units, state.playerBenchmark),
            }
            return final
          })
        }
      }
    },
    updateGraph(state, { title, key1, key2 }) {
      const bench = configuration
        .strokesGained[0].tables
        .concat(configuration.strokesGained[1].tables)
        .concat(configuration.strokesGained[2].tables)
        .find(item => item.key === key2).benchmarks

      if (state.playerStats === null) {
        state.strokeGain = []
      }
      state.strokeGain = [{
        name: title,
        data: state.playerStats.map(item => {
          const r = {
            x: item.date,
            y: (Array.isArray(item.stats[key1][key2]) ? item.stats[key1][key2][0] : item.stats[key1][key2]) - bench[state.benchmark] + bench[state.benchmarks.indexOf('Men Tours')],
            course: item.stats.info.course,
            fw: Math.round(item.stats.offTee.fairwaysPercentage),
            gir: Math.round(item.stats.approach.girPercentage),
            putts: Math.round(item.stats.onGreen.totalPutts),
            title: title
          }
          return r
        }).sort((a, b) => Date.parse(a.x) - Date.parse(b.x)).filter(item => !Number.isNaN(item.y)),
      }]
    },
    saveNew(state, obj) {
      state.allData = state.players.map(p => {
        const result = {
          rounds: obj.data.filter(item => item.player === p.id).map(r => {
            const shots = r.shots.map(s => {
              const l = {
                round: s.round,
                hole: s.hole,
                shotNumber: s.shotnumber,
                surface: s.surface,
                club: s.club,
                attackGreen: s.attackgreen,
                quality: s.quality,
                x: s.x,
                y: s.y,
                distanceToHole: s.distancetohole,
                well_read: s.well_read,
                slope: s.slope,
                shotDistanceSurface: s.shotdistancesurface,
                shotDistance: s.shotdistance,
                visible: s.visible,
                routine: s.routine
              }
              return l
            })
            const f = {
              id: r.id,
              course: r.course,
              teebox: r.teebox,
              pars: r.pars,
              length: r.length,
              wind: r.wind,
              greenspeed: r.greenspeed,
              rain: r.rain,
              pointsGiven: r.pointsgiven,
              handicap: r.handicap,
              handicapPlayed: r.handicapplayed,
              score: r.score,
              putts: r.putts,
              fairways: r.fairways,
              dateRound: r.date,
              competition: r.competition,
              played: r.played,
              shotByShot: r.shotbyshot,
              aimPoint: r.aimpoint,
              validated: r.validated,
              sumScore: r.sumscore,
              shots: {
                1: shots.filter(s => s.hole === 1),
                2: shots.filter(s => s.hole === 2),
                3: shots.filter(s => s.hole === 3),
                4: shots.filter(s => s.hole === 4),
                5: shots.filter(s => s.hole === 5),
                6: shots.filter(s => s.hole === 6),
                7: shots.filter(s => s.hole === 7),
                8: shots.filter(s => s.hole === 8),
                9: shots.filter(s => s.hole === 9),
                10: shots.filter(s => s.hole === 10),
                11: shots.filter(s => s.hole === 11),
                12: shots.filter(s => s.hole === 12),
                13: shots.filter(s => s.hole === 13),
                14: shots.filter(s => s.hole === 14),
                15: shots.filter(s => s.hole === 15),
                16: shots.filter(s => s.hole === 16),
                17: shots.filter(s => s.hole === 17),
                18: shots.filter(s => s.hole === 18),
              },
            }
            return f
          }),
          player: {
            id: p.id,
            name: p.name,
            firstname: p.firstname,
            fullName: `${p.firstname} ${p.name}`,
          },
        }
        return result
      }).filter(o => o.rounds.length !== 0)
    },
    filterData(state, {
      greenspeed,
      rain,
      wind,
      temperature,
      holesNumber,
      qualification,
      competition,
      shotByShot,
      roundType,
      selectedPlayers,
      selectedGroup,
      startDate,
      endDate,
    }) {
      let playerFiltering = false
      if (Array.isArray(selectedPlayers) && selectedPlayers.length) {
        playerFiltering = selectedPlayers
      } else if (selectedGroup !== null) {
        playerFiltering = state.players.filter(item => selectedGroup.players.includes(item.id)).map(item => item.id)
      }

      state.filteredData = state.allData.filter(rounds => {
        const players = playerFiltering ? playerFiltering.includes(rounds.player.id) : true
        return players
      }).map(rounds => {
        const result = {
          player: rounds.player,
          rounds: rounds.rounds.filter(r => {
            const hn = r.played.filter(item => item !== '0').length === holesNumber
            if (!hn) {
              return false
            }
            const gs = greenspeed === -1 ? true : greenspeed === r.greenspeed
            if (!gs) {
              return false
            }
            const rn = rain === -1 ? true : rain === r.rain
            if (!rn) {
              return false
            }
            const wd = wind === -1 ? true : wind === r.wind
            if (!wd) {
              return false
            }
            const tp = temperature === null ? true : temperature === r.weather
            if (!tp) {
              return false
            }
            const sbs = shotByShot === r.shotByShot
            if (!sbs) {
              return false
            }
            const cpt = qualification === -1 ? true : qualification === r.competition
            if (!cpt) {
              return false
            }
            if (qualification === 2) {
              const compRounds = competition === -1 ? true : competition === r.competitionRound
              if (!compRounds) {
                return false
              }
            }
            const ds = startDate === '' ? true : Date.parse(startDate) <= Date.parse(r.dateRound)
            if (!ds) {
              return false
            }
            const de = endDate === '' ? true : Date.parse(endDate) >= Date.parse(r.dateRound)
            if (!de) {
              return false
            }
            return true
          }),
        }
        return result
      }).filter(rounds => rounds.rounds.length)

      if (roundType === 'worst_5') {
        state.filteredData = state.filteredData.map(rounds => {
          const result = {
            player: rounds.player,
            rounds: rounds.rounds.sort((a, b) => b.sumScore - a.sumScore).slice(0, 5),
          }
          return result
        }).filter(rounds => rounds.rounds.length)
      } else if (roundType === 'best_5') {
        state.filteredData = state.filteredData.map(rounds => {
          const result = {
            player: rounds.player,
            rounds: rounds.rounds.sort((a, b) => a.sumScore - b.sumScore).slice(0, 5),
          }
          return result
        }).filter(rounds => rounds.rounds.length)
      } else if (roundType !== null && roundType.startsWith('last')) {
        state.filteredData = state.filteredData.map(rounds => {
          const result = {
            player: rounds.player,
            rounds: rounds.rounds.sort((a, b) => Date.parse(b.dateRound) - Date.parse(a.dateRound)).slice(0, +(roundType.slice(5))),
          }
          return result
        }).filter(rounds => rounds.rounds.length)
      }
    },
    filterTrends(state, {
      greenspeed,
      rain,
      wind,
      temperature,
      holesNumber,
      qualification,
      competition,
      shotByShot,
      roundType,
      selectedPlayers,
      // selectedGroup,
      startDate,
      endDate,
    }) {
      state.filteredTrends = state.allData.filter(rounds => {
        const players = (Array.isArray(selectedPlayers) && selectedPlayers.length) ? selectedPlayers.includes(rounds.player.id) : true
        return players
      }).map(rounds => {
        const result = {
          player: rounds.player,
          rounds: rounds.rounds.filter(r => {
            const hn = r.played.filter(item => item !== '0').length === holesNumber
            if (!hn) {
              return false
            }
            const gs = greenspeed === -1 ? true : greenspeed === r.greenspeed
            if (!gs) {
              return false
            }
            const rn = rain === -1 ? true : rain === r.rain
            if (!rn) {
              return false
            }
            const wd = wind === -1 ? true : wind === r.wind
            if (!wd) {
              return false
            }
            const tp = temperature === null ? true : temperature === r.weather
            if (!tp) {
              return false
            }
            const sbs = shotByShot === r.shotByShot
            if (!sbs) {
              return false
            }
            const cpt = qualification === -1 ? true : qualification === r.competition
            if (!cpt) {
              return false
            }
            if (qualification === 2) {
              const compRounds = competition === -1 ? true : competition === r.competitionRound
              if (!compRounds) {
                return false
              }
            }
            const ds = startDate === '' ? true : Date.parse(startDate) <= Date.parse(r.dateRound)
            if (!ds) {
              return false
            }
            const de = endDate === '' ? true : Date.parse(endDate) >= Date.parse(r.dateRound)
            if (!de) {
              return false
            }
            return true
          }),
        }
        return result
      }).filter(rounds => rounds.rounds.length)

      if (roundType === 'worst_5') {
        state.filteredData = state.filteredData.map(rounds => {
          const result = {
            player: rounds.player,
            rounds: rounds.rounds.sort((a, b) => b.sumScore - a.sumScore).slice(0, 5),
          }
          return result
        }).filter(rounds => rounds.rounds.length)
      } else if (roundType === 'best_5') {
        state.filteredData = state.filteredData.map(rounds => {
          const result = {
            player: rounds.player,
            rounds: rounds.rounds.sort((a, b) => a.sumScore - b.sumScore).slice(0, 5),
          }
          return result
        }).filter(rounds => rounds.rounds.length)
      } else if (roundType !== null && roundType.startsWith('last')) {
        state.filteredData = state.filteredData.map(rounds => {
          const result = {
            player: rounds.player,
            rounds: rounds.rounds.sort((a, b) => Date.parse(b.dateRound) - Date.parse(a.dateRound)).slice(0, +(roundType.slice(5))),
          }
          return result
        }).filter(rounds => rounds.rounds.length)
      }
    },
  },
  actions: {
    fetchPlayers() {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/coach-players/')
          .then(response => resolve(response))
          .catch(error => {
            reject(error)
          })
      })
    },
    updatePlayers({ dispatch, commit }) {
      return dispatch('fetchPlayers').then(response => {
        commit('setPlayers', response.data)
      })
        .catch(() => {
          dispatch('redirectToLogin')
        })
    },
    fetchGroups() {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/groups/')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateGroups({ dispatch, commit }) {
      return dispatch('fetchGroups').then(response => {
        commit('setGroups', response.data)
      })
        .catch(() => {
          dispatch('redirectToLogin')
        })
    },
    setGroup(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/groups/', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteGroup(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/groups/${payload}/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateGroupData(ctx, groupId) {
      return new Promise((resolve, reject) => {
        const updatedGroup = ctx.state.groups.find(obj => obj.id === groupId)
        if (groupId !== -1) {
          axios
            .put(
              `/api/groups/${groupId}/`,
              updatedGroup,
            )
            .then(response => resolve(response))
            .catch(error => reject(error))
        } else {
          axios
            .post(
              '/api/groups/',
              updatedGroup,
            )
            .then(response => resolve(response))
            .catch(error => reject(error))
        }
      })
    },
    getRounds({ dispatch }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/rounds/')
          .then(response => resolve(response))
          .catch(error => {
            dispatch('redirectToLogin')
            reject(error)
          })
      })
    },
    updateRounds({ dispatch, commit, state }) {
      return dispatch('getRounds').then(rep => {
        commit('saveNew', rep)
      })
    },
    updateStats({ dispatch, commit, state }, { filters, trends }) {
      if (!state.allData) {
        state.loadingData = true
        return dispatch('getRounds').then(rep => {
          commit('saveNew', rep)
          if (filters !== null) {
            commit('filterData', filters)
          }
          if (trends !== null) {
            commit('filterTrends', trends)
          }
          commit('updateStatistics')
          commit('loadingDone')
        })
          .catch(() => {
            commit('loadingDone')
            // dispatch('redirectToLogin')
          })
      }
      if (!state.stats) {
        return dispatch('computeStats', { filters, trends })
      }
      commit('loadingDone')
      return true
    },
    computeStats({ commit }, { filters, trends }) {
      return new Promise((resolve, reject) => {
        if (filters !== null) {
          commit('filterData', filters)
        }
        if (trends !== null) {
          commit('filterTrends', trends)
        }
        commit('updateStatistics')
        commit('loadingDone')
        resolve()
        reject()
      })
    },
    redirectToLogin() {
      /* eslint-disable */
      this._vm.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'You have been logged out',
          icon: 'SlashIcon',
          variant: 'danger',
          text: 'Please refresh your login.',
        },
      })
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      // Reset ability
      // this.$ability.update(initialAbility)
      router.push({ name: 'auth-login' })
    },
  },
}
