import Vue from 'vue'
import Vuex from 'vuex'
// import VuexPersistence from 'vuex-persist'

// Modules
// import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import spider from './spider'
// import dashboard from './dashboard'

Vue.use(Vuex)

// const vuexLocal = new VuexPersistence({
//   storage: window.localStorage,
//   reducer: state => ({
//     user: state.spider,
//   }),
//   filter: mutation => [
//     'spider/updateStatistics',
//   ].includes(mutation.type),
// })

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    spider,
    // dashboard,
    // 'app-ecommerce': ecommerceStoreModule,
  },
  // strict: process.env.DEV,
  // plugins: [vuexLocal.plugin],
})
