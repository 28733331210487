export default [
  {
    path: '/input-redeem-codes',
    name: 'input-redeem-codes',
    component: () => import('@/views/spider/general/RedeemCodes.vue'),
    meta: {
      resource: 'player',
      action: 'read',
    },
  },
  {
    path: '/generate-redeem-codes',
    name: 'generate-redeem-codes',
    component: () => import('@/views/spider/general/GenerateCodes.vue'),
    meta: {
      resource: 'admin',
      action: 'read',
    },
  },
]
