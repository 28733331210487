export default [
  {
    path: '/subscription',
    name: 'subscription',
    component: () => import('@/views/spider/subscription/Subscription.vue'),
    meta: {
      resource: 'player',
      action: 'read',
    },
  },
  {
    path: '/subscription/list',
    name: 'subscriptions-list',
    component: () => import('@/views/spider/subscription/SubscriptionList.vue'),
    meta: {
      resource: 'player',
      action: 'read',
    },
  },
]
