export default [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/spider/dashboard/Dashboard.vue'),
    meta: {
      resource: 'player',
      action: 'read',
      contentRenderer: 'sidebar-left-detached',
      contentClass: 'ecommerce-application',
      pageTitle: 'Strokes Gained',
      breadcrumbOff: true,
    },
  },
  {
    path: '/dashboard/:playerId',
    name: 'dashboard-player',
    props(route) {
      const props = { ...route.params }
      props.playerId = +props.playerId
      return props
    },
    component: () => import('@/views/spider/dashboard/Dashboard.vue'),
    meta: {
      navActiveLink: 'dashboard',
      resource: 'coach',
      action: 'read',
      contentRenderer: 'sidebar-left-detached',
      contentClass: 'ecommerce-application',
      pageTitle: 'Strokes Gained',
      breadcrumbOff: true,
    },
  },
]
