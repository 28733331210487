export default [
  {
    path: '/stats/summary',
    name: 'stats-summary',
    component: () => import('@/views/spider/stats/Summary.vue'),
    meta: {
      resource: 'coach',
      action: 'read',
      pageTitle: 'Summary',
      contentRenderer: 'sidebar-left-detached',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Stats',
          active: true,
        },
      ],
    },
  },
  {
    path: '/stats/summary/:groupId',
    name: 'stats-summary-group',
    component: () => import('@/views/spider/stats/Summary.vue'),
    props(route) {
      const props = { ...route.params }
      props.groupId = +props.groupId
      return props
    },
    meta: {
      resource: 'coach',
      action: 'read',
      navActiveLink: 'stats-summary',
      pageTitle: 'Summary',
      contentRenderer: 'sidebar-left-detached',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Stats',
          active: true,
        },
      ],
    },
  },
  {
    path: '/stats/scoring',
    name: 'scoring',
    component: () => import('@/views/spider/stats/WastedShots.vue'),
    props: {
      page: 'scoring',
    },
    meta: {
      resource: 'coach',
      action: 'read',
      pageTitle: 'Scoring',
      contentRenderer: 'sidebar-left-detached',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Stats',
          to: '/stats/summary',
        },
        {
          text: 'Scoring',
          active: true,
        },
      ],
    },
  },
  {
    path: '/stats/off-the-tee',
    name: 'off-the-tee',
    component: () => import('@/views/spider/stats/WastedShots.vue'),
    props: {
      page: 'offTheTee',
    },
    meta: {
      resource: 'coach',
      action: 'read',
      pageTitle: 'Off The Tee',
      contentRenderer: 'sidebar-left-detached',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Stats',
          to: '/stats/summary',
        },
        {
          text: 'Off The Tee',
          active: true,
        },
      ],
    },
  },
  {
    path: '/stats/to-the-green',
    name: 'to-the-green',
    component: () => import('@/views/spider/stats/WastedShots.vue'),
    props: {
      page: 'toTheGreen',
    },
    meta: {
      resource: 'coach',
      action: 'read',
      pageTitle: 'To The Green',
      contentRenderer: 'sidebar-left-detached',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Stats',
          to: '/stats/summary',
        },
        {
          text: 'To The Green',
          active: true,
        },
      ],
    },
  },
  {
    path: '/stats/greenside',
    name: 'greenside',
    component: () => import('@/views/spider/stats/WastedShots.vue'),
    props: {
      page: 'greenSide',
    },
    meta: {
      resource: 'coach',
      action: 'read',
      pageTitle: 'Greenside',
      contentRenderer: 'sidebar-left-detached',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Stats',
          to: '/stats/summary',
        },
        {
          text: 'Greenside',
          active: true,
        },
      ],
    },
  },
  {
    path: '/stats/putting',
    name: 'putting',
    // component: () => import('@/views/spider/stats/Putting.vue'),
    component: () => import('@/views/spider/stats/WastedShots.vue'),
    props: {
      page: 'putting',
    },
    meta: {
      resource: 'coach',
      action: 'read',
      pageTitle: 'Putting',
      contentRenderer: 'sidebar-left-detached',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Stats',
          to: '/stats/summary',
        },
        {
          text: 'Putting',
          active: true,
        },
      ],
    },
  },
  {
    path: '/stats/strokes-gained',
    name: 'strokes-gained',
    // component: () => import('@/views/spider/stats/StrokesGained.vue'),
    component: () => import('@/views/spider/stats/WastedShots.vue'),
    props: {
      page: 'strokesGained',
    },
    meta: {
      resource: 'coach',
      action: 'read',
      pageTitle: 'Strokes Gained',
      contentRenderer: 'sidebar-left-detached',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Stats',
          to: '/stats/summary',
        },
        {
          text: 'Strokes Gained',
          active: true,
        },
      ],
    },
  },
  {
    path: '/stats/spider-proximity',
    name: 'spider-proximity',
    // component: () => import('@/views/spider/stats/StrokesGained.vue'),
    component: () => import('@/views/spider/stats/WastedShots.vue'),
    props: {
      page: 'proximity',
    },
    meta: {
      resource: 'coach',
      action: 'read',
      pageTitle: 'Spider Proximity',
      contentRenderer: 'sidebar-left-detached',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Stats',
          to: '/stats/summary',
        },
        {
          text: 'Spider Proximity',
          active: true,
        },
      ],
    },
  },
  {
    path: '/stats/impact',
    name: 'impact',
    // component: () => import('@/views/spider/stats/Impact.vue'),
    component: () => import('@/views/spider/stats/WastedShots.vue'),
    props: {
      page: 'impact',
    },
    meta: {
      resource: 'coach',
      action: 'read',
      pageTitle: 'Impact',
      contentRenderer: 'sidebar-left-detached',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Stats',
          to: '/stats/summary',
        },
        {
          text: 'Impact',
          active: true,
        },
      ],
    },
  },
  {
    path: '/stats/routine',
    name: 'routine',
    // component: () => import('@/views/spider/stats/Impact.vue'),
    component: () => import('@/views/spider/stats/WastedShots.vue'),
    props: {
      page: 'routine',
    },
    meta: {
      resource: 'player',
      action: 'read',
      pageTitle: 'Routine',
      contentRenderer: 'sidebar-left-detached',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Stats',
          to: '/stats/summary',
        },
        {
          text: 'Routine',
          active: true,
        },
      ],
    },
  },
  {
    path: '/stats/wasted-shots',
    name: 'wasted-shots',
    component: () => import('@/views/spider/stats/WastedShots.vue'),
    meta: {
      resource: 'coach',
      action: 'read',
      pageTitle: 'Wasted Shots',
      contentRenderer: 'sidebar-left-detached',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Stats',
          to: '/stats/summary',
        },
        {
          text: 'Wasted Shots',
          active: true,
        },
      ],
    },
    props: {
      page: 'wastedShots',
    },
  },
]
