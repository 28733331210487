export default {
  scoring: [
    {
      title: 'Scoring',
      tabTitle: 'Averages',
      key: 'scoring',
      tables: [
        {
          title: 'Strokes To Par',
          units: 'both',
          key: 'strokesToPar',
          info: 'Some text info',
          benchmarktype: 'L',
          benchmarks: [
            -2.8, -0.1, +2.8, +6.1, +9.3, +12.5, +15.5, +19, +24.4, +29.1, +1.4, -0.6,
            -1.7, +0.2, +3.3, +5.9, +8.6, +12.1, +14.8, +19.2, +23.6, +28.6, +1.4, -1.5,
          ],
        },
        {
          title: 'Birdies & -',
          units: 'both',
          key: 'nbBirdiesM',
          benchmarktype: 'H',
          benchmarks: [
            4.9, 3.6, 2.6, 1.8, 1.2, 0.8, 0.7, 0.4, 0.2, 0.1, 3.1, 3.75,
            4.1, 3.4, 2.2, 1.8, 1.1, 0.7, 0.6, 0.4, 0.2, 0.1, 3.1, 3.9,
          ],
        },
        {
          title: 'Bogeys',
          units: 'both',
          key: 'nbBogeys',
          benchmarktype: 'L',
          benchmarks: [
            1.8, 2.8, 3.8, 5.0, 6.1, 6.9, 7.4, 7.9, 8.0, 8.3, 3.1, 2.52,
            2.0, 2.9, 4.0, 5.2, 6.5, 7.6, 7.8, 8.0, 9.2, 9.6, 3.1, 2.2,
          ],
        },
        {
          title: 'Double Bogeys & +',
          units: 'both',
          key: 'nb2BogeysP',
          benchmarktype: 'L',
          benchmarks: [
            0.1, 0.3, 0.7, 1.2, 1.6, 2.6, 3.7, 4.3, 6.5, 8.1, 0.6, 0.11,
            0.2, 0.3, 0.6, 1.1, 1.6, 2.3, 3.1, 5.5, 7.5, 9.8, 0.6, 0.3,
          ],
        },
        {
          title: 'Par 3 Scoring',
          units: 'both',
          key: 'par3Score',
          benchmarktype: 'L',
          benchmarks: [
            0.996, 3.014, 3.238, 3.369, 3.496, 3.665, 3.719, 3.887, 4.132, 4.329, 3.147, 3.040,
            2.992, 3.050, 3.209, 3.339, 3.532, 3.631, 3.734, 3.890, 4.122, 4.324, 3.147, 3.010,
          ],
        },
        {
          title: 'Par 4 Scoring',
          units: 'both',
          key: 'par4Score',
          benchmarktype: 'L',
          benchmarks: [
            3.845, 4.073, 4.125, 4.409, 4.580, 4.751, 4.918, 5.135, 5.416, 5.719, 4.144, 4.020,
            3.974, 3.956, 4.233, 4.397, 4.568, 4.787, 4.962, 5.232, 5.356, 5.565, 4.144, 3.989,
          ],
        },
        {
          title: 'Par 5 Scoring',
          units: 'both',
          key: 'par5Score',
          benchmarktype: 'L',
          benchmarks: [
            4.140, 4.685, 4.910, 5.124, 5.387, 5.585, 5.880, 6.052, 6.471, 6.654, 4.817, 4.610,
            4.632, 4.832, 5.031, 5.222, 5.395, 5.646, 5.884, 6.350, 6.560, 6.890, 4.817, 4.720,
          ],
        },
      ],
    },
    {
      title: 'Scoring',
      tabTitle: 'Scorecard',
      key: 'scoring',
      tables: [
        {
          title: 'Front Nine',
          key: 'averageScoreFront',
          units: 'both',
          benchmarktype: 'L',
          benchmarks: [
            -1.4, +0.0, +1.4, +3.2, +4.6, +6.2, +7.5, +9.5, +12.5, +14.7, +0.7, -0.9,
            -0.8, +0.1, +1.8, +3.0, +4.8, +6.0, +7.2, +8.9, +10.4, +13.8, +0.7, -0.7,
          ],
        },
        {
          title: 'Back Nine',
          key: 'averageScoreBack',
          units: 'both',
          benchmarktype: 'L',
          benchmarks: [
            -1.4, -0.1, +1.4, +2.9, +4.7, +4.2, +8.0, +9.5, +11.9, +14.4, +0.7, -1.3,
            -0.9, +0.1, +1.5, +2.9, +4.2, +6.1, +7.6, +10.3, +12.2, +14.8, +0.7, -0.8,
          ],
        },
        {
          title: 'First 6',
          key: 'averageScoreFirst6',
          units: 'both',
          benchmarktype: 'L',
          benchmarks: [
            -0.9, +0.0, +0.9, +2.1, +3.1, +4.2, +5.1, +6.3, +8.2, +10.1, +0.5, -0.4,
            -0.6, +0.0, +1.2, +2.0, +3.3, +4.0, +4.8, +6.4, +7.5, +8.4, +0.5, -0.5,
          ],
        },
        {
          title: 'Middle 6',
          key: 'averageScoreMiddle6',
          units: 'both',
          benchmarktype: 'L',
          benchmarks: [
            -1.0, -0.1, +0.8, +2.0, +3.1, +4.1, +5.1, +6.5, +8.2, +9.7, +0.5, -0.7,
            -0.7, +0.1, +1.2, +2.0, +3.0, +4.1, +5.0, +6.0, +7.8, +9.6, +0.5, -0.6,
          ],
        },
        {
          title: 'Last 6',
          key: 'averageScoreLast6',
          units: 'both',
          benchmarktype: 'L',
          benchmarks: [
            -0.9, +0.0, +1.1, +1.9, +3.2, +4.2, +5.3, +6.3, +8.0, +9.3, +0.4, -0.8,
            -0.2, +0.1, +1.0, +1.9, +3.3, +4.0, +5.0, +6.8, +8.3, +10.6, +0.4, -0.4,
          ],
        },
      ],
    },
  ],
  offTheTee: [
    {
      title: 'Off The Tee',
      key: 'offTee',
      noGraph: true,
      percent: true,
      tables: [
        {
          title: 'Fairways %',
          units: 'both',
          key: 'fairwaysPercentage',
          benchmarktype: 'H',
          benchmarks: [
            +61.2, +60.3, +58.4, +59.9, +59.4, +59.2, +57.1, +57.9, +60.6, +63.2, +58.8, +62.52,
            +75.5, +72.1, +71.1, +69.4, +68.1, +67.2, +66.2, +65.1, +63.1, +59.4, +58.8, +73.4,
          ],
        },
        {
          title: 'Miss Left %',
          units: 'both',
          key: 'fairwaysLeftPercentage',
          benchmarktype: 'L',
          benchmarks: [
            +20.1, +20.6, +21.3, +20.3, +21.6, +20.4, +20.6, +15.9, +15.1, +12.1, +21.5, +12.89,
            +15.2, +14.5, +16.7, +16.7, +16.4, +14.5, +9.0, +7.8, +7.5, +5.6, +21.5, +14.2,
          ],

        },
        {
          title: 'Miss Right %',
          units: 'both',
          key: 'fairwaysRightPercentage',
          benchmarktype: 'L',
          benchmarks: [
            +18.7, +19.2, +20.2, +19.4, +19.1, +20.3, +20.6, +16.8, +17.8, +22.5, +19.5, +14.56,
            +9.3, +11.3, +12.2, +13.9, +15.5, +18.4, +20.1, +22.6, +24.4, +26.7, +19.5, +10.4,
          ],
        },
        {
          title: 'Average Distance',
          units: 'imperial',
          key: 'averageDrive',
          benchmarktype: 'H',
          benchmarks: [
            301, 295, 290, 283, 272, 261, 241, 235, 221, 216, 299, 294.58,
            243, 239, 235, 233, 234, 220, 208, 202, 188, 180, 237, 240,
          ],
        },
        {
          title: 'Average Distance',
          units: 'metric',
          key: 'averageDrive',
          benchmarktype: 'H',
          benchmarks: [
            +275, +270, +265, +259, +249, +239, +227, +215, +198, +195, +271, +269.2,
            +222, +219, +215, +213, +214, +201, +190, +185, +172, +165, +217, +220,
          ],
        },
        {
          title: 'Avr. Score When Fairway Hit',
          units: 'both',
          key: 'scoreFWHit',
          benchmarktype: 'L',
          benchmarks: [
            -0.3, -0.2, +0.0, +0.2, +0.3, +0.5, +0.7, +0.9, +1.3, +1.5, -0.1, -0.43,
            -0.2, -0.1, +0.1, +0.2, +0.4, +0.6, +0.8, +1.0, +1.2, +1.5, -0.1, -0.1,
          ],
        },
        {
          title: 'Avr. Score When Fairway Missed',
          units: 'both',
          key: 'scoreFWMiss',
          benchmarktype: 'L',
          benchmarks: [
            +0.0, +0.2, +0.4, +0.6, +0.8, +1.0, +1.2, +1.4, +1.7, +2.0, +0.3, +0.72,
            +0.1, +0.3, +0.4, +0.6, +0.8, +1.0, +1.2, +1.4, +1.6, +1.8, +0.3, +0.0,
          ],
        },
      ],
    },
  ],
  toTheGreen: [
    {
      title: 'To The Green',
      tabTitle: 'GIR',
      key: 'approach',
      percent: true,
      tables: [
        {
          title: 'Greens in Regulation %',
          units: 'both',
          key: 'girPercentage',
          benchmarktype: 'H',
          benchmarks: [
            +75.9, +69.5, +63.4, +55.8, +47.8, +41.6, +33.7, +26.1, +18.2, +14.1, +68.2, +66.64,
            +75.6, +69.1, +63.4, +56.4, +48.3, +38.2, +36.1, +35.4, +31.5, +26.5, +68.2, +73.5,
          ],
        },
        {
          title: 'Par 3 GIR %',
          units: 'both',
          key: 'girPar3Percentage',
          benchmarktype: 'H',
          benchmarks: [
            +69.2, +63.7, +58.2, +52.6, +46.3, +41.8, +36.4, +30.3, +25.1, +19.4, +64.5, +59.57,
            +75.7, +66.6, +62.4, +56.5, +49.0, +44.6, +42.5, +41.3, +38.4, +36.4, +64.5, +74.2,
          ],
        },
        {
          title: 'Par 4 GIR %',
          units: 'both',
          key: 'girPar4Percentage',
          benchmarktype: 'H',
          benchmarks: [
            +74.1, +67.4, +60.1, +52.1, +44.4, +38.8, +30.3, +22.2, +14.7, +10.8, +65.9, +64.23,
            +70.2, +65.9, +58.7, +52.4, +44.2, +33.1, +31.1, +30.1, +28.6, +25.6, +65.9, +69.1,
          ],
        },
        {
          title: 'Par 5 GIR %',
          units: 'both',
          key: 'girPar5Percentage',
          benchmarktype: 'H',
          benchmarks: [
            +88.3, +81.6, +75.2, +68.7, +57.8, +50.4, +39.3, +30.9, +18.9, +16.1, +78.7, +81.56,
            +88.8, +80.1, +75.9, +65.8, +58.0, +44.5, +42.6, +40.9, +38.1, +36.2, +78.7, +86.4,
          ],
        },
      ],
    },
    {
      title: 'To The Green',
      tabTitle: 'Green Hits',
      key: 'approach',
      percent: true,
      tables: [
        {
          title: 'Green Attacks Hit %',
          units: 'both',
          key: 'greenHit',
          benchmarktype: 'H',
          benchmarks: [
            +72, +68, +63, +57, +51, +45, +42, +33, +28, +27, +67, +69.34,
            +77, +72, +64, +59, +53, +45, +42, +40, +37, +32, +67, +75,
          ],
        },
        {
          title: 'Green Att. From Fairway %',
          units: 'both',
          key: 'greenHitFromFW',
          benchmarktype: 'H',
          benchmarks: [
            +79, +75, +71, +66, +61, +56, +49, +40, +32, +30, +74, +78.02,
            +81, +78, +70, +67, +59, +52, +48, +46, +43, +37, +74, +79,
          ],
        },
        {
          title: 'Green Att. From Rough %',
          units: 'both',
          key: 'greenHitFromRough',
          benchmarktype: 'H',
          benchmarks: [
            +65, +60, +53, +48, +42, +33, +30, +27, +22, +20, +60, +55.45,
            +64, +62, +53, +43, +41, +36, +34, +36, +33, +29, +60, +63,
          ],
        },
        {
          title: 'Green Att. From Bunker %',
          units: 'both',
          key: 'greenHitFromBunker',
          benchmarktype: 'H',
          benchmarks: [
            +52, +45, +40, +36, +35, +29, +43, +23, +22, +18, +41, +48.23,
            +52, +42, +37, +29, +24, +37, +33, +30, +25, +20, +41, +51,
          ],
        },
      ],
    },
  ],
  greenSide: [
    {
      title: 'Greenside',
      key: 'around',
      percent: true,
      tables: [
        {
          title: 'Up & Down %',
          units: 'both',
          key: 'upDownPercentage',
          benchmarktype: 'H',
          benchmarks: [
            +64.9, +57.5, +50.7, +42.9, +37.7, +32.5, +24.9, +25.6, +22.9, +12.9, +52.6, +60.41,
            +64.6, +55.8, +49.3, +42.0, +35.4, +34.0, +32.6, +30.6, +28.4, +26.1, +52.6, +63.4,
          ],
        },
        {
          title: 'Up & Down Fw %',
          units: 'both',
          key: 'upDownFromFWPercentage',
          benchmarktype: 'H',
          benchmarks: [
            +75.4, +70.0, +64.3, +53.7, +47.7, +44.7, +37.1, +33.5, +26.7, +23.8, +66.2, +68.39,
            +75.5, +69.2, +62.6, +50.6, +45.7, +42.6, +40.1, +38.6, +34.6, +32.4, +66.2, +74.1,
          ],
        },
        {
          title: 'Up & Down Rough %',
          units: 'both',
          key: 'upDownFromRoughPercentage',
          benchmarktype: 'H',
          benchmarks: [
            +62.2, +53.0, +45.3, +38.9, +33.5, +27.5, +18.9, +21.8, +22.9, +20.7, +47.8, +58.78,
            +58.4, +49.5, +41.0, +39.4, +32.4, +30.7, +28.6, +26.4, +24.9, +21.4, +47.8, +57.4,
          ],
        },
        {
          title: 'Up & Down Bunker %',
          units: 'both',
          key: 'upDownFromBunkerPercentage',
          benchmarktype: 'H',
          benchmarks: [
            +48.8, +42.9, +35.2, +31.1, +27.7, +18.6, +13.0, +17.2, +11.8, +8.4, +38.0, +51.21,
            +44.7, +37.0, +33.8, +25.5, +21.5, +20.7, +18.6, +17.4, +15.3, +13.6, +38.0, +43.1,
          ],
        },
        {
          title: 'Scrambling %',
          units: 'both',
          key: 'scramblingPercentage',
          benchmarktype: 'H',
          benchmarks: [
            +61.2, +51.2, +42.3, +33.4, +26.7, +20.4, +15.7, +11.3, +7.8, +5.8, +44.6, +59.34,
            +61.4, +52.0, +42.2, +32.6, +25.2, +19.7, +17.5, +16.5, +15.1, +13.8, +44.6, +60.1,
          ],
        },
        // {
        //   title: 'Avr. Hole Outs',
        //   key: 'holeOuts',
        //   benchmarktype: 'H',
        //   benchmarks: [
        //     +0.2, +0.2, +0.1, +0.1, +0.1, +0.1, +0.1, +0.1, +0.1, +0.1, +0.1, +0.3,
        //     +0.2, +0.1, +0.1, +0.1, +0.1, +0.1, +0.1, +0.1, +0.1, +0.1, +0.1, +0.2,
        //   ],
        // },
      ],
    },
  ],
  putting: [
    {
      title: 'Putting',
      tabTitle: 'Make %',
      key: 'onGreen',
      percent: true,
      tables: [
        {
          title: '0-3ft',
          units: 'imperial',
          key: 'dist_0_1',
          benchmarktype: 'H',
          benchmarks: [
            +98.2, +97.6, +96.3, +94.7, +94.1, +92.7, +91.2, +89.5, +91.1, +85.3, +97.2, +98.4,
            +96.9, +97.0, +96.5, +93.9, +91.7, +89.7, +87.6, +85.4, +83.1, +78.5, +97.2, +97.2,
          ],
        },
        {
          title: '3-6ft',
          units: 'imperial',
          key: 'dist_1_2',
          benchmarktype: 'H',
          benchmarks: [
            +74.4, +69.5, +63.8, +59.8, +57.3, +56.6, +47.2, +55.4, +51.5, +47.2, +65.6, +86.4,
            +78.8, +70.4, +69.6, +61.7, +59.0, +57.3, +55.4, +53.2, +48.5, +46.8, +65.6, +76.4,
          ],
        },
        {
          title: '6-10ft',
          units: 'imperial',
          key: 'dist_2_3',
          benchmarktype: 'H',
          benchmarks: [
            +48.5, +41.9, +35.4, +30.8, +28.5, +22.6, +21.3, +19.4, +18.4, +15.6, +34.7, +56.2,
            +47.9, +40.0, +37.4, +38.9, +36.7, +34.0, +31.2, +29.6, +27.5, +24.4, +34.7, +46.8,
          ],
        },
        {
          title: '10-13ft',
          units: 'imperial',
          key: 'dist_3_4',
          benchmarktype: 'H',
          benchmarks: [
            +32.6, +27.6, +22.6, +21.1, +19.8, +15.0, +13.2, +12.2, +10.0, +8.9, +23.2, +32.6,
            +26.9, +27.4, +26.0, +25.6, +22.0, +21.1, +20.1, +19.4, +17.6, +16.5, +23.2, +26.5,
          ],
        },
        {
          title: '13-16ft',
          units: 'imperial',
          key: 'dist_4_5',
          benchmarktype: 'H',
          benchmarks: [
            +23.2, +18.6, +17.5, +16.2, +15.1, +10.2, +13.8, +12.6, +10.6, +6.2, +17.9, +26.4,
            +22.2, +21.4, +21.5, +21.4, +20.8, +21.2, +22.3, +20.3, +18.3, +14.5, +17.9, +24.1,
          ],
        },
        {
          title: '16-20ft',
          units: 'imperial',
          key: 'dist_5_6',
          benchmarktype: 'H',
          benchmarks: [
            +19.5, +12.8, +12.1, +10.3, +9.5, +6.6, +5.5, +5.6, +4.6, +4.2, +11.5, +19.4,
            +11.7, +15.3, +15.1, +14.5, +10.2, +12.1, +10.6, +9.6, +8.5, +3.5, +11.5, +14.5,
          ],
        },
        {
          title: '20-30ft',
          units: 'imperial',
          key: 'dist_6_9',
          benchmarktype: 'H',
          benchmarks: [
            +11.7, +9.7, +9.5, +7.8, +6.9, +7.0, +3.6, +3.0, +2.9, +2.2, +7.9, +13.6,
            +11.9, +12.0, +11.3, +11.0, +11.4, +10.9, +8.9, +8.4, +5.4, +4.5, +7.9, +12.4,
          ],
        },
        {
          title: '30-40ft',
          units: 'imperial',
          key: 'dist_9_12',
          enchmarktype: 'H',
          benchmarks: [
            +6.6, +5.9, +6.0, +5.5, +4.4, +3.5, +2.7, +2.7, +2.2, +1.1, +5.0, +6.3,
            +7.8, +6.7, +7.2, +7.1, +6.6, +6.9, +6.6, +6.1, +2.1, +0.8, +5.0, +8.6,
          ],
        },
        {
          title: '> 40ft',
          units: 'imperial',
          key: 'dist_12',
          benchmarktype: 'H',
          benchmarks: [
            4.6, +4.5, +5.2, +4.2, +3.5, +3.4, +0.3, +0.2, +0.0, +0.0, +3.4, 3.1,
            +7.0, +4.1, +3.6, +3.4, +4.3, +5.5, +2.3, +1.3, +1.1, +0.2, +3.4, +7.0,
          ],
        },

        {
          title: '0-1m',
          units: 'metric',
          key: 'dist_0_1',
          benchmarktype: 'H',
          benchmarks: [
            +98.2, +97.6, +96.3, +94.7, +94.1, +92.7, +91.2, +89.5, +91.1, +85.3, +97.2, +98.4,
            +96.9, +97.0, +96.5, +93.9, +91.7, +89.7, +87.6, +85.4, +83.1, +78.5, +97.2, +97.2,
          ],
        },
        {
          title: '1-2m',
          units: 'metric',
          key: 'dist_1_2',
          benchmarktype: 'H',
          benchmarks: [
            +74.4, +69.5, +63.8, +59.8, +57.3, +56.6, +47.2, +55.4, +51.5, +47.2, +65.6, +86.4,
            +78.8, +70.4, +69.6, +61.7, +59.0, +57.3, +55.4, +53.2, +48.5, +46.8, +65.6, +76.4,
          ],
        },
        {
          title: '2-3m',
          units: 'metric',
          key: 'dist_2_3',
          benchmarktype: 'H',
          benchmarks: [
            +48.5, +41.9, +35.4, +30.8, +28.5, +22.6, +21.3, +19.4, +18.4, +15.6, +34.7, +56.2,
            +47.9, +40.0, +37.4, +38.9, +36.7, +34.0, +31.2, +29.6, +27.5, +24.4, +34.7, +46.8,
          ],
        },
        {
          title: '3-4m',
          units: 'metric',
          key: 'dist_3_4',
          benchmarktype: 'H',
          benchmarks: [
            +32.6, +27.6, +22.6, +21.1, +19.8, +15.0, +13.2, +12.2, +10.0, +8.9, +23.2, +32.6,
            +26.9, +27.4, +26.0, +25.6, +22.0, +21.1, +20.1, +19.4, +17.6, +16.5, +23.2, +26.5,
          ],
        },
        {
          title: '4-5m',
          units: 'metric',
          key: 'dist_4_5',
          benchmarktype: 'H',
          benchmarks: [
            +23.2, +18.6, +17.5, +16.2, +15.1, +10.2, +13.8, +12.6, +10.6, +6.2, +17.9, +26.4,
            +22.2, +21.4, +21.5, +21.4, +20.8, +21.2, +22.3, +20.3, +18.3, +14.5, +17.9, +24.1,
          ],
        },
        {
          title: '5-6m',
          units: 'metric',
          key: 'dist_5_6',
          benchmarktype: 'H',
          benchmarks: [
            +19.5, +12.8, +12.1, +10.3, +9.5, +6.6, +5.5, +5.6, +4.6, +4.2, +11.5, +19.4,
            +11.7, +15.3, +15.1, +14.5, +10.2, +12.1, +10.6, +9.6, +8.5, +3.5, +11.5, +14.5,
          ],
        },
        {
          title: '6-9m',
          units: 'metric',
          key: 'dist_6_9',
          benchmarktype: 'H',
          benchmarks: [
            +11.7, +9.7, +9.5, +7.8, +6.9, +7.0, +3.6, +3.0, +2.9, +2.2, +7.9, +13.6,
            +11.9, +12.0, +11.3, +11.0, +11.4, +10.9, +8.9, +8.4, +5.4, +4.5, +7.9, +12.4,
          ],
        },
        {
          title: '9-12m',
          units: 'metric',
          key: 'dist_9_12',
          enchmarktype: 'H',
          benchmarks: [
            +6.6, +5.9, +6.0, +5.5, +4.4, +3.5, +2.7, +2.7, +2.2, +1.1, +5.0, +6.3,
            +7.8, +6.7, +7.2, +7.1, +6.6, +6.9, +6.6, +6.1, +2.1, +0.8, +5.0, +8.6,
          ],
        },
        {
          title: '> 12m',
          units: 'metric',
          key: 'dist_12',
          benchmarktype: 'H',
          benchmarks: [
            +4.6, +4.5, +5.2, +4.2, +3.5, +3.4, +0.3, +0.2, +0.0, +0.0, +3.4, 3.1,
            +7.0, +4.1, +3.6, +3.4, +4.3, +5.5, +2.3, +1.3, +1.1, +0.2, +3.4, +7.0,
          ],
        },
      ],
    },
    {
      title: 'Putting',
      tabTitle: 'Averages',
      key: 'onGreen',
      noGraph: true,
      tables: [
        {
          title: '# Putts Total',
          units: 'both',
          key: 'totalPutts',
          benchmarktype: 'L',
          benchmarks: [
            +29.5, +30.2, +31.1, +32.4, +33.2, +34.3, +34.3, +35.1, +35.8, +36.9, +31, +28.97,
            +29.9, +30.9, +31.9, +32.8, +33.7, +34.4, +35.6, +36.2, +38.4, +40.1, +31, +29.9,
          ],
        },
        {
          title: 'Putts/Hole GIR',
          units: 'both',
          key: 'puttsPerHoleGir',
          benchmarktype: 'L',
          benchmarks: [
            +1.7, +1.8, +1.9, +2.0, +2.0, +2.1, +2.1, +2.1, +2.2, +2.3, +1.8, +1.75,
            +1.8, +1.8, +1.9, +2.0, +2.0, +2.1, +2.2, +2.3, +2.4, +2.5, +1.8, +1.8,
          ],
        },
        {
          title: 'Putts/Hole non GIR',
          units: 'both',
          key: 'puttsPerHoleNonGir',
          benchmarktype: 'L',
          benchmarks: [
            +1.3, +1.4, +1.5, +1.6, +1.7, +1.8, +1.8, +1.9, +1.9, +2.1, +1.5, +1.32,
            +1.3, +1.4, +1.5, +1.6, +1.7, +1.8, +1.9, +2.0, +2.1, +2.3, +1.5, +1.3,
          ],
        },
        {
          title: 'Average 1 Putt',
          units: 'both',
          key: 'putts1',
          benchmarktype: 'H',
          benchmarks: [
            +6.7, +6.0, +5.5, +4.8, +4.4, +3.8, +3.5, +2.9, +2.5, +2.3, +5.6, +7.08,
            +6.3, +5.8, +5.1, +4.5, +4.1, +3.6, +3.1, +2.4, +1.9, +1.8, +5.6, +6.1,
          ],
        },
        {
          title: 'Average 3 Putts +',
          units: 'both',
          key: 'putts3',
          benchmarktype: 'L',
          benchmarks: [
            +0.5, +0.8, +1.0, +1.3, +1.7, +2.1, +2.0, +2.1, +2.5, +3.1, +0.9, +0.48,
            +0.6, +0.9, +1.1, +1.5, +1.9, +2.2, +2.6, +3.4, +4.2, +4.6, +0.9, +0.7,
          ],
        },
        {
          title: 'Birdie Conversion %',
          units: 'both',
          key: 'birdieConversion',
          benchmarktype: 'H',
          benchmarks: [
            +33.9, +28.6, +22.5, +17.6, +14.1, +10.8, +10.3, +7.9, +7.2, +6.3, +25.7, +31.34,
            +29.1, +24.8, +18.9, +16.0, +12.9, +9.7, +7.6, +5.2, +3.5, +2.1, +25.7, +28.2,
          ],
        },
        {
          title: 'Par Conversion %',
          units: 'both',
          key: 'parConversion',
          benchmarktype: 'H',
          benchmarks: [
            +63.4, +58.4, +49.6, +40.6, +34.2, +27.6, +22.5, +17.3, +14.1, +12.5, +51.7, +65.26,
            +63.9, +52.6, +48.1, +38.8, +31.2, +24.7, +18.5, +16.3, +14.5, +13.4, +51.7, +61.6,
          ],
        },
        {
          title: 'Miss Read Putts %',
          units: 'both',
          key: 'misReadPuttPercentage',
          benchmarktype: 'L',
          benchmarks: [
            +4.2, +4.5, +5.3, +5.4, +5.5, +7.2, +7.5, +8, +8.2, +10.2, +3.8, +4.78,
            +6.1, +5.6, +6.4, +6.1, +7.5, +8.0, +9.0, +9.1, +11.1, +14.5, +3.8, +4.1,
          ],
        },
      ],
    },
  ],
  strokesGained: [
    {
      title: 'Strokes Gained',
      tabTitle: 'Categories',
      hideTour: true,
      key: 'strokesGained',
      tables: [
        {
          title: 'Total',
          units: 'both',
          key: 'strokesGainedTotal',
          benchmarktype: 'H',
          benchmarks: [
            -0.389, -2.367, -4.089, -7.895, -11.293, -14.963, -18.578, -22.563, -28.312, -35.173, -2.771, 0.000,
            -2.367, -4.700, -7.566, -10.874, -13.630, -17.750, -20.969, -24.706, -31.807, -44.160, -6.571, -4.827,
          ],
        },
        {
          title: 'Tee To Green',
          units: 'both',
          key: 'strokesGainedTeeToGreen',
          benchmarktype: 'H',
          benchmarks: [
            -0.379, -1.895, -2.706, -4.868, -7.716, -10.612, -13.110, -16.660, -22.223, -26.970, -1.399, 0.000,
            -1.895, -3.855, -5.772, -7.658, -10.211, -12.764, -13.643, -17.144, -20.191, 31.614, -5.399, -4.053,
          ],
        },
        {
          title: 'Off The Tee',
          units: 'both',
          key: 'strokesGainedOffTee',
          benchmarktype: 'H',
          benchmarks: [
            -0.244, -1.420, -0.874, -1.536, -2.360, -2.916, -3.975, -4.592, -5.713, -6.270, -0.484, 0.000,
            -1.420, -1.768, -2.240, -2.651, -3.266, -3.834, -3.828, -4.481, -5.800, -8.636, -0.484, -1.713,
          ],
        },
        {
          title: 'Long Shots & Lay-ups',
          units: 'both',
          key: 'strokesGainedLongShots',
          benchmarktype: 'H',
          benchmarks: [
            -0.162, -0.492, -0.399, -0.585, -0.781, -0.986, -1.198, -1.495, -2.255, -3.042, -0.275, 0.000,
            -1.420, -1.768, -2.240, -2.651, -3.266, -3.834, -3.828, -4.481, -5.800, -8.636, -0.484, -1.713,
          ],
        },
        {
          title: 'To The Green',
          units: 'both',
          key: 'strokesGainedApproach',
          benchmarktype: 'H',
          benchmarks: [
            -0.080, -0.110, -1.120, -1.998, -3.166, -4.688, -5.349, -7.256, -10.628, -12.776, -0.468, 0.000,
            -0.110, -1.358, -2.265, -3.274, -4.621, -5.999, -6.269, -7.880, -8.738, -12.130, -0.468, -1.951,
          ],
        },
        {
          title: 'Greenside',
          units: 'both',
          key: 'strokesGainedAround',
          benchmarktype: 'H',
          benchmarks: [
            0.107, 0.127, -0.313, -0.747, -1.409, -2.022, -2.589, -3.317, -3.627, -4.882, -0.171, 0.000,
            0.127, -0.183, -0.579, -1.041, -1.684, -2.140, -2.589, -3.239, -3.741, -6.503, -0.171, -0.500,
          ],
        },
        {
          title: 'Putting',
          units: 'both',
          key: 'strokesGainedOnGreen',
          benchmarktype: 'H',
          benchmarks: [
            -0.010, -0.472, -1.383, -3.027, -3.576, -4.351, -5.467, -5.903, -6.540, -8.203, -1.372, 0.000,
            -0.472, -0.845, -1.794, -3.217, -3.969, -4.886, -6.326, -7.562, -11.617, -12.546, -1.172, -0.674,
          ],
        },
      ],
    },
    {
      title: 'Strokes Gained',
      tabTitle: 'Putting Distances',
      hideTour: true,
      key: 'strokesGained',
      tables: [
        {
          title: '< 3ft',
          units: 'imperial',
          key: 'sg_d_1',
          benchmarktype: 'H',
          benchmarks: [
            -0.080, -0.124, -0.324, -0.560, -0.676, -0.898, -1.503, -1.544, -1.728, -1.785, -0.236, 0.000,
            -0.080, -0.129, -0.385, -0.826, -1.075, -1.406, -2.086, -1.956, -3.219, -3.385, -0.236, -0.090,
          ],
        },
        {
          title: '3-6ft',
          units: 'imperial',
          key: 'sg_d_1_2',
          benchmarktype: 'H',
          benchmarks: [
            0.088, -0.287, -0.477, -0.758, -0.777, -0.839, -1.150, -1.175, -1.266, -1.350, -0.349, 0.000,
            0.088, -0.134, -0.263, -0.629, -0.705, -0.801, -1.099, -1.676, -1.874, -2.050, -0.149, -0.117,
          ],
        },
        {
          title: '6-10ft',
          units: 'imperial',
          key: 'sg_d_2_3',
          benchmarktype: 'H',
          benchmarks: [
            0.011, -0.198, -0.380, -0.657, -0.787, -0.906, -1.046, -1.164, -1.563, -1.785, -0.144, 0.000,
            0.011, -0.141, -0.243, -0.432, -0.487, -0.738, -0.971, -1.066, -1.511, -1.585, -0.144, -0.069,
          ],
        },
        {
          title: '10-13ft',
          units: 'imperial',
          key: 'sg_d_3_4',
          benchmarktype: 'H',
          benchmarks: [
            -0.103, -0.138, -0.220, -0.304, -0.328, -0.459, -0.619, -0.633, -0.850, -1.010, -0.078, 0.000,
            -0.033, -0.095, -0.178, -0.303, -0.378, -0.398, -0.428, -0.402, -0.752, -0.810, -0.078, -0.081,
          ],
        },
        {
          title: '13-16ft',
          units: 'imperial',
          key: 'sg_d_4_5',
          benchmarktype: 'H',
          benchmarks: [
            -0.041, -0.084, -0.136, -0.232, -0.280, -0.326, -0.336, -0.345, -0.362, -0.562, -0.062, 0.000,
            -0.041, -0.103, -0.150, -0.246, -0.267, -0.282, -0.373, -0.396, -0.601, -0.662, -0.062, -0.087,
          ],
        },
        {
          title: '16-20ft',
          units: 'imperial',
          key: 'sg_d_5_6',
          benchmarktype: 'H',
          benchmarks: [
            -0.032, -0.056, -0.072, -0.149, -0.195, -0.239, -0.260, -0.299, -0.354, -0.375, -0.110, 0.000,
            -0.072, -0.086, -0.112, -0.183, -0.233, -0.296, -0.360, -0.443, -0.727, 0.775, -0.110, -0.071,
          ],
        },
        {
          title: '20-30ft',
          units: 'imperial',
          key: 'sg_d_6_9',
          benchmarktype: 'H',
          benchmarks: [
            -0.054, -0.069, -0.146, -0.193, -0.350, -0.386, -0.394, -0.444, -0.504, -0.524, -0.028, 0.000,
            -0.044, -0.053, -0.165, -0.268, -0.376, -0.444, -0.518, -0.664, -0.816, -0.924, -0.028, -0.080,
          ],
        },
        {
          title: '30-40ft',
          units: 'imperial',
          key: 'sg_d_9_12',
          benchmarktype: 'H',
          benchmarks: [
            -0.025, -0.034, -0.078, -0.123, -0.153, -0.203, -0.227, -0.344, -0.385, -0.412, -0.017, 0.000,
            -0.035, -0.038, -0.185, -0.207, -0.285, -0.358, -0.398, -0.677, -0.844, -0.912, -0.017, -0.061,
          ],
        },
        {
          title: '> 40ft',
          units: 'imperial',
          key: 'sg_d_12',
          benchmarktype: 'H',
          benchmarks: [
            0.008, -0.027, -0.051, -0.061, -0.130, -0.155, -0.163, -0.194, -0.298, -0.310, -0.171, 0.000,
            0.008, -0.066, -0.103, -0.122, -0.163, -0.164, -0.193, -0.283, -1.273, -1.443, -0.171, 0.018,
          ],
        },

        {
          title: '< 1m',
          units: 'metric',
          key: 'sg_d_1',
          benchmarktype: 'H',
          benchmarks: [
            -0.080, -0.124, -0.324, -0.560, -0.676, -0.898, -1.503, -1.544, -1.728, -1.785, -0.236, 0.000,
            -0.080, -0.129, -0.385, -0.826, -1.075, -1.406, -2.086, -1.956, -3.219, -3.385, -0.236, -0.090,
          ],
        },
        {
          title: '1-2m',
          units: 'metric',
          key: 'sg_d_1_2',
          benchmarktype: 'H',
          benchmarks: [
            0.088, -0.287, -0.477, -0.758, -0.777, -0.839, -1.150, -1.175, -1.266, -1.350, -0.349, 0.000,
            0.088, -0.134, -0.263, -0.629, -0.705, -0.801, -1.099, -1.676, -1.874, -2.050, -0.149, -0.117,
          ],
        },
        {
          title: '2-3m',
          units: 'metric',
          key: 'sg_d_2_3',
          benchmarktype: 'H',
          benchmarks: [
            0.011, -0.198, -0.380, -0.657, -0.787, -0.906, -1.046, -1.164, -1.563, -1.785, -0.144, 0.000,
            0.011, -0.141, -0.243, -0.432, -0.487, -0.738, -0.971, -1.066, -1.511, -1.585, -0.144, -0.069,
          ],
        },
        {
          title: '3-4m',
          units: 'metric',
          key: 'sg_d_3_4',
          benchmarktype: 'H',
          benchmarks: [
            -0.103, -0.138, -0.220, -0.304, -0.328, -0.459, -0.619, -0.633, -0.850, -1.010, -0.078, 0.000,
            -0.033, -0.095, -0.178, -0.303, -0.378, -0.398, -0.428, -0.402, -0.752, -0.810, -0.078, -0.081,
          ],
        },
        {
          title: '4-5m',
          units: 'metric',
          key: 'sg_d_4_5',
          benchmarktype: 'H',
          benchmarks: [
            -0.041, -0.084, -0.136, -0.232, -0.280, -0.326, -0.336, -0.345, -0.362, -0.562, -0.062, 0.000,
            -0.041, -0.103, -0.150, -0.246, -0.267, -0.282, -0.373, -0.396, -0.601, -0.662, -0.062, -0.087,
          ],
        },
        {
          title: '5-6m',
          units: 'metric',
          key: 'sg_d_5_6',
          benchmarktype: 'H',
          benchmarks: [
            -0.032, -0.056, -0.072, -0.149, -0.195, -0.239, -0.260, -0.299, -0.354, -0.375, -0.110, 0.000,
            -0.072, -0.086, -0.112, -0.183, -0.233, -0.296, -0.360, -0.443, -0.727, 0.775, -0.110, -0.071,
          ],
        },
        {
          title: '6-9m',
          units: 'metric',
          key: 'sg_d_6_9',
          benchmarktype: 'H',
          benchmarks: [
            -0.054, -0.069, -0.146, -0.193, -0.350, -0.386, -0.394, -0.444, -0.504, -0.524, -0.028, 0.000,
            -0.044, -0.053, -0.165, -0.268, -0.376, -0.444, -0.518, -0.664, -0.816, -0.924, -0.028, -0.080,
          ],
        },
        {
          title: '9-12m',
          units: 'metric',
          key: 'sg_d_9_12',
          benchmarktype: 'H',
          benchmarks: [
            -0.025, -0.034, -0.078, -0.123, -0.153, -0.203, -0.227, -0.344, -0.385, -0.412, -0.017, 0.000,
            -0.035, -0.038, -0.185, -0.207, -0.285, -0.358, -0.398, -0.677, -0.844, -0.912, -0.017, -0.061,
          ],
        },
        {
          title: '> 12m',
          units: 'metric',
          key: 'sg_d_12',
          benchmarktype: 'H',
          benchmarks: [
            0.008, -0.027, -0.051, -0.061, -0.130, -0.155, -0.163, -0.194, -0.298, -0.310, -0.171, 0.000,
            0.008, -0.066, -0.103, -0.122, -0.163, -0.164, -0.193, -0.283, -1.273, -1.443, -0.171, 0.018,
          ],
        },
      ],
    },
    {
      title: 'Strokes Gained',
      tabTitle: 'Shot Distances',
      hideTour: true,
      key: 'strokesGained',
      tables: [
        {
          title: '< 30m',
          units: 'metric',
          key: 'sg_d_30',
          benchmarktype: 'H',
          benchmarks: [
            0.127, 0.105, -0.313, -0.747, -1.409, -2.022, -2.589, -3.316, -3.857, -5.575, -0.171, 0.000,
            0.127, -0.083, -0.479, -1.041, -1.484, -2.140, -2.389, -3.039, -3.241, -5.075, -0.171, -0.100,
          ],
        },
        {
          title: '30-60m',
          units: 'metric',
          key: 'sg_d_30_60',
          benchmarktype: 'H',
          benchmarks: [
            0.056, -0.003, -0.052, -0.144, -0.210, -0.434, -0.310, -0.602, -1.321, -1.558, -0.046, 0.000,
            0.056, -0.076, -0.161, -0.241, -0.405, -0.452, -0.583, -0.681, -0.865, -1.258, -0.086, -0.025,
          ],
        },
        {
          title: '60-70m',
          units: 'metric',
          key: 'sg_d_60_70',
          benchmarktype: 'H',
          benchmarks: [
            0.060, 0.018, -0.019, -0.029, -0.084, -0.119, -0.147, -0.186, -0.495, -0.673, 0.014, 0.000,
            0.060, 0.026, -0.062, -0.081, -0.093, -0.199, -0.287, -0.348, -0.457, -0.673, -0.103, -0.007,
          ],
        },
        {
          title: '70-80m',
          units: 'metric',
          key: 'sg_d_70_80',
          benchmarktype: 'H',
          benchmarks: [
            0.070, 0.014, -0.029, -0.041, -0.133, -0.190, -0.119, -0.192, -0.397, -1.038, 0.016, 0.000,
            0.070, -0.006, -0.071, -0.168, -0.202, -0.295, -0.378, -0.382, -0.422, -1.038, -0.089, -0.025,
          ],
        },
        {
          title: '80-90m',
          units: 'metric',
          key: 'sg_d_80_90',
          benchmarktype: 'H',
          benchmarks: [
            0.057, 0.023, -0.028, -0.041, -0.094, -0.115, -0.228, -0.322, -0.570, -1.399, 0.016, 0.000,
            0.057, -0.009, -0.085, -0.132, -0.227, -0.247, -0.285, -0.401, 0.427, -1.199, -0.121, -0.070,
          ],
        },
        {
          title: '90-100m',
          units: 'metric',
          key: 'sg_d_90_100',
          benchmarktype: 'H',
          benchmarks: [
            0.062, 0.014, -0.061, -0.100, -0.127, -0.230, -0.181, -0.395, -0.498, -0.886, -0.013, 0.000,
            0.062, -0.145, -0.248, -0.536, -0.673, -0.770, -0.806, -0.865, -0.898, -0.986, -0.197, -0.025,
          ],
        },
        {
          title: '100-110m',
          units: 'metric',
          key: 'sg_d_100_110',
          benchmarktype: 'H',
          benchmarks: [
            0.027, 0.022, -0.038, -0.085, -0.139, -0.279, -0.317, -0.334, -0.483, -0.963, -0.011, 0.000,
            -0.027, -0.037, -0.117, -0.248, -0.339, -0.417, -0.422, -0.498, -0.645, -0.963, -0.104, 0.020,
          ],
        },
        {
          title: '110-120m',
          units: 'metric',
          key: 'sg_d_110_120',
          benchmarktype: 'H',
          benchmarks: [
            0.006, -0.018, -0.053, -0.120, -0.206, -0.237, -0.292, -0.519, -1.068, -1.490, 0.013, 0.000,
            -0.036, -0.178, -0.220, -0.260, -0.355, -0.607, -0.636, -0.736, -0.775, -1.290, -0.144, -0.005,
          ],
        },
        {
          title: '120-130m',
          units: 'metric',
          key: 'sg_d_120_130',
          benchmarktype: 'H',
          benchmarks: [
            0.003, -0.003, -0.084, -0.159, -0.221, -0.331, -0.280, -0.570, -0.765, -1.015, 0.002, 0.000,
            -0.013, -0.051, -0.187, -0.261, -0.478, -0.609, -0.681, -0.722, -0.744, -1.015, -0.144, -0.065,
          ],
        },
        {
          title: '130-140m',
          units: 'metric',
          key: 'sg_d_130_140',
          benchmarktype: 'H',
          benchmarks: [
            0.012, -0.017, -0.114, -0.179, -0.278, -0.441, -0.575, -0.728, -0.977, -1.405, -0.030, 0.000,
            0.012, -0.153, -0.286, -0.388, -0.505, -0.711, -0.850, -0.872, -1.086, -1.205, -0.144, -0.139,
          ],
        },
        {
          title: '140-150m',
          units: 'metric',
          key: 'sg_d_140_150',
          benchmarktype: 'H',
          benchmarks: [
            0.019, -0.026, -0.142, -0.238, -0.334, -0.588, -0.744, -0.835, -1.312, -1.563, -0.059, 0.000,
            -0.049, -0.145, -0.302, -0.374, -0.580, -0.652, -0.787, -0.791, -0.939, -1.363, -0.210, -0.138,
          ],
        },
        {
          title: '150-160m',
          units: 'metric',
          key: 'sg_d_150_160',
          benchmarktype: 'H',
          benchmarks: [
            0.025, -0.030, -0.085, -0.210, -0.324, -0.509, -0.581, -0.765, -1.126, -1.555, -0.076, 0.000,
            -0.045, -0.156, -0.193, -0.265, -0.289, -0.398, -0.448, -0.696, -0.842, -1.455, -0.161, -0.130,
          ],
        },
        {
          title: '160-170m',
          units: 'metric',
          key: 'sg_d_160_170',
          benchmarktype: 'H',
          benchmarks: [
            0.028, -0.045, -0.132, -0.196, -0.303, -0.438, -0.563, -0.644, -0.856, -1.479, -0.125, 0.000,
            -0.028, -0.118, -0.124, -0.205, -0.291, -0.314, -0.413, -0.600, -0.694, -1.279, -0.149, -0.177,
          ],
        },
        {
          title: '170-180m',
          units: 'metric',
          key: 'sg_d_170_180',
          benchmarktype: 'H',
          benchmarks: [
            0.030, -0.066, -0.130, -0.181, -0.347, -0.363, -0.436, -0.482, -0.672, -0.981, -0.102, 0.000,
            -0.090, -0.135, -0.149, -0.195, -0.264, -0.282, -0.426, -0.566, -0.628, -0.881, -0.137, -0.135,
          ],
        },
        {
          title: '180-190m',
          units: 'metric',
          key: 'sg_d_180_190',
          benchmarktype: 'H',
          benchmarks: [
            0.020, -0.031, -0.089, -0.145, -0.206, -0.227, -0.269, -0.375, -0.578, -0.900, -0.044, 0.000,
            -0.070, -0.113, -0.141, -0.161, -0.223, -0.208, -0.369, -0.341, -0.624, -0.800, -0.125, -0.155,
          ],
        },
        {
          title: '190-200m',
          units: 'metric',
          key: 'sg_d_190_200',
          benchmarktype: 'H',
          benchmarks: [
            0.032, -0.047, -0.083, -0.156, -0.181, -0.218, -0.319, -0.417, -0.728, -1.213, -0.051, 0.000,
            -0.092, -0.124, -0.157, -0.262, -0.279, -0.292, -0.359, -0.382, -0.547, -1.013, -0.115, -0.115,
          ],
        },
        {
          title: '200-220m',
          units: 'metric',
          key: 'sg_d_200_220',
          benchmarktype: 'H',
          benchmarks: [
            0.036, -0.056, -0.130, -0.205, -0.243, -0.296, -0.348, -0.391, -0.546, -0.913, -0.077, 0.000,
            -0.216, -0.234, -0.295, -0.300, -0.377, -0.423, -0.490, -0.532, -0.611, -0.813, -0.177, -0.183,
          ],
        },
        {
          title: '220-250m',
          units: 'metric',
          key: 'sg_d_220_250',
          benchmarktype: 'H',
          benchmarks: [
            0.069, -0.072, -0.171, -0.223, -0.362, -0.413, -0.535, -0.601, -0.851, -1.110, -0.114, 0.000,
            -0.269, -0.341, -0.477, -0.499, -0.554, -0.711, -0.701, -0.680, -0.730, -1.010, -0.314, -0.368,
          ],
        },
        {
          title: '> 250m',
          units: 'metric',
          key: 'sg_d_250',
          benchmarktype: 'H',
          benchmarks: [
            0.184, -0.261, -0.957, -1.586, -2.583, -3.201, -4.278, -5.097, -6.553, -8.388, -0.540, 0.000,
            -1.384, -1.538, -1.937, -2.098, -2.632, -3.336, -3.534, -4.013, -5.071, -8.288, -1.430, -1.511,
          ],
        },
        {
          title: '< 30yds',
          units: 'imperial',
          key: 'sg_d_30',
          benchmarktype: 'H',
          benchmarks: [
            0.380, 0.115, -0.291, -0.704, -1.360, -1.947, -2.379, -3.171, -3.530, -6.075, -0.157, 0.000,
            0.173, -0.065, -0.349, -1.006, -1.419, -2.105, -2.294, -2.677, -3.071, -5.075, -0.169, -0.130,
          ],
        },
        {
          title: '30-60yds',
          units: 'imperial',
          key: 'sg_d_30_60',
          benchmarktype: 'H',
          benchmarks: [
            0.077, -0.022, -0.068, -0.167, -0.221, -0.449, -0.468, -0.575, -0.973, -1.458, -0.055, 0.000,
            0.062, 0.075, -0.155, -0.242, -0.408, -0.483, -0.663, -0.659, -0.761, -1.458, -0.092, -0.021,
          ],
        },
        {
          title: '60-70yds',
          units: 'imperial',
          key: 'sg_d_60_70',
          benchmarktype: 'H',
          benchmarks: [
            0.026, 0.015, -0.007, -0.032, -0.055, -0.079, -0.099, -0.175, -0.627, -0.865, -0.009, 0.000,
            0.006, -0.016, -0.049, -0.052, -0.093, -0.141, -0.186, -0.196, -0.348, -0.865, -0.098, -0.007,
          ],
        },
        {
          title: '70-80yds',
          units: 'imperial',
          key: 'sg_d_70_80',
          benchmarktype: 'H',
          benchmarks: [
            0.023, 0.019, -0.018, -0.020, -0.082, -0.143, -0.157, -0.210, -0.413, -1.012, 0.020, 0.000,
            0.109, 0.023, -0.067, -0.098, -0.118, -0.207, -0.224, -0.234, -0.283, -0.812, -0.091, -0.029,
          ],
        },
        {
          title: '80-90yds',
          units: 'imperial',
          key: 'sg_d_80_90',
          benchmarktype: 'H',
          benchmarks: [
            0.031, 0.007, -0.021, -0.018, -0.129, -0.170, -0.198, -0.223, -0.397, -1.099, 0.015, 0.000,
            0.043, 0.003, -0.059, -0.133, -0.181, -0.258, -0.374, -0.486, -0.673, -1.099, -0.119, -0.068,
          ],
        },
        {
          title: '90-100yds',
          units: 'imperial',
          key: 'sg_d_90_100',
          benchmarktype: 'H',
          benchmarks: [
            0.055, 0.025, -0.031, -0.049, -0.089, -0.119, -0.180, -0.336, -0.570, -0.986, 0.011, 0.000,
            0.032, 0.004, -0.104, -0.132, -0.239, -0.274, -0.308, -0.400, 0.419, -0.686, -0.204, -0.016,
          ],
        },
        {
          title: '100-110yds',
          units: 'imperial',
          key: 'sg_d_100_110',
          benchmarktype: 'H',
          benchmarks: [
            0.060, 0.011, -0.063, -0.097, -0.102, -0.229, -0.239, -0.379, -0.498, -0.963, -0.008, 0.000,
            0.026, -0.266, -0.294, -0.410, -0.539, -0.761, -0.873, -0.961, -1.010, -1.263, -0.099, 0.011,
          ],
        },
        {
          title: '110-120yds',
          units: 'imperial',
          key: 'sg_d_110_120',
          benchmarktype: 'H',
          benchmarks: [
            0.068, 0.024, -0.024, -0.067, -0.083, -0.177, -0.241, -0.275, -0.570, -1.290, -0.001, 0.000,
            0.025, -0.012, -0.072, -0.177, -0.287, -0.363, -0.384, -0.410, -0.697, -1.090, -0.138, -0.007,
          ],
        },
        {
          title: '120-130yds',
          units: 'imperial',
          key: 'sg_d_120_130',
          benchmarktype: 'H',
          benchmarks: [
            0.064, -0.014, -0.047, -0.079, -0.169, -0.197, -0.210, -0.394, -0.860, -1.235, 0.012, 0.000,
            -0.007, -0.127, -0.207, -0.232, -0.370, -0.519, -0.625, -0.641, -0.754, -1.035, -0.146, -0.058,
          ],
        },
        {
          title: '130-140yds',
          units: 'imperial',
          key: 'sg_d_130_140',
          benchmarktype: 'H',
          benchmarks: [
            0.061, 0.003, -0.058, -0.146, -0.232, -0.324, -0.399, -0.519, -0.892, -1.305, 0.003, 0.000,
            -0.025, -0.038, -0.168, -0.263, -0.362, -0.520, -0.661, -0.752, -0.830, -1.105, -0.147, -0.122,
          ],
        },
        {
          title: '140-150yds',
          units: 'imperial',
          key: 'sg_d_140_150',
          benchmarktype: 'H',
          benchmarks: [
            0.061, -0.027, -0.101, -0.181, -0.212, -0.369, -0.376, -0.531, -0.693, -1.163, -0.020, 0.000,
            0.004, -0.092, -0.177, -0.328, -0.472, -0.659, -0.718, -0.972, -0.983, -1.163, -0.199, -0.125,
          ],
        },
        {
          title: '150-160yds',
          units: 'imperial',
          key: 'sg_d_150_160',
          benchmarktype: 'H',
          benchmarks: [
            0.047, -0.007, -0.127, -0.214, -0.285, -0.521, -0.607, -0.636, -0.814, -1.455, -0.061, 0.000,
            -0.040, -0.128, -0.331, -0.366, -0.507, -0.634, -0.621, -0.624, 0.689, -0.955, -0.152, -0.118,
          ],
        },
        {
          title: '160-170yds',
          units: 'imperial',
          key: 'sg_d_160_170',
          benchmarktype: 'H',
          benchmarks: [
            0.043, -0.018, -0.101, -0.184, -0.291, -0.512, -0.656, -0.796, -1.429, -1.845, -0.031, 0.000,
            -0.069, -0.163, -0.258, -0.301, -0.367, -0.483, -0.514, -0.653, -0.920, -1.245, -0.160, -0.167,
          ],
        },
        {
          title: '170-180yds',
          units: 'imperial',
          key: 'sg_d_170_180',
          benchmarktype: 'H',
          benchmarks: [
            0.007, -0.035, -0.101, -0.196, -0.324, -0.432, -0.466, -0.482, -0.741, -0.997, -0.109, 0.000,
            -0.027, -0.089, -0.114, -0.199, -0.231, -0.249, -0.351, -0.497, -0.869, -1.197, -0.141, -0.135,
          ],
        },
        {
          title: '180-190yds',
          units: 'imperial',
          key: 'sg_d_180_190',
          benchmarktype: 'H',
          benchmarks: [
            0.043, -0.062, -0.123, -0.155, -0.272, -0.362, -0.502, -0.519, -0.772, -0.930, -0.111, 0.000,
            -0.036, -0.127, -0.129, -0.195, -0.212, -0.283, -0.304, -0.595, -0.656, -0.930, -0.117, -0.153,
          ],
        },
        {
          title: '190-200yds',
          units: 'imperial',
          key: 'sg_d_190_200',
          benchmarktype: 'H',
          benchmarks: [
            0.031, -0.046, -0.111, -0.167, -0.313, -0.393, -0.454, -0.472, -0.609, -1.213, -0.082, 0.000,
            -0.081, -0.150, -0.165, -0.215, -0.219, -0.222, -0.437, -0.637, -0.789, -1.213, -0.123, -0.112,
          ],
        },
        {
          title: '200-220yds',
          units: 'imperial',
          key: 'sg_d_200_220',
          benchmarktype: 'H',
          benchmarks: [
            0.019, -0.085, -0.160, -0.285, -0.366, -0.406, -0.549, -0.800, -1.184, -1.913, -0.088, 0.000,
            -0.193, -0.210, -0.284, -0.400, -0.417, -0.437, -0.681, -0.722, -0.871, -1.413, -0.182, -0.188,
          ],
        },
        {
          title: '220-250yds',
          units: 'imperial',
          key: 'sg_d_220_250',
          benchmarktype: 'H',
          benchmarks: [
            0.031, -0.055, -0.170, -0.237, -0.265, -0.360, -0.371, -0.428, -0.668, -1.110, -0.090, 0.000,
            -0.278, -0.292, -0.375, -0.384, -0.390, -0.395, -0.438, -0.532, -0.611, -1.110, -0.311, -0.358,
          ],
        },
        {
          title: '> 250yds',
          units: 'imperial',
          key: 'sg_d_250',
          benchmarktype: 'H',
          benchmarks: [
            0.203, -0.326, -1.085, -1.871, -2.864, -3.532, -4.758, -5.646, -7.381, -8.388, -0.637, 0.000,
            -1.568, -1.783, -2.315, -2.613, -3.012, -3.899, -4.786, -4.894, -6.001, -8.388, -1.427, -1.541,
          ],
        },
      ],
    },
  ],
  proximity: [
    {
      title: 'Spider Proximity',
      tabTitle: 'M/Yds',
      key: 'dispersion',
      noGraph: true,
      tables: [
        {
          title: '< 30yds',
          units: 'imperial',
          key: 'target_0_30',
          key2: 'flag_0_30',
          benchmarktype: 'L',
          benchmarks: [
            +2.4, +2.7, +3.0, +3.1, +3.5, +4.0, +3.9, +4.3, +4.5, +6.8, +3.1, +2.1,
            +2.5, +2.9, +3.1, +3.5, +4.1, +4.3, +4.6, +4.8, +5.1, +5.3, +3.0, +2.7,
          ],
        },
        {
          title: '30-60yds',
          units: 'imperial',
          key: 'target_30_60',
          key2: 'flag_30_60',
          benchmarktype: 'L',
          benchmarks: [
            +4.9, +5.6, +5.7, +6.2, +7.1, +8.1, +8.3, +8.1, +9.5, +10.5, +5.8, +4.8,
            +5.6, +5.7, +6.4, +6.9, +7.4, +8.0, +8.5, +8.4, +8.6, +8.9, +5.9, +5.8,
          ],
        },
        {
          title: '60-70yds',
          units: 'imperial',
          key: 'target_60_70',
          key2: 'flag_60_70',
          benchmarktype: 'L',
          benchmarks: [
            +5.7, +6.1, +6.5, +7.3, +8.5, +9.1, +8.2, +10.0, +12.3, +13.2, +6.4, +6.3,
            +5.3, +5.8, +6.4, +6.7, +7.8, +7.9, +8.3, +8.6, +8.9, +9.2, +5.9, +5.5,
          ],
        },
        {
          title: '70-80yds',
          units: 'imperial',
          key: 'target_70_80',
          key2: 'flag_70_80',
          benchmarktype: 'L',
          benchmarks: [
            +5.8, +6.0, +7.2, +7.8, +8.2, +8.7, +9.0, +11.5, +13.2, +14.5, +5.8, +6.5,
            +4.7, +5.6, +7.2, +7.6, +8.1, +9.2, +10.2, +11.4, +12.4, +12.6, +5.8, +4.8,
          ],
        },
        {
          title: '80-90yds',
          units: 'imperial',
          key: 'target_80_90',
          key2: 'flag_80_90',
          benchmarktype: 'L',
          benchmarks: [
            +5.9, +6.5, +7.2, +7.7, +9.5, +9.3, +9.1, +11.2, +12.8, +13.4, +6.6, +6.6,
            +5.5, +6.5, +7.6, +8.3, +8.9, +10.8, +11.2, +11.6, +12.1, +12.3, +7.2, +5.7,
          ],
        },
        {
          title: '90-100yds',
          units: 'imperial',
          key: 'target_90_100',
          key2: 'flag_90_100',
          benchmarktype: 'L',
          benchmarks: [
            +6.1, +6.8, +7.7, +7.9, +9.0, +10.0, +10.4, +12.9, +12.5, +14.2, +6.9, +6.8,
            +5.9, +7.1, +7.6, +8.6, +9.9, +10.8, +11.2, +11.6, +12.4, +12.5, +7.3, +5.9,
          ],
        },
        {
          title: '100-110yds',
          units: 'imperial',
          key: 'target_100_110',
          key2: 'flag_100_110',
          benchmarktype: 'L',
          benchmarks: [
            +6.7, +7.1, +8.0, +8.6, +8.3, +10.2, +9.7, +12.4, +12.5, +15.3, +7.1, +7.2,
            +6.7, +7.9, +8.2, +8.2, +9.2, +10.9, +11.3, +11.8, +12.6, +12.9, +8.2, +6.9,
          ],
        },
        {
          title: '110-120yds',
          units: 'imperial',
          key: 'target_110_120',
          key2: 'flag_110_120',
          benchmarktype: 'L',
          benchmarks: [
            +7.0, +7.5, +7.9, +8.4, +9.0, +9.7, +10.5, +10.8, +11.6, +14.6, +7.8, +7.4,
            +7.6, +7.6, +8.2, +9.1, +9.8, +12.3, +13.1, +12.9, +13.4, +13.8, +7.8, +7.8,
          ],
        },
        {
          title: '120-130yds',
          units: 'imperial',
          key: 'target_120_130',
          key2: 'flag_120_130',
          benchmarktype: 'L',
          benchmarks: [
            +7.6, +8.4, +8.8, +9.1, +9.9, +10.5, +11.1, +12.1, +13.4, +14.5, +8.1, +7.8,
            +7.3, +7.6, +9.5, +9.6, +11.1, +12.2, +13.4, +13.6, +13.9, +14.5, +7.8, +7.5,
          ],
        },
        {
          title: '130-140yds',
          units: 'imperial',
          key: 'target_130_140',
          key2: 'flag_130_140',
          benchmarktype: 'L',
          benchmarks: [
            +7.9, +8.6, +9.0, +9.8, +10.8, +12.1, +11.2, +14.4, +16.2, +17.6, +8.5, +8.2,
            +8.9, +9.1, +10.0, +10.2, +11.3, +13.0, +13.6, +13.7, +14.1, +14.6, +9.3, +8.9,
          ],
        },
        {
          title: '140-150yds',
          units: 'imperial',
          key: 'target_140_150',
          key2: 'flag_140_150',
          benchmarktype: 'L',
          benchmarks: [
            +8.6, +9.3, +10.0, +10.7, +10.9, +12.1, +11.8, +15.0, +15.9, +16.5, +9.3, +8.7,
            +9.3, +9.4, +10.2, +10.6, +12.4, +15.0, +15.2, +15.3, +16.2, +16.8, +9.6, +9.6,
          ],
        },
        {
          title: '150-160yds',
          units: 'imperial',
          key: 'target_150_160',
          key2: 'flag_150_160',
          benchmarktype: 'L',
          benchmarks: [
            +9.0, +9.6, +10.5, +10.6, +11.9, +13.0, +13.6, +14.5, +15.2, +16.1, +10.2, +9.5,
            +10.2, +11.7, +12.0, +12.3, +14.7, +15.6, +15.8, +15.5, +16.7, +17.5, +11.6, +10.4,
          ],
        },
        {
          title: '160-170yds',
          units: 'imperial',
          key: 'target_160_170',
          key2: 'flag_160_170',
          benchmarktype: 'L',
          benchmarks: [
            +9.7, +9.9, +11.1, +11.6, +12.6, +14.4, +14.4, +16.3, +17.0, +17.6, +10.2, +10.7,
            +10.4, +12.2, +13.8, +13.8, +14.0, +16.1, +16.3, +16.4, +17.8, +18.2, +12.7, +10.5,
          ],
        },
        {
          title: '170-180yds',
          units: 'imperial',
          key: 'target_170_180',
          key2: 'flag_170_180',
          benchmarktype: 'L',
          benchmarks: [
            +10.5, +10.9, +11.5, +11.7, +13.2, +14.4, +17.1, +18.0, +19.5, +20.6, +11.9, +12.1,
            +12.1, +12.4, +13.7, +16.1, +17.4, +18.6, +18.8, +19.0, +22.4, +25.6, +12.6, +12.3,
          ],
        },
        {
          title: '180-190yds',
          units: 'imperial',
          key: 'target_180_190',
          key2: 'flag_180_190',
          benchmarktype: 'L',
          benchmarks: [
            +10.5, +11.9, +12.4, +12.9, +13.1, +15.3, +16.5, +18.9, +20.0, +22.4, +12.3, +13.6,
            +14.0, +14.5, +15.2, +16.7, +19.5, +20.7, +20.9, +21.2, +24.8, +27.8, +14.8, +14.2,
          ],
        },
        {
          title: '190-200yds',
          units: 'imperial',
          key: 'target_190_200',
          key2: 'flag_190_200',
          benchmarktype: 'L',
          benchmarks: [
            +11.1, +12.2, +13.3, +13.9, +16.4, +14.9, +19.3, +18.2, +24.6, +26.6, +12.8, +14.7,
            +14.4, +16.6, +18.3, +17.2, +21.9, +23.0, +23.7, +24.5, +28.9, +30.5, +16.9, +14.6,
          ],
        },
        {
          title: '200-220yds',
          units: 'imperial',
          key: 'target_200_220',
          key2: 'flag_200_220',
          benchmarktype: 'L',
          benchmarks: [
            +12.6, +13.9, +14.8, +15.2, +16.8, +19.3, +20.2, +21.6, +24.6, +26.9, +14.3, +16.2,
            +16.3, +17.2, +21.6, +22.3, +25.4, +26.6, +27.5, +29.6, +34.5, +38.6, +17.8, +16.5,
          ],
        },
        {
          title: '220-250yds',
          units: 'imperial',
          key: 'target_220_250',
          key2: 'flag_220_250',
          benchmarktype: 'L',
          benchmarks: [
            +15.6, +16.4, +18.5, +19.8, +19.0, +18.7, +22.7, +25.5, +28.5, +30.4, +17.0, +18.9,
            +23.7, +25.4, +27.2, +28.8, +29.8, +30.1, +31.2, +34.1, +38.4, +40.2, +26.8, +23.8,
          ],
        },
        {
          title: '> 250yds',
          units: 'imperial',
          key: 'target_250',
          key2: 'flag_250',
          benchmarktype: 'L',
          benchmarks: [
            +20.5, +20.9, +23.1, +24.3, +26.8, +28.7, +28.6, +29.2, +29.8, +32.1, +22.1, +24.8,
            +32.0, +33.6, +34.1, +33.3, +34.7, +36.0, +36.5, +36.8, +39.5, +44.5, +34.5, +32.2,
          ],
        },

        {
          title: '< 30m',
          units: 'metric',
          key: 'target_0_30',
          key2: 'flag_0_30',
          benchmarktype: 'L',
          benchmarks: [
            +2.5, +2.8, +3.1, +3.2, +3.6, +4.0, +4.1, +4.5, +4.6, +5.2, +3.2, +2.1,
            +2.6, +3.0, +3.3, +3.6, +4.2, +4.3, +4.6, +4.8, +4.9, +5.1, +3.1, +2.7,
          ],
        },
        {
          title: '30-60m',
          units: 'metric',
          key: 'target_30_60',
          key2: 'flag_30_60',
          benchmarktype: 'L',
          benchmarks: [
            +5.1, +5.8, +6.1, +6.7, +7.8, +9.0, +7.4, +9.0, +12.6, +13.2, +6.2, +4.5,
            +5.6, +5.8, +6.7, +7.2, +8.2, +8.9, +9.2, +9.6, +9.8, +10.4, +6.1, +5.8,
          ],
        },
        {
          title: '60-70m',
          units: 'metric',
          key: 'target_60_70',
          key2: 'flag_60_70',
          benchmarktype: 'L',
          benchmarks: [
            +5.9, +6.0, +7.1, +7.2, +8.0, +8.3, +8.7, +11.1, +12.4, +13.4, +6.0, +5.8,
            +5.2, +5.7, +7.0, +7.1, +7.5, +8.9, +9.2, +9.4, +9.6, +9.9, +5.9, +5.4,
          ],
        },
        {
          title: '70-80m',
          units: 'metric',
          key: 'target_70_80',
          key2: 'flag_70_80',
          benchmarktype: 'L',
          benchmarks: [
            +5.9, +6.3, +7.2, +7.5, +9.2, +9.4, +9.0, +11.1, +12.8, +13.5, +6.4, +6.0,
            +5.4, +6.6, +7.6, +8.6, +9.2, +11.9, +12.4, +12.6, +12.8, +13.1, +7.2, +5.6,
          ],
        },
        {
          title: '80-90m',
          units: 'metric',
          key: 'target_80_90',
          key2: 'flag_80_90',
          benchmarktype: 'L',
          benchmarks: [
            +6.0, +6.7, +7.6, +7.9, +9.3, +10.0, +11.0, +12.5, +12.5, +13.2, +6.8, +6.2,
            +5.6, +6.8, +7.5, +8.4, +9.8, +10.7, +11.6, +11.7, +12.1, +12.4, +7.3, +5.7,
          ],
        },
        {
          title: '90-100m',
          units: 'metric',
          key: 'target_90_100',
          key2: 'flag_90_100',
          benchmarktype: 'L',
          benchmarks: [
            +6.7, +7.1, +8.0, +8.6, +8.2, +10.0, +10.0, +12.5, +10.2, +12.4, +7.1, +6.6,
            +6.8, +8.0, +8.2, +8.2, +9.2, +10.8, +11.4, +11.6, +12.2, +12.5, +8.1, +6.9,
          ],
        },
        {
          title: '100-110m',
          units: 'metric',
          key: 'target_100_110',
          key2: 'flag_100_110',
          benchmarktype: 'L',
          benchmarks: [
            +7.2, +7.7, +8.1, +8.4, +9.0, +10.6, +9.9, +11.2, +12.6, +13.6, +7.8, +6.8,
            +8.1, +8.0, +8.4, +9.5, +10.0, +11.7, +12.4, +12.4, +12.6, +12.7, +8.2, +8.3,
          ],
        },
        {
          title: '110-120m',
          units: 'metric',
          key: 'target_110_120',
          key2: 'flag_110_120',
          benchmarktype: 'L',
          benchmarks: [
            +7.6, +8.4, +8.9, +8.9, +10.4, +11.1, +12.5, +14.1, +15.4, +16.4, +8.2, +7.1,
            +8.6, +9.0, +9.5, +9.7, +10.9, +12.1, +12.6, +12.8, +12.9, +13.4, +9.3, +8.8,
          ],
        },
        {
          title: '120-130m',
          units: 'metric',
          key: 'target_120_130',
          key2: 'flag_120_130',
          benchmarktype: 'L',
          benchmarks: [
            +8.0, +8.7, +9.3, +10.1, +10.7, +11.7, +10.4, +13.7, +14.8, +15.4, +8.8, +7.5,
            +8.4, +9.0, +10.3, +10.2, +12.0, +13.2, +14.2, +14.6, +15.7, +15.5, +9.4, +8.5,
          ],
        },
        {
          title: '130-140m',
          units: 'metric',
          key: 'target_130_140',
          key2: 'flag_130_140',
          benchmarktype: 'L',
          benchmarks: [
            +8.9, +9.4, +10.2, +10.7, +11.7, +12.2, +12.4, +15.1, +16.2, +17.2, +9.5, +8.0,
            +9.8, +10.3, +11.0, +11.4, +13.0, +14.8, +15.4, +15.9, +16.2, +16.4, +10.4, +9.9,
          ],
        },
        {
          title: '140-150m',
          units: 'metric',
          key: 'target_140_150',
          key2: 'flag_140_150',
          benchmarktype: 'L',
          benchmarks: [
            +9.1, +9.9, +10.8, +11.2, +12.1, +13.6, +14.0, +15.6, +18.5, +19.2, +10.1, +8.9,
            +9.8, +11.9, +12.5, +12.9, +14.9, +15.8, +16.2, +16.4, +16.7, +17.2, +12.1, +9.9,
          ],
        },
        {
          title: '150-160m',
          units: 'metric',
          key: 'target_150_160',
          key2: 'flag_150_160',
          benchmarktype: 'L',
          benchmarks: [
            +10.3, +10.5, +11.1, +11.9, +12.7, +14.7, +13.4, +17.2, +19.3, +20.1, +11.1, +10.6,
            +11.6, +12.2, +13.9, +15.2, +16.1, +18.0, +19.0, +20.3, +21.4, +21.8, +12.4, +11.8,
          ],
        },
        {
          title: '160-170m',
          units: 'metric',
          key: 'target_160_170',
          key2: 'flag_160_170',
          benchmarktype: 'L',
          benchmarks: [
            +10.4, +11.3, +12.1, +12.2, +13.0, +15.0, +15.3, +19.2, +22.1, +22.6, +12.1, +11.5,
            +14.1, +14.4, +13.3, +16.1, +17.5, +18.5, +18.6, +19.4, +22.5, +22.9, +13.5, +14.3,
          ],
        },
        {
          title: '170-180m',
          units: 'metric',
          key: 'target_170_180',
          key2: 'flag_170_180',
          benchmarktype: 'L',
          benchmarks: [
            +10.9, +12.2, +13.0, +13.5, +15.9, +14.9, +17.2, +17.9, +22.6, +23.4, +12.7, +12.8,
            +14.6, +15.5, +13.0, +14.1, +18.0, +22.1, +22.6, +23.4, +24.6, +24.5, +14.8, +14.7,
          ],
        },
        {
          title: '180-190m',
          units: 'metric',
          key: 'target_180_190',
          key2: 'flag_180_190',
          benchmarktype: 'L',
          benchmarks: [
            +12.0, +13.0, +14.3, +15.0, +16.1, +17.2, +16.7, +20.4, +24.8, +25.8, +13.4, +14.5,
            +15.5, +17.6, +16.1, +17.5, +23.7, +24.2, +25.1, +27.3, +28.2, +28.7, +16.4, +15.7,
          ],
        },
        {
          title: '190-200m',
          units: 'metric',
          key: 'target_190_200',
          key2: 'flag_190_200',
          benchmarktype: 'L',
          benchmarks: [
            +13.2, +14.8, +15.2, +15.8, +17.4, +21.1, +18.5, +22.0, +25.7, +26.7, +15.5, +16.2,
            +15.3, +16.0, +20.3, +24.6, +26.9, +25.7, +26.4, +28.6, +29.9, +30.1, +18.2, +15.5,
          ],
        },
        {
          title: '200-220m',
          units: 'metric',
          key: 'target_200_220',
          key2: 'flag_200_220',
          benchmarktype: 'L',
          benchmarks: [
            +14.8, +16.0, +17.9, +19.4, +18.9, +18.7, +23.4, +24.5, +28.4, +30.2, +16.7, +18.6,
            +23.7, +28.7, +27.0, +28.5, +29.0, +29.1, +30.1, +32.4, +33.4, +35.7, +26.8, +23.8,
          ],
        },
        {
          title: '220-250m',
          units: 'metric',
          key: 'target_220_250',
          key2: 'flag_220_250',
          benchmarktype: 'L',
          benchmarks: [
            +19.1, +19.3, +21.4, +22.5, +24.9, +28.8, +29.3, +28.6, +27.5, +31.7, +19.8, +22.6,
            +26.3, +31.8, +28.3, +27.9, +31.0, +32.0, +33.0, +35.6, +35.9, +37.4, +28.6, +26.4,
          ],
        },
        {
          title: '> 250m',
          units: 'metric',
          key: 'target_250',
          key2: 'flag_250',
          benchmarktype: 'L',
          benchmarks: [
            +21.4, +21.9, +24.6, +25.4, +28.5, +28.1, +27.3, +30.4, +31.1, +33.5, +22.1, +27.6,
            +28.1, +32.0, +28.9, +32.0, +34.6, +35.0, +36.6, +38.2, +40.1, +41.5, +29.6, +28.3,
          ],
        },
      ],
    },
    {
      title: 'Spider Proximity',
      tabTitle: '%',
      key: 'dispersion',
      noGraph: true,
      tables: [
        {
          title: '< 30yds',
          units: 'imperial',
          key: 'percent_target_0_30',
          key2: 'percent_flag_0_30',
          benchmarktype: 'L',
          benchmarks: [
            +17.3, +19.5, +22.5, +25.0, +29.0, +31.5, +33.6, +35.4, +37.2, +39.7, +19.5, +7.0,
            +18.4, +21.7, +25.0, +27.4, +30.8, +34.6, +35.6, +36.0, +38.1, +39.4, +22.4, +18.6,
          ],
        },
        {
          title: '30-60yds',
          units: 'imperial',
          key: 'percent_target_30_60',
          key2: 'percent_flag_30_60',
          benchmarktype: 'L',
          benchmarks: [
            +12.7, +14.7, +15.3, +16.4, +18.9, +20.7, +22.4, +23.5, +23.7, +25.4, +13.4, +10.6,
            +13.8, +14.8, +16.7, +17.9, +19.6, +20.7, +21.6, +21.8, +22.4, +23.5, +15.1, +13.9,
          ],
        },
        {
          title: '60-70yds',
          units: 'imperial',
          key: 'percent_target_60_70',
          key2: 'percent_flag_60_70',
          benchmarktype: 'L',
          benchmarks: [
            +9.7, +10.3, +11.1, +12.3, +14.6, +15.5, +14.8, +16.8, +19.8, +21.4, +10.2, +9.7,
            +9.0, +9.8, +11.5, +10.9, +13.0, +13.4, +14.3, +14.5, +15.4, +15.8, +10.2, +9.5,
          ],
        },
        {
          title: '70-80yds',
          units: 'imperial',
          key: 'percent_target_70_80',
          key2: 'percent_flag_70_80',
          benchmarktype: 'L',
          benchmarks: [
            +8.4, +8.8, +10.5, +11.2, +12.0, +12.6, +13.5, +16.7, +18.4, +20.3, +8.5, +8.7,
            +6.9, +8.1, +10.6, +11.2, +11.8, +13.5, +14.6, +14.8, +15.4, +15.6, +9.2, +6.9,
          ],

        },
        {
          title: '80-90yds',
          units: 'imperial',
          key: 'percent_target_80_90',
          key2: 'percent_flag_80_90',
          benchmarktype: 'L',
          benchmarks: [
            +7.6, +8.3, +9.3, +9.6, +12.2, +11.9, +12.6, +14.3, +16.2, +18.6, +8.5, +7.7,
            +7.1, +8.4, +9.8, +10.7, +11.4, +12.3, +13.5, +13.6, +14.1, +14.8, +9.2, +7.5,
          ],
        },
        {
          title: '90-100yds',
          units: 'imperial',
          key: 'percent_target_90_100',
          key2: 'percent_flag_90_100',
          benchmarktype: 'L',
          benchmarks: [
            +7.0, +7.8, +8.9, +9.4, +10.3, +11.4, +11.7, +14.6, +15.4, +17.5, +7.9, +7.1,
            +6.8, +8.2, +8.7, +9.9, +11.3, +12.3, +13.4, +13.4, +14.2, +14.8, +8.5, +6.9,
          ],
        },
        {
          title: '100-110yds',
          units: 'imperial',
          key: 'percent_target_100_110',
          key2: 'percent_flag_100_110',
          benchmarktype: 'L',
          benchmarks: [
            +6.9, +7.4, +8.4, +8.9, +8.5, +10.5, +10.5, +12.6, +14.1, +15.4, +7.3, +6.8,
            +7.0, +8.2, +8.5, +8.6, +9.6, +11.2, +12.6, +12.8, +13.2, +14.2, +8.3, +7.4,
          ],
        },
        {
          title: '110-120yds',
          units: 'imperial',
          key: 'percent_target_110_120',
          key2: 'percent_flag_110_120',
          benchmarktype: 'L',
          benchmarks: [
            +6.6, +7.2, +7.6, +7.9, +8.5, +9.3, +9.3, +10.2, +11.3, +13.4, +7.4, +6.4,
            +7.0, +7.2, +7.8, +8.7, +9.3, +11.7, +12.4, +12.6, +13.1, +13.7, +7.6, +7.4,
          ],
        },
        {
          title: '120-130yds',
          units: 'imperial',
          key: 'percent_target_120_130',
          key2: 'percent_flag_120_130',
          benchmarktype: 'L',
          benchmarks: [
            +6.6, +7.4, +7.7, +8.2, +8.8, +9.2, +9.8, +10.7, +13.4, +15.4, +7.1, +6.2,
            +7.4, +8.0, +8.4, +8.5, +9.8, +9.9, +10.3, +11.3, +12.1, +12.4, +8.2, +7.8,
          ],
        },
        {
          title: '130-140yds',
          units: 'imperial',
          key: 'percent_target_130_140',
          key2: 'percent_flag_130_140',
          benchmarktype: 'L',
          benchmarks: [
            +6.4, +7.0, +7.3, +7.9, +8.8, +9.8, +9.8, +11.8, +12.9, +13.9, +6.9, +6.0,
            +7.1, +7.3, +8.1, +8.3, +9.2, +10.6, +11.6, +12.4, +13.4, +14.1, +7.5, +7.6,
          ],
        },
        {
          title: '140-150yds',
          units: 'imperial',
          key: 'percent_target_140_150',
          key2: 'percent_flag_140_150',
          benchmarktype: 'L',
          benchmarks: [
            +6.5, +7.0, +7.5, +8.1, +8.2, +9.2, +8.5, +11.3, +12.5, +13.5, +7.0, +6.0,
            +7.0, +7.2, +7.7, +8.0, +9.3, +11.2, +12.4, +12.6, +13.2, +13.7, +7.6, +7.4,
          ],
        },
        {
          title: '150-160yds',
          units: 'imperial',
          key: 'percent_target_150_160',
          key2: 'percent_flag_150_160',
          benchmarktype: 'L',
          benchmarks: [
            +6.4, +6.8, +7.4, +7.5, +8.4, +9.2, +9.0, +10.2, +14.6, +15.7, +7.2, +6.1,
            +7.2, +8.3, +8.5, +8.7, +10.4, +11.3, +12.3, +12.8, +13.1, +13.6, +8.4, +7.5,
          ],
        },
        {
          title: '160-170yds',
          units: 'imperial',
          key: 'percent_target_160_170',
          key2: 'percent_flag_160_170',
          benchmarktype: 'L',
          benchmarks: [
            +6.4, +6.8, +7.3, +7.7, +8.3, +9.5, +8.8, +10.8, +14.0, +15.2, +6.8, +6.5,
            +6.9, +9.0, +9.2, +9.2, +10.3, +10.7, +11.4, +11.7, +12.2, +12.8, +9.1, +6.9,
          ],
        },
        {
          title: '170-180yds',
          units: 'imperial',
          key: 'percent_target_170_180',
          key2: 'percent_flag_170_180',
          benchmarktype: 'L',
          benchmarks: [
            +6.6, +6.8, +7.2, +7.3, +8.3, +9.0, +9.8, +11.2, +13.1, +14.6, +7.4, +6.9,
            +7.5, +7.7, +8.6, +10.1, +11.3, +11.7, +12.1, +12.9, +13.4, +15.4, +7.9, +7.8,
          ],
        },
        {
          title: '180-190yds',
          units: 'imperial',
          key: 'percent_target_180_190',
          key2: 'percent_flag_180_190',
          benchmarktype: 'L',
          benchmarks: [
            +6.2, +7.1, +7.3, +7.7, +7.8, +9.1, +9.1, +11.2, +13.4, +15.2, +7.3, +7.3,
            +8.2, +8.6, +7.8, +9.9, +11.6, +12.1, +13.1, +13.4, +14.2, +15.4, +8.5, +8.4,
          ],
        },
        {
          title: '190-200yds',
          units: 'imperial',
          key: 'percent_target_190_200',
          key2: 'percent_flag_190_200',
          benchmarktype: 'L',
          benchmarks: [
            +6.2, +6.9, +7.5, +8.6, +9.2, +8.4, +9.8, +10.2, +12.5, +15.2, +7.2, +7.5,
            +8.1, +9.4, +10.4, +11.8, +12.3, +13.9, +14.5, +15.6, +16.7, +18.4, +9.8, +8.5,
          ],
        },
        {
          title: '200-220yds',
          units: 'imperial',
          key: 'percent_target_200_220',
          key2: 'percent_flag_200_220',
          benchmarktype: 'L',
          benchmarks: [
            +6.6, +7.3, +7.8, +8.4, +8.8, +10.1, +9.0, 11.3, +13.5, +15.8, +7.5, +8.9,
            +9.6, +11.1, +11.2, +11.6, +13.1, +13.8, +14.3, +15.2, +16.4, +18.9, +11.5, +9.9,
          ],
        },
        {
          title: '220-250yds',
          units: 'imperial',
          key: 'percent_target_220_250',
          key2: 'percent_flag_220_250',
          benchmarktype: 'L',
          benchmarks: [
            +7.3, +7.7, +8.7, +8.8, +9.0, +8.9, +11.3, +12.0, +15.2, +17.5, +7.9, +8.0,
            +11.3, +12.7, +12.8, +13.7, +14.1, +15.6, +16.5, +17.4, +19.4, +22.4, +12.9, +11.5,
          ],
        },
        {
          title: '> 250yds',
          units: 'imperial',
          key: 'percent_target_250',
          key2: 'percent_flag_250',
          benchmarktype: 'L',
          benchmarks: [
            +7.8, +7.9, +8.9, +9.2, +10.2, +11.0, +12.5, +12.9, +14.5, +18.2, +8.4, +10.0
            + 12.1, +12.3, +13.1, +14.1, +15.5, +16.4, +17.2, +18.3, +19.8, +23.1, +13.0, +12.5,
          ],
        },

        {
          title: '< 30m',
          units: 'metric',
          key: 'percent_target_0_30',
          key2: 'percent_flag_0_30',
          benchmarktype: 'L',
          benchmarks: [
            +17.2, +19.8, +22.2, +24.7, +28.7, +30.9, +31.1, +36.4, +38.2, +48.5, +21.6, +7.0,
            +18.5, +21.4, +24.8, +27.0, +30.2, +35.9, +36.2, +37.6, +38.4, +39.4, +22.2, +18.8,
          ],
        },
        {
          title: '30-60m',
          units: 'metric',
          key: 'percent_target_30_60',
          key2: 'percent_flag_30_60',
          benchmarktype: 'L',
          benchmarks: [
            +11.8, +13.4, +14.0, +14.9, +17.5, +20.6, +16.6, +19.2, +25.9, +26.7, +14.2, +10.0,
            +12.6, +12.9, +15.1, +16.1, +18.5, +19.3, +20.3, +20.8, +21.4, +22.4, +13.2, +12.9,
          ],
        },
        {
          title: '60-70m',
          units: 'metric',
          key: 'percent_target_60_70',
          key2: 'percent_flag_60_70',
          benchmarktype: 'L',
          benchmarks: [
            +8.9, +9.1, +10.7, +10.8, +12.1, +12.5, +13.2, +16.1, +18.5, +22.5, +9.1, +9.0,
            +7.8, +8.7, +10.6, +10.6, +11.4, +13.3, +13.4, +13.7, +14.5, +15.1, +9.1, +7.9,
          ],
        },
        {
          title: '70-80m',
          units: 'metric',
          key: 'percent_target_70_80',
          key2: 'percent_flag_70_80',
          benchmarktype: 'L',
          benchmarks: [
            +7.8, +8.3, +9.5, +9.5, +12.1, +12.3, +11.9, +14.5, +16.2, +18.6, +8.5, +8.0,
            +7.1, +8.7, +10.0, +11.3, +12.1, +15.5, +16.2, +16.4, +16.8, +16.9, +9.1, +7.5,
          ],

        },
        {
          title: '80-90m',
          units: 'metric',
          key: 'percent_target_80_90',
          key2: 'percent_flag_80_90',
          benchmarktype: 'L',
          benchmarks: [
            +6.9, +7.8, +8.9, +9.5, +10.8, +11.6, +12.9, +14.3, +14.1, +16.4, +7.9, +8.2,
            +6.6, +7.9, +8.8, +9.8, +11.3, +12.3, +13.2, +13.6, +13.2, +14.2, +8.4, +6.8,
          ],
        },
        {
          title: '90-100m',
          units: 'metric',
          key: 'percent_target_90_100',
          key2: 'percent_flag_90_100',
          benchmarktype: 'L',
          benchmarks: [
            +7.0, +7.4, +8.4, +8.5, +8.5, +10.4, +10.3, +12.8, +13.1, +15.2, +7.4, +6.9,
            +7.0, +8.3, +8.5, +8.5, +9.6, +11.1, +11.9, +12.4, +13.2, +14.0, +8.4, +7.2,
          ],
        },
        {
          title: '100-110m',
          units: 'metric',
          key: 'percent_target_100_110',
          key2: 'percent_flag_100_110',
          benchmarktype: 'L',
          benchmarks: [
            +6.8, +7.2, +7.6, +7.9, +8.5, +9.9, +9.4, +10.5, +11.7, +15.2, +7.4, +6.4,
            +7.6, +7.5, +7.9, +8.9, +9.5, +11.0, +11.9, +12.3, +13.1, +13.9, +7.6, +7.8,
          ],
        },
        {
          title: '110-120m',
          units: 'metric',
          key: 'percent_target_110_120',
          key2: 'percent_flag_110_120',
          benchmarktype: 'L',
          benchmarks: [
            +6.5, +7.2, +7.6, +7.8, +9.0, +9.5, +10.6, +12.0, +12.8, +14.2, +7.1, +6.1,
            +7.4, +8.3, +8.2, +8.3, +9.4, +10.4, +11.4, +11.6, +12.1, +13.4, +8.3, +7.6,
          ],
        },
        {
          title: '120-130m',
          units: 'metric',
          key: 'percent_target_120_130',
          key2: 'percent_flag_120_130',
          benchmarktype: 'L',
          benchmarks: [
            +6.3, +6.9, +7.4, +8.0, +8.5, +9.3, +8.2, +10.7, +11.5, +16.2, +7.0, +6.0,
            +6.7, +7.5, +8.2, +8.1, +9.5, +10.4, +11.4, +11.4, +12.3, +13.1, +7.8, +6.9,
          ],
        },
        {
          title: '130-140m',
          units: 'metric',
          key: 'percent_target_130_140',
          key2: 'percent_flag_130_140',
          benchmarktype: 'L',
          benchmarks: [
            +6.6, +6.9, +7.5, +7.8, +8.6, +8.9, +9.1, +11.1, +11.8, +15.6, +7.0, +5.9,
            +7.2, +7.8, +8.1, +8.3, +9.5, +10.9, +11.3, +11.7, +12.2, +13.4, +7.9, +7.3,
          ],
        },
        {
          title: '140-150m',
          units: 'metric',
          key: 'percent_target_140_150',
          key2: 'percent_flag_140_150',
          benchmarktype: 'L',
          benchmarks: [
            +6.3, +6.8, +7.4, +7.7, +8.3, +9.3, +9.5, +10.6, +12.6, +13.6, +7.0, +6.1,
            +6.8, +7.1, +8.6, +8.9, +10.2, +10.8, +11.6, +11.9, +12.4, +13.5, +7.6, +6.9,
          ],
        },
        {
          title: '150-160m',
          units: 'metric',
          key: 'percent_target_150_160',
          key2: 'percent_flag_150_160',
          benchmarktype: 'L',
          benchmarks: [
            +6.6, +6.7, +7.1, +7.7, +8.1, +9.4, +8.6, +11.0, +12.3, +14.2, +7.1, +6.8,
            +7.5, +7.6, +9.0, +9.7, +9.7, +11.5, +12.3, +12.4, +13.4, +13.8, +7.9, +7.7,
          ],
        },
        {
          title: '160-170m',
          units: 'metric',
          key: 'percent_target_160_170',
          key2: 'percent_flag_160_170',
          benchmarktype: 'L',
          benchmarks: [
            +6.3, +6.9, +7.3, +7.4, +7.8, +9.1, +9.2, +11.5, +14.5, +15.3, +7.3, +6.9,
            +9.0, +8.3, +9.0, +9.7, +10.5, +11.1, +12.0, +12.6, +13.4, +14.1, +8.6, +8.4,
          ],
        },
        {
          title: '170-180m',
          units: 'metric',
          key: 'percent_target_170_180',
          key2: 'percent_flag_170_180',
          benchmarktype: 'L',
          benchmarks: [
            +6.2, +7.0, +7.4, +7.7, +9.0, +8.5, +9.8, +10.1, +13.7, +15.4, +7.2, +7.3,
            +8.3, +8.6, +9.7, +10.1, +12.5, +12.5, +13.4, +13.7, +14.2, +14.5, +8.9, +8.4,
          ],
        },
        {
          title: '180-190m',
          units: 'metric',
          key: 'percent_target_180_190',
          key2: 'percent_flag_180_190',
          benchmarktype: 'L',
          benchmarks: [
            +6.5, +7.0, +7.7, +8.1, +8.6, +9.3, +8.9, +10.9, +13.3, +16.4, +7.2, +7.8,
            +11.5, +12.2, +11.8, +12.4, +12.7, +13.7, +14.6, +15.1, +15.6, +16.2, +9.2, +11.6,
          ],
        },
        {
          title: '190-200m',
          units: 'metric',
          key: 'percent_target_190_200',
          key2: 'percent_flag_190_200',
          benchmarktype: 'L',
          benchmarks: [
            +6.7, +7.6, +7.8, +7.9, +8.9, +10.7, +9.3, +11.1, +12.9, +14.2, +7.9, +8.3,
            +7.8, +13.6, +10.3, +12.6, +13.6, +13.5, +13.6, +14.9, +15.7, +16.5, +11.6, +7.9,
          ],
        },
        {
          title: '200-220m',
          units: 'metric',
          key: 'percent_target_200_220',
          key2: 'percent_flag_200_220',
          benchmarktype: 'L',
          benchmarks: [
            +7.1, +7.7, +8.5, +9.2, +9.0, +8.9, +11.0, +11.6, +13.4, +14.6, +7.9, +8.7,
            +11.3, +12.6, +12.9, +13.6, +13.9, +14.6, +15.4, +16.5, +16.4, +17.5, +12.8, +11.5,
          ],
        },
        {
          title: '220-250m',
          units: 'metric',
          key: 'percent_target_220_250',
          key2: 'percent_flag_220_250',
          benchmarktype: 'L',
          benchmarks: [
            +8.2, +8.3, +9.1, +9.6, +10.5, +12.0, +12.1, +11.8, +12.2, +13.9, +8.4, +9.6,
            +10.2, +13.5, +13.0, +12.8, +13.0, +14.5, +15.3, +16.5, +17.5, +18.3, +13.4, +10.4,
          ],
        },
        {
          title: '> 250m',
          units: 'metric',
          key: 'percent_target_250',
          key2: 'percent_flag_250',
          benchmarktype: 'L',
          benchmarks: [
            +7.5, +7.6, +8.7, +8.9, +9.5, +9.8, +9.1, +10.2, +11.4, +13.2, +8.1, +11.0,
            +12.3, +11.2, +12.9, +12.9, +12.6, +12.7, +13.4, +15.3, +16.4, +17.6, +11.4, +12.4,
          ],
        },
      ],
    },
  ],
  impact: [
    {
      title: 'Impact',
      key: 'impact',
      percent: true,
      tables: [
        {
          title: 'Total',
          units: 'both',
          key: 'total',
          benchmarktype: 'H',
          benchmarks: [
            +81.1, +79.4, +76.5, +72.3, +70.1, +69.4, +69.1, +69, +66, +60, +75, +85,
            +78.3, +75.2, +73.4, +72.0, +69.4, +69.1, +67.5, +66.6, +65.1, +64.2, +75, +77.3,
          ],
        },
        {
          title: 'Off The Tee',
          units: 'both',
          key: 'offTee',
          benchmarktype: 'H',
          benchmarks: [
            +81.2, +79.3, +75.8, +74.1, +70.3, +71.2, +68.1, +70.1, +67.2, +73.2, +76.2, +82,
            +78.1, +78.0, +75.1, +73.6, +72.1, +69.6, +68.2, +67.5, +66.8, +67.5, +76.2, +77.6,
          ],
        },
        {
          title: 'Long Shots & Lay-Ups',
          units: 'both',
          key: 'longShots',
          benchmarktype: 'H',
          benchmarks: [
            +79.6, +76.5, +73.2, +72.8, +71.6, +70.0, +71.3, +65.2, +68.3, +58, +74.3, +88,
            +81.6, +75.3, +75.2, +76.1, +71.3, +75.2, +73.1, +72.1, +68.9, +66.8, +74.3, +78.6,
          ],
        },
        {
          title: 'To The Green',
          units: 'both',
          key: 'approach',
          benchmarktype: 'H',
          benchmarks: [
            +80.1, +77.8, +74.1, +71.3, +69.3, +67.2, +69.3, +66.3, +63.1, +56, +73.6, +83,
            +74.6, +73.1, +69.8, +67.2, +64.3, +65.1, +63.0, +64.5, +66.5, +64.5, +73.6, +73.4,
          ],
        },
        {
          title: 'Greenside',
          units: 'both',
          key: 'around',
          benchmarktype: 'H',
          benchmarks: [
            +84.2, +81.5, +78.6, +75.6, +74.3, +73.1, +72.0, +73.1, +70.1, +54.2, +77.5, +86,
            +86.9, +78.6, +78.3, +76.3, +75.4, +72.1, +72.0, +69.3, +63.2, +58.2, +77.5, +85.6,
          ],
        },
      ],
    },
  ],
  routine: [
    {
      title: 'Routine',
      key: 'routine',
      percent: true,
      tables: [
        {
          title: 'Total',
          units: 'both',
          key: 'total',
          benchmarktype: 'H',
          benchmarks: [
            +81.1, +79.4, +76.5, +72.3, +70.1, +69.4, +69.1, +69, +66, +60, +75, +82,
            +78.3, +75.2, +73.4, +72.0, +69.4, +69.1, +67.5, +66.6, +65.1, +64.2, +75, +77.3,
          ],
        },
        {
          title: 'Off The Tee',
          units: 'both',
          key: 'offTee',
          benchmarktype: 'H',
          benchmarks: [
            +81.2, +79.3, +75.8, +74.1, +70.3, +71.2, +68.1, +70.1, +67.2, +73.2, +76.2, +78,
            +78.1, +78.0, +75.1, +73.6, +72.1, +69.6, +68.2, +67.5, +66.8, +67.5, +76.2, +77.6,
          ],
        },
        {
          title: 'Long Shots & Lay-Ups',
          units: 'both',
          key: 'longShots',
          benchmarktype: 'H',
          benchmarks: [
            +79.6, +76.5, +73.2, +72.8, +71.6, +70.0, +71.3, +65.2, +68.3, +58, +74.3, +86,
            +81.6, +75.3, +75.2, +76.1, +71.3, +75.2, +73.1, +72.1, +68.9, +66.8, +74.3, +78.6,
          ],
        },
        {
          title: 'To The Green',
          units: 'both',
          key: 'approach',
          benchmarktype: 'H',
          benchmarks: [
            +84.2, +81.5, +78.6, +75.6, +74.3, +73.1, +72.0, +73.1, +70.1, +54.2, +77.5, +82,
            +86.9, +78.6, +78.3, +76.3, +75.4, +72.1, +72.0, +69.3, +63.2, +58.2, +77.5, +85.6,
          ],
        },
        {
          title: 'Greenside',
          units: 'both',
          key: 'around',
          benchmarktype: 'H',
          benchmarks: [
            +80.1, +77.8, +74.1, +71.3, +69.3, +67.2, +69.3, +66.3, +63.1, +56, +73.6, +78,
            +74.6, +73.1, +69.8, +67.2, +64.3, +65.1, +63.0, +64.5, +66.5, +64.5, +73.6, +73.4,
          ],
        },
        {
          title: 'Putting',
          units: 'both',
          key: 'onGreen',
          benchmarktype: 'H',
          benchmarks: [
            +81.2, +79.3, +75.8, +74.1, +70.3, +71.2, +68.1, +70.1, +67.2, +73.2, +76.2, +81,
            +78.1, +78.0, +75.1, +73.6, +72.1, +69.6, +68.2, +67.5, +66.8, +67.5, +76.2, +77.6,
          ],
        },
      ],
    },
  ],
  wastedShots: [
    {
      title: 'Wasted Shots',
      key: 'wasted',
      tables: [
        {
          title: 'Total',
          units: 'both',
          key: 'total',
          benchmarktype: 'L',
          benchmarks: [
            +2.3, +3.2, +4.8, +6.6, +8.6, +10.4, +11.1, +15.3, +17.6, +19.8, +4.1, +2.0,
            +2.5, +3.8, +5.1, +7.2, +9.4, +12.0, +13.6, +15.3, +23.5, +27.8, +4.1, +2.8,
          ],
        },
        {
          title: 'Off The Tee',
          units: 'both',
          key: 'offTee',
          benchmarktype: 'L',
          benchmarks: [
            +0.7, +1.0, +1.4, +2.1, +2.5, +2.7, +3.0, +3.0, +2.9, +1.8, +1.5, +0.6,
            +0.9, +1.1, +1.2, +1.7, +1.7, +2.2, +2.0, +3.0, +4.5, +4.8, +1.5, +1.0,
          ],
        },
        {
          title: 'Long Shots & Lay-Ups',
          units: 'both',
          key: 'longShots',
          benchmarktype: 'L',
          benchmarks: [
            +0.1, +0.2, +0.3, +0.4, +0.5, +0.6, +1.0, +0.9, +1.7, +2.0, +0.3, +0.1,
            +0.1, +0.3, +0.3, +0.5, +0.5, +0.9, +0.8, +1.0, +1.6, +2.6, +0.3, +0.1,
          ],
        },
        {
          title: 'To The Green',
          units: 'both',
          key: 'approach',
          benchmarktype: 'L',
          benchmarks: [
            +0.5, +0.7, +1.0, +1.4, +2.0, +2.7, +2.9, +5.0, +6.9, +5.8, +0.8, +0.8,
            +0.2, +0.5, +0.9, +1.2, +2.0, +2.6, +3.8, +4.1, +6.6, +7.8, +0.8, +0.4,
          ],
        },
        {
          title: 'Greenside',
          units: 'both',
          key: 'around',
          benchmarktype: 'L',
          benchmarks: [
            +0.2, +0.4, +0.6, +0.8, +1.2, +1.5, +1.5, +2.5, +2.7, +5.2, +0.5, +0.4,
            +0.3, +0.4, +0.6, +0.8, +1.1, +1.5, +1.4, +2.3, +2.4, +3.1, +0.5, +0.4,
          ],
        },
        {
          title: 'Putting',
          units: 'both',
          key: 'onGreen',
          benchmarktype: 'L',
          benchmarks: [
            +0.7, +1.0, +1.5, +2.0, +2.4, +3.0, +2.7, +3.8, +3.5, +5.0, +1.0, +0.1,
            +1.0, +1.3, +2.1, +3.0, +4.0, +4.8, +5.6, +4.9, +8.4, +9.5, +1.0, +0.9,
          ],
        },
      ],
    },
  ],
}
