export default [
  {
    path: '/community',
    name: 'community',
    component: () => import('@/views/spider/community/Community.vue'),
    meta: {
      resource: 'coach',
      action: 'read',
    },
  },
  {
    path: '/groups',
    name: 'groups',
    component: () => import('@/views/spider/community/Groups.vue'),
    meta: {
      resource: 'coach',
      action: 'read',
      pageTitle: 'Groups',
      breadcrumb: [
        {
          text: 'Groups',
          active: true,
        },
      ],
    },
  },
  {
    path: '/groups/edit/:groupId',
    name: 'edit-group',
    props(route) {
      const props = { ...route.params }
      props.groupId = +props.groupId
      return props
    },
    component: () => import('@/views/spider/community/EditGroup.vue'),
    meta: {
      resource: 'coach',
      action: 'read',
      navActiveLink: 'groups',
      pageTitle: 'Group Edition',
      breadcrumb: [
        {
          text: 'Groups',
          to: '/groups',
        },
        {
          text: 'Group Edition',
          active: true,
        },
      ],
    },
  },
  {
    path: '/groups/edit',
    name: 'new-group',
    component: () => import('@/views/spider/community/EditGroup.vue'),
    meta: {
      resource: 'coach',
      action: 'read',
      navActiveLink: 'groups',
      pageTitle: 'Group Edition',
      breadcrumb: [
        {
          text: 'Groups',
          to: '/groups',
        },
        {
          text: 'Group Edition',
          active: true,
        },
      ],
    },
  },
]
