export default [
  {
    path: '/faq',
    name: 'faq',
    component: () => import('@/views/spider/general/Faq.vue'),
    meta: {
      resource: 'player',
      action: 'read',
    },
  },
]
