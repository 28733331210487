/* eslint-disable */

const Parameters = {}
Parameters.unmissable = 0.9
Parameters.shortLongPuttLimit = 5
Parameters.shortPuttCenterZone = 0.3
Parameters.longPuttCenterZone = 0.6
Parameters.aroundGreenCenterZone = 2
Parameters.pickupCode = 97
Parameters.penaltyCode = 99

Parameters.teeCode = 1
Parameters.fairwayCode = 2
Parameters.roughCode = 3
Parameters.bunkerCode = 4
Parameters.otherCode = 5
Parameters.greensideFairwayCode = 6
Parameters.greensideRoughCode = 7
Parameters.greensideBunkerCode = 8
Parameters.greensideOtherCode = 9
Parameters.greenCode = 10

Parameters.lengthLongPar4 = 400

Parameters.unitSystemMetric = 'metric'
Parameters.unitSystemImperial = 'imperial'

let unitSystem = 'metric'

/*
transform list of shots into ordered list of shots by hole
*/
// function orderShotsToShotsByHole(shots) {
// 	const shotsByHole = []
//   shots.sort(function(a, b) {
//     return a.shotNumber - b.shotNumber
//   })
//   shots.forEach(function(shot) {
//     shotsByHole[shot.hole - 1].push(shot)
//   })
//   return shotsByHole
// }

/*
getGirScrFwStatsRound

Parameters:
round JSON

Return:
JSON {
		gir: 0,
		girCount: 0,
		scrambling: 0,
		scramblingCount: 0,
		left: 0,
		fairway: 0,
		right: 0,
		fairwayCount: 0,
	}
*/
function getGirScrFwStatsRound(round) {
  const data = {
    gir: 0,
    girCount: 0,
    scrambling: 0,
    scramblingCount: 0,
    left: 0,
    fairway: 0,
    right: 0,
    fairwayCount: 0,
  }

  // loop through the holes
  for (let hole = 0; hole < 18; hole += 1) {
    if (round.played[hole] !== '0') {
      data.girCount += 1
      if (
        IsInRegulation(
          round.score[hole],
          round.pars[hole],
          round.putts[hole],
          round.played[hole],
        )
      ) data.gir += 1
      else {
        data.scramblingCount += 1
        if (Number(round.score[hole]) <= Number(round.pars[hole])) data.scrambling += 1
      }

      if (round.pars[hole] > 3) {
        data.fairwayCount += 1
        if (round.fairways[hole] === '1') data.fairway += 1
        else if (round.fairways[hole] === 'L') data.left += 1
        else if (round.fairways[hole] === 'R') data.right += 1
      }
    }
  }

  return data
}

/*
IsInRegulation

Parameters:
score Int, par Int, putts Int, played String

Return:
Bool : if green hit in regulation (gir) => true, else false
*/
function IsInRegulation(score, par, putts, played) {
  if (Number(score) - Number(putts) <= Number(par) - 2 && played === '1') {
    return true
  }
  return false
}

/*
cartesianToPolar
convert cartesian coordinates to polar coordinates (distance to center)

Parameters:
x Double, y Double

Return:
Double
*/
function cartesianToPolar(x, y) {
  return Math.sqrt(x * x + y * y)
}

/*
get sum of all elements of the array
*/
function arraySum(array) {
  let sum = 0
  for (let i = 0; i < array.length; i += 1) {
    const value = Number(array[i])
    sum += value
  }
  return sum
}

/*
getHolesPlayed

Parameters:
played: [String]

Return:
Json:
{
	played: [Integer], // Index of hole played
	pickedUp: [Integer] // Index of hole picked up
}
*/
function getHolesPlayed(played) {
  const play = []
  const pickUp = []

  // loop through the holes
  for (let hole = 0; hole < 18; hole += 1) {
    if (played[hole] === '1') {
      play.push(hole)
    } else if (played[hole] === 'X') {
      pickUp.push(hole)
      play.push(hole)
    }
  }

  return { played: play.length, pickedUp: pickUp.length }
}

/*
getScoreIndexForHole

Parameters:
score: Int,
par: Int,
played: String

Return:
Int // index corresponding to: eagles-: 0, birdies: 1, par: 2, bogey: 3, 2xbogey: 4, 3xbogey+: 5
*/
function getScoreIndexForHole(score, par, played) {
  let scoreIndex = -1

  if (played !== '0') {
    scoreIndex = score - par + 2
    if (scoreIndex < 0) scoreIndex = 0
    if (scoreIndex > 5) scoreIndex = 5
  }

  return scoreIndex
}




/*
getScoringDataForShot

Parameters:
shot

Return average strokes from shot distance & surface
*/
function getScoringDataForShot(shot) {
  if(shot.surface === 0) {
    return 0;
  }
  let scoringData = 0

  let distance = 0
  let surface = shot.surface
  if (surface >= Parameters.greensideFairwayCode) surface -= 4

  if (surface !== 6) distance = convertMetersToYards(shot.distanceToHole)
  else distance = convertMetersToFeet(shot.distanceToHole)

  const data = Parameters.scoringData[distance]
  scoringData = data[surface - 1]

  return scoringData
}

// func calculateWastedShots(shotsForHole: [Shot], shotNumber: Int, nbPuttsForHole: Int) -> [Double] {
function calculateWastedShots(shotsForHole, shotNumber, nbPuttsForHole) {
  // pen, rec, other

  const wastedShots = [0.0, 0.0, 0.0]

  const s = shotsForHole[shotNumber]

  if (s.club === Parameters.penaltyCode) {
    wastedShots[0] += 1
  } else if (s.club === Parameters.recoveryCode) {
    wastedShots[1] += 1
  } else if (shotNumber < shotsForHole.length - 1) {
    const nextShot = shotsForHole[shotNumber + 1]

    if (nextShot.club === Parameters.penaltyCode) {
      if (shotNumber < shotsForHole.length - 2) {
        if (
          s.distanceToHole - shotsForHole[shotNumber + 2].distanceToHole
          < 80
        ) {
          wastedShots[2] += 1
        }
      }
    } else if (
      s.attackGreen === 0
      && s.distanceToHole - nextShot.distanceToHole < 80
    ) {
      wastedShots[2] += 1
    } else if (
      s.attackGreen === 1
      && nextShot.surface < Parameters.greensideFairwayCode
    ) {
      wastedShots[2] += 1
    } else if (
      s.surface >= Parameters.greensideFairwayCode
      && s.surface < Parameters.greenCode
      && nextShot.surface !== Parameters.greenCode
    ) {
      wastedShots[2] += 1
    } else if (s.surface === 10 && s.distanceToHole < Parameters.unmissable) {
      wastedShots[2] += 1
    } else if (s.surface === 10 && nextShot.surface !== Parameters.greenCode) {
      wastedShots[2] += 1
    } else if (
      s.surface === 10
      && s.distanceToHole <= nextShot.distanceToHole
    ) {
      wastedShots[2] += 1
    } else if (nbPuttsForHole >= 2) {
      wastedShots[2] += 1
    }
  }

  return wastedShots
}

/*
convert meters To Feet
*/
function convertMetersToFeet(meters) {
  let feet = meters * 3.2808399
  feet = Math.round(feet)
  if (feet <= 0) feet = 1
  else if (feet > 600) feet = 600
  return feet
}

/*
calculateStrokesGained

Parameters:
rounds: Round,
shotsByRound: [[Shot]]

Return:
Json containing all strokes gained data
{
	// for each category, first is sum of strokes gained // second is nb of shots
	categories: {
		total: [0,0],
		offTee: [0,0],
		longShots: [0,0],
		approach: [0,0],
		around: [0,0],
		onGreen: [0,0],
	},
	distances: [],
	distancesCount: [],
}
*/
function calculateStrokesGained(round, shots) {
  const strokesGained = {
    categories: {
      total: [0, 0],
      offTee: [0, 0],
      longShots: [0, 0],
      approach: [0, 0],
      around: [0, 0],
      onGreen: [0, 0],
    },
    distances: [],
    distancesCount: [],
  }

  for (let i = 0; i < 28; i += 1) {
    strokesGained.distances.push(0)
    strokesGained.distancesCount.push(0)
  }

  if (round.shotByShot) {
    // loop through the holes of the round
    for (let hole = 0; hole < 18; hole += 1) {
      const par = round.pars[hole]
      const shotsForHole = shots[hole + 1] || []

      let j = 0

      let strokesGainedForHole = 0

      const convert = unitSystem === Parameters.unitSystemMetric ? 1 : 0.9144
      // while is not last shot
      while (j < shotsForHole.length - 1) {
        let nbShots = 1
        const shot = shotsForHole[j]
        j += 1

        // skip shot has penalty code
        while (j < shotsForHole.length - 1 && shotsForHole[j].club === Parameters.penaltyCode) {
          nbShots += 1
          j += 1
        }

        const nextShot = shotsForHole[j]

        let stkGained = getScoringDataForShot(shot)
          - getScoringDataForShot(nextShot)
          - nbShots

        if (nextShot.club === Parameters.pickupCode) {
          stkGained = -1 * (Number(round.score[hole]) + strokesGainedForHole)
        }

        const distanceIndex = shot.surface === Parameters.greenCode
          ? getPuttingDistanceIndex(shot.distanceToHole)
          : getDispersionDistanceIndex(shot.distanceToHole, unitSystem) + 9
        strokesGained.distances[distanceIndex] += stkGained
        strokesGained.distancesCount[distanceIndex] += 1

        strokesGained.categories.total[0] += stkGained
        strokesGained.categories.total[1] += 1

        if (shot.surface === Parameters.teeCode && par > 3) {
          strokesGained.categories.offTee[0] += stkGained // offtee
          strokesGained.categories.offTee[1] += 1
        } else if (shot.surface === Parameters.greenCode) {
          strokesGained.categories.onGreen[0] += stkGained // putting
          strokesGained.categories.onGreen[1] += 1
        } else if (shot.surface >= Parameters.greensideFairwayCode) {
          strokesGained.categories.around[0] += stkGained // around
          strokesGained.categories.around[1] += 1
          } else if (shot.distanceToHole <= 200 * convert) {
          strokesGained.categories.approach[0] += stkGained // approach
          strokesGained.categories.approach[1] += 1
        } else {
          strokesGained.categories.longShots[0] += stkGained // longshot + lay up
          strokesGained.categories.longShots[1] += 1
        }

        /* eslint-disable no-restricted-globals */
        if (isNaN(strokesGained.categories.longShots[0])) {
          console.log(
            stkGained,
            round.score[hole],
            strokesGainedForHole,
            JSON.stringify(shot),
            JSON.stringify(nextShot),
          )
        }

        strokesGainedForHole += stkGained
      }

      /* eslint-disable-next-line no-continue */
      if (shotsForHole.length === 0) continue

      const shot = shotsForHole[shotsForHole.length - 1]

      /* eslint-disable-next-line no-continue */
      if (shot.club === Parameters.pickupCode) continue

      const stkGained = getScoringDataForShot(shot) - 1

      const distanceIndex = shot.surface === Parameters.greenCode
        ? getPuttingDistanceIndex(shot.distanceToHole)
        : getDispersionDistanceIndex(shot.distanceToHole, unitSystem) + 9
      strokesGained.distances[distanceIndex] += stkGained
      strokesGained.distancesCount[distanceIndex] += 1

      strokesGained.categories.total[0] += stkGained
      strokesGained.categories.total[1] += 1

      if (shot.surface === Parameters.teeCode && par > 3) {
        strokesGained.categories.offTee[0] += stkGained // offtee
        strokesGained.categories.offTee[1] += 1
      } else if (shot.surface === Parameters.greenCode) {
        strokesGained.categories.onGreen[0] += stkGained // putting
        strokesGained.categories.onGreen[1] += 1
      } else if (shot.surface >= Parameters.greensideFairwayCode) {
        strokesGained.categories.around[0] += stkGained // around
        strokesGained.categories.around[1] += 1
        } else if (shot.distanceToHole <= 200 * convert) {
        strokesGained.categories.approach[0] += stkGained // approach
        strokesGained.categories.approach[1] += 1
      } else {
        strokesGained.categories.longShots[0] += stkGained // longshot + lay up
        strokesGained.categories.longShots[1] += 1
      }

      /* eslint-disable no-restricted-globals */
      if (isNaN(strokesGained.categories.longShots[0])) console.log(JSON.stringify(shot))
    }
  }

  return strokesGained
}

/*
getDispersionDistanceIndex

Parameters:
shotDistance: Int
unitSystem: String [metric|imperial]

Return:
Int - index of distance braket
ex: 0-30 -> 0 / 30-60 -> 1 / ... 120-130 -> 8 ...
*/
function getDispersionDistanceIndex(shotDistance, unitSystem) {
  const convert = unitSystem === Parameters.unitSystemMetric ? 1 : 0.9144
  if (shotDistance <= 30 * convert) return 0
  if (shotDistance <= 60 * convert) return 1
  if (shotDistance <= 70 * convert) return 2
  if (shotDistance <= 80 * convert) return 3
  if (shotDistance <= 90 * convert) return 4
  if (shotDistance <= 100 * convert) return 5
  if (shotDistance <= 110 * convert) return 6
  if (shotDistance <= 120 * convert) return 7
  if (shotDistance <= 130 * convert) return 8
  if (shotDistance <= 140 * convert) return 9
  if (shotDistance <= 150 * convert) return 10
  if (shotDistance <= 160 * convert) return 11
  if (shotDistance <= 170 * convert) return 12
  if (shotDistance <= 180 * convert) return 13
  if (shotDistance <= 190 * convert) return 14
  if (shotDistance <= 200 * convert) return 15
  if (shotDistance <= 220 * convert) return 16
  if (shotDistance <= 250 * convert) return 17
  return 18
}

/*
getPuttingDistanceIndex

Parameters:
shotDistance: Int

Return:
Int - index of distance braket
ex: 0-1 -> 0 / 1-2 -> 1 / ... 6-9 -> 6 ...
*/
function getPuttingDistanceIndex(shotDistance) {
  if (shotDistance <= 1) return 0
  if (shotDistance <= 2) return 1
  if (shotDistance <= 3) return 2
  if (shotDistance <= 4) return 3
  if (shotDistance <= 5) return 4
  if (shotDistance <= 6) return 5
  if (shotDistance <= 9) return 6
  if (shotDistance <= 12) return 7
  return 8
}

/*
getShotZoneIndex
returns index of the zone (center, top right, top left, bottom right, bottom left)

Parameters:
shot
centerZone (Float) rayon of center zone in meters

Return:
Int - index of zone
*/

// TODO check
function getShotZoneIndex(shot, centerZone) {
  let index = 0
  if (cartesianToPolar(shot.x, shot.y) > centerZone) {
    if (shot.x < -centerZone) index = 1
    else if (shot.x > centerZone) index = 3
    else index = shot.y < 0 ? 4 : 2
  }
  return index
}

/*
getStats

Parameters:
rounds: [Round],
shotsByRound: [[[Shot]]]

Return:
Json containing all the stats
*/
export function getStats(rounds, unit) {
  unitSystem = unit

  let fullRounds = 0

  let lowestScore = rounds.length ? arraySum(rounds[0].score) : 0
  let highestScore = lowestScore

  const girScrFwStats = {
    gir: 0,
    girCount: 0,
    scrambling: 0,
    scramblingCount: 0,
    left: 0,
    fairway: 0,
    right: 0,
    fairwayCount: 0,
  }

  const birdieConversion = { made: 0, attempts: 0 }
  const parConversion = { made: 0, attempts: 0 }

  // for each slope, for each distance
  const puttStatsFullQuantity = [] // average quality
  const puttStatsFullReads = [] // average read
  const puttStatsFullHoled = [] // averageholed

  // initialize the 11 slopes with the 10 distances
  for (let i = 0; i < 11; i += 1) {
    puttStatsFullQuantity.push([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
    puttStatsFullReads.push([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
    puttStatsFullHoled.push([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
  }

  const data = {
    nbHolesPlayed: 0,
    totalStrokes: 0,
    totalPar: 0,
    totalPutts: 0,
    girScrFwStats,
    scoreStats: [0, 0, 0, 0, 0, 0],
    puttsStats: [0, 0, 0, 0, 0],
    scoreOfPars: [0, 0, 0],
    girsOfPars: [0, 0, 0],
    nbHolesOfPars: [0, 0, 0],
    birdieConversion,
    parConversion,
    driveDistance: [],
    distancesFirstAttack: [[], [], []],
    distancesGIRShot: [[], [], []],
    scoresByThirds: [[], [], []],
    scoresByHalves: [[], []],
    penalties: 0,
    recoveries: 0,
    wastedShotsPerType: [0, 0, 0, 0, 0],
    firstPuttDistances: [],
    secondPuttDistances: [],
    shortPuttsMissed: 0,

    longShotsFWStats: [0, 0, 0],
    longShotsNextCanAttack: 0.0,

    approachAttacksHitsGreen: [0, 0, 0, 0, 0],
    approachAttacks: [0, 0, 0, 0, 0],

    upDownsBySurface: [0, 0, 0, 0],
    upDownsAttemptsBySurface: [0, 0, 0, 0],

    approachStats: [0, 0, 0, 0, 0],
    approachStatsByDistance: [
      [0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0],
    ],

    aroundStats: [0, 0, 0, 0, 0],

    puttingStatsShort: [0, 0, 0, 0, 0],
    puttingStatsLong: [0, 0, 0, 0, 0],

    puttsGir: [],
    puttsNonGir: [],

    missedPutts: [],
    birdiePuttsMade: [],
    puttsMade: [],
    longPuttResult: [],

    distancePuttFromNonGRS: [],
    distancePuttFromGRS: [],

    quality: [0, 0, 0, 0, 0],
    qualityCount: [0, 0, 0, 0, 0],

    routine: [0, 0, 0, 0, 0],
    routineCount: [0, 0, 0, 0, 0],

    longShotsByPars: [0, 0, 0],

    nbOnePutts: [0, 0, 0, 0, 0, 0, 0, 0, 0],
    nbOnePuttsTotal: [0, 0, 0, 0, 0, 0, 0, 0, 0],

    nbOnePuttsForBirdie: [0, 0, 0, 0, 0, 0, 0, 0, 0],
    nbOnePuttsForBirdieTotal: [0, 0, 0, 0, 0, 0, 0, 0, 0],
    nbOnePuttsForPar: [0, 0, 0, 0, 0, 0, 0, 0, 0],
    nbOnePuttsForParTotal: [0, 0, 0, 0, 0, 0, 0, 0, 0],

    nbThreePutts: [0, 0, 0, 0, 0, 0],
    nbThreePuttsTotal: [0, 0, 0, 0, 0, 0],

    maxStreakParsAndBelow: 0,
    currentStreakParsAndBelow: 0,

    maxStreakBelowPar: 0,
    currentStreakBelowPar: 0,

    maxStreakWithoutDoubleBogeys: 0,
    currentStreakWithoutDoubleBogeys: 0,

    maxStreak1Putt: 0,
    currentStreak1Putt: 0,

    maxStreakWithout3Putts: 0,
    currentStreakWithout3Putts: 0,

    maxStreakFairways: 0,
    currentStreakFairways: 0,

    maxStreakGIR: 0,
    currentStreakGIR: 0,

    maxStreakWithoutPenalty: 0,
    currentStreakWithoutPenalty: 0,

    scoreFWHit: [[], []],

    greensideDispersion: [],
    greensideDistance: [],

    wellHitShotByType: [0, 0, 0],
    wellHitMissFWShotByType: [0, 0, 0],

    missHitShotByType: [0, 0, 0],
    missHitHitFwShotByType: [0, 0, 0],

    qualityAfterMadePutt: [0, 0],
    qualityAfterMissPutt: [0, 0],
    qualityAfterNormalPutt: [0, 0],

    routineAfterMadePutt: [0, 0],
    routineAfterMissPutt: [0, 0],
    routineAfterNormalPutt: [0, 0],

    scoreShortPar4: [],
    scoreLongPar4: [],

    puttStatsFullQuantity,
    puttStatsFullReads,
    puttStatsFullHoled,

    flagDispersionMeters: [
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
    ],
    flagDispersionPercentage: [
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
    ],
    targetDispersionMeters: [
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
    ],
    targetDispersionPercentage: [
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
    ],
    holesPlayedShotByShot: 0,
  }

  const strokesGained = {
    categories: {
      total: [0, 0],
      offTee: [0, 0],
      longShots: [0, 0],
      approach: [0, 0],
      around: [0, 0],
      onGreen: [0, 0],
    },
    distances: [],
    distancesCount: [],
  };

  for (let i = 0; i < 28; i += 1) {
    strokesGained.distances.push(0)
    strokesGained.distancesCount.push(0)
  }

  // add up all the data of all rounds
  for (let i = 0; i < rounds.length; i += 1) {
    const round = rounds[i]
    const shots = round.shots // shotsByRound[i]

    if (round.shotByShot) fullRounds += 1

    const dataRound = calculateStatsRound(round, shots)

    const strokesGainedRound = calculateStrokesGained(round, shots)

    data.nbHolesPlayed += dataRound.nbHolesPlayed
    data.holesPlayedShotByShot += dataRound.holesPlayedShotByShot

    data.totalStrokes += dataRound.totalStrokes
    data.totalPar += dataRound.totalPar

    data.totalPutts += dataRound.totalPutts

    // TODO STREAKS

    lowestScore = Math.min(lowestScore, dataRound.totalStrokes)
    highestScore = Math.max(highestScore, dataRound.totalStrokes)

    // TODO STFD, HCP ...

    for (let j = 0; j < data.scoreStats.length; j += 1) {
      data.scoreStats[j] += dataRound.scoreStats[j]
    }

    for (let j = 0; j < 3; j += 1) {
      data.scoreOfPars[j] += dataRound.scoreOfPars[j]
      data.nbHolesOfPars[j] += dataRound.nbHolesOfPars[j]

      data.scoresByThirds[j] = data.scoresByThirds[j].concat(
        dataRound.scoresByThirds[j]
      )

      data.distancesGIRShot[j] = data.distancesGIRShot[j].concat(
        dataRound.distancesGIRShot[j]
      )
      data.distancesFirstAttack[j] = data.distancesFirstAttack[j].concat(
        dataRound.distancesFirstAttack[j]
      )
    }

    for (let j = 0; j < 2; j += 1) {
      data.scoresByHalves[j] = data.scoresByHalves[j].concat(
        dataRound.scoresByHalves[j]
      )
    }

    for (let j = 0; j < data.puttsStats.length; j += 1) {
      data.puttsStats[j] += dataRound.puttsStats[j]
    }

    data.scoreShortPar4 = data.scoreShortPar4.concat(dataRound.scoreShortPar4)
    data.scoreLongPar4 = data.scoreLongPar4.concat(dataRound.scoreLongPar4)

    data.penalties += dataRound.penalties
    data.recoveries += dataRound.recoveries

    for (let j = 0; j < data.wastedShotsPerType.length; j += 1) {
      data.wastedShotsPerType[j] += dataRound.wastedShotsPerType[j]
    }

    data.driveDistance = data.driveDistance.concat(dataRound.driveDistance)

    data.girScrFwStats.fairway += dataRound.girScrFwStats.fairway
    data.girScrFwStats.left += dataRound.girScrFwStats.left
    data.girScrFwStats.right += dataRound.girScrFwStats.right
    data.girScrFwStats.fairwayCount += dataRound.girScrFwStats.fairwayCount

    data.girScrFwStats.gir += dataRound.girScrFwStats.gir
    data.girScrFwStats.girCount += dataRound.girScrFwStats.girCount

    data.girScrFwStats.scrambling += dataRound.girScrFwStats.scrambling
    data.girScrFwStats.scramblingCount +=
      dataRound.girScrFwStats.scramblingCount

    data.scoreFWHit[0] = data.scoreFWHit[0].concat(dataRound.scoreFWHit[0])
    data.scoreFWHit[1] = data.scoreFWHit[1].concat(dataRound.scoreFWHit[1])

    for (let j = 0; j < data.quality.length; j += 1) {
      data.quality[j] += dataRound.quality[j]
      data.qualityCount[j] += dataRound.qualityCount[j]
    }

    for (let j = 0; j < data.routine.length; j += 1) {
      data.routine[j] += dataRound.routine[j]
      data.routineCount[j] += dataRound.routineCount[j]
    }

    for (let j = 0; j < data.wellHitMissFWShotByType.length; j += 1) {
      data.wellHitMissFWShotByType[j] += dataRound.wellHitMissFWShotByType[j]
      data.wellHitShotByType[j] += dataRound.wellHitShotByType[j]
      data.missHitHitFwShotByType[j] += dataRound.missHitHitFwShotByType[j]
      data.missHitShotByType[j] += dataRound.missHitShotByType[j]
    }

    for (let j = 0; j < data.qualityAfterMadePutt.length; j += 1) {
      data.qualityAfterMadePutt[j] += dataRound.qualityAfterMadePutt[j]
      data.qualityAfterMissPutt[j] += dataRound.qualityAfterMissPutt[j]
    }

    for (let j = 0; j < data.routineAfterMadePutt.length; j += 1) {
      data.routineAfterMadePutt[j] += dataRound.routineAfterMadePutt[j]
      data.routineAfterMissPutt[j] += dataRound.routineAfterMissPutt[j]
    }

    for (let j = 0; j < data.longShotsByPars.length; j += 1) {
      data.longShotsByPars[j] += dataRound.longShotsByPars[j]
    }

    for (let j = 0; j < data.longShotsFWStats.length; j += 1) {
      data.longShotsFWStats[j] += dataRound.longShotsFWStats[j]
    }
    data.longShotsNextCanAttack += dataRound.longShotsNextCanAttack

    for (let j = 0; j < data.approachAttacksHitsGreen.length; j += 1) {
      data.approachAttacksHitsGreen[j] += dataRound.approachAttacksHitsGreen[j]
      data.approachAttacks[j] += dataRound.approachAttacks[j]
    }

    for (let j = 0; j < data.upDownsBySurface.length; j += 1) {
      data.upDownsBySurface[j] += dataRound.upDownsBySurface[j]
      data.upDownsAttemptsBySurface[j] += dataRound.upDownsAttemptsBySurface[j]
    }

    for (let j = 0; j < data.girsOfPars.length; j += 1) {
      data.girsOfPars[j] += dataRound.girsOfPars[j]
    }

    data.puttsGir = data.puttsGir.concat(dataRound.puttsGir)
    data.puttsNonGir = data.puttsNonGir.concat(dataRound.puttsNonGir)

    data.birdieConversion.made += dataRound.birdieConversion.made
    data.birdieConversion.attempts += dataRound.birdieConversion.attempts
    data.parConversion.made += dataRound.parConversion.made
    data.parConversion.attempts += dataRound.parConversion.attempts

    data.shortPuttsMissed += dataRound.shortPuttsMissed

    for (let j = 0; j < data.puttStatsFullQuantity.length; j += 1) {
      for (let k = 0; k < data.puttStatsFullQuantity[j].length; k += 1) {
        data.puttStatsFullQuantity[j][k] +=
          dataRound.puttStatsFullQuantity[j][k]
        data.puttStatsFullReads[j][k] += dataRound.puttStatsFullReads[j][k]
        data.puttStatsFullHoled[j][k] += dataRound.puttStatsFullHoled[j][k]
      }
    }

    for (let j = 0; j < data.nbOnePutts.length; j += 1) {
      data.nbOnePutts[j] += dataRound.nbOnePutts[j]
      data.nbOnePuttsTotal[j] += dataRound.nbOnePuttsTotal[j];
      data.nbOnePuttsForBirdie[j] += dataRound.nbOnePuttsForBirdie[j];
      data.nbOnePuttsForBirdieTotal[j] += dataRound.nbOnePuttsForBirdieTotal[j];
      data.nbOnePuttsForPar[j] += dataRound.nbOnePuttsForPar[j];
      data.nbOnePuttsForParTotal[j] += dataRound.nbOnePuttsForParTotal[j];
    }

    for (let j = 0; j < data.nbThreePutts.length; j += 1) {
      data.nbThreePutts[j] += dataRound.nbThreePutts[j];
      data.nbThreePuttsTotal[j] += dataRound.nbThreePuttsTotal[j];
    }

    for (let j = 0; j < data.flagDispersionMeters.length; j += 1) {
      data.flagDispersionMeters[j] = data.flagDispersionMeters[j].concat(
        dataRound.flagDispersionMeters[j]
      );
      data.flagDispersionPercentage[j] = data.flagDispersionPercentage[
        j
      ].concat(dataRound.flagDispersionPercentage[j]);
      data.targetDispersionMeters[j] = data.targetDispersionMeters[j].concat(
        dataRound.targetDispersionMeters[j]
      );
      data.targetDispersionPercentage[j] = data.targetDispersionPercentage[
        j
      ].concat(dataRound.targetDispersionPercentage[j]);
    }

    for (let j = 0; j < strokesGained.distances.length; j += 1) {
      strokesGained.distances[j] += strokesGainedRound.distances[j];
      strokesGained.distancesCount[j] += strokesGainedRound.distancesCount[j];
    }

    for (let j = 0; j < 2; j += 1) {
      strokesGained.categories.total[j] +=
        strokesGainedRound.categories.total[j];
      strokesGained.categories.offTee[j] +=
        strokesGainedRound.categories.offTee[j];
      strokesGained.categories.longShots[j] +=
        strokesGainedRound.categories.longShots[j];
      strokesGained.categories.approach[j] +=
        strokesGainedRound.categories.approach[j];
      strokesGained.categories.around[j] +=
        strokesGainedRound.categories.around[j];
      strokesGained.categories.onGreen[j] +=
        strokesGainedRound.categories.onGreen[j];
    }
  }

  for (let j = 0; j < strokesGained.distances.length; j += 1) {
    strokesGained.distances[j] /= fullRounds;
    strokesGained.distancesCount[j] /= fullRounds;
  }

  for (let j = 0; j < 2; j += 1) {
    strokesGained.categories.total[j] /= fullRounds;
    strokesGained.categories.offTee[j] /= fullRounds;
    strokesGained.categories.longShots[j] /= fullRounds;
    strokesGained.categories.approach[j] /= fullRounds;
    strokesGained.categories.around[j] /= fullRounds;
    strokesGained.categories.onGreen[j] /= fullRounds;
  }

  // Generate object containing all stats

  const stats = {
    scoring: {},
    offTee: {},
    longShots: {},
    approach: {},
    around: {},
    onGreen: {},
    onePuttsDistances: {},
    birdiePuttsDistances: {},
    parPuttsDistances: {},
    threePuttsDistances: {},
    mental: {},
    dispersionTargetFlagMeters: {},
    dispersionTargetFlagPercentage: {},
    strokesGained: {},
    strokesGainedCategory: {},
    strokesGainedDistances: [],
    dispersion: {},
    impact: {},
    routine: {},
    wasted: {},
  };

  // SCORING

  stats.scoring.rounds = rounds.length;

  stats.scoring.holesPlayed = data.nbHolesPlayed;
  stats.scoring.strokes = (data.totalStrokes / rounds.length).toFixed(1);

  stats.scoring.strokesToPar = (
    (data.totalStrokes - data.totalPar) /
    rounds.length
  ).toFixed(1);

  stats.scoring.lowestScore = lowestScore;
  stats.scoring.highestScore = highestScore;

  // TODO pickups
  // STFD
  // HCP Played

  stats.scoring.nbEagles = (data.scoreStats[0] / rounds.length).toFixed(1);
  stats.scoring.nbBirdies = (data.scoreStats[1] / rounds.length).toFixed(1);
  stats.scoring.nbBirdiesM = (
    (data.scoreStats[1] + data.scoreStats[0]) /
    rounds.length
  ).toFixed(1);
  stats.scoring.nbPars = (data.scoreStats[2] / rounds.length).toFixed(1);
  stats.scoring.nbBogeys = (data.scoreStats[3] / rounds.length).toFixed(1);
  stats.scoring.nb2Bogeys = (data.scoreStats[4] / rounds.length).toFixed(1);
  stats.scoring.nb2BogeysP = (
    (data.scoreStats[4] + data.scoreStats[5]) /
    rounds.length
  ).toFixed(1);
  stats.scoring.nb3Bogeys = (data.scoreStats[5] / rounds.length).toFixed(1);
  stats.scoring.par3Score = (
    data.scoreOfPars[0] / data.nbHolesOfPars[0]
  ).toFixed(3);
  stats.scoring.par4Score = (
    data.scoreOfPars[1] / data.nbHolesOfPars[1]
  ).toFixed(3);
  stats.scoring.par5Score = (
    data.scoreOfPars[2] / data.nbHolesOfPars[2]
  ).toFixed(3);
  stats.scoring.shortPar4Score = (
    arraySum(data.scoreShortPar4) / data.scoreShortPar4.length
  ).toFixed(3);
  stats.scoring.longPar4Score = (
    arraySum(data.scoreLongPar4) / data.scoreLongPar4.length
  ).toFixed(3);
  stats.scoring.averageScoreFront = (
    (arraySum(data.scoresByHalves[0]) / data.scoresByHalves[0].length) *
    9
  ).toFixed(1);
  stats.scoring.averageScoreBack = (
    (arraySum(data.scoresByHalves[1]) / data.scoresByHalves[1].length) *
    9
  ).toFixed(1);
  stats.scoring.averageScoreFirst6 = (
    (arraySum(data.scoresByThirds[0]) / data.scoresByThirds[0].length) *
    6
  ).toFixed(1);
  stats.scoring.averageScoreMiddle6 = (
    (arraySum(data.scoresByThirds[1]) / data.scoresByThirds[1].length) *
    6
  ).toFixed(1);
  stats.scoring.averageScoreLast6 = (
    (arraySum(data.scoresByThirds[2]) / data.scoresByThirds[2].length) *
    6
  ).toFixed(1);
  stats.scoring.penalties = (data.penalties / fullRounds).toFixed(1);
  stats.scoring.recoveries = (data.recoveries / fullRounds).toFixed(1);
  stats.scoring.wastedShots = (
    arraySum(data.wastedShotsPerType) / fullRounds
  ).toFixed(1);

  // OFF THE TEE
  let metricValue = 0;
  let yardValue = 0;

  stats.offTee.longestDrive = Math.max.apply(null, data.driveDistance);

  if (unitSystem === Parameters.unitSystemMetric) {
    metricValue = (
      arraySum(data.driveDistance) / data.driveDistance.length
    ).toFixed(1);
    stats.offTee.averageDrive = (1 * metricValue).toFixed(1);
  }
 else {
    metricValue = (
      arraySum(data.driveDistance) / data.driveDistance.length
    ).toFixed(1);
    yardValue = 1 * metricValue;
    stats.offTee.averageDrive = (yardValue * 1.093613).toFixed(1);
  }

  stats.offTee.fairways = {
    made: data.girScrFwStats.fairway,
    attempts: data.girScrFwStats.fairwayCount,
  };
  stats.offTee.fairwaysPercentage = (
    (data.girScrFwStats.fairway / data.girScrFwStats.fairwayCount) *
    100
  ).toFixed(1); // FAIRWAYS_PERC   Fairways %
  stats.offTee.fairwaysLeftPercentage = (
    (data.girScrFwStats.left / data.girScrFwStats.fairwayCount) *
    100
  ).toFixed(1); // MISS_LEFT   Miss Left
  stats.offTee.fairwaysRightPercentage = (
    (data.girScrFwStats.right / data.girScrFwStats.fairwayCount) *
    100
  ).toFixed(1); // MISS_RIGHT   Miss Right
  stats.offTee.scoreFWHit = (
    arraySum(data.scoreFWHit[0]) / data.scoreFWHit[0].length
  ).toFixed(1); // SCORE_WHEN_FW   Average Score When Fairway Hit
  stats.offTee.scoreFWMiss = (
    arraySum(data.scoreFWHit[1]) / data.scoreFWHit[1].length
  ).toFixed(1); // SCORE_WHEN_NOT_FW   Average Score When Fairway Missed
  stats.offTee.wellHitMissFWPercentage = (
    (data.wellHitMissFWShotByType[0] / data.wellHitShotByType[0]) *
    100
  ).toFixed(0); // WELL_HIT_MISS_FW Well Hit -> Miss FW
  stats.offTee.missHitHitFWPercentage = (
    (data.missHitHitFwShotByType[0] / data.missHitShotByType[0]) *
    100
  ).toFixed(0); // MISS_HIT_HIT_FW Miss Hit -> Hit FW
  stats.offTee.fwHitAfter1Putting = (
    (data.qualityAfterMadePutt[0] / data.qualityAfterMadePutt[1]) *
    100
  ).toFixed(0); // FAIRWAY_HIT_AFTER_ONE_PUTT   Fairway Hit After 1-Putt
  stats.offTee.fwHitAfterMissPutting = (
    (data.qualityAfterMissPutt[0] / data.qualityAfterMissPutt[1]) *
    100
  ).toFixed(0); // FAIRWAY_HIT_AFTER_THREE_PUTT_OR_SHORT   Fairway Hit After Miss Putt
  stats.offTee.wastedShots = (data.wastedShotsPerType[0] / fullRounds).toFixed(
    1
  ); // WASTED_SHOTS  Wasted Shots

  // LONG SHOTS

  stats.longShots.longShotsCount = arraySum(data.longShotsByPars) / fullRounds; // NB_LONG_SHOTS
  stats.longShots.longShotsPar3Count = data.longShotsByPars[0] / fullRounds; // NB_PAR_3_LONG_SHOTS
  stats.longShots.longShotsPar4Count = data.longShotsByPars[1] / fullRounds; // NB_PAR_4_LONG_SHOTS
  stats.longShots.longShotsPar5Count = data.longShotsByPars[2] / fullRounds; // NB_PAR_5_LONG_SHOTS
  stats.longShots.fairwaysPercentage =
    (data.longShotsFWStats[1] / arraySum(data.longShotsFWStats)) * 100; // LONG_SHOT_FW_HIT
  stats.longShots.fairwaysLeftPercentage =
    (data.longShotsFWStats[0] / arraySum(data.longShotsFWStats)) * 100;
  stats.longShots.fairwaysRightPercentage =
    (data.longShotsFWStats[2] / arraySum(data.longShotsFWStats)) * 100;
  stats.longShots.nextShotCanAttack =
    (data.longShotsNextCanAttack / arraySum(data.longShotsFWStats)) * 100; // LONG_SHOT_NEXT_CAN_ATTACK
  stats.longShots.wellHitMissFWPercentage =
    (data.wellHitMissFWShotByType[1] / data.wellHitShotByType[1]) * 100; // WELL_HIT_MISS_FW
  stats.longShots.missHitHitFWPercentage =
    (data.missHitHitFwShotByType[1] / data.missHitShotByType[1]) * 100; // MISS_HIT_HIT_FW
  stats.longShots.wastedShots = (
    data.wastedShotsPerType[1] / fullRounds
  ).toFixed(1); // WASTED_SHOTS

  // APPROACH

  stats.approach.gir = {
    made: data.girScrFwStats.gir / fullRounds,
    attempts: data.girScrFwStats.girCount / fullRounds,
  }; // REGULATION
  stats.approach.girPercentage = (
    (data.girScrFwStats.gir / data.girScrFwStats.girCount) *
    100
  ).toFixed(1); // REGULATION_PERC    Greens in Regulation %
  stats.approach.girPar3Percentage = (
    (data.girsOfPars[0] / data.nbHolesOfPars[0]) *
    100
  ).toFixed(1); // REGULATION_PERC_PAR_3    Par 3 GIR
  stats.approach.girPar4Percentage = (
    (data.girsOfPars[1] / data.nbHolesOfPars[1]) *
    100
  ).toFixed(1); // REGULATION_PERC_PAR_4    Par 4 GIR
  stats.approach.girPar5Percentage = (
    (data.girsOfPars[2] / data.nbHolesOfPars[2]) *
    100
  ).toFixed(1); // REGULATION_PERC_PAR_5    Par 5 GIR
  stats.approach.averageGirDistancePar3 =
    arraySum(data.distancesGIRShot[0]) / data.distancesGIRShot[0].length; // DISTANCE_GIR_SHOT_PAR_3
  stats.approach.averageGirDistancePar4 =
    arraySum(data.distancesGIRShot[1]) / data.distancesGIRShot[1].length; // DISTANCE_GIR_SHOT_PAR_4
  stats.approach.averageGirDistancePar5 =
    arraySum(data.distancesGIRShot[2]) / data.distancesGIRShot[2].length; // DISTANCE_GIR_SHOT_PAR_5
  stats.approach.averageFirstAttackDistancePar3 =
    arraySum(data.distancesFirstAttack[0]) /
    data.distancesFirstAttack[0].length; // DISTANCE_FIRST_ATTACK_SHOT_PAR_3
  stats.approach.averageFirstAttackDistancePar4 =
    arraySum(data.distancesFirstAttack[1]) /
    data.distancesFirstAttack[1].length; // DISTANCE_FIRST_ATTACK_SHOT_PAR_4
  stats.approach.averageFirstAttackDistancePar5 =
    arraySum(data.distancesFirstAttack[2]) /
    data.distancesFirstAttack[2].length; // DISTANCE_FIRST_ATTACK_SHOT_PAR_5
  stats.approach.greenHit = (
    (arraySum(data.approachAttacksHitsGreen) /
      fullRounds /
      (arraySum(data.approachAttacks) / fullRounds)) *
    100
  ).toFixed(1); // GREEN_HIT    Green Attacks Hit
  stats.approach.greenHitFromFW = (
    (data.approachAttacksHitsGreen[1] /
      fullRounds /
      (data.approachAttacks[1] / fullRounds)) *
    100
  ).toFixed(1); // GREEN_HIT_FROM_FW    Green Att. from Fairway
  stats.approach.greenHitFromRough = (
    (data.approachAttacksHitsGreen[2] /
      fullRounds /
      (data.approachAttacks[2] / fullRounds)) *
    100
  ).toFixed(1); // GREEN_HIT_FROM_ROUGH   Green Att. from Rough
  stats.approach.greenHitFromBunker = (
    (data.approachAttacksHitsGreen[3] /
      fullRounds /
      (data.approachAttacks[3] / fullRounds)) *
    100
  ).toFixed(1); // GREEN_HIT_FROM_BUNKER  Green Att. from Bunker
  stats.approach.greenHitPercentage =
    (arraySum(data.approachAttacksHitsGreen) / arraySum(data.approachAttacks)) *
    100; // GREEN_HIT
  stats.approach.greenHitFromFWPercentage =
    (data.approachAttacksHitsGreen[1] / data.approachAttacks[1]) * 100; // GREEN_HIT_FROM_FW
  stats.approach.greenHitFromRoughPercentage =
    (data.approachAttacksHitsGreen[2] / data.approachAttacks[2]) * 100; // GREEN_HIT_FROM_ROUGH
  stats.approach.greenHitFromBunkerPercentage =
    (data.approachAttacksHitsGreen[3] / data.approachAttacks[3]) * 100; // GREEN_HIT_FROM_BUNKER
  stats.approach.wellHitMissGreenPercentage = (
    (data.wellHitMissFWShotByType[2] / data.wellHitShotByType[2]) *
    100
  ).toFixed(1); // WELL_HIT_MISS_GREEN   Well Hit -> Miss Green
  stats.approach.missHitHitGreenPercentage =
    (data.missHitHitFwShotByType[2] / data.missHitShotByType[2]) * 100; // MISS_HIT_HIT_GREEN
  stats.approach.wastedShots = (
    data.wastedShotsPerType[2] / fullRounds
  ).toFixed(1); // WASTED_SHOTS

  // AROUND

  stats.around.scrambling = {
    made: data.girScrFwStats.scrambling / fullRounds,
    attempts: data.girScrFwStats.scramblingCount / fullRounds,
  }; // SCRAMBLING
  stats.around.scramblingPercentage = (
    (data.girScrFwStats.scrambling / data.girScrFwStats.scramblingCount) *
    100
  ).toFixed(1); // SCRAMBLING_PERC   Scrambling %
  stats.around.upDown = {
    made: arraySum(data.upDownsBySurface) / fullRounds,
    attempts: arraySum(data.upDownsAttemptsBySurface) / fullRounds,
  }; // UP_DOWN   Up & Downs
  stats.around.upDownFromFW = {
    made: data.upDownsBySurface[1] / fullRounds,
    attempts: data.upDownsAttemptsBySurface[1] / fullRounds,
  }; // UP_DOWN_FROM_FW   Up & Downs from Fairway
  stats.around.upDownFromRough = {
    made: data.upDownsBySurface[2] / fullRounds,
    attempts: data.upDownsAttemptsBySurface[2] / fullRounds,
  }; // UP_DOWN_FROM_ROUGH   Up & Downs from Rough
  stats.around.upDownFromBunker = {
    made: data.upDownsBySurface[3] / fullRounds,
    attempts: data.upDownsAttemptsBySurface[3] / fullRounds,
  }; // UP_DOWN_FROM_BUNKER    Up & Downs from Bunker
  stats.around.upDownPercentage = (
    (arraySum(data.upDownsBySurface) /
      arraySum(data.upDownsAttemptsBySurface)) *
    100
  ).toFixed(1); // UP_DOWN
  stats.around.upDownFromFWPercentage = (
    (data.upDownsBySurface[0] / data.upDownsAttemptsBySurface[0]) *
    100
  ).toFixed(1); // UP_DOWN_FROM_FW
  stats.around.upDownFromRoughPercentage = (
    ((data.upDownsBySurface[1] + data.upDownsBySurface[3]) /
      (data.upDownsAttemptsBySurface[1] + data.upDownsAttemptsBySurface[3])) *
    100
  ).toFixed(1); // UP_DOWN_FROM_ROUGH
  stats.around.upDownFromBunkerPercentage = (
    (data.upDownsBySurface[2] / data.upDownsAttemptsBySurface[2]) *
    100
  ).toFixed(1); // UP_DOWN_FROM_BUNKER
  // TODO Grs Dispersion
  stats.around.holeOuts = (data.puttsStats[0] / fullRounds).toFixed(1); // AVERAGE_HOLE_OUTS   Average Hole Outs
  stats.around.wastedShots = (data.wastedShotsPerType[3] / fullRounds).toFixed(
    1
  ); // WASTED_SHOTS

  // GREEN , PUTTING
  stats.onGreen.totalPutts = (data.totalPutts / rounds.length).toFixed(1); // AVERAGE_PUTTS_ROUND
  stats.onGreen.puttsPerHole = (data.totalPutts / data.nbHolesPlayed).toFixed(
    1
  ); // PUTTS_PER_HOLE
  stats.onGreen.puttsPerHoleGir = (
    arraySum(data.puttsGir) / data.puttsGir.length
  ).toFixed(1); // PUTTS_PER_HOLE_GIR
  stats.onGreen.puttsPerHoleNonGir = (
    arraySum(data.puttsNonGir) / data.puttsNonGir.length
  ).toFixed(1); // PUTTS_PER_HOLE_NON_GIR
  stats.onGreen.putts1 = (data.puttsStats[1] / rounds.length).toFixed(1); // AVERAGE_ONE_PUTTS
  stats.onGreen.putts2 = (data.puttsStats[2] / rounds.length).toFixed(1); // AVERAGE_TWO_PUTTS
  stats.onGreen.putts3 = (
    (data.puttsStats[3] + data.puttsStats[4]) /
    rounds.length
  ).toFixed(1); // AVERAGE_THREE_PUTTS
  stats.onGreen.birdieConversion = (
    (data.birdieConversion.made / data.birdieConversion.attempts) *
    100
  ).toFixed(1); // AVERAGE_BIRDIE_CONVERSION
  stats.onGreen.parConversion = (
    (data.parConversion.made / data.parConversion.attempts) *
    100
  ).toFixed(1); // AVERAGE_PAR_CONVERSION
  stats.onGreen.shortPuttsMissed = (data.shortPuttsMissed / fullRounds).toFixed(
    1
  ); // AVERAGE_NB_SHORT_PUTTS_MISSED
  // TODO
  /*
	stats.onGreen.firstPuttDistance =
	stats.onGreen.secondPuttDistance =
	stats.onGreen.metersPuttsMade =
	stats.onGreen.metersBirdiePuttsMade =
	stats.onGreen.makePuttZone =
	stats.onGreen.shortestMissed =
	stats.onGreen.longestMade =
	*/
  let wellreadPutts = 0;
  data.puttStatsFullReads.forEach(function(array) {
    wellreadPutts += array[0];
  });
  stats.onGreen.misReadPutts = (data.totalPutts - wellreadPutts) / fullRounds; // MISREAD_PUTTS
  stats.onGreen.misReadPuttPercentage = (
    ((data.totalPutts - wellreadPutts) / data.totalPutts) *
    100
  ).toFixed(1); // MISREAD_PUTTS_PERC
  stats.onGreen.wellReadPutts = wellreadPutts / fullRounds;
  stats.onGreen.wellReadPuttPercentage =
    (wellreadPutts / data.totalPutts) * 100;
  stats.onGreen.wastedShots = (data.wastedShotsPerType[4] / fullRounds).toFixed(
    1
  ); // WASTED_SHOTS

  // ONE PUTTS
  stats.onGreen.dist_0_1 = (
    (data.nbOnePutts[0] / data.nbOnePuttsTotal[0]) *
    100
  ).toFixed(1);
  stats.onGreen.dist_1_2 = (
    (data.nbOnePutts[1] / data.nbOnePuttsTotal[1]) *
    100
  ).toFixed(1);
  stats.onGreen.dist_2_3 = (
    (data.nbOnePutts[2] / data.nbOnePuttsTotal[2]) *
    100
  ).toFixed(1);
  stats.onGreen.dist_3_4 = (
    (data.nbOnePutts[3] / data.nbOnePuttsTotal[3]) *
    100
  ).toFixed(1);
  stats.onGreen.dist_4_5 = (
    (data.nbOnePutts[4] / data.nbOnePuttsTotal[4]) *
    100
  ).toFixed(1);
  stats.onGreen.dist_5_6 = (
    (data.nbOnePutts[5] / data.nbOnePuttsTotal[5]) *
    100
  ).toFixed(1);
  stats.onGreen.dist_6_9 = (
    (data.nbOnePutts[6] / data.nbOnePuttsTotal[6]) *
    100
  ).toFixed(1);
  stats.onGreen.dist_9_12 = (
    (data.nbOnePutts[7] / data.nbOnePuttsTotal[7]) *
    100
  ).toFixed(1);
  stats.onGreen.dist_12 = (
    (data.nbOnePutts[8] / data.nbOnePuttsTotal[8]) *
    100
  ).toFixed(1);

  stats.onGreen.dist_0_1_attempts = data.nbOnePuttsTotal[0];

  // BIRDIE PUTTS
  stats.birdiePuttsDistances.dist_0_1 = {
    rate: (
      (data.nbOnePuttsForBirdie[0] / data.nbOnePuttsForBirdieTotal[0]) *
      100
    ).toFixed(1),
    attempts: data.nbOnePuttsForBirdieTotal[0],
  };
  stats.birdiePuttsDistances.dist_1_2 = {
    rate: (
      (data.nbOnePuttsForBirdie[1] / data.nbOnePuttsForBirdieTotal[1]) *
      100
    ).toFixed(1),
    attempts: data.nbOnePuttsForBirdieTotal[1],
  };
  stats.birdiePuttsDistances.dist_2_3 = {
    rate: (
      (data.nbOnePuttsForBirdie[2] / data.nbOnePuttsForBirdieTotal[2]) *
      100
    ).toFixed(1),
    attempts: data.nbOnePuttsForBirdieTotal[2],
  };
  stats.birdiePuttsDistances.dist_3_4 = {
    rate: (
      (data.nbOnePuttsForBirdie[3] / data.nbOnePuttsForBirdieTotal[3]) *
      100
    ).toFixed(1),
    attempts: data.nbOnePuttsForBirdieTotal[3],
  };
  stats.birdiePuttsDistances.dist_4_5 = {
    rate: (
      (data.nbOnePuttsForBirdie[4] / data.nbOnePuttsForBirdieTotal[4]) *
      100
    ).toFixed(1),
    attempts: data.nbOnePuttsForBirdieTotal[4],
  };
  stats.birdiePuttsDistances.dist_5_6 = {
    rate: (
      (data.nbOnePuttsForBirdie[5] / data.nbOnePuttsForBirdieTotal[5]) *
      100
    ).toFixed(1),
    attempts: data.nbOnePuttsForBirdieTotal[5],
  };
  stats.birdiePuttsDistances.dist_6_9 = {
    rate: (
      (data.nbOnePuttsForBirdie[6] / data.nbOnePuttsForBirdieTotal[6]) *
      100
    ).toFixed(1),
    attempts: data.nbOnePuttsForBirdieTotal[6],
  };
  stats.birdiePuttsDistances.dist_9_12 = {
    rate: (
      (data.nbOnePuttsForBirdie[7] / data.nbOnePuttsForBirdieTotal[7]) *
      100
    ).toFixed(1),
    attempts: data.nbOnePuttsForBirdieTotal[7],
  };
  stats.birdiePuttsDistances.dist_12 = {
    rate: (
      (data.nbOnePuttsForBirdie[8] / data.nbOnePuttsForBirdieTotal[8]) *
      100
    ).toFixed(1),
    attempts: data.nbOnePuttsForBirdieTotal[8],
  };

  // PAR PUTTS
  stats.parPuttsDistances.dist_0_1 = {
    rate: (
      (data.nbOnePuttsForPar[0] / data.nbOnePuttsForParTotal[0]) *
      100
    ).toFixed(1),
    attempts: data.nbOnePuttsForParTotal[0],
  };
  stats.parPuttsDistances.dist_1_2 = {
    rate: (
      (data.nbOnePuttsForPar[1] / data.nbOnePuttsForParTotal[1]) *
      100
    ).toFixed(1),
    attempts: data.nbOnePuttsForParTotal[1],
  };
  stats.parPuttsDistances.dist_2_3 = {
    rate: (
      (data.nbOnePuttsForPar[2] / data.nbOnePuttsForParTotal[2]) *
      100
    ).toFixed(1),
    attempts: data.nbOnePuttsForParTotal[2],
  };
  stats.parPuttsDistances.dist_3_4 = {
    rate: (
      (data.nbOnePuttsForPar[3] / data.nbOnePuttsForParTotal[3]) *
      100
    ).toFixed(1),
    attempts: data.nbOnePuttsForParTotal[3],
  };
  stats.parPuttsDistances.dist_4_5 = {
    rate: (
      (data.nbOnePuttsForPar[4] / data.nbOnePuttsForParTotal[4]) *
      100
    ).toFixed(1),
    attempts: data.nbOnePuttsForParTotal[4],
  };
  stats.parPuttsDistances.dist_5_6 = {
    rate: (
      (data.nbOnePuttsForPar[5] / data.nbOnePuttsForParTotal[5]) *
      100
    ).toFixed(1),
    attempts: data.nbOnePuttsForParTotal[5],
  };
  stats.parPuttsDistances.dist_6_9 = {
    rate: (
      (data.nbOnePuttsForPar[6] / data.nbOnePuttsForParTotal[6]) *
      100
    ).toFixed(1),
    attempts: data.nbOnePuttsForParTotal[6],
  };
  stats.parPuttsDistances.dist_9_12 = {
    rate: (
      (data.nbOnePuttsForPar[7] / data.nbOnePuttsForParTotal[7]) *
      100
    ).toFixed(1),
    attempts: data.nbOnePuttsForParTotal[7],
  };
  stats.parPuttsDistances.dist_12 = {
    rate: (
      (data.nbOnePuttsForPar[8] / data.nbOnePuttsForParTotal[8]) *
      100
    ).toFixed(1),
    attempts: data.nbOnePuttsForParTotal[8],
  };

  // THREE PUTTS
  stats.threePuttsDistances.dist_0_2 = {
    rate: ((data.nbThreePutts[0] / data.nbThreePuttsTotal[0]) * 100).toFixed(1),
    attempts: data.nbThreePuttsTotal[0],
  };
  stats.threePuttsDistances.dist_2_4 = {
    rate: ((data.nbThreePutts[1] / data.nbThreePuttsTotal[1]) * 100).toFixed(1),
    attempts: data.nbThreePuttsTotal[1],
  };
  stats.threePuttsDistances.dist_4_6 = {
    rate: ((data.nbThreePutts[2] / data.nbThreePuttsTotal[2]) * 100).toFixed(1),
    attempts: data.nbThreePuttsTotal[2],
  };
  stats.threePuttsDistances.dist_6_9 = {
    rate: ((data.nbThreePutts[3] / data.nbThreePuttsTotal[3]) * 100).toFixed(1),
    attempts: data.nbThreePuttsTotal[3],
  };
  stats.threePuttsDistances.dist_9_12 = {
    rate: ((data.nbThreePutts[4] / data.nbThreePuttsTotal[4]) * 100).toFixed(1),
    attempts: data.nbThreePuttsTotal[4],
  };
  stats.threePuttsDistances.dist_12 = {
    rate: ((data.nbThreePutts[5] / data.nbThreePuttsTotal[5]) * 100).toFixed(1),
    attempts: data.nbThreePuttsTotal[5],
  };

  // TODO STREAKS

  // DISPERSION METERS

  stats.dispersion.flag_0_30 = (
    arraySum(data.flagDispersionMeters[0]) / data.flagDispersionMeters[0].length
  ).toFixed(1);
  stats.dispersion.target_0_30 = (
    arraySum(data.targetDispersionMeters[0]) /
    data.targetDispersionMeters[0].length
  ).toFixed(1);
  stats.dispersion.count_0_30 = data.targetDispersionMeters[0].length;

  stats.dispersion.flag_30_60 = (
    arraySum(data.flagDispersionMeters[1]) / data.flagDispersionMeters[1].length
  ).toFixed(1);
  stats.dispersion.target_30_60 = (
    arraySum(data.targetDispersionMeters[1]) /
    data.targetDispersionMeters[1].length
  ).toFixed(1);
  stats.dispersion.count_30_60 = data.targetDispersionMeters[1].length.toFixed(
    1
  );

  stats.dispersion.flag_60_70 = (
    arraySum(data.flagDispersionMeters[2]) / data.flagDispersionMeters[2].length
  ).toFixed(1);
  stats.dispersion.target_60_70 = (
    arraySum(data.targetDispersionMeters[2]) /
    data.targetDispersionMeters[2].length
  ).toFixed(1);
  stats.dispersion.count_60_70 = data.targetDispersionMeters[2].length;

  stats.dispersion.flag_70_80 = (
    arraySum(data.flagDispersionMeters[3]) / data.flagDispersionMeters[3].length
  ).toFixed(1);
  stats.dispersion.target_70_80 = (
    arraySum(data.targetDispersionMeters[3]) /
    data.targetDispersionMeters[3].length
  ).toFixed(1);
  stats.dispersion.count_70_80 = data.targetDispersionMeters[3].length;

  stats.dispersion.flag_80_90 = (
    arraySum(data.flagDispersionMeters[4]) / data.flagDispersionMeters[4].length
  ).toFixed(1);
  stats.dispersion.target_80_90 = (
    arraySum(data.targetDispersionMeters[4]) /
    data.targetDispersionMeters[4].length
  ).toFixed(1);
  stats.dispersion.count_80_90 = data.targetDispersionMeters[4].length;

  stats.dispersion.flag_90_100 = (
    arraySum(data.flagDispersionMeters[5]) / data.flagDispersionMeters[5].length
  ).toFixed(1);
  stats.dispersion.target_90_100 = (
    arraySum(data.targetDispersionMeters[5]) /
    data.targetDispersionMeters[5].length
  ).toFixed(1);
  stats.dispersion.count_90_100 = data.targetDispersionMeters[5].length;

  stats.dispersion.flag_100_110 = (
    arraySum(data.flagDispersionMeters[6]) / data.flagDispersionMeters[6].length
  ).toFixed(1);
  stats.dispersion.target_100_110 = (
    arraySum(data.targetDispersionMeters[6]) /
    data.targetDispersionMeters[6].length
  ).toFixed(1);
  stats.dispersion.count_100_110 = data.targetDispersionMeters[6].length;

  stats.dispersion.flag_110_120 = (
    arraySum(data.flagDispersionMeters[7]) / data.flagDispersionMeters[7].length
  ).toFixed(1);
  stats.dispersion.target_110_120 = (
    arraySum(data.targetDispersionMeters[7]) /
    data.targetDispersionMeters[7].length
  ).toFixed(1);
  stats.dispersion.count_110_120 = data.targetDispersionMeters[7].length;

  stats.dispersion.flag_120_130 = (
    arraySum(data.flagDispersionMeters[8]) / data.flagDispersionMeters[8].length
  ).toFixed(1);
  stats.dispersion.target_120_130 = (
    arraySum(data.targetDispersionMeters[8]) /
    data.targetDispersionMeters[8].length
  ).toFixed(1);
  stats.dispersion.count_120_130 = data.targetDispersionMeters[8].length;

  stats.dispersion.flag_130_140 = (
    arraySum(data.flagDispersionMeters[9]) / data.flagDispersionMeters[9].length
  ).toFixed(1);
  stats.dispersion.target_130_140 = (
    arraySum(data.targetDispersionMeters[9]) /
    data.targetDispersionMeters[9].length
  ).toFixed(1);
  stats.dispersion.count_130_140 = data.targetDispersionMeters[9].length;

  stats.dispersion.flag_140_150 = (
    arraySum(data.flagDispersionMeters[10]) /
    data.flagDispersionMeters[10].length
  ).toFixed(1);
  stats.dispersion.target_140_150 = (
    arraySum(data.targetDispersionMeters[10]) /
    data.targetDispersionMeters[10].length
  ).toFixed(1);
  stats.dispersion.count_140_150 = data.targetDispersionMeters[10].length;

  stats.dispersion.flag_150_160 = (
    arraySum(data.flagDispersionMeters[11]) /
    data.flagDispersionMeters[11].length
  ).toFixed(1);
  stats.dispersion.target_150_160 = (
    arraySum(data.targetDispersionMeters[11]) /
    data.targetDispersionMeters[11].length
  ).toFixed(1);
  stats.dispersion.count_150_160 = data.targetDispersionMeters[11].length;

  stats.dispersion.flag_160_170 = (
    arraySum(data.flagDispersionMeters[12]) /
    data.flagDispersionMeters[12].length
  ).toFixed(1);
  stats.dispersion.target_160_170 = (
    arraySum(data.targetDispersionMeters[12]) /
    data.targetDispersionMeters[12].length
  ).toFixed(1);
  stats.dispersion.count_160_170 = data.targetDispersionMeters[12].length;

  stats.dispersion.flag_170_180 = (
    arraySum(data.flagDispersionMeters[13]) /
    data.flagDispersionMeters[13].length
  ).toFixed(1);
  stats.dispersion.target_170_180 = (
    arraySum(data.targetDispersionMeters[13]) /
    data.targetDispersionMeters[13].length
  ).toFixed(1);
  stats.dispersion.count_170_180 = data.targetDispersionMeters[13].length;

  stats.dispersion.flag_180_190 = (
    arraySum(data.flagDispersionMeters[14]) /
    data.flagDispersionMeters[14].length
  ).toFixed(1);
  stats.dispersion.target_180_190 = (
    arraySum(data.targetDispersionMeters[14]) /
    data.targetDispersionMeters[14].length
  ).toFixed(1);
  stats.dispersion.count_180_190 = data.targetDispersionMeters[14].length;

  stats.dispersion.flag_190_200 = (
    arraySum(data.flagDispersionMeters[15]) /
    data.flagDispersionMeters[15].length
  ).toFixed(1);
  stats.dispersion.target_190_200 = (
    arraySum(data.targetDispersionMeters[15]) /
    data.targetDispersionMeters[15].length
  ).toFixed(1);
  stats.dispersion.count_190_200 = data.targetDispersionMeters[15].length;

  stats.dispersion.flag_200_220 = (
    arraySum(data.flagDispersionMeters[16]) /
    data.flagDispersionMeters[16].length
  ).toFixed(1);
  stats.dispersion.target_200_220 = (
    arraySum(data.targetDispersionMeters[16]) /
    data.targetDispersionMeters[16].length
  ).toFixed(1);
  stats.dispersion.count_200_220 = data.targetDispersionMeters[16].length;

  stats.dispersion.flag_220_250 = (
    arraySum(data.flagDispersionMeters[17]) /
    data.flagDispersionMeters[17].length
  ).toFixed(1);
  stats.dispersion.target_220_250 = (
    arraySum(data.targetDispersionMeters[17]) /
    data.targetDispersionMeters[17].length
  ).toFixed(1);
  stats.dispersion.count_220_250 = data.targetDispersionMeters[17].length;

  stats.dispersion.flag_250 = (
    arraySum(data.flagDispersionMeters[18]) /
    data.flagDispersionMeters[18].length
  ).toFixed(1);
  stats.dispersion.target_250 = (
    arraySum(data.targetDispersionMeters[18]) /
    data.targetDispersionMeters[18].length
  ).toFixed(1);
  stats.dispersion.count_250 = data.targetDispersionMeters[18].length;

  // DISPERSION %

  stats.dispersion.percent_flag_0_30 = (
    arraySum(data.flagDispersionPercentage[0]) /
    data.flagDispersionPercentage[0].length
  ).toFixed(1);
  stats.dispersion.percent_target_0_30 = (
    arraySum(data.targetDispersionPercentage[0]) /
    data.targetDispersionPercentage[0].length
  ).toFixed(1);
  stats.dispersion.percent_count_0_30 =
    data.targetDispersionPercentage[0].length;

  stats.dispersion.percent_flag_30_60 = (
    arraySum(data.flagDispersionPercentage[1]) /
    data.flagDispersionPercentage[1].length
  ).toFixed(1);
  stats.dispersion.percent_target_30_60 = (
    arraySum(data.targetDispersionPercentage[1]) /
    data.targetDispersionPercentage[1].length
  ).toFixed(1);
  stats.dispersion.percent_count_30_60 =
    data.targetDispersionPercentage[1].length;

  stats.dispersion.percent_flag_60_70 = (
    arraySum(data.flagDispersionPercentage[2]) /
    data.flagDispersionPercentage[2].length
  ).toFixed(1);
  stats.dispersion.percent_target_60_70 = (
    arraySum(data.targetDispersionPercentage[2]) /
    data.targetDispersionPercentage[2].length
  ).toFixed(1);
  stats.dispersion.percent_count_60_70 =
    data.targetDispersionPercentage[2].length;

  stats.dispersion.percent_flag_70_80 = (
    arraySum(data.flagDispersionPercentage[3]) /
    data.flagDispersionPercentage[3].length
  ).toFixed(1);
  stats.dispersion.percent_target_70_80 = (
    arraySum(data.targetDispersionPercentage[3]) /
    data.targetDispersionPercentage[3].length
  ).toFixed(1);
  stats.dispersion.percent_count_70_80 =
    data.targetDispersionPercentage[3].length;

  stats.dispersion.percent_flag_80_90 = (
    arraySum(data.flagDispersionPercentage[4]) /
    data.flagDispersionPercentage[4].length
  ).toFixed(1);
  stats.dispersion.percent_target_80_90 = (
    arraySum(data.targetDispersionPercentage[4]) /
    data.targetDispersionPercentage[4].length
  ).toFixed(1);
  stats.dispersion.percent_count_80_90 =
    data.targetDispersionPercentage[4].length;

  stats.dispersion.percent_flag_90_100 = (
    arraySum(data.flagDispersionPercentage[5]) /
    data.flagDispersionPercentage[5].length
  ).toFixed(1);
  stats.dispersion.percent_target_90_100 = (
    arraySum(data.targetDispersionPercentage[5]) /
    data.targetDispersionPercentage[5].length
  ).toFixed(1);
  stats.dispersion.percent_count_90_100 =
    data.targetDispersionPercentage[5].length;

  stats.dispersion.percent_flag_100_110 = (
    arraySum(data.flagDispersionPercentage[6]) /
    data.flagDispersionPercentage[6].length
  ).toFixed(1);
  stats.dispersion.percent_target_100_110 = (
    arraySum(data.targetDispersionPercentage[6]) /
    data.targetDispersionPercentage[6].length
  ).toFixed(1);
  stats.dispersion.percent_count_100_110 =
    data.targetDispersionPercentage[6].length;

  stats.dispersion.percent_flag_110_120 = (
    arraySum(data.flagDispersionPercentage[7]) /
    data.flagDispersionPercentage[7].length
  ).toFixed(1);
  stats.dispersion.percent_target_110_120 = (
    arraySum(data.targetDispersionPercentage[7]) /
    data.targetDispersionPercentage[7].length
  ).toFixed(1);
  stats.dispersion.percent_count_110_120 =
    data.targetDispersionPercentage[7].length;

  stats.dispersion.percent_flag_120_130 = (
    arraySum(data.flagDispersionPercentage[8]) /
    data.flagDispersionPercentage[8].length
  ).toFixed(1);
  stats.dispersion.percent_target_120_130 = (
    arraySum(data.targetDispersionPercentage[8]) /
    data.targetDispersionPercentage[8].length
  ).toFixed(1);
  stats.dispersion.percent_count_120_130 =
    data.targetDispersionPercentage[8].length;

  stats.dispersion.percent_flag_130_140 = (
    arraySum(data.flagDispersionPercentage[9]) /
    data.flagDispersionPercentage[9].length
  ).toFixed(1);
  stats.dispersion.percent_target_130_140 = (
    arraySum(data.targetDispersionPercentage[9]) /
    data.targetDispersionPercentage[9].length
  ).toFixed(1);
  stats.dispersion.percent_count_130_140 =
    data.targetDispersionPercentage[9].length;

  stats.dispersion.percent_flag_140_150 = (
    arraySum(data.flagDispersionPercentage[10]) /
    data.flagDispersionPercentage[10].length
  ).toFixed(1);
  stats.dispersion.percent_target_140_150 = (
    arraySum(data.targetDispersionPercentage[10]) /
    data.targetDispersionPercentage[10].length
  ).toFixed(1);
  stats.dispersion.percent_count_140_150 =
    data.targetDispersionPercentage[10].length;

  stats.dispersion.percent_flag_150_160 = (
    arraySum(data.flagDispersionPercentage[11]) /
    data.flagDispersionPercentage[11].length
  ).toFixed(1);
  stats.dispersion.percent_target_150_160 = (
    arraySum(data.targetDispersionPercentage[11]) /
    data.targetDispersionPercentage[11].length
  ).toFixed(1);
  stats.dispersion.percent_count_150_160 =
    data.targetDispersionPercentage[11].length;

  stats.dispersion.percent_flag_160_170 = (
    arraySum(data.flagDispersionPercentage[12]) /
    data.flagDispersionPercentage[12].length
  ).toFixed(1);
  stats.dispersion.percent_target_160_170 = (
    arraySum(data.targetDispersionPercentage[12]) /
    data.targetDispersionPercentage[12].length
  ).toFixed(1);
  stats.dispersion.percent_count_160_170 =
    data.targetDispersionPercentage[12].length;

  stats.dispersion.percent_flag_170_180 = (
    arraySum(data.flagDispersionPercentage[13]) /
    data.flagDispersionPercentage[13].length
  ).toFixed(1);
  stats.dispersion.percent_target_170_180 = (
    arraySum(data.targetDispersionPercentage[13]) /
    data.targetDispersionPercentage[13].length
  ).toFixed(1);
  stats.dispersion.percent_count_170_180 =
    data.targetDispersionPercentage[13].length;

  stats.dispersion.percent_flag_180_190 = (
    arraySum(data.flagDispersionPercentage[14]) /
    data.flagDispersionPercentage[14].length
  ).toFixed(1);
  stats.dispersion.percent_target_180_190 = (
    arraySum(data.targetDispersionPercentage[14]) /
    data.targetDispersionPercentage[14].length
  ).toFixed(1);
  stats.dispersion.percent_count_180_190 =
    data.targetDispersionPercentage[14].length;

  stats.dispersion.percent_flag_190_200 = (
    arraySum(data.flagDispersionPercentage[15]) /
    data.flagDispersionPercentage[15].length
  ).toFixed(1);
  stats.dispersion.percent_target_190_200 = (
    arraySum(data.targetDispersionPercentage[15]) /
    data.targetDispersionPercentage[15].length
  ).toFixed(1);
  stats.dispersion.percent_count_190_200 =
    data.targetDispersionPercentage[15].length;

  stats.dispersion.percent_flag_200_220 = (
    arraySum(data.flagDispersionPercentage[16]) /
    data.flagDispersionPercentage[16].length
  ).toFixed(1);
  stats.dispersion.percent_target_200_220 = (
    arraySum(data.targetDispersionPercentage[16]) /
    data.targetDispersionPercentage[16].length
  ).toFixed(1);
  stats.dispersion.percent_count_200_220 =
    data.targetDispersionPercentage[16].length;

  stats.dispersion.percent_flag_220_250 = (
    arraySum(data.flagDispersionPercentage[17]) /
    data.flagDispersionPercentage[17].length
  ).toFixed(1);
  stats.dispersion.percent_target_220_250 = (
    arraySum(data.targetDispersionPercentage[17]) /
    data.targetDispersionPercentage[17].length
  ).toFixed(1);
  stats.dispersion.percent_count_220_250 =
    data.targetDispersionPercentage[17].length;

  stats.dispersion.percent_flag_250 = (
    arraySum(data.flagDispersionPercentage[18]) /
    data.flagDispersionPercentage[18].length
  ).toFixed(1);
  stats.dispersion.percent_target_250 = (
    arraySum(data.targetDispersionPercentage[18]) /
    data.targetDispersionPercentage[18].length
  ).toFixed(1);
  stats.dispersion.percent_count_250 =
    data.targetDispersionPercentage[18].length;

  // STROKES GAINED CATEGORY
  stats.strokesGainedCategory = strokesGained.categories;
  stats.strokesGainedDistances = strokesGained.distances;
  /* eslint-disable */
  stats.strokesGained.strokesGainedTotal = stats.strokesGainedCategory.total[0].toFixed(
    3
  );
  stats.strokesGained.strokesGainedTotalCount = stats.strokesGainedCategory.total[1].toFixed(
    3
  );
  stats.strokesGained.strokesGainedApproach = stats.strokesGainedCategory.approach[0].toFixed(
    3
  );
  stats.strokesGained.strokesGainedApproachCount = stats.strokesGainedCategory.approach[1].toFixed(
    3
  );
  stats.strokesGained.strokesGainedOffTee = stats.strokesGainedCategory.offTee[0].toFixed(
    3
  );
  stats.strokesGained.strokesGainedOffTeeCount = stats.strokesGainedCategory.offTee[1].toFixed(
    3
  );
  stats.strokesGained.strokesGainedLongShots = stats.strokesGainedCategory.longShots[0].toFixed(
    3
  );
  stats.strokesGained.strokesGainedLongShotsCount = stats.strokesGainedCategory.longShots[1].toFixed(
    3
  );
  stats.strokesGained.strokesGainedAround = stats.strokesGainedCategory.around[0].toFixed(
    3
  );
  stats.strokesGained.strokesGainedAroundCount = stats.strokesGainedCategory.around[1].toFixed(
    3
  );
  stats.strokesGained.strokesGainedTeeToGreen = (
    stats.strokesGainedCategory.offTee[0] +
    stats.strokesGainedCategory.longShots[0] +
    stats.strokesGainedCategory.around[0] +
    stats.strokesGainedCategory.approach[0]
  ).toFixed(3);
  stats.strokesGained.strokesGainedTeeToGreenCount = (
    stats.strokesGainedCategory.offTee[1] +
    stats.strokesGainedCategory.longShots[1] +
    stats.strokesGainedCategory.around[1] +
    stats.strokesGainedCategory.approach[1]
  ).toFixed(3);
  stats.strokesGained.strokesGainedOnGreen = stats.strokesGainedCategory.onGreen[0].toFixed(
    3
  );
  stats.strokesGained.strokesGainedOnGreenCount = stats.strokesGainedCategory.onGreen[1].toFixed(
    3
  );
  /* eslint-enable */
  // OFF THE TEE

  metricValue = stats.strokesGainedDistances[0].toFixed(3)
  stats.strokesGained.sg_d_1 = (1 * metricValue).toFixed(3)

  metricValue = stats.strokesGainedDistances[1].toFixed(3)
  stats.strokesGained.sg_d_1_2 = (1 * metricValue).toFixed(3)

  metricValue = stats.strokesGainedDistances[2].toFixed(3)
  stats.strokesGained.sg_d_2_3 = (1 * metricValue).toFixed(3)

  metricValue = stats.strokesGainedDistances[3].toFixed(3)
  stats.strokesGained.sg_d_3_4 = (1 * metricValue).toFixed(3)

  metricValue = stats.strokesGainedDistances[4].toFixed(3)
  stats.strokesGained.sg_d_4_5 = (1 * metricValue).toFixed(3)

  metricValue = stats.strokesGainedDistances[5].toFixed(3)
  stats.strokesGained.sg_d_5_6 = (1 * metricValue).toFixed(3)

  metricValue = stats.strokesGainedDistances[6].toFixed(3)
  stats.strokesGained.sg_d_6_9 = (1 * metricValue).toFixed(3)

  metricValue = stats.strokesGainedDistances[7].toFixed(3)
  stats.strokesGained.sg_d_9_12 = (1 * metricValue).toFixed(3)

  metricValue = stats.strokesGainedDistances[8].toFixed(3)
  stats.strokesGained.sg_d_12 = (1 * metricValue).toFixed(3)

  metricValue = stats.strokesGainedDistances[9].toFixed(3)
  stats.strokesGained.sg_d_30 = (1 * metricValue).toFixed(3)

  metricValue = stats.strokesGainedDistances[10].toFixed(3)
  stats.strokesGained.sg_d_30_60 = (1 * metricValue).toFixed(3)

  metricValue = stats.strokesGainedDistances[11].toFixed(3)
  stats.strokesGained.sg_d_60_70 = (1 * metricValue).toFixed(3)

  metricValue = stats.strokesGainedDistances[12].toFixed(3)
  stats.strokesGained.sg_d_70_80 = (1 * metricValue).toFixed(3)

  metricValue = stats.strokesGainedDistances[13].toFixed(3)
  stats.strokesGained.sg_d_80_90 = (1 * metricValue).toFixed(3)

  metricValue = stats.strokesGainedDistances[14].toFixed(3)
  stats.strokesGained.sg_d_90_100 = (1 * metricValue).toFixed(3)

  metricValue = stats.strokesGainedDistances[15].toFixed(3)
  stats.strokesGained.sg_d_100_110 = (1 * metricValue).toFixed(3)

  metricValue = stats.strokesGainedDistances[16].toFixed(3)
  stats.strokesGained.sg_d_110_120 = (1 * metricValue).toFixed(3)

  metricValue = stats.strokesGainedDistances[17].toFixed(3)
  stats.strokesGained.sg_d_120_130 = (1 * metricValue).toFixed(3)

  metricValue = stats.strokesGainedDistances[18].toFixed(3)
  stats.strokesGained.sg_d_130_140 = (1 * metricValue).toFixed(3)

  metricValue = stats.strokesGainedDistances[19].toFixed(3)
  stats.strokesGained.sg_d_140_150 = (1 * metricValue).toFixed(3)

  metricValue = stats.strokesGainedDistances[20].toFixed(3)
  stats.strokesGained.sg_d_150_160 = (1 * metricValue).toFixed(3)

  metricValue = stats.strokesGainedDistances[21].toFixed(3)
  stats.strokesGained.sg_d_160_170 = (1 * metricValue).toFixed(3)

  metricValue = stats.strokesGainedDistances[22].toFixed(3)
  stats.strokesGained.sg_d_170_180 = (1 * metricValue).toFixed(3)

  metricValue = stats.strokesGainedDistances[23].toFixed(3)
  stats.strokesGained.sg_d_180_190 = (1 * metricValue).toFixed(3)

  metricValue = stats.strokesGainedDistances[24].toFixed(3)
  stats.strokesGained.sg_d_190_200 = (1 * metricValue).toFixed(3)

  metricValue = stats.strokesGainedDistances[25].toFixed(3)
  stats.strokesGained.sg_d_200_220 = (1 * metricValue).toFixed(3)

  metricValue = stats.strokesGainedDistances[26].toFixed(3)
  stats.strokesGained.sg_d_220_250 = (1 * metricValue).toFixed(3)

  metricValue = stats.strokesGainedDistances[27].toFixed(3)
  stats.strokesGained.sg_d_250 = (1 * metricValue).toFixed(3)

  // ignore putting quality (not recorded)
  data.quality[4] = 0
  data.qualityCount[4] = 0

  stats.impact.total = (
    (arraySum(data.quality) / arraySum(data.qualityCount))
    * 100
  ).toFixed(1)
  stats.impact.offTee = (
    (data.quality[0] / data.qualityCount[0])
    * 100
  ).toFixed(1)
  stats.impact.longShots = (
    (data.quality[1] / data.qualityCount[1])
    * 100
  ).toFixed(1)
  stats.impact.approach = (
    (data.quality[2] / data.qualityCount[2])
    * 100
  ).toFixed(1)
  stats.impact.around = (
    (data.quality[3] / data.qualityCount[3])
    * 100
  ).toFixed(1)
  stats.impact.onGreen = '-' // data.quality[4]/data.qualityCount[4];

  // ROUTINE
  // ignore putting quality (not recorded)
  // data.routine[4] = 0
  // data.routineCount[4] = 0

  stats.routine.total = (
    (arraySum(data.routine) / arraySum(data.routineCount))
    * 100
  ).toFixed(1)
  stats.routine.offTee = (
    (data.routine[0] / data.routineCount[0])
    * 100
  ).toFixed(1)
  stats.routine.longShots = (
    (data.routine[1] / data.routineCount[1])
    * 100
  ).toFixed(1)
  stats.routine.approach = (
    (data.routine[2] / data.routineCount[2])
    * 100
  ).toFixed(1)
  stats.routine.around = (
    (data.routine[3] / data.routineCount[3])
    * 100
  ).toFixed(1)
  stats.routine.onGreen = (
    (data.routine[4] / data.routineCount[4])
    * 100
  ).toFixed(1)
  // stats.routine.onGreen = '-' // data.quality[4]/data.qualityCount[4];

  // WASTED SHOTS

  stats.wasted.total = stats.scoring.wastedShots
  stats.wasted.offTee = stats.offTee.wastedShots
  stats.wasted.longShots = stats.longShots.wastedShots
  stats.wasted.approach = stats.approach.wastedShots
  stats.wasted.around = stats.around.wastedShots
  stats.wasted.onGreen = stats.onGreen.wastedShots

  return stats
}

/*
getStatsRound

Parameters:
rounds: Round,
shots: [[Shot]]

Return:
Json containing all the stats
*/
// function getStatsRound(round, shots) {
//   const data = calculateStatsRound(round, shots);

//   const strokesGained = calculateStrokesGained(round, shots);

//   const stats = {
//     scoring: {},
//     offTee: {},
//     longShots: {},
//     approach: {},
//     around: {},
//     onGreen: {},
//     onePuttsDistances: {},
//     birdiePuttsDistances: {},
//     parPuttsDistances: {},
//     threePuttsDistances: {},
//     mental: {},
//     dispersionTargetFlagMeters: {},
//     dispersionTargetFlagPercentage: {},
//     strokesGainedCategory: {},
//     strokesGainedDistances: []
//   };

//   // SCORING

//   stats.scoring.holesPlayed = data.nbHolesPlayed;
//   stats.scoring.strokes = data.totalStrokes;
//   stats.scoring.strokesToPar = data.totalStrokes - data.totalPar;

//   // TODO pickups
//   // STFD
//   // HCP Played

//   stats.scoring.nbEagles = data.scoreStats[0];
//   stats.scoring.nbBirdies = data.scoreStats[1];
//   stats.scoring.nbBirdiesM = data.scoreStats[0] + data.scoreStats[1];
//   stats.scoring.nbPars = data.scoreStats[2];
//   stats.scoring.nbBogeys = data.scoreStats[3];
//   stats.scoring.nb2Bogeys = data.scoreStats[4];
//   stats.scoring.nb2BogeysP = data.scoreStats[4] + data.scoreStats[5];
//   stats.scoring.nb3Bogeys = data.scoreStats[5];
//   stats.scoring.par3Score = data.scoreOfPars[0] / data.nbHolesOfPars[0];
//   stats.scoring.par4Score = data.scoreOfPars[1] / data.nbHolesOfPars[1];
//   stats.scoring.par5Score = data.scoreOfPars[2] / data.nbHolesOfPars[2];
//   stats.scoring.shortPar4Score =
//     arraySum(data.scoreShortPar4) / data.scoreShortPar4.length;
//   stats.scoring.longPar4Score =
//     arraySum(data.scoreLongPar4) / data.scoreLongPar4.length;
//   stats.scoring.averageScoreFront =
//     (arraySum(data.scoresByHalves[0]) / data.scoresByHalves[0].length) * 9;
//   stats.scoring.averageScoreBack =
//     (arraySum(data.scoresByHalves[1]) / data.scoresByHalves[1].length) * 9;
//   stats.scoring.averageScoreFirst6 =
//     (arraySum(data.scoresByThirds[0]) / data.scoresByThirds[0].length) * 6;
//   stats.scoring.averageScoreMiddle6 =
//     (arraySum(data.scoresByThirds[1]) / data.scoresByThirds[1].length) * 6;
//   stats.scoring.averageScoreLast6 =
//     (arraySum(data.scoresByThirds[2]) / data.scoresByThirds[2].length) * 6;
//   stats.scoring.penalties = data.penalties;
//   stats.scoring.recoveries = data.recoveries;
//   stats.scoring.wastedShots = arraySum(data.wastedShotsPerType);

//   // OFF THE TEE

//   stats.offTee.longestDrive = Math.max.apply(null, data.driveDistance);
//   stats.offTee.averageDrive =
//     arraySum(data.driveDistance) / data.driveDistance.length;
//   stats.offTee.fairways = {
//     made: data.girScrFwStats.fairway,
//     attempts: data.girScrFwStats.fairwayCount
//   };
//   stats.offTee.fairwaysPercentage =
//     (data.girScrFwStats.fairway / data.girScrFwStats.fairwayCount) * 100;
//   stats.offTee.fairwaysLeftPercentage =
//     (data.girScrFwStats.left / data.girScrFwStats.fairwayCount) * 100;
//   stats.offTee.fairwaysRightPercentage =
//     (data.girScrFwStats.right / data.girScrFwStats.fairwayCount) * 100;
//   stats.offTee.scoreFWHit =
//     arraySum(data.scoreFWHit[0]) / data.scoreFWHit[0].length;
//   stats.offTee.scoreFWMiss =
//     arraySum(data.scoreFWHit[1]) / data.scoreFWHit[1].length;
//   stats.offTee.wellHitMissFWPercentage =
//     (data.wellHitMissFWShotByType[0] / data.wellHitShotByType[0]) * 100;
//   stats.offTee.missHitHitFWPercentage =
//     (data.missHitHitFwShotByType[0] / data.missHitShotByType[0]) * 100;
//   stats.offTee.fwHitAfter1Putting =
//     (data.qualityAfterMadePutt[0] / data.qualityAfterMadePutt[1]) * 100;
//   stats.offTee.fwHitAfterMissPutting =
//     (data.qualityAfterMissPutt[0] / data.qualityAfterMissPutt[1]) * 100;
//   stats.offTee.wastedShots = data.wastedShotsPerType[0];

//   // LONG SHOTS

//   stats.longShots.longShotsCount = arraySum(data.longShotsByPars);
//   stats.longShots.longShotsPar3Count = data.longShotsByPars[0];
//   stats.longShots.longShotsPar4Count = data.longShotsByPars[1];
//   stats.longShots.longShotsPar5Count = data.longShotsByPars[2];
//   stats.longShots.fairwaysPercentage =
//     (data.longShotsFWStats[1] / arraySum(data.longShotsFWStats)) * 100;
//   stats.longShots.fairwaysLeftPercentage =
//     (data.longShotsFWStats[0] / arraySum(data.longShotsFWStats)) * 100;
//   stats.longShots.fairwaysRightPercentage =
//     (data.longShotsFWStats[2] / arraySum(data.longShotsFWStats)) * 100;
//   stats.longShots.nextShotCanAttack =
//     (data.longShotsNextCanAttack / arraySum(data.longShotsFWStats)) * 100;
//   stats.longShots.wellHitMissFWPercentage =
//     (data.wellHitMissFWShotByType[1] / data.wellHitShotByType[1]) * 100;
//   stats.longShots.missHitHitFWPercentage =
//     (data.missHitHitFwShotByType[1] / data.missHitShotByType[1]) * 100;
//   stats.longShots.wastedShots = data.wastedShotsPerType[1];

//   // APPROACH

//   stats.approach.gir = {
//     made: data.girScrFwStats.gir,
//     attempts: data.girScrFwStats.girCount
//   };
//   stats.approach.girPercentage =
//     (data.girScrFwStats.gir / data.girScrFwStats.girCount) * 100;
//   stats.approach.girPar3Percentage =
//     (data.girsOfPars[0] / data.nbHolesOfPars[0]) * 100;
//   stats.approach.girPar4Percentage =
//     (data.girsOfPars[1] / data.nbHolesOfPars[1]) * 100;
//   stats.approach.girPar5Percentage =
//     (data.girsOfPars[2] / data.nbHolesOfPars[2]) * 100;
//   stats.approach.averageGirDistancePar3 =
//     arraySum(data.distancesGIRShot[0]) / data.distancesGIRShot[0].length;
//   stats.approach.averageGirDistancePar4 =
//     arraySum(data.distancesGIRShot[1]) / data.distancesGIRShot[1].length;
//   stats.approach.averageGirDistancePar5 =
//     arraySum(data.distancesGIRShot[2]) / data.distancesGIRShot[2].length;
//   stats.approach.averageFirstAttackDistancePar3 =
//     arraySum(data.distancesFirstAttack[0]) /
//     data.distancesFirstAttack[0].length;
//   stats.approach.averageFirstAttackDistancePar4 =
//     arraySum(data.distancesFirstAttack[1]) /
//     data.distancesFirstAttack[1].length;
//   stats.approach.averageFirstAttackDistancePar5 =
//     arraySum(data.distancesFirstAttack[2]) /
//     data.distancesFirstAttack[2].length;
//   stats.approach.greenHit = {
//     made: arraySum(data.approachAttacksHitsGreen),
//     attempts: arraySum(data.approachAttacks)
//   };
//   stats.approach.greenHitFromFW = {
//     made: data.approachAttacksHitsGreen[1],
//     attempts: data.approachAttacks[1]
//   };
//   stats.approach.greenHitFromRough = {
//     made: data.approachAttacksHitsGreen[2],
//     attempts: data.approachAttacks[2]
//   };
//   stats.approach.greenHitFromBunker = {
//     made: data.approachAttacksHitsGreen[3],
//     attempts: data.approachAttacks[3]
//   };
//   stats.approach.greenHitPercentage =
//     (arraySum(data.approachAttacksHitsGreen) / arraySum(data.approachAttacks)) *
//     100;
//   stats.approach.greenHitFromFWPercentage =
//     (data.approachAttacksHitsGreen[1] / data.approachAttacks[1]) * 100;
//   stats.approach.greenHitFromRoughPercentage =
//     (data.approachAttacksHitsGreen[2] / data.approachAttacks[2]) * 100;
//   stats.approach.greenHitFromBunkerPercentage =
//     (data.approachAttacksHitsGreen[3] / data.approachAttacks[3]) * 100;
//   stats.approach.wellHitMissGreenPercentage =
//     (data.wellHitMissFWShotByType[2] / data.wellHitShotByType[2]) * 100;
//   stats.approach.missHitHitGreenPercentage =
//     (data.missHitHitFwShotByType[2] / data.missHitShotByType[2]) * 100;
//   stats.approach.wastedShots = data.wastedShotsPerType[2];

//   // AROUND

//   stats.around.scrambling = {
//     made: data.girScrFwStats.scrambling,
//     attempts: data.girScrFwStats.scramblingCount
//   };
//   stats.around.scramblingPercentage =
//     (data.girScrFwStats.scrambling / data.girScrFwStats.scramblingCount) * 100;
//   stats.around.upDown = {
//     made: arraySum(data.upDownsBySurface),
//     attempts: arraySum(data.upDownsAttemptsBySurface)
//   };
//   stats.around.upDownFromFW = {
//     made: data.upDownsBySurface[1],
//     attempts: data.upDownsAttemptsBySurface[1]
//   };
//   stats.around.upDownFromRough = {
//     made: data.upDownsBySurface[2],
//     attempts: data.upDownsAttemptsBySurface[2]
//   };
//   stats.around.upDownFromBunker = {
//     made: data.upDownsBySurface[3],
//     attempts: data.upDownsAttemptsBySurface[3]
//   };
//   stats.around.upDownPercentage =
//     (arraySum(data.upDownsBySurface) /
//       arraySum(data.upDownsAttemptsBySurface)) *
//     100;
//   stats.around.upDownFromFWPercentage =
//     (data.upDownsBySurface[0] / data.upDownsAttemptsBySurface[0]) * 100;
//   stats.around.upDownFromRoughPercentage =
//     ((data.upDownsBySurface[1] + data.upDownsBySurface[3]) /
//       (data.upDownsAttemptsBySurface[1] + data.upDownsAttemptsBySurface[3])) *
//     100;
//   stats.around.upDownFromBunkerPercentage =
//     (data.upDownsBySurface[2] / data.upDownsAttemptsBySurface[2]) * 100;
//   // TODO Grs Dispersion
//   stats.around.holeOuts = data.puttsStats[0];
//   stats.around.wastedShots = data.wastedShotsPerType[3];

//   // GREEN
//   stats.onGreen.totalPutts = data.totalPutts;
//   stats.onGreen.puttsPerHole = data.totalPutts / data.nbHolesPlayed;
//   stats.onGreen.puttsPerHoleGir =
//     arraySum(data.puttsGir) / data.puttsGir.length;
//   stats.onGreen.puttsPerHoleNonGir =
//     arraySum(data.puttsNonGir) / data.puttsNonGir.length;
//   stats.onGreen.putts1 = data.puttsStats[1];
//   stats.onGreen.putts2 = data.puttsStats[2];
//   stats.onGreen.putts3 = data.puttsStats[3] + data.puttsStats[4];
//   stats.onGreen.birdieConversion =
//     (data.birdieConversion.made / data.birdieConversion.attempts) * 100;
//   stats.onGreen.parConversion =
//     (data.parConversion.made / data.parConversion.attempts) * 100;
//   stats.onGreen.shortPuttsMissed = data.shortPuttsMissed;
//   let wellreadPutts = 0;
//   data.puttStatsFullReads.forEach(array => {
//     wellreadPutts += array[0];
//   });
//   stats.onGreen.misReadPutts = data.totalPutts - wellreadPutts;
//   stats.onGreen.misReadPuttPercentage =
//     ((data.totalPutts - wellreadPutts) / data.totalPutts) * 100;
//   stats.onGreen.wellReadPutts = wellreadPutts;
//   stats.onGreen.wellReadPuttPercentage =
//     (wellreadPutts / data.totalPutts) * 100;
//   stats.onGreen.wastedShots = data.wastedShotsPerType[4];

//   // ONE PUTTS
//   stats.onePuttsDistances.dist_0_1 = {
//     rate: (data.nbOnePutts[0] / data.nbOnePuttsTotal[0]) * 100,
//     attempts: data.nbOnePuttsTotal[0]
//   };
//   stats.onePuttsDistances.dist_1_2 = {
//     rate: (data.nbOnePutts[1] / data.nbOnePuttsTotal[1]) * 100,
//     attempts: data.nbOnePuttsTotal[1]
//   };
//   stats.onePuttsDistances.dist_2_3 = {
//     rate: (data.nbOnePutts[2] / data.nbOnePuttsTotal[2]) * 100,
//     attempts: data.nbOnePuttsTotal[2]
//   };
//   stats.onePuttsDistances.dist_3_4 = {
//     rate: (data.nbOnePutts[3] / data.nbOnePuttsTotal[3]) * 100,
//     attempts: data.nbOnePuttsTotal[3]
//   };
//   stats.onePuttsDistances.dist_4_5 = {
//     rate: (data.nbOnePutts[4] / data.nbOnePuttsTotal[4]) * 100,
//     attempts: data.nbOnePuttsTotal[4]
//   };
//   stats.onePuttsDistances.dist_5_6 = {
//     rate: (data.nbOnePutts[5] / data.nbOnePuttsTotal[5]) * 100,
//     attempts: data.nbOnePuttsTotal[5]
//   };
//   stats.onePuttsDistances.dist_6_9 = {
//     rate: (data.nbOnePutts[6] / data.nbOnePuttsTotal[6]) * 100,
//     attempts: data.nbOnePuttsTotal[6]
//   };
//   stats.onePuttsDistances.dist_9_12 = {
//     rate: (data.nbOnePutts[7] / data.nbOnePuttsTotal[7]) * 100,
//     attempts: data.nbOnePuttsTotal[7]
//   };
//   stats.onePuttsDistances.dist_12 = {
//     rate: (data.nbOnePutts[8] / data.nbOnePuttsTotal[8]) * 100,
//     attempts: data.nbOnePuttsTotal[8]
//   };

//   // BIRDIE PUTTS
//   stats.birdiePuttsDistances.dist_0_1 = {
//     rate:
//       (data.nbOnePuttsForBirdie[0] / data.nbOnePuttsForBirdieTotal[0]) * 100,
//     attempts: data.nbOnePuttsForBirdieTotal[0]
//   };
//   stats.birdiePuttsDistances.dist_1_2 = {
//     rate:
//       (data.nbOnePuttsForBirdie[1] / data.nbOnePuttsForBirdieTotal[1]) * 100,
//     attempts: data.nbOnePuttsForBirdieTotal[1]
//   };
//   stats.birdiePuttsDistances.dist_2_3 = {
//     rate:
//       (data.nbOnePuttsForBirdie[2] / data.nbOnePuttsForBirdieTotal[2]) * 100,
//     attempts: data.nbOnePuttsForBirdieTotal[2]
//   };
//   stats.birdiePuttsDistances.dist_3_4 = {
//     rate:
//       (data.nbOnePuttsForBirdie[3] / data.nbOnePuttsForBirdieTotal[3]) * 100,
//     attempts: data.nbOnePuttsForBirdieTotal[3]
//   };
//   stats.birdiePuttsDistances.dist_4_5 = {
//     rate:
//       (data.nbOnePuttsForBirdie[4] / data.nbOnePuttsForBirdieTotal[4]) * 100,
//     attempts: data.nbOnePuttsForBirdieTotal[4]
//   };
//   stats.birdiePuttsDistances.dist_5_6 = {
//     rate:
//       (data.nbOnePuttsForBirdie[5] / data.nbOnePuttsForBirdieTotal[5]) * 100,
//     attempts: data.nbOnePuttsForBirdieTotal[5]
//   };
//   stats.birdiePuttsDistances.dist_6_9 = {
//     rate:
//       (data.nbOnePuttsForBirdie[6] / data.nbOnePuttsForBirdieTotal[6]) * 100,
//     attempts: data.nbOnePuttsForBirdieTotal[6]
//   };
//   stats.birdiePuttsDistances.dist_9_12 = {
//     rate:
//       (data.nbOnePuttsForBirdie[7] / data.nbOnePuttsForBirdieTotal[7]) * 100,
//     attempts: data.nbOnePuttsForBirdieTotal[7]
//   };
//   stats.birdiePuttsDistances.dist_12 = {
//     rate:
//       (data.nbOnePuttsForBirdie[8] / data.nbOnePuttsForBirdieTotal[8]) * 100,
//     attempts: data.nbOnePuttsForBirdieTotal[8]
//   };

//   // PAR PUTTS
//   stats.parPuttsDistances.dist_0_1 = {
//     rate: (data.nbOnePuttsForPar[0] / data.nbOnePuttsForParTotal[0]) * 100,
//     attempts: data.nbOnePuttsForParTotal[0]
//   };
//   stats.parPuttsDistances.dist_1_2 = {
//     rate: (data.nbOnePuttsForPar[1] / data.nbOnePuttsForParTotal[1]) * 100,
//     attempts: data.nbOnePuttsForParTotal[1]
//   };
//   stats.parPuttsDistances.dist_2_3 = {
//     rate: (data.nbOnePuttsForPar[2] / data.nbOnePuttsForParTotal[2]) * 100,
//     attempts: data.nbOnePuttsForParTotal[2]
//   };
//   stats.parPuttsDistances.dist_3_4 = {
//     rate: (data.nbOnePuttsForPar[3] / data.nbOnePuttsForParTotal[3]) * 100,
//     attempts: data.nbOnePuttsForParTotal[3]
//   };
//   stats.parPuttsDistances.dist_4_5 = {
//     rate: (data.nbOnePuttsForPar[4] / data.nbOnePuttsForParTotal[4]) * 100,
//     attempts: data.nbOnePuttsForParTotal[4]
//   };
//   stats.parPuttsDistances.dist_5_6 = {
//     rate: (data.nbOnePuttsForPar[5] / data.nbOnePuttsForParTotal[5]) * 100,
//     attempts: data.nbOnePuttsForParTotal[5]
//   };
//   stats.parPuttsDistances.dist_6_9 = {
//     rate: (data.nbOnePuttsForPar[6] / data.nbOnePuttsForParTotal[6]) * 100,
//     attempts: data.nbOnePuttsForParTotal[6]
//   };
//   stats.parPuttsDistances.dist_9_12 = {
//     rate: (data.nbOnePuttsForPar[7] / data.nbOnePuttsForParTotal[7]) * 100,
//     attempts: data.nbOnePuttsForParTotal[7]
//   };
//   stats.parPuttsDistances.dist_12 = {
//     rate: (data.nbOnePuttsForPar[8] / data.nbOnePuttsForParTotal[8]) * 100,
//     attempts: data.nbOnePuttsForParTotal[8]
//   };

//   // THREE PUTTS
//   stats.threePuttsDistances.dist_0_2 = {
//     rate: (data.nbThreePutts[0] / data.nbThreePuttsTotal[0]) * 100,
//     attempts: data.nbThreePuttsTotal[0]
//   };
//   stats.threePuttsDistances.dist_2_4 = {
//     rate: (data.nbThreePutts[1] / data.nbThreePuttsTotal[1]) * 100,
//     attempts: data.nbThreePuttsTotal[1]
//   };
//   stats.threePuttsDistances.dist_4_6 = {
//     rate: (data.nbThreePutts[2] / data.nbThreePuttsTotal[2]) * 100,
//     attempts: data.nbThreePuttsTotal[2]
//   };
//   stats.threePuttsDistances.dist_6_9 = {
//     rate: (data.nbThreePutts[3] / data.nbThreePuttsTotal[3]) * 100,
//     attempts: data.nbThreePuttsTotal[3]
//   };
//   stats.threePuttsDistances.dist_9_12 = {
//     rate: (data.nbThreePutts[4] / data.nbThreePuttsTotal[4]) * 100,
//     attempts: data.nbThreePuttsTotal[4]
//   };
//   stats.threePuttsDistances.dist_12 = {
//     rate: (data.nbThreePutts[5] / data.nbThreePuttsTotal[5]) * 100,
//     attempts: data.nbThreePuttsTotal[5]
//   };

//   // TODO STREAKS

//   // DISPERSION METERS

//   stats.dispersionTargetFlagMeters.flag_0_30 =
//     arraySum(data.flagDispersionMeters[0]) /
//     data.flagDispersionMeters[0].length;
//   stats.dispersionTargetFlagMeters.target_0_30 =
//     arraySum(data.targetDispersionMeters[0]) /
//     data.targetDispersionMeters[0].length;
//   stats.dispersionTargetFlagMeters.count_0_30 =
//     data.targetDispersionMeters[0].length;

//   stats.dispersionTargetFlagMeters.flag_30_60 =
//     arraySum(data.flagDispersionMeters[1]) /
//     data.flagDispersionMeters[1].length;
//   stats.dispersionTargetFlagMeters.target_30_60 =
//     arraySum(data.targetDispersionMeters[1]) /
//     data.targetDispersionMeters[1].length;
//   stats.dispersionTargetFlagMeters.count_30_60 =
//     data.targetDispersionMeters[1].length;

//   stats.dispersionTargetFlagMeters.flag_60_70 =
//     arraySum(data.flagDispersionMeters[2]) /
//     data.flagDispersionMeters[2].length;
//   stats.dispersionTargetFlagMeters.target_60_70 =
//     arraySum(data.targetDispersionMeters[2]) /
//     data.targetDispersionMeters[2].length;
//   stats.dispersionTargetFlagMeters.count_60_70 =
//     data.targetDispersionMeters[2].length;

//   stats.dispersionTargetFlagMeters.flag_70_80 =
//     arraySum(data.flagDispersionMeters[3]) /
//     data.flagDispersionMeters[3].length;
//   stats.dispersionTargetFlagMeters.target_70_80 =
//     arraySum(data.targetDispersionMeters[3]) /
//     data.targetDispersionMeters[3].length;
//   stats.dispersionTargetFlagMeters.count_70_80 =
//     data.targetDispersionMeters[3].length;

//   stats.dispersionTargetFlagMeters.flag_80_90 =
//     arraySum(data.flagDispersionMeters[4]) /
//     data.flagDispersionMeters[4].length;
//   stats.dispersionTargetFlagMeters.target_80_90 =
//     arraySum(data.targetDispersionMeters[4]) /
//     data.targetDispersionMeters[4].length;
//   stats.dispersionTargetFlagMeters.count_80_90 =
//     data.targetDispersionMeters[4].length;

//   stats.dispersionTargetFlagMeters.flag_90_100 =
//     arraySum(data.flagDispersionMeters[5]) /
//     data.flagDispersionMeters[5].length;
//   stats.dispersionTargetFlagMeters.target_90_100 =
//     arraySum(data.targetDispersionMeters[5]) /
//     data.targetDispersionMeters[5].length;
//   stats.dispersionTargetFlagMeters.count_90_100 =
//     data.targetDispersionMeters[5].length;

//   stats.dispersionTargetFlagMeters.flag_100_110 =
//     arraySum(data.flagDispersionMeters[6]) /
//     data.flagDispersionMeters[6].length;
//   stats.dispersionTargetFlagMeters.target_100_110 =
//     arraySum(data.targetDispersionMeters[6]) /
//     data.targetDispersionMeters[6].length;
//   stats.dispersionTargetFlagMeters.count_100_110 =
//     data.targetDispersionMeters[6].length;

//   stats.dispersionTargetFlagMeters.flag_110_120 =
//     arraySum(data.flagDispersionMeters[7]) /
//     data.flagDispersionMeters[7].length;
//   stats.dispersionTargetFlagMeters.target_110_120 =
//     arraySum(data.targetDispersionMeters[7]) /
//     data.targetDispersionMeters[7].length;
//   stats.dispersionTargetFlagMeters.count_110_120 =
//     data.targetDispersionMeters[7].length;

//   stats.dispersionTargetFlagMeters.flag_120_130 =
//     arraySum(data.flagDispersionMeters[8]) /
//     data.flagDispersionMeters[8].length;
//   stats.dispersionTargetFlagMeters.target_120_130 =
//     arraySum(data.targetDispersionMeters[8]) /
//     data.targetDispersionMeters[8].length;
//   stats.dispersionTargetFlagMeters.count_120_130 =
//     data.targetDispersionMeters[8].length;

//   stats.dispersionTargetFlagMeters.flag_130_140 =
//     arraySum(data.flagDispersionMeters[9]) /
//     data.flagDispersionMeters[9].length;
//   stats.dispersionTargetFlagMeters.target_130_140 =
//     arraySum(data.targetDispersionMeters[9]) /
//     data.targetDispersionMeters[9].length;
//   stats.dispersionTargetFlagMeters.count_130_140 =
//     data.targetDispersionMeters[9].length;

//   stats.dispersionTargetFlagMeters.flag_140_150 =
//     arraySum(data.flagDispersionMeters[10]) /
//     data.flagDispersionMeters[10].length;
//   stats.dispersionTargetFlagMeters.target_140_150 =
//     arraySum(data.targetDispersionMeters[10]) /
//     data.targetDispersionMeters[10].length;
//   stats.dispersionTargetFlagMeters.count_140_150 =
//     data.targetDispersionMeters[10].length;

//   stats.dispersionTargetFlagMeters.flag_150_160 =
//     arraySum(data.flagDispersionMeters[11]) /
//     data.flagDispersionMeters[11].length;
//   stats.dispersionTargetFlagMeters.target_150_160 =
//     arraySum(data.targetDispersionMeters[11]) /
//     data.targetDispersionMeters[11].length;
//   stats.dispersionTargetFlagMeters.count_150_160 =
//     data.targetDispersionMeters[11].length;

//   stats.dispersionTargetFlagMeters.flag_160_170 =
//     arraySum(data.flagDispersionMeters[12]) /
//     data.flagDispersionMeters[12].length;
//   stats.dispersionTargetFlagMeters.target_160_170 =
//     arraySum(data.targetDispersionMeters[12]) /
//     data.targetDispersionMeters[12].length;
//   stats.dispersionTargetFlagMeters.count_160_170 =
//     data.targetDispersionMeters[12].length;

//   stats.dispersionTargetFlagMeters.flag_170_180 =
//     arraySum(data.flagDispersionMeters[13]) /
//     data.flagDispersionMeters[13].length;
//   stats.dispersionTargetFlagMeters.target_170_180 =
//     arraySum(data.targetDispersionMeters[13]) /
//     data.targetDispersionMeters[13].length;
//   stats.dispersionTargetFlagMeters.count_170_180 =
//     data.targetDispersionMeters[13].length;

//   stats.dispersionTargetFlagMeters.flag_180_190 =
//     arraySum(data.flagDispersionMeters[14]) /
//     data.flagDispersionMeters[14].length;
//   stats.dispersionTargetFlagMeters.target_180_190 =
//     arraySum(data.targetDispersionMeters[14]) /
//     data.targetDispersionMeters[14].length;
//   stats.dispersionTargetFlagMeters.count_180_190 =
//     data.targetDispersionMeters[14].length;

//   stats.dispersionTargetFlagMeters.flag_190_200 =
//     arraySum(data.flagDispersionMeters[15]) /
//     data.flagDispersionMeters[15].length;
//   stats.dispersionTargetFlagMeters.target_190_200 =
//     arraySum(data.targetDispersionMeters[15]) /
//     data.targetDispersionMeters[15].length;
//   stats.dispersionTargetFlagMeters.count_190_200 =
//     data.targetDispersionMeters[15].length;

//   stats.dispersionTargetFlagMeters.flag_200_220 =
//     arraySum(data.flagDispersionMeters[16]) /
//     data.flagDispersionMeters[16].length;
//   stats.dispersionTargetFlagMeters.target_200_220 =
//     arraySum(data.targetDispersionMeters[16]) /
//     data.targetDispersionMeters[16].length;
//   stats.dispersionTargetFlagMeters.count_200_220 =
//     data.targetDispersionMeters[16].length;

//   stats.dispersionTargetFlagMeters.flag_220_250 =
//     arraySum(data.flagDispersionMeters[17]) /
//     data.flagDispersionMeters[17].length;
//   stats.dispersionTargetFlagMeters.target_220_250 =
//     arraySum(data.targetDispersionMeters[17]) /
//     data.targetDispersionMeters[17].length;
//   stats.dispersionTargetFlagMeters.count_220_250 =
//     data.targetDispersionMeters[17].length;

//   stats.dispersionTargetFlagMeters.flag_250 =
//     arraySum(data.flagDispersionMeters[18]) /
//     data.flagDispersionMeters[18].length;
//   stats.dispersionTargetFlagMeters.target_250 =
//     arraySum(data.targetDispersionMeters[18]) /
//     data.targetDispersionMeters[18].length;
//   stats.dispersionTargetFlagMeters.count_250 =
//     data.targetDispersionMeters[18].length;

//   // DISPERSION %

//   stats.dispersionTargetFlagPercentage.flag_0_30 =
//     arraySum(data.flagDispersionPercentage[0]) /
//     data.flagDispersionPercentage[0].length;
//   stats.dispersionTargetFlagPercentage.target_0_30 =
//     arraySum(data.targetDispersionPercentage[0]) /
//     data.targetDispersionPercentage[0].length;
//   stats.dispersionTargetFlagPercentage.count_0_30 =
//     data.targetDispersionPercentage[0].length;

//   stats.dispersionTargetFlagPercentage.flag_30_60 =
//     arraySum(data.flagDispersionPercentage[1]) /
//     data.flagDispersionPercentage[1].length;
//   stats.dispersionTargetFlagPercentage.target_30_60 =
//     arraySum(data.targetDispersionPercentage[1]) /
//     data.targetDispersionPercentage[1].length;
//   stats.dispersionTargetFlagPercentage.count_30_60 =
//     data.targetDispersionPercentage[1].length;

//   stats.dispersionTargetFlagPercentage.flag_60_70 =
//     arraySum(data.flagDispersionPercentage[2]) /
//     data.flagDispersionPercentage[2].length;
//   stats.dispersionTargetFlagPercentage.target_60_70 =
//     arraySum(data.targetDispersionPercentage[2]) /
//     data.targetDispersionPercentage[2].length;
//   stats.dispersionTargetFlagPercentage.count_60_70 =
//     data.targetDispersionPercentage[2].length;

//   stats.dispersionTargetFlagPercentage.flag_70_80 =
//     arraySum(data.flagDispersionPercentage[3]) /
//     data.flagDispersionPercentage[3].length;
//   stats.dispersionTargetFlagPercentage.target_70_80 =
//     arraySum(data.targetDispersionPercentage[3]) /
//     data.targetDispersionPercentage[3].length;
//   stats.dispersionTargetFlagPercentage.count_70_80 =
//     data.targetDispersionPercentage[3].length;

//   stats.dispersionTargetFlagPercentage.flag_80_90 =
//     arraySum(data.flagDispersionPercentage[4]) /
//     data.flagDispersionPercentage[4].length;
//   stats.dispersionTargetFlagPercentage.target_80_90 =
//     arraySum(data.targetDispersionPercentage[4]) /
//     data.targetDispersionPercentage[4].length;
//   stats.dispersionTargetFlagPercentage.count_80_90 =
//     data.targetDispersionPercentage[4].length;

//   stats.dispersionTargetFlagPercentage.flag_90_100 =
//     arraySum(data.flagDispersionPercentage[5]) /
//     data.flagDispersionPercentage[5].length;
//   stats.dispersionTargetFlagPercentage.target_90_100 =
//     arraySum(data.targetDispersionPercentage[5]) /
//     data.targetDispersionPercentage[5].length;
//   stats.dispersionTargetFlagPercentage.count_90_100 =
//     data.targetDispersionPercentage[5].length;

//   stats.dispersionTargetFlagPercentage.flag_100_110 =
//     arraySum(data.flagDispersionPercentage[6]) /
//     data.flagDispersionPercentage[6].length;
//   stats.dispersionTargetFlagPercentage.target_100_110 =
//     arraySum(data.targetDispersionPercentage[6]) /
//     data.targetDispersionPercentage[6].length;
//   stats.dispersionTargetFlagPercentage.count_100_110 =
//     data.targetDispersionPercentage[6].length;

//   stats.dispersionTargetFlagPercentage.flag_110_120 =
//     arraySum(data.flagDispersionPercentage[7]) /
//     data.flagDispersionPercentage[7].length;
//   stats.dispersionTargetFlagPercentage.target_110_120 =
//     arraySum(data.targetDispersionPercentage[7]) /
//     data.targetDispersionPercentage[7].length;
//   stats.dispersionTargetFlagPercentage.count_110_120 =
//     data.targetDispersionPercentage[7].length;

//   stats.dispersionTargetFlagPercentage.flag_120_130 =
//     arraySum(data.flagDispersionPercentage[8]) /
//     data.flagDispersionPercentage[8].length;
//   stats.dispersionTargetFlagPercentage.target_120_130 =
//     arraySum(data.targetDispersionPercentage[8]) /
//     data.targetDispersionPercentage[8].length;
//   stats.dispersionTargetFlagPercentage.count_120_130 =
//     data.targetDispersionPercentage[8].length;

//   stats.dispersionTargetFlagPercentage.flag_130_140 =
//     arraySum(data.flagDispersionPercentage[9]) /
//     data.flagDispersionPercentage[9].length;
//   stats.dispersionTargetFlagPercentage.target_130_140 =
//     arraySum(data.targetDispersionPercentage[9]) /
//     data.targetDispersionPercentage[9].length;
//   stats.dispersionTargetFlagPercentage.count_130_140 =
//     data.targetDispersionPercentage[9].length;

//   stats.dispersionTargetFlagPercentage.flag_140_150 =
//     arraySum(data.flagDispersionPercentage[10]) /
//     data.flagDispersionPercentage[10].length;
//   stats.dispersionTargetFlagPercentage.target_140_150 =
//     arraySum(data.targetDispersionPercentage[10]) /
//     data.targetDispersionPercentage[10].length;
//   stats.dispersionTargetFlagPercentage.count_140_150 =
//     data.targetDispersionPercentage[10].length;

//   stats.dispersionTargetFlagPercentage.flag_150_160 =
//     arraySum(data.flagDispersionPercentage[11]) /
//     data.flagDispersionPercentage[11].length;
//   stats.dispersionTargetFlagPercentage.target_150_160 =
//     arraySum(data.targetDispersionPercentage[11]) /
//     data.targetDispersionPercentage[11].length;
//   stats.dispersionTargetFlagPercentage.count_150_160 =
//     data.targetDispersionPercentage[11].length;

//   stats.dispersionTargetFlagPercentage.flag_160_170 =
//     arraySum(data.flagDispersionPercentage[12]) /
//     data.flagDispersionPercentage[12].length;
//   stats.dispersionTargetFlagPercentage.target_160_170 =
//     arraySum(data.targetDispersionPercentage[12]) /
//     data.targetDispersionPercentage[12].length;
//   stats.dispersionTargetFlagPercentage.count_160_170 =
//     data.targetDispersionPercentage[12].length;

//   stats.dispersionTargetFlagPercentage.flag_170_180 =
//     arraySum(data.flagDispersionPercentage[13]) /
//     data.flagDispersionPercentage[13].length;
//   stats.dispersionTargetFlagPercentage.target_170_180 =
//     arraySum(data.targetDispersionPercentage[13]) /
//     data.targetDispersionPercentage[13].length;
//   stats.dispersionTargetFlagPercentage.count_170_180 =
//     data.targetDispersionPercentage[13].length;

//   stats.dispersionTargetFlagPercentage.flag_180_190 =
//     arraySum(data.flagDispersionPercentage[14]) /
//     data.flagDispersionPercentage[14].length;
//   stats.dispersionTargetFlagPercentage.target_180_190 =
//     arraySum(data.targetDispersionPercentage[14]) /
//     data.targetDispersionPercentage[14].length;
//   stats.dispersionTargetFlagPercentage.count_180_190 =
//     data.targetDispersionPercentage[14].length;

//   stats.dispersionTargetFlagPercentage.flag_190_200 =
//     arraySum(data.flagDispersionPercentage[15]) /
//     data.flagDispersionPercentage[15].length;
//   stats.dispersionTargetFlagPercentage.target_190_200 =
//     arraySum(data.targetDispersionPercentage[15]) /
//     data.targetDispersionPercentage[15].length;
//   stats.dispersionTargetFlagPercentage.count_190_200 =
//     data.targetDispersionPercentage[15].length;

//   stats.dispersionTargetFlagPercentage.flag_200_220 =
//     arraySum(data.flagDispersionPercentage[16]) /
//     data.flagDispersionPercentage[16].length;
//   stats.dispersionTargetFlagPercentage.target_200_220 =
//     arraySum(data.targetDispersionPercentage[16]) /
//     data.targetDispersionPercentage[16].length;
//   stats.dispersionTargetFlagPercentage.count_200_220 =
//     data.targetDispersionPercentage[16].length;

//   stats.dispersionTargetFlagPercentage.flag_220_250 =
//     arraySum(data.flagDispersionPercentage[17]) /
//     data.flagDispersionPercentage[17].length;
//   stats.dispersionTargetFlagPercentage.target_220_250 =
//     arraySum(data.targetDispersionPercentage[17]) /
//     data.targetDispersionPercentage[17].length;
//   stats.dispersionTargetFlagPercentage.count_220_250 =
//     data.targetDispersionPercentage[17].length;

//   stats.dispersionTargetFlagPercentage.flag_250 =
//     arraySum(data.flagDispersionPercentage[18]) /
//     data.flagDispersionPercentage[18].length;
//   stats.dispersionTargetFlagPercentage.target_250 =
//     arraySum(data.targetDispersionPercentage[18]) /
//     data.targetDispersionPercentage[18].length;
//   stats.dispersionTargetFlagPercentage.count_250 =
//     data.targetDispersionPercentage[18].length;

//   // STROKES GAINED CATEGORY
//   stats.strokesGainedCategory = strokesGained.categories;
//   stats.strokesGainedDistances = strokesGained.distances;

//   stats.strokesGained.strokesGainedTotal = stats.strokesGainedCategory.total[0].toFixed(
//     1
//   );
//   stats.strokesGained.strokesGainedTotalCount = stats.strokesGainedCategory.total[1].toFixed(
//     1
//   );
//   stats.strokesGained.strokesGainedApproach = stats.strokesGainedCategory.approach[0].toFixed(
//     1
//   );
//   stats.strokesGained.strokesGainedApproachCount = stats.strokesGainedCategory.approach[1].toFixed(
//     1
//   );
//   stats.strokesGained.strokesGainedOffTee = stats.strokesGainedCategory.offTee[0].toFixed(
//     1
//   );
//   stats.strokesGained.strokesGainedOffTeeCount = stats.strokesGainedCategory.offTee[1].toFixed(
//     1
//   );
//   stats.strokesGained.strokesGainedLongShots = stats.strokesGainedCategory.longShots[0].toFixed(
//     1
//   );
//   stats.strokesGained.strokesGainedLongShotsCount = stats.strokesGainedCategory.longShots[1].toFixed(
//     1
//   );
//   stats.strokesGained.strokesGainedAround = stats.strokesGainedCategory.around[0].toFixed(
//     1
//   );
//   stats.strokesGained.strokesGainedAroundCount = stats.strokesGainedCategory.around[1].toFixed(
//     1
//   );
//   stats.strokesGained.strokesGainedOnGreen = stats.strokesGainedCategory.onGreen[0].toFixed(
//     1
//   );
//   stats.strokesGained.strokesGainedOnGreenCount = stats.strokesGainedCategory.onGreen[1].toFixed(
//     1
//   );

//   stats.strokesGained.sg_d_1 = stats.strokesGainedDistances[0].toFixed(1);
//   stats.strokesGained.sg_d_1_2 = stats.strokesGainedDistances[1].toFixed(1);
//   stats.strokesGained.sg_d_2_3 = stats.strokesGainedDistances[2].toFixed(1);
//   stats.strokesGained.sg_d_3_4 = stats.strokesGainedDistances[3].toFixed(1);
//   stats.strokesGained.sg_d_4_5 = stats.strokesGainedDistances[4].toFixed(1);
//   stats.strokesGained.sg_d_5_6 = stats.strokesGainedDistances[5].toFixed(1);
//   stats.strokesGained.sg_d_6_9 = stats.strokesGainedDistances[6].toFixed(1);
//   stats.strokesGained.sg_d_9_12 = stats.strokesGainedDistances[7].toFixed(1);
//   stats.strokesGained.sg_d_12 = stats.strokesGainedDistances[8].toFixed(1);
//   stats.strokesGained.sg_d_30 = stats.strokesGainedDistances[9].toFixed(1);
//   stats.strokesGained.sg_d_30_60 = stats.strokesGainedDistances[10].toFixed(1);
//   stats.strokesGained.sg_d_60_70 = stats.strokesGainedDistances[11].toFixed(1);
//   stats.strokesGained.sg_d_70_80 = stats.strokesGainedDistances[12].toFixed(1);
//   stats.strokesGained.sg_d_80_90 = stats.strokesGainedDistances[13].toFixed(1);
//   stats.strokesGained.sg_d_90_100 = stats.strokesGainedDistances[14].toFixed(1);
//   stats.strokesGained.sg_d_100_110 = stats.strokesGainedDistances[15].toFixed(
//     1
//   );
//   stats.strokesGained.sg_d_110_120 = stats.strokesGainedDistances[16].toFixed(
//     1
//   );
//   stats.strokesGained.sg_d_120_130 = stats.strokesGainedDistances[17].toFixed(
//     1
//   );
//   stats.strokesGained.sg_d_130_140 = stats.strokesGainedDistances[18].toFixed(
//     1
//   );
//   stats.strokesGained.sg_d_140_150 = stats.strokesGainedDistances[19].toFixed(
//     1
//   );
//   stats.strokesGained.sg_d_150_160 = stats.strokesGainedDistances[20].toFixed(
//     1
//   );
//   stats.strokesGained.sg_d_160_170 = stats.strokesGainedDistances[21].toFixed(
//     1
//   );
//   stats.strokesGained.sg_d_170_180 = stats.strokesGainedDistances[22].toFixed(
//     1
//   );
//   stats.strokesGained.sg_d_180_190 = stats.strokesGainedDistances[23].toFixed(
//     1
//   );
//   stats.strokesGained.sg_d_190_200 = stats.strokesGainedDistances[24].toFixed(
//     1
//   );
//   stats.strokesGained.sg_d_200_220 = stats.strokesGainedDistances[25].toFixed(
//     1
//   );
//   stats.strokesGained.sg_d_220_250 = stats.strokesGainedDistances[26].toFixed(
//     1
//   );
//   stats.strokesGained.sg_d_250 = stats.strokesGainedDistances[27].toFixed(1);

//   // ignore putting quality (not recorded)
//   data.quality[4] = 0;
//   data.qualityCount[4] = 0;

//   stats.impact.total = (
//     (arraySum(data.quality) / arraySum(data.qualityCount)) *
//     100
//   ).toFixed(1);
//   stats.impact.offTee = (
//     (data.quality[0] / data.qualityCount[0]) *
//     100
//   ).toFixed(1);
//   stats.impact.longShots = (
//     (data.quality[1] / data.qualityCount[1]) *
//     100
//   ).toFixed(1);
//   stats.impact.approach = (
//     (data.quality[2] / data.qualityCount[2]) *
//     100
//   ).toFixed(1);
//   stats.impact.around = (
//     (data.quality[3] / data.qualityCount[3]) *
//     100
//   ).toFixed(1);
//   stats.impact.onGreen = "-"; // data.quality[4]/data.qualityCount[4];

//   // WASTED SHOTS

//   stats.wasted.total = stats.scoring.wastedShots;
//   stats.wasted.offTee = stats.offTee.wastedShots;
//   stats.wasted.longShots = stats.longShots.wastedShots;
//   stats.wasted.approach = stats.approach.wastedShots;
//   stats.wasted.around = stats.around.wastedShots;
//   stats.wasted.onGreen = stats.onGreen.wastedShots;

//   return stats;
// }

/*
calculateStatsRound

Parameters:
rounds: Round,
shotsByRound: [[Shot]]

Return:
Json containing all data to calculate stats
*/
function calculateStatsRound(round, shots) {
  const nbHolesPlayed = getHolesPlayed(round.played).played // number of holes played in this round

  const scoresByThirds = [[], [], []] // array of three arrays: containing scores of holes 1-6 7-12 13-18
  const scoresByHalves = [[], []] // array of three arrays: containing scores of holes 1-9 10-18

  let totalStrokes = 0 // sum of scores of all holes
  let totalPar = 0 // sum of pars of all holes

  let totalPutts = 0 // sum of putts of all holes

  const scoreStats = [0, 0, 0, 0, 0, 0] // total number of each of following (index): eagles-: 0, birdies: 1, par: 2, bogey: 3, 2xbogey: 4, 3xbogey+: 5
  const puttsStats = [0, 0, 0, 0, 0] // total number of each of following nb of putts: 0, 1, 2, 3, 4+

  const girScrFwStats = getGirScrFwStatsRound(round) // get if is in gir, if is scr, if is on fw/left/right

  const scoreOfPars = [0, 0, 0] // sum of all scores of par 3s, par 4s, par 5s
  const girsOfPars = [0, 0, 0] // nb of gir on par 3s, par 4s, par5s
  const nbHolesOfPars = [0, 0, 0] // number of par 3, par 4, par 5 of course

  const puttsGir = [] // array of all nb of putts on each hole in gir
  const puttsNonGir = [] // array of all nb of putts on each hole not in gir

  const birdieConversion = { made: 0, attempts: 0 } // number of birdies made when in regulation (one putt to make birdie), number of attempts
  const parConversion = { made: 0, attempts: 0 } // number of pars made when not in regulation (one putt to save par), number of attempts

  const driveDistance = [] // array containing all drive distances
  const distancesFirstAttack = [[], [], []] // array of three array: containing each distance first green attack on par3s, par4s, par5s
  const distancesGIRShot = [[], [], []] // array of three array: containing each distance of gir shot on par3s, par4s, par5s

  let penalties = 0 // nb of penalty shots
  let recoveries = 0 // nb of recovery shots
  const wastedShotsPerType = [0, 0, 0, 0, 0] // wasted shots on: tee / long shots / approach / around green / putting

  const firstPuttDistances = [] // array containing all first putt distances
  const secondPuttDistances = [] // array containing all second putt distances

  let shortPuttsMissed = 0 // number of short putts missed

  // dispersion for 30,60,70,80,90,100,110,120,130,140,150,160,170,180,190,200,220,250,250+
  const flagDispersionMeters = [
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
  ]
  const flagDispersionPercentage = [
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
  ]
  const targetDispersionMeters = [
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
  ]
  const targetDispersionPercentage = [
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
  ]

  const longShotsFWStats = [0, 0, 0]
  let longShotsNextCanAttack = 0.0

  const approachAttacksHitsGreen = [0, 0, 0, 0, 0]
  const approachAttacks = [0, 0, 0, 0, 0]

  const upDownsBySurface = [0, 0, 0, 0]
  const upDownsAttemptsBySurface = [0, 0, 0, 0]

  const approachStats = [0, 0, 0, 0, 0]
  const approachStatsByDistance = [
    [0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0],
  ]

  const aroundStats = [0, 0, 0, 0, 0]

  const puttingStatsShort = [0, 0, 0, 0, 0]
  const puttingStatsLong = [0, 0, 0, 0, 0]

  const missedPutts = []
  const birdiePuttsMade = []
  const puttsMade = []
  const longPuttResult = []

  const distancePuttFromNonGRS = []
  const distancePuttFromGRS = []

  const quality = [0, 0, 0, 0, 0]
  const qualityCount = [0, 0, 0, 0, 0]

  const routine = [0, 0, 0, 0, 0]
  const routineCount = [0, 0, 0, 0, 0]

  const longShotsByPars = [0, 0, 0]

  const nbOnePutts = [0, 0, 0, 0, 0, 0, 0, 0, 0]
  const nbOnePuttsTotal = [0, 0, 0, 0, 0, 0, 0, 0, 0]

  const nbOnePuttsForBirdie = [0, 0, 0, 0, 0, 0, 0, 0, 0]
  const nbOnePuttsForBirdieTotal = [0, 0, 0, 0, 0, 0, 0, 0, 0]
  const nbOnePuttsForPar = [0, 0, 0, 0, 0, 0, 0, 0, 0]
  const nbOnePuttsForParTotal = [0, 0, 0, 0, 0, 0, 0, 0, 0]

  const nbThreePutts = [0, 0, 0, 0, 0, 0]
  const nbThreePuttsTotal = [0, 0, 0, 0, 0, 0]

  let maxStreakParsAndBelow = 0
  let currentStreakParsAndBelow = 0

  let maxStreakBelowPar = 0
  let currentStreakBelowPar = 0

  let maxStreakWithoutDoubleBogeys = 0
  let currentStreakWithoutDoubleBogeys = 0

  let maxStreak1Putt = 0
  let currentStreak1Putt = 0

  let maxStreakWithout3Putts = 0
  let currentStreakWithout3Putts = 0

  let maxStreakFairways = 0
  let currentStreakFairways = 0

  let maxStreakGIR = 0
  let currentStreakGIR = 0

  let maxStreakWithoutPenalty = 0
  let currentStreakWithoutPenalty = 0

  const scoreFWHit = [[], []]

  const greensideDispersion = []
  const greensideDistance = []

  const wellHitShotByType = [0, 0, 0]
  const wellHitMissFWShotByType = [0, 0, 0]

  const missHitShotByType = [0, 0, 0]
  const missHitHitFwShotByType = [0, 0, 0]

  const qualityAfterMadePutt = [0, 0]
  const qualityAfterMissPutt = [0, 0]
  const qualityAfterNormalPutt = [0, 0]

  const routineAfterMadePutt = [0, 0]
  const routineAfterMissPutt = [0, 0]
  const routineAfterNormalPutt = [0, 0]

  const scoreShortPar4 = []
  const scoreLongPar4 = []

  // for each slope, for each distance
  const puttStatsFullQuantity = [] // average quality
  const puttStatsFullReads = [] // average read
  const puttStatsFullHoled = [] // averageholed

  // initialize the 11 slopes with the 10 distances
  for (let i = 0; i < 11; i += 1) {
    puttStatsFullQuantity.push([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
    puttStatsFullReads.push([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
    puttStatsFullHoled.push([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
  }

  let madePutt = false
  let missPutt = false

  let holesPlayedShotByShot = 0

  // loop through the holes of the round
  for (let hole = 0; hole < 18; hole += 1) {
    const score = Number(round.score[hole])
    const par = Number(round.pars[hole])
    const putts = Number(round.putts[hole])
    const fairway = round.fairways[hole]
    const played = round.played[hole]

    // if hole is played
    if (played !== '0') {
      if (round.shotByShot) holesPlayedShotByShot += 1 // number of holes played with all shot data

      const netScore = score - par

      // only calculate thirds/halves, if all 18 holes are played
      if (nbHolesPlayed === 18) {
        // add the net score (real score - par) to the correct third / half
        const indexThirds = Math.floor(hole / 6)
        scoresByThirds[indexThirds].push(netScore)
        const indexHalves = Math.floor(hole / 9)
        scoresByHalves[indexHalves].push(netScore)
      }

      totalStrokes += score // add hole score to total score
      totalPar += par // add hole par to total par

      totalPutts += putts // add hole putts par to total putts

      // check score streaks, either reset if fail, or increment if succeed
      if (netScore < 0) {
        // if better than par
        currentStreakBelowPar += 1
        currentStreakParsAndBelow += 1
        currentStreakWithoutDoubleBogeys += 1
      } else if (netScore === 0) {
        // if par
        currentStreakBelowPar += 0
        currentStreakParsAndBelow += 1
        currentStreakWithoutDoubleBogeys += 1
      } else if (netScore < 0) {
        // if better than double bogey
        currentStreakBelowPar = 0
        currentStreakParsAndBelow = 0
        currentStreakWithoutDoubleBogeys += 1
      } else {
        currentStreakBelowPar = 0
        currentStreakParsAndBelow = 0
        currentStreakWithoutDoubleBogeys = 0
      }

      // check putting streaks
      if (played === 'X') {
        // if picked up, reset 1putt streak
        currentStreak1Putt = 0
      } else if (putts === 0) {
        currentStreakWithout3Putts += 1
      } else if (putts === 1) {
        currentStreak1Putt += 1
        currentStreakWithout3Putts += 1
      } else if (putts < 3) {
        currentStreak1Putt = 0
        currentStreakWithout3Putts += 1
      } else {
        currentStreak1Putt = 0
        currentStreakWithout3Putts = 0
      }

      const isInRegulation = IsInRegulation(score, par, putts, played) // check if in regulation

      // check gir streaks
      if (isInRegulation) currentStreakGIR += 1
      else currentStreakGIR = 0

      if (par > 3) {
        if (fairway === '1') {
          currentStreakFairways += 1
          scoreFWHit[0].push(netScore) // add net Score to list of fw hit holes
        } else {
          currentStreakFairways = 0
          scoreFWHit[1].push(netScore) // add net Score to list of fw missed holes
        }
      }

      if (par === 4) {
        if (round.length[hole] >= Parameters.lengthLongPar4) scoreLongPar4.push(score)
        else scoreShortPar4.push(score)
      }

      // check if current streaks is better than best steak
      maxStreakBelowPar = Math.max(maxStreakBelowPar, currentStreakBelowPar)
      maxStreakParsAndBelow = Math.max(
        maxStreakParsAndBelow,
        currentStreakParsAndBelow,
      )
      maxStreakWithoutDoubleBogeys = Math.max(
        maxStreakWithoutDoubleBogeys,
        currentStreakWithoutDoubleBogeys,
      )

      maxStreak1Putt = Math.max(maxStreak1Putt, currentStreak1Putt)
      maxStreakWithout3Putts = Math.max(
        maxStreakWithout3Putts,
        currentStreakWithout3Putts,
      )

      maxStreakGIR = Math.max(maxStreakGIR, currentStreakGIR)

      maxStreakFairways = Math.max(maxStreakFairways, currentStreakFairways)

      const scoreIndex = getScoreIndexForHole(score, par, played) // get index of the type of score (eagle/birdie/par...)
      scoreStats[scoreIndex] += 1 // add the type of score to total

      nbHolesOfPars[par - 3] += 1 // add which type of par it is (par3/4/5)
      scoreOfPars[par - 3] += score // add the score to total for the type

      // if in regulation
      if (isInRegulation) {
        girsOfPars[par - 3] += 1 // add one gir to the type of hole (par3/4/5)
        puttsGir.push(putts) // add nb of putts to the array for gir
      } else {
        puttsNonGir.push(putts) // add nb of putts to the array for not gir
      }

      const puttIndex = Math.min(putts, 4) // get nb of putts (0,1,2,3,4+)
      puttsStats[puttIndex] += 1 // add to round stats

      if (isInRegulation) {
        birdieConversion.attempts += 1
        if (score < par) birdieConversion.made += 1
      } else if (IsInRegulation(score, par + 1, putts, played)) {
        parConversion.attempts += 1
        if (score === par) parConversion.made += 1
      }
    }

    const shotsForHole = shots[hole + 1] || [] // get all shots of this hole

    const nbShotsForHole = shotsForHole.length // shots[hole].length;

    let isFirstAttack = true
    let isFirstPutt = true
    let isOnGreen = false

    let lastShotType = 0 // 0: tee  -  1: long  -  2: approach -  3: around -  4: putting

    let hasPenaltyRecoveryPickup = false

    let puttNumberOnHole = 0

    let isPlayed = false

    // loop through the shots of the hole
    for (let shotNumber = 0; shotNumber < nbShotsForHole; shotNumber += 1) {
      const shot = shotsForHole[shotNumber]

      isPlayed = true

      if (shot.shotDistanceSurface === 1 && shot.club === 1) driveDistance.push(shot.shotDistance) // if off the tee with driver, add shot to drive distance

      // if is tee shot not on par 3
      if (shotNumber === 0 && par > 3) {
        if (missPutt) {
          // if i miss-putted on previous hole (short miss or 3-putt)
          qualityAfterMissPutt[1] += 1 // increment nb attempts
          if (fairway === '1') {
            // if hit fairway, increment made attempts
            qualityAfterMissPutt[0] += 1
          }
        } else if (madePutt) {
          // if i well-putted on previous hole (1-putt)
          qualityAfterMadePutt[1] += 1 // increment nb attempts
          if (fairway === '1') {
            // if hit fairway, increment made attempts
            qualityAfterMadePutt[0] += 1
          }
        }
      }

      if (shotNumber === 0 && par > 3) {
        if (missPutt) {
          // if i miss-putted on previous hole (short miss or 3-putt)
          routineAfterMissPutt[1] += 1 // increment nb attempts
          if (fairway === '1') {
            // if hit fairway, increment made attempts
            routineAfterMissPutt[0] += 1
          }
        } else if (madePutt) {
          // if i well-putted on previous hole (1-putt)
          routineAfterMadePutt[1] += 1 // increment nb attempts
          if (fairway === '1') {
            // if hit fairway, increment made attempts
            routineAfterMadePutt[0] += 1
          }
        }
      }

      madePutt = false
      missPutt = false

      if (!isOnGreen && shot.surface === Parameters.greenCode) isOnGreen = true // if wasn't yet on green and on it, set as on green
      if (isOnGreen) puttNumberOnHole += 1 // get which putt this is for the hole

      // if is the first attack of green on this hole, record the shot distance to pin
      if (isFirstAttack && shot.attackGreen === 1) {
        if (par - 3 < distancesFirstAttack.length) {
          isFirstAttack = false
          distancesFirstAttack[par - 3].push(shot.distanceToHole)
        }
      }

      const wastedStatsForShot = calculateWastedShots(
        shotsForHole,
        shotNumber,
        puttNumberOnHole,
      )
      const wasted = arraySum(wastedStatsForShot)

      penalties += wastedStatsForShot[0]
      recoveries += wastedStatsForShot[1]

      // penalty / recovery / pickup
      if (shot.club > 90) {
        hasPenaltyRecoveryPickup = true

        // off the tee (not on par 3)
      } else if (par > 3 && shot.surface === 1) {
        lastShotType = 0

        // store quality off tee shots
        quality[lastShotType] += shot.quality
        qualityCount[lastShotType] += 1

        // store routine off tee shots
        routine[lastShotType] += shot.routine
        routineCount[lastShotType] += 1

        // if well hit
        if (shot.quality === 1) {
          wellHitShotByType[lastShotType] += 1 // store nb of well hit shots
          // did it miss the fairway/greenside fairway/green
          if (
            shotNumber < shotsForHole.length - 1
            && [
              Parameters.fairwayCode,
              Parameters.greensideFairwayCode,
              Parameters.greenCode,
            ].indexOf(shotsForHole[shotNumber + 1].surface) === -1
          ) wellHitMissFWShotByType[0] += 1
        } else {
          // if miss hit
          missHitShotByType[lastShotType] += 1
          // did i hole it or did it hit the fairway/greenside fairway/green
          if (
            shotNumber === shotsForHole.length
              || (shotsForHole[shotNumber + 1] && [
                Parameters.fairwayCode,
                Parameters.greensideFairwayCode,
                Parameters.greenCode,
              ].indexOf(shotsForHole[shotNumber + 1].surface) > -1)
          ) missHitHitFwShotByType[0] += 1
        }

        // putting
      } else if (shot.surface === Parameters.greenCode) {
        lastShotType = 4

        quality[lastShotType] += shot.well_read % 2
        qualityCount[lastShotType] += 1

        routine[lastShotType] += shot.routine
        routineCount[lastShotType] += 1

        let result = 0.0
        if (shotNumber < shotsForHole.length - 1) {
          // if not the last shot (putt is missed)
          result = shotsForHole[shotNumber + 1].distanceToHole // get result (by checking distance of next putt)

          // if current putt was within unmissable distance
          if (shot.distanceToHole <= Parameters.unmissable) {
            shortPuttsMissed += 1 // add to count of short missed putts
            missPutt = true // store to check next tee shot result after missing putt
          }
          missedPutts.push(shot.distanceToHole) // store the distance of the missed putt

          // if putt is holed
        } else {
          puttsMade.push(shot.distanceToHole) // store the distance of the made putt
          if (shotNumber < par - 1) birdiePuttsMade.push(shot.distanceToHole) // if it was for birdie, store birdie distance made
        }

        // If long putt
        if (shot.distanceToHole >= Parameters.shortLongPuttLimit) {
          longPuttResult.push(result) // store the result distance
          const zoneIndex = getShotZoneIndex(
            shot,
            Parameters.longPuttCenterZone,
          ) // get where it went (center, long left, long right, short left, short right)
          puttingStatsLong[zoneIndex] += 1

          // If short putt
        } else {
          const zoneIndex = getShotZoneIndex(
            shot,
            Parameters.shortPuttCenterZone,
          ) // get where it went (center, long left, long right, short left, short right)
          puttingStatsShort[zoneIndex] += 1
        }

        let onePutt = false
        let threePutt = false

        if (shotNumber === shotsForHole.length - 1) onePutt = true
        // if made one putt
        else if (shotNumber <= shotsForHole.length - 3) threePutt = true // if made 3 putts or more

        if (putts <= 1) madePutt = true
        // store to check next tee shot result after making putt
        else if (putts >= 3) missPutt = true // store to check next tee shot result after missing putt

        // get distance index
        let index = 1
        if (shot.distanceToHole <= 1) index = 0
        else if (shot.distanceToHole <= 2) index = 1
        else if (shot.distanceToHole <= 3) index = 2
        else if (shot.distanceToHole <= 4) index = 3
        else if (shot.distanceToHole <= 5) index = 4
        else if (shot.distanceToHole <= 6) index = 5
        else if (shot.distanceToHole <= 9) index = 6
        else if (shot.distanceToHole <= 12) index = 7
        else index = 8

        const slopeIndex = Math.round(shot.slope) + 5 // get slope index
        // store putt data (quantity, read, holed) depending on slope and distance
        puttStatsFullQuantity[slopeIndex][0] += 1
        puttStatsFullQuantity[slopeIndex][index + 1] += 1
        puttStatsFullReads[slopeIndex][0] += shot.well_read
        puttStatsFullReads[slopeIndex][index + 1] += shot.well_read
        if (shotNumber === shotsForHole.length - 1) {
          puttStatsFullHoled[slopeIndex][0] += 1
          puttStatsFullHoled[slopeIndex][index + 1] += 1
        }

        // get one putt & birdie/par make data per distance
        nbOnePuttsTotal[index] += 1
        if (onePutt) nbOnePutts[index] += 1

        if (shotNumber <= par - 2) {
          nbOnePuttsForBirdieTotal[index] += 1
          if (onePutt) nbOnePuttsForBirdie[index] += 1
        } else if (shotNumber === par - 1) {
          nbOnePuttsForParTotal[index] += 1
          if (onePutt) nbOnePuttsForPar[index] += 1
        }

        if (shot.distanceToHole <= 2) index = 0
        else if (shot.distanceToHole <= 4) index = 1
        else if (shot.distanceToHole <= 6) index = 2
        else if (shot.distanceToHole <= 9) index = 3
        else if (shot.distanceToHole <= 12) index = 4
        else index = 5

        // get three putt data per distance
        nbThreePuttsTotal[index] += 1
        if (threePutt) nbThreePutts[index] += 1

        // get distances of first putt according to previous shot surface (greenside or not)
        if (isFirstPutt) {
          isFirstPutt = false
          if (shotsForHole[shotNumber - 1] !== undefined) {
            if ([6, 7, 8, 9].indexOf(shotsForHole[shotNumber - 1].surface) > -1) distancePuttFromGRS.push(shot.distanceToHole)
            else distancePuttFromNonGRS.push(shot.distanceToHole)
          }

          firstPuttDistances.push(shot.distanceToHole)

          if (shotNumber < shotsForHole.length - 1) secondPuttDistances.push(result)
        }

        // Up & Down
      } else if ([6, 7, 8, 9].indexOf(shot.surface) > -1) {
        lastShotType = 3

        const srfNumber = shot.surface - 6

        upDownsAttemptsBySurface[srfNumber] += 1 // attempts
        // if one-putted then made it
        if (played === '1' && shotNumber >= shotsForHole.length - 2) upDownsBySurface[srfNumber] += 1

        quality[lastShotType] += shot.quality
        qualityCount[lastShotType] += 1

        routine[lastShotType] += shot.routine
        routineCount[lastShotType] += 1

        const zoneIndex = getShotZoneIndex(
          shot,
          Parameters.aroundGreenCenterZone,
        )
        aroundStats[zoneIndex] += 1

        greensideDistance.push(shot.distanceToHole)

        // get dispersion of greenside shot
        if (shotNumber < shotsForHole.length - 1) {
          const nextShot = shotsForHole[shotNumber + 1]
          let result = nextShot.distanceToHole
          let nextShotPickedUp = false

          if (nextShot.club === Parameters.pickupCode) {
            nextShotPickedUp = true
          } else if (nextShot.club === Parameters.penaltyCode) {
            let shotNb = shotNumber + 2
            while (shotsForHole[shotNb].club === Parameters.penaltyCode) {
              shotNb += 1
            }
            result = shotsForHole[shotNb].distanceToHole
          }

          if (!nextShotPickedUp) greensideDispersion.push(result)
        } else {
          greensideDispersion.push(0)
        }

        // Long + Approach + Par 3 Tee
      } else {
        // Approach Shots Attack Green Stats
        /* eslint-disable no-lonely-if */
        if (shot.attackGreen === 1) {
          lastShotType = 2

          // get surface index
          const srfNumber = Math.min(shot.surface - 1, 4)
          approachAttacks[srfNumber] += 1

          // get distance index
          let distType = 0
          if (shot.distanceToHole < 100) distType = 0
          else if (shot.distanceToHole < 150) distType = 1
          else distType = 2

          // TODO: put in function
          // if not last shot
          if (shotNumber < shotsForHole.length - 1) {
            const nextShot = shotsForHole[shotNumber + 1]

            if (nextShot.surface === Parameters.greenCode) {
              approachAttacksHitsGreen[srfNumber] += 1
              approachStats[0] += 1
              approachStatsByDistance[distType][0] += 1
            } else if (shot.x < 0 && shot.y < 0) {
              approachStats[1] += 1
              approachStatsByDistance[distType][1] += 1
            } else if (shot.x < 0 && shot.y >= 0) {
              approachStats[2] += 1
              approachStatsByDistance[distType][2] += 1
            } else if (shot.x >= 0 && shot.y < 0) {
              approachStats[4] += 1
              approachStatsByDistance[distType][4] += 1
            } else {
              approachStats[3] += 1
              approachStatsByDistance[distType][3] += 1
            }
            // else it is holed
          } else {
            approachAttacksHitsGreen[srfNumber] += 1
            approachStats[0] += 1
            approachStatsByDistance[distType][0] += 1
          }

          quality[lastShotType] += shot.quality
          qualityCount[lastShotType] += 1

          routine[lastShotType] += shot.routine
          routineCount[lastShotType] += 1

          if (shot.quality === 1) {
            wellHitShotByType[lastShotType] += 1
            if (
              shotNumber < shotsForHole.length - 1
              && shotsForHole[shotNumber + 1].surface === Parameters.greenCode
            ) wellHitMissFWShotByType[lastShotType] += 1
          } else {
            missHitShotByType[lastShotType] += 1
            if (
              shotNumber === nbShotsForHole - 1
              || (shotsForHole[shotNumber + 1] && shotsForHole[shotNumber + 1].surface === Parameters.greenCode)
            ) missHitHitFwShotByType[lastShotType] += 1
          }

          // Long Shots -> Hit FW / Allow Green Attack
        } else {
          longShotsByPars[par - 3] += 1

          lastShotType = 1

          // if not last shot
          if (shotNumber < nbShotsForHole - 1) {
            const nextShot = shotsForHole[shotNumber + 1]
            if ([2, 6, Parameters.greenCode].indexOf(nextShot.surface) > -1) longShotsFWStats[1] += 1
            else if (shot.x < 0) longShotsFWStats[0] += 1
            else longShotsFWStats[2] += 1

            if (nextShot.attackGreen === 1) longShotsNextCanAttack += 1
          } else {
            longShotsFWStats[1] += 1
            longShotsNextCanAttack += 1
          }

          quality[lastShotType] += shot.quality
          qualityCount[lastShotType] += 1

          routine[lastShotType] += shot.routine
          routineCount[lastShotType] += 1

          if (shot.quality === 1) {
            wellHitShotByType[lastShotType] += 1
            if (
              shotNumber < shotsForHole.length - 1
              && [2, 6, Parameters.greenCode].indexOf(
                shotsForHole[shotNumber + 1].surface,
              ) === -1
            ) wellHitMissFWShotByType[lastShotType] += 1
          } else {
            missHitShotByType[1] += 1
            if (
              shotNumber === shotsForHole.length - 1
                || (shotsForHole[shotNumber + 1] && [2, 6, Parameters.greenCode].indexOf(
                  shotsForHole[shotNumber + 1].surface,
                ) > -1)
            ) missHitHitFwShotByType[lastShotType] += 1
          }
        }
      }

      wastedShotsPerType[lastShotType] += wasted

      // if not pickedup and not a penaltu shot
      if (
        shot.club !== Parameters.pickupCode
        && shot.club !== Parameters.penaltyCode
      ) {
        // if it is the shot to get gir
        if (par - shotNumber === 3) {
          // add the shot distance to the array for the right type (par3/4/5)
          if (par - 3 < distancesGIRShot.length) {
            distancesGIRShot[par - 3].push(shot.distanceToHole)
          }
        }
      }

      // if attacking the green (and not on green)
      if (shot.attackGreen === 1 && shot.surface !== Parameters.greenCode) {
        let result = 0
        let nextShotPickedUp = false

        // if not the last shot
        if (shotNumber < shotsForHole.length - 1) {
          // get next shot
          const nextShot = shotsForHole[shotNumber + 1]
          // result of this shot is distance to hole of next shot
          result = Math.min(nextShot.distanceToHole, 32)

          // if the next shot is picked up, ignore this shot
          if (nextShot.club === Parameters.pickupCode) nextShotPickedUp = true
          // if the next shot is a penalty, get distance from following shot that is not a penalty
          else if (nextShot.club === Parameters.penaltyCode) {
            let shotNb = shotNumber + 2
            while (shotsForHole[shotNb].club === Parameters.penaltyCode) {
              shotNb += 1
            }
            result = Math.min(shotsForHole[shotNb].distanceToHole, 32)
            // if the next shot after the penalty if picked up, ignore this shot
            if (shotsForHole[shotNb].club === Parameters.pickupCode) nextShotPickedUp = true
          }
        }

        // if the next shot not picked up
        if (!nextShotPickedUp) {
          const resultFlagMeters = result // dispersion to flag (m)
          const resultFlagPercentage = (result / shot.distanceToHole) * 100 // dispersion to flag (%)
          const resultTargetMeters = Math.min(
            cartesianToPolar(shot.x, shot.y),
            32,
          ) // dispersion to target (m)
          const resultTargetPercentage = (resultTargetMeters / shot.distanceToHole) * 100 // dispersion to target (%)

          // add the shot dispersion to the right distance brackets
          const dispersionDistanceIndex = getDispersionDistanceIndex(
            shot.distanceToHole,
            unitSystem,
          )
          flagDispersionMeters[dispersionDistanceIndex].push(resultFlagMeters)
          flagDispersionPercentage[dispersionDistanceIndex].push(
            resultFlagPercentage,
          )
          targetDispersionMeters[dispersionDistanceIndex].push(
            resultTargetMeters,
          )
          targetDispersionPercentage[dispersionDistanceIndex].push(
            resultTargetPercentage,
          )
        }
      }
    }

    // update streaks on penalty/recoveries/pickups
    if (isPlayed) {
      if (hasPenaltyRecoveryPickup) currentStreakWithoutPenalty = 0
      else currentStreakWithoutPenalty += 1
      maxStreakWithoutPenalty = Math.max(
        maxStreakWithoutPenalty,
        currentStreakWithoutPenalty,
      )
    }
  }

  // order putts in ascending order
  puttsMade.sort((a, b) => a - b)
  missedPutts.sort((a, b) => a - b)

  // distance in which all putts are made
  // let distanceMakePutt = puttsMade.length ? puttsMade[0] : 0;

  // const count = 0;
  // if (missedPutts.length) {
  //   let i = 0;
  //   // while i haven't missed a putt in the distance, increase the make putt distance
  //   while (i < puttsMade.length && missedPutts[0] > puttsMade[i]) {
  //     distanceMakePutt = puttsMade[i];
  //     i += 1;
  //   }
  // }

  return {
    totalStrokes, // int
    totalPar, // int
    totalPutts, // int

    nbHolesPlayed,

    scoreStats,
    puttsStats,

    girScrFwStats,

    scoresByThirds,
    scoresByHalves,

    scoreOfPars,
    girsOfPars,
    nbHolesOfPars,

    puttsGir,
    puttsNonGir,

    birdieConversion,
    parConversion,

    driveDistance,
    distancesFirstAttack,
    distancesGIRShot,

    penalties,
    recoveries,
    wastedShotsPerType,

    firstPuttDistances,
    secondPuttDistances,

    shortPuttsMissed,

    flagDispersionMeters,
    flagDispersionPercentage,
    targetDispersionMeters,
    targetDispersionPercentage,

    longShotsFWStats,
    longShotsNextCanAttack,

    approachAttacksHitsGreen,
    approachAttacks,

    upDownsBySurface,
    upDownsAttemptsBySurface,

    approachStats,
    approachStatsByDistance,

    aroundStats,

    puttingStatsShort,
    puttingStatsLong,

    missedPutts,
    birdiePuttsMade,
    puttsMade,
    longPuttResult,

    distancePuttFromNonGRS,
    distancePuttFromGRS,

    quality,
    qualityCount,

    routine,
    routineCount,

    longShotsByPars,

    nbOnePutts,
    nbOnePuttsTotal,

    nbOnePuttsForBirdie,
    nbOnePuttsForBirdieTotal,

    nbOnePuttsForPar,
    nbOnePuttsForParTotal,

    nbThreePutts,
    nbThreePuttsTotal,

    maxStreakParsAndBelow,
    currentStreakParsAndBelow,

    maxStreakBelowPar,
    currentStreakBelowPar,

    maxStreakWithoutDoubleBogeys,
    currentStreakWithoutDoubleBogeys,

    maxStreak1Putt,
    currentStreak1Putt,

    maxStreakWithout3Putts,
    currentStreakWithout3Putts,

    maxStreakFairways,
    currentStreakFairways,

    maxStreakGIR,
    currentStreakGIR,

    maxStreakWithoutPenalty,
    currentStreakWithoutPenalty,

    scoreFWHit,

    greensideDispersion,
    greensideDistance,

    wellHitShotByType,
    wellHitMissFWShotByType,

    missHitShotByType,
    missHitHitFwShotByType,

    qualityAfterMadePutt,
    qualityAfterMissPutt,
    qualityAfterNormalPutt,

    routineAfterMadePutt,
    routineAfterMissPutt,
    routineAfterNormalPutt,

    scoreShortPar4,
    scoreLongPar4,

    puttStatsFullQuantity,
    puttStatsFullReads,
    puttStatsFullHoled,
    holesPlayedShotByShot,
  }
}

/*
convert meters To Yards
*/
function convertMetersToYards(meters) {
  let yards = meters * 1.0936133
  yards = Math.round(yards)
  if (yards <= 0) yards = 1
  else if (yards > 600) yards = 600
  return yards
}

/*
getGirScrFwStatsForHole

Parameters:
score Int, par Int, putts Int, fairway String, played String

Return:
JSON {
    gir: 0,
    girCount: 0,
    scrambling: 0,
    scramblingCount: 0,
    left: 0,
    fairway: 0,
    right: 0,
    fairwayCount: 0,
    }
*/
// function getGirScrFwStatsForHole(score, par, putts, fairway, played) {
//   const data = {
//     gir: 0,
//     girCount: 0,
//     scrambling: 0,
//     scramblingCount: 0,
//     left: 0,
//     fairway: 0,
//     right: 0,
//     fairwayCount: 0
//   };

//   if (played !== "0") {
//     data.girCount = 1;
//     if (IsInRegulation(score, par, putts, played)) data.gir = 1;
//     else {
//       data.scramblingCount = 1;
//       if (score <= par) data.scrambling = 1;
//     }

//     if (par > 3) {
//       data.fairwayCount = 1;
//       if (fairway === "1") data.fairway = 1;
//       else if (fairway === "L") data.left = 1;
//       else if (fairway === "R") data.right = 1;
//     }
//   }

//   return data;
// }

/*
get sum of all elements of an array of arrays (2-levels)
*/
// function arrayOfArraySum(array) {
//   let sum = 0;
//   for (let i = 0; i < array.length; i += 1) {
//     const subarray = array[i];
//     sum += Number(arraySum(subarray));
//   }
//   return sum;
// }

Parameters.scoringData = {
  1: [2.03, 1.91, 2.07, 2.16, 2.73, 1.01],
  2: [2.06, 1.94, 2.1, 2.19, 2.76, 1.025],
  3: [2.09, 1.97, 2.13, 2.22, 2.79, 1.04],
  4: [2.12, 2, 2.16, 2.25, 2.82, 1.13],
  5: [2.15, 2.03, 2.19, 2.28, 2.85, 1.23],
  6: [2.18, 2.06, 2.22, 2.31, 2.88, 1.34],
  7: [2.21, 2.09, 2.25, 2.34, 2.91, 1.42],
  8: [2.24, 2.12, 2.28, 2.37, 2.94, 1.5],
  9: [2.27, 2.15, 2.31, 2.4, 2.97, 1.56],
  10: [2.3, 2.18, 2.34, 2.43, 3, 1.61],
  11: [2.322, 2.202, 2.366, 2.44, 3.01, 1.644],
  12: [2.344, 2.224, 2.392, 2.45, 3.02, 1.678],
  13: [2.366, 2.246, 2.418, 2.46, 3.03, 1.712],
  14: [2.388, 2.268, 2.444, 2.47, 3.04, 1.746],
  15: [2.41, 2.29, 2.47, 2.48, 3.05, 1.78],
  16: [2.432, 2.312, 2.494, 2.49, 3.06, 1.798],
  17: [2.454, 2.334, 2.518, 2.5, 3.07, 1.816],
  18: [2.476, 2.356, 2.542, 2.51, 3.08, 1.834],
  19: [2.498, 2.378, 2.566, 2.52, 3.09, 1.852],
  20: [2.52, 2.4, 2.59, 2.53, 3.1, 1.87],
  21: [2.53, 2.41, 2.5995, 2.5445, 3.1145, 1.882],
  22: [2.54, 2.42, 2.609, 2.559, 3.129, 1.894],
  23: [2.55, 2.43, 2.6185, 2.5735, 3.1435, 1.906],
  24: [2.56, 2.44, 2.628, 2.588, 3.158, 1.918],
  25: [2.57, 2.45, 2.6375, 2.6025, 3.1725, 1.93],
  26: [2.58, 2.46, 2.647, 2.617, 3.187, 1.94],
  27: [2.59, 2.47, 2.6565, 2.6315, 3.2015, 1.95],
  28: [2.6, 2.48, 2.666, 2.646, 3.216, 1.96],
  29: [2.61, 2.49, 2.6755, 2.6605, 3.2305, 1.97],
  30: [2.62, 2.5, 2.685, 2.675, 3.245, 1.98],
  31: [2.63, 2.51, 2.6945, 2.6895, 3.2595, 1.988],
  32: [2.64, 2.52, 2.704, 2.704, 3.274, 1.996],
  33: [2.65, 2.53, 2.7135, 2.7185, 3.2885, 2.004],
  34: [2.66, 2.54, 2.723, 2.733, 3.303, 2.012],
  35: [2.67, 2.55, 2.7325, 2.7475, 3.3175, 2.02],
  36: [2.68, 2.56, 2.742, 2.762, 3.332, 2.028],
  37: [2.69, 2.57, 2.7515, 2.7765, 3.3465, 2.036],
  38: [2.7, 2.58, 2.761, 2.791, 3.361, 2.044],
  39: [2.71, 2.59, 2.7705, 2.8055, 3.3755, 2.052],
  40: [2.72, 2.6, 2.78, 2.82, 3.39, 2.06],
  41: [2.725, 2.605, 2.7865, 2.8365, 3.4065, 2.068],
  42: [2.73, 2.61, 2.793, 2.853, 3.423, 2.076],
  43: [2.735, 2.615, 2.7995, 2.8695, 3.4395, 2.084],
  44: [2.74, 2.62, 2.806, 2.886, 3.456, 2.092],
  45: [2.745, 2.625, 2.8125, 2.9025, 3.4725, 2.1],
  46: [2.75, 2.63, 2.819, 2.919, 3.489, 2.108],
  47: [2.755, 2.635, 2.8255, 2.9355, 3.5055, 2.116],
  48: [2.76, 2.64, 2.832, 2.952, 3.522, 2.124],
  49: [2.765, 2.645, 2.8385, 2.9685, 3.5385, 2.132],
  50: [2.77, 2.65, 2.845, 2.985, 3.555, 2.14],
  51: [2.775, 2.655, 2.8515, 3.0015, 3.5715, 2.146],
  52: [2.78, 2.66, 2.858, 3.018, 3.588, 2.152],
  53: [2.785, 2.665, 2.8645, 3.0345, 3.6045, 2.158],
  54: [2.79, 2.67, 2.871, 3.051, 3.621, 2.164],
  55: [2.795, 2.675, 2.8775, 3.0675, 3.6375, 2.17],
  56: [2.8, 2.68, 2.884, 3.084, 3.654, 2.178],
  57: [2.805, 2.685, 2.8905, 3.1005, 3.6705, 2.186],
  58: [2.81, 2.69, 2.897, 3.117, 3.687, 2.194],
  59: [2.815, 2.695, 2.9035, 3.1335, 3.7035, 2.202],
  60: [2.82, 2.7, 2.91, 3.15, 3.72, 2.21],
  61: [2.8225, 2.7025, 2.9125, 3.1545, 3.7245, 2.217],
  62: [2.825, 2.705, 2.915, 3.159, 3.729, 2.224],
  63: [2.8275, 2.7075, 2.9175, 3.1635, 3.7335, 2.231],
  64: [2.83, 2.71, 2.92, 3.168, 3.738, 2.238],
  65: [2.8325, 2.7125, 2.9225, 3.1725, 3.7425, 2.245],
  66: [2.835, 2.715, 2.925, 3.177, 3.747, 2.252],
  67: [2.8375, 2.7175, 2.9275, 3.1815, 3.7515, 2.259],
  68: [2.84, 2.72, 2.93, 3.186, 3.756, 2.266],
  69: [2.8425, 2.7225, 2.9325, 3.1905, 3.7605, 2.273],
  70: [2.845, 2.725, 2.935, 3.195, 3.765, 2.28],
  71: [2.8475, 2.7275, 2.9375, 3.1995, 3.7695, 2.286],
  72: [2.85, 2.73, 2.94, 3.204, 3.774, 2.292],
  73: [2.8525, 2.7325, 2.9425, 3.2085, 3.7785, 2.298],
  74: [2.855, 2.735, 2.945, 3.213, 3.783, 2.304],
  75: [2.8575, 2.7375, 2.9475, 3.2175, 3.7875, 2.31],
  76: [2.86, 2.74, 2.95, 3.222, 3.792, 2.316],
  77: [2.8625, 2.7425, 2.9525, 3.2265, 3.7965, 2.322],
  78: [2.865, 2.745, 2.955, 3.231, 3.801, 2.328],
  79: [2.8675, 2.7475, 2.9575, 3.2355, 3.8055, 2.334],
  80: [2.87, 2.75, 2.96, 3.24, 3.81, 2.34],
  81: [2.8725, 2.7525, 2.963, 3.2395, 3.8095, 2.346],
  82: [2.875, 2.755, 2.966, 3.239, 3.809, 2.352],
  83: [2.8775, 2.7575, 2.969, 3.2385, 3.8085, 2.358],
  84: [2.88, 2.76, 2.972, 3.238, 3.808, 2.364],
  85: [2.8825, 2.7625, 2.975, 3.2375, 3.8075, 2.37],
  86: [2.885, 2.765, 2.978, 3.237, 3.807, 2.376],
  87: [2.8875, 2.7675, 2.981, 3.2365, 3.8065, 2.382],
  88: [2.89, 2.77, 2.984, 3.236, 3.806, 2.388],
  89: [2.8925, 2.7725, 2.987, 3.2355, 3.8055, 2.394],
  90: [2.895, 2.775, 2.99, 3.235, 3.805, 2.4],
  91: [2.8975, 2.7775, 2.993, 3.2345, 3.8045, 2.4],
  92: [2.9, 2.78, 2.996, 3.234, 3.804, 2.4],
  93: [2.9025, 2.7825, 2.999, 3.2335, 3.8035, 2.4],
  94: [2.905, 2.785, 3.002, 3.233, 3.803, 2.4],
  95: [2.9075, 2.7875, 3.005, 3.2325, 3.8025, 2.4],
  96: [2.91, 2.79, 3.008, 3.232, 3.802, 2.4],
  97: [2.9125, 2.7925, 3.011, 3.2315, 3.8015, 2.4],
  98: [2.915, 2.795, 3.014, 3.231, 3.801, 2.4],
  99: [2.9175, 2.7975, 3.017, 3.2305, 3.8005, 2.4],
  100: [2.92, 2.8, 3.02, 3.23, 3.8, 2.4],
  101: [2.9235, 2.8025, 3.023, 3.229, 3.799, 2.4],
  102: [2.927, 2.805, 3.026, 3.228, 3.798, 2.4],
  103: [2.9305, 2.8075, 3.029, 3.227, 3.797, 2.4],
  104: [2.934, 2.81, 3.032, 3.226, 3.796, 2.4],
  105: [2.9375, 2.8125, 3.035, 3.225, 3.795, 2.4],
  106: [2.941, 2.815, 3.038, 3.224, 3.794, 2.4],
  107: [2.9445, 2.8175, 3.041, 3.223, 3.793, 2.4],
  108: [2.948, 2.82, 3.044, 3.222, 3.792, 2.4],
  109: [2.9515, 2.8225, 3.047, 3.221, 3.791, 2.4],
  110: [2.955, 2.825, 3.05, 3.22, 3.79, 2.4],
  111: [2.9585, 2.8275, 3.053, 3.219, 3.789, 2.4],
  112: [2.962, 2.83, 3.056, 3.218, 3.788, 2.4],
  113: [2.9655, 2.8325, 3.059, 3.217, 3.787, 2.4],
  114: [2.969, 2.835, 3.062, 3.216, 3.786, 2.4],
  115: [2.9725, 2.8375, 3.065, 3.215, 3.785, 2.4],
  116: [2.976, 2.84, 3.068, 3.214, 3.784, 2.4],
  117: [2.9795, 2.8425, 3.071, 3.213, 3.783, 2.4],
  118: [2.983, 2.845, 3.074, 3.212, 3.782, 2.4],
  119: [2.9865, 2.8475, 3.077, 3.211, 3.781, 2.4],
  120: [2.99, 2.85, 3.08, 3.21, 3.78, 2.4],
  121: [2.989, 2.853, 3.0835, 3.2105, 3.781, 2.4],
  122: [2.988, 2.856, 3.087, 3.211, 3.782, 2.4],
  123: [2.987, 2.859, 3.0905, 3.2115, 3.783, 2.4],
  124: [2.986, 2.862, 3.094, 3.212, 3.784, 2.4],
  125: [2.985, 2.865, 3.0975, 3.2125, 3.785, 2.4],
  126: [2.984, 2.868, 3.101, 3.213, 3.786, 2.4],
  127: [2.983, 2.871, 3.1045, 3.2135, 3.787, 2.4],
  128: [2.982, 2.874, 3.108, 3.214, 3.788, 2.4],
  129: [2.981, 2.877, 3.1115, 3.2145, 3.789, 2.4],
  130: [2.98, 2.88, 3.115, 3.215, 3.79, 2.4],
  131: [2.979, 2.883, 3.1185, 3.2155, 3.791, 2.4],
  132: [2.978, 2.886, 3.122, 3.216, 3.792, 2.4],
  133: [2.977, 2.889, 3.1255, 3.2165, 3.793, 2.4],
  134: [2.976, 2.892, 3.129, 3.217, 3.794, 2.4],
  135: [2.975, 2.895, 3.1325, 3.2175, 3.795, 2.4],
  136: [2.974, 2.898, 3.136, 3.218, 3.796, 2.4],
  137: [2.973, 2.901, 3.1395, 3.2185, 3.797, 2.4],
  138: [2.972, 2.904, 3.143, 3.219, 3.798, 2.4],
  139: [2.971, 2.907, 3.1465, 3.2195, 3.799, 2.4],
  140: [2.97, 2.91, 3.15, 3.22, 3.8, 2.4],
  141: [2.971, 2.9135, 3.154, 3.223, 3.8005, 2.4],
  142: [2.972, 2.917, 3.158, 3.226, 3.801, 2.4],
  143: [2.973, 2.9205, 3.162, 3.229, 3.8015, 2.4],
  144: [2.974, 2.924, 3.166, 3.232, 3.802, 2.4],
  145: [2.975, 2.9275, 3.17, 3.235, 3.8025, 2.4],
  146: [2.976, 2.931, 3.174, 3.238, 3.803, 2.4],
  147: [2.977, 2.9345, 3.178, 3.241, 3.8035, 2.4],
  148: [2.978, 2.938, 3.182, 3.244, 3.804, 2.4],
  149: [2.979, 2.9415, 3.186, 3.247, 3.8045, 2.4],
  150: [2.98, 2.945, 3.19, 3.25, 3.805, 2.4],
  151: [2.981, 2.9485, 3.194, 3.253, 3.8055, 2.4],
  152: [2.982, 2.952, 3.198, 3.256, 3.806, 2.4],
  153: [2.983, 2.9555, 3.202, 3.259, 3.8065, 2.4],
  154: [2.984, 2.959, 3.206, 3.262, 3.807, 2.4],
  155: [2.985, 2.9625, 3.21, 3.265, 3.8075, 2.4],
  156: [2.986, 2.966, 3.214, 3.268, 3.808, 2.4],
  157: [2.987, 2.9695, 3.218, 3.271, 3.8085, 2.4],
  158: [2.988, 2.973, 3.222, 3.274, 3.809, 2.4],
  159: [2.989, 2.9765, 3.226, 3.277, 3.8095, 2.4],
  160: [2.99, 2.98, 3.23, 3.28, 3.81, 2.4],
  161: [2.993, 2.985, 3.234, 3.286, 3.8105, 2.4],
  162: [2.996, 2.99, 3.238, 3.292, 3.811, 2.4],
  163: [2.999, 2.995, 3.242, 3.298, 3.8115, 2.4],
  164: [3.002, 3, 3.246, 3.304, 3.812, 2.4],
  165: [3.005, 3.005, 3.25, 3.31, 3.8125, 2.4],
  166: [3.008, 3.01, 3.254, 3.316, 3.813, 2.4],
  167: [3.011, 3.015, 3.258, 3.322, 3.8135, 2.4],
  168: [3.014, 3.02, 3.262, 3.328, 3.814, 2.4],
  169: [3.017, 3.025, 3.266, 3.334, 3.8145, 2.4],
  170: [3.02, 3.03, 3.27, 3.34, 3.815, 2.4],
  171: [3.023, 3.035, 3.274, 3.346, 3.8155, 2.4],
  172: [3.026, 3.04, 3.278, 3.352, 3.816, 2.4],
  173: [3.029, 3.045, 3.282, 3.358, 3.8165, 2.4],
  174: [3.032, 3.05, 3.286, 3.364, 3.817, 2.4],
  175: [3.035, 3.055, 3.29, 3.37, 3.8175, 2.4],
  176: [3.038, 3.06, 3.294, 3.376, 3.818, 2.4],
  177: [3.041, 3.065, 3.298, 3.382, 3.8185, 2.4],
  178: [3.044, 3.07, 3.302, 3.388, 3.819, 2.4],
  179: [3.047, 3.075, 3.306, 3.394, 3.8195, 2.4],
  180: [3.05, 3.08, 3.31, 3.4, 3.82, 2.4],
  181: [3.0535, 3.0855, 3.3155, 3.4075, 3.8225, 2.4],
  182: [3.057, 3.091, 3.321, 3.415, 3.825, 2.4],
  183: [3.0605, 3.0965, 3.3265, 3.4225, 3.8275, 2.4],
  184: [3.064, 3.102, 3.332, 3.43, 3.83, 2.4],
  185: [3.0675, 3.1075, 3.3375, 3.4375, 3.8325, 2.4],
  186: [3.071, 3.113, 3.343, 3.445, 3.835, 2.4],
  187: [3.0745, 3.1185, 3.3485, 3.4525, 3.8375, 2.4],
  188: [3.078, 3.124, 3.354, 3.46, 3.84, 2.4],
  189: [3.0815, 3.1295, 3.3595, 3.4675, 3.8425, 2.4],
  190: [3.085, 3.135, 3.365, 3.475, 3.845, 2.4],
  191: [3.0885, 3.1405, 3.3705, 3.4825, 3.8475, 2.4],
  192: [3.092, 3.146, 3.376, 3.49, 3.85, 2.4],
  193: [3.0955, 3.1515, 3.3815, 3.4975, 3.8525, 2.4],
  194: [3.099, 3.157, 3.387, 3.505, 3.855, 2.4],
  195: [3.1025, 3.1625, 3.3925, 3.5125, 3.8575, 2.4],
  196: [3.106, 3.168, 3.398, 3.52, 3.86, 2.4],
  197: [3.1095, 3.1735, 3.4035, 3.5275, 3.8625, 2.4],
  198: [3.113, 3.179, 3.409, 3.535, 3.865, 2.4],
  199: [3.1165, 3.1845, 3.4145, 3.5425, 3.8675, 2.4],
  200: [3.12, 3.19, 3.42, 3.55, 3.87, 2.4],
  201: [3.1225, 3.1965, 3.4255, 3.5575, 3.8725, 2.4],
  202: [3.125, 3.203, 3.431, 3.565, 3.875, 2.4],
  203: [3.1275, 3.2095, 3.4365, 3.5725, 3.8775, 2.4],
  204: [3.13, 3.216, 3.442, 3.58, 3.88, 2.4],
  205: [3.1325, 3.2225, 3.4475, 3.5875, 3.8825, 2.4],
  206: [3.135, 3.229, 3.453, 3.595, 3.885, 2.4],
  207: [3.1375, 3.2355, 3.4585, 3.6025, 3.8875, 2.4],
  208: [3.14, 3.242, 3.464, 3.61, 3.89, 2.4],
  209: [3.1425, 3.2485, 3.4695, 3.6175, 3.8925, 2.4],
  210: [3.145, 3.255, 3.475, 3.625, 3.895, 2.4],
  211: [3.1475, 3.2615, 3.4805, 3.6325, 3.8975, 2.4],
  212: [3.15, 3.268, 3.486, 3.64, 3.9, 2.4],
  213: [3.1525, 3.2745, 3.4915, 3.6475, 3.9025, 2.4],
  214: [3.155, 3.281, 3.497, 3.655, 3.905, 2.4],
  215: [3.1575, 3.2875, 3.5025, 3.6625, 3.9075, 2.4],
  216: [3.16, 3.294, 3.508, 3.67, 3.91, 2.4],
  217: [3.1625, 3.3005, 3.5135, 3.6775, 3.9125, 2.4],
  218: [3.165, 3.307, 3.519, 3.685, 3.915, 2.4],
  219: [3.1675, 3.3135, 3.5245, 3.6925, 3.9175, 2.4],
  220: [3.17, 3.32, 3.53, 3.7, 3.92, 2.4],
  221: [3.174, 3.3265, 3.5355, 3.707, 3.9225, 2.4],
  222: [3.178, 3.333, 3.541, 3.714, 3.925, 2.4],
  223: [3.182, 3.3395, 3.5465, 3.721, 3.9275, 2.4],
  224: [3.186, 3.346, 3.552, 3.728, 3.93, 2.4],
  225: [3.19, 3.3525, 3.5575, 3.735, 3.9325, 2.4],
  226: [3.194, 3.359, 3.563, 3.742, 3.935, 2.4],
  227: [3.198, 3.3655, 3.5685, 3.749, 3.9375, 2.4],
  228: [3.202, 3.372, 3.574, 3.756, 3.94, 2.4],
  229: [3.206, 3.3785, 3.5795, 3.763, 3.9425, 2.4],
  230: [3.21, 3.385, 3.585, 3.77, 3.945, 2.4],
  231: [3.214, 3.3915, 3.5905, 3.777, 3.9475, 2.4],
  232: [3.218, 3.398, 3.596, 3.784, 3.95, 2.4],
  233: [3.222, 3.4045, 3.6015, 3.791, 3.9525, 2.4],
  234: [3.226, 3.411, 3.607, 3.798, 3.955, 2.4],
  235: [3.23, 3.4175, 3.6125, 3.805, 3.9575, 2.4],
  236: [3.234, 3.424, 3.618, 3.812, 3.96, 2.4],
  237: [3.238, 3.4305, 3.6235, 3.819, 3.9625, 2.4],
  238: [3.242, 3.437, 3.629, 3.826, 3.965, 2.4],
  239: [3.246, 3.4435, 3.6345, 3.833, 3.9675, 2.4],
  240: [3.25, 3.45, 3.64, 3.84, 3.97, 2.4],
  241: [3.26, 3.4565, 3.645, 3.8445, 3.973, 2.4],
  242: [3.27, 3.463, 3.65, 3.849, 3.976, 2.4],
  243: [3.28, 3.4695, 3.655, 3.8535, 3.979, 2.4],
  244: [3.29, 3.476, 3.66, 3.858, 3.982, 2.4],
  245: [3.3, 3.4825, 3.665, 3.8625, 3.985, 2.4],
  246: [3.31, 3.489, 3.67, 3.867, 3.988, 2.4],
  247: [3.32, 3.4955, 3.675, 3.8715, 3.991, 2.4],
  248: [3.33, 3.502, 3.68, 3.876, 3.994, 2.4],
  249: [3.34, 3.5085, 3.685, 3.8805, 3.997, 2.4],
  250: [3.35, 3.515, 3.69, 3.885, 4, 2.4],
  251: [3.36, 3.5215, 3.695, 3.8895, 4.003, 2.4],
  252: [3.37, 3.528, 3.7, 3.894, 4.006, 2.4],
  253: [3.38, 3.5345, 3.705, 3.8985, 4.009, 2.4],
  254: [3.39, 3.541, 3.71, 3.903, 4.012, 2.4],
  255: [3.4, 3.5475, 3.715, 3.9075, 4.015, 2.4],
  256: [3.41, 3.554, 3.72, 3.912, 4.018, 2.4],
  257: [3.42, 3.5605, 3.725, 3.9165, 4.021, 2.4],
  258: [3.43, 3.567, 3.73, 3.921, 4.024, 2.4],
  259: [3.44, 3.5735, 3.735, 3.9255, 4.027, 2.4],
  260: [3.45, 3.58, 3.74, 3.93, 4.03, 2.4],
  261: [3.46, 3.5855, 3.7445, 3.9335, 4.0335, 2.4],
  262: [3.47, 3.591, 3.749, 3.937, 4.037, 2.4],
  263: [3.48, 3.5965, 3.7535, 3.9405, 4.0405, 2.4],
  264: [3.49, 3.602, 3.758, 3.944, 4.044, 2.4],
  265: [3.5, 3.6075, 3.7625, 3.9475, 4.0475, 2.4],
  266: [3.51, 3.613, 3.767, 3.951, 4.051, 2.4],
  267: [3.52, 3.6185, 3.7715, 3.9545, 4.0545, 2.4],
  268: [3.53, 3.624, 3.776, 3.958, 4.058, 2.4],
  269: [3.54, 3.6295, 3.7805, 3.9615, 4.0615, 2.4],
  270: [3.55, 3.635, 3.785, 3.965, 4.065, 2.4],
  271: [3.56, 3.6405, 3.7895, 3.9685, 4.0685, 2.4],
  272: [3.57, 3.646, 3.794, 3.972, 4.072, 2.4],
  273: [3.58, 3.6515, 3.7985, 3.9755, 4.0755, 2.4],
  274: [3.59, 3.657, 3.803, 3.979, 4.079, 2.4],
  275: [3.6, 3.6625, 3.8075, 3.9825, 4.0825, 2.4],
  276: [3.61, 3.668, 3.812, 3.986, 4.086, 2.4],
  277: [3.62, 3.6735, 3.8165, 3.9895, 4.0895, 2.4],
  278: [3.63, 3.679, 3.821, 3.993, 4.093, 2.4],
  279: [3.64, 3.6845, 3.8255, 3.9965, 4.0965, 2.4],
  280: [3.65, 3.69, 3.83, 4, 4.1, 2.4],
  281: [3.653, 3.6945, 3.8335, 4.002, 4.105, 2.4],
  282: [3.656, 3.699, 3.837, 4.004, 4.11, 2.4],
  283: [3.659, 3.7035, 3.8405, 4.006, 4.115, 2.4],
  284: [3.662, 3.708, 3.844, 4.008, 4.12, 2.4],
  285: [3.665, 3.7125, 3.8475, 4.01, 4.125, 2.4],
  286: [3.668, 3.717, 3.851, 4.012, 4.13, 2.4],
  287: [3.671, 3.7215, 3.8545, 4.014, 4.135, 2.4],
  288: [3.674, 3.726, 3.858, 4.016, 4.14, 2.4],
  289: [3.677, 3.7305, 3.8615, 4.018, 4.145, 2.4],
  290: [3.68, 3.735, 3.865, 4.02, 4.15, 2.4],
  291: [3.683, 3.7395, 3.8685, 4.022, 4.155, 2.4],
  292: [3.686, 3.744, 3.872, 4.024, 4.16, 2.4],
  293: [3.689, 3.7485, 3.8755, 4.026, 4.165, 2.4],
  294: [3.692, 3.753, 3.879, 4.028, 4.17, 2.4],
  295: [3.695, 3.7575, 3.8825, 4.03, 4.175, 2.4],
  296: [3.698, 3.762, 3.886, 4.032, 4.18, 2.4],
  297: [3.701, 3.7665, 3.8895, 4.034, 4.185, 2.4],
  298: [3.704, 3.771, 3.893, 4.036, 4.19, 2.4],
  299: [3.707, 3.7755, 3.8965, 4.038, 4.195, 2.4],
  300: [3.71, 3.78, 3.9, 4.04, 4.2, 2.4],
  301: [3.714, 3.783, 3.9025, 4.044, 4.2055, 2.4],
  302: [3.718, 3.786, 3.905, 4.048, 4.211, 2.4],
  303: [3.722, 3.789, 3.9075, 4.052, 4.2165, 2.4],
  304: [3.726, 3.792, 3.91, 4.056, 4.222, 2.4],
  305: [3.73, 3.795, 3.9125, 4.06, 4.2275, 2.4],
  306: [3.734, 3.798, 3.915, 4.064, 4.233, 2.4],
  307: [3.738, 3.801, 3.9175, 4.068, 4.2385, 2.4],
  308: [3.742, 3.804, 3.92, 4.072, 4.244, 2.4],
  309: [3.746, 3.807, 3.9225, 4.076, 4.2495, 2.4],
  310: [3.75, 3.81, 3.925, 4.08, 4.255, 2.4],
  311: [3.754, 3.813, 3.9275, 4.084, 4.2605, 2.4],
  312: [3.758, 3.816, 3.93, 4.088, 4.266, 2.4],
  313: [3.762, 3.819, 3.9325, 4.092, 4.2715, 2.4],
  314: [3.766, 3.822, 3.935, 4.096, 4.277, 2.4],
  315: [3.77, 3.825, 3.9375, 4.1, 4.2825, 2.4],
  316: [3.774, 3.828, 3.94, 4.104, 4.288, 2.4],
  317: [3.778, 3.831, 3.9425, 4.108, 4.2935, 2.4],
  318: [3.782, 3.834, 3.945, 4.112, 4.299, 2.4],
  319: [3.786, 3.837, 3.9475, 4.116, 4.3045, 2.4],
  320: [3.79, 3.84, 3.95, 4.12, 4.31, 2.4],
  321: [3.7935, 3.842, 3.9535, 4.127, 4.3165, 2.4],
  322: [3.797, 3.844, 3.957, 4.134, 4.323, 2.4],
  323: [3.8005, 3.846, 3.9605, 4.141, 4.3295, 2.4],
  324: [3.804, 3.848, 3.964, 4.148, 4.336, 2.4],
  325: [3.8075, 3.85, 3.9675, 4.155, 4.3425, 2.4],
  326: [3.811, 3.852, 3.971, 4.162, 4.349, 2.4],
  327: [3.8145, 3.854, 3.9745, 4.169, 4.3555, 2.4],
  328: [3.818, 3.856, 3.978, 4.176, 4.362, 2.4],
  329: [3.8215, 3.858, 3.9815, 4.183, 4.3685, 2.4],
  330: [3.825, 3.86, 3.985, 4.19, 4.375, 2.4],
  331: [3.8285, 3.862, 3.9885, 4.197, 4.3815, 2.4],
  332: [3.832, 3.864, 3.992, 4.204, 4.388, 2.4],
  333: [3.8355, 3.866, 3.9955, 4.211, 4.3945, 2.4],
  334: [3.839, 3.868, 3.999, 4.218, 4.401, 2.4],
  335: [3.8425, 3.87, 4.0025, 4.225, 4.4075, 2.4],
  336: [3.846, 3.872, 4.006, 4.232, 4.414, 2.4],
  337: [3.8495, 3.874, 4.0095, 4.239, 4.4205, 2.4],
  338: [3.853, 3.876, 4.013, 4.246, 4.427, 2.4],
  339: [3.8565, 3.878, 4.0165, 4.253, 4.4335, 2.4],
  340: [3.86, 3.88, 4.02, 4.26, 4.44, 2.4],
  341: [3.863, 3.8835, 4.0245, 4.2675, 4.446, 2.4],
  342: [3.866, 3.887, 4.029, 4.275, 4.452, 2.4],
  343: [3.869, 3.8905, 4.0335, 4.2825, 4.458, 2.4],
  344: [3.872, 3.894, 4.038, 4.29, 4.464, 2.4],
  345: [3.875, 3.8975, 4.0425, 4.2975, 4.47, 2.4],
  346: [3.878, 3.901, 4.047, 4.305, 4.476, 2.4],
  347: [3.881, 3.9045, 4.0515, 4.3125, 4.482, 2.4],
  348: [3.884, 3.908, 4.056, 4.32, 4.488, 2.4],
  349: [3.887, 3.9115, 4.0605, 4.3275, 4.494, 2.4],
  350: [3.89, 3.915, 4.065, 4.335, 4.5, 2.4],
  351: [3.893, 3.9185, 4.0695, 4.3425, 4.506, 2.4],
  352: [3.896, 3.922, 4.074, 4.35, 4.512, 2.4],
  353: [3.899, 3.9255, 4.0785, 4.3575, 4.518, 2.4],
  354: [3.902, 3.929, 4.083, 4.365, 4.524, 2.4],
  355: [3.905, 3.9325, 4.0875, 4.3725, 4.53, 2.4],
  356: [3.908, 3.936, 4.092, 4.38, 4.536, 2.4],
  357: [3.911, 3.9395, 4.0965, 4.3875, 4.542, 2.4],
  358: [3.914, 3.943, 4.101, 4.395, 4.548, 2.4],
  359: [3.917, 3.9465, 4.1055, 4.4025, 4.554, 2.4],
  360: [3.92, 3.95, 4.11, 4.41, 4.56, 2.4],
  361: [3.922, 3.954, 4.115, 4.417, 4.565, 2.4],
  362: [3.924, 3.958, 4.12, 4.424, 4.57, 2.4],
  363: [3.926, 3.962, 4.125, 4.431, 4.575, 2.4],
  364: [3.928, 3.966, 4.13, 4.438, 4.58, 2.4],
  365: [3.93, 3.97, 4.135, 4.445, 4.585, 2.4],
  366: [3.932, 3.974, 4.14, 4.452, 4.59, 2.4],
  367: [3.934, 3.978, 4.145, 4.459, 4.595, 2.4],
  368: [3.936, 3.982, 4.15, 4.466, 4.6, 2.4],
  369: [3.938, 3.986, 4.155, 4.473, 4.605, 2.4],
  370: [3.94, 3.99, 4.16, 4.48, 4.61, 2.4],
  371: [3.942, 3.994, 4.165, 4.487, 4.615, 2.4],
  372: [3.944, 3.998, 4.17, 4.494, 4.62, 2.4],
  373: [3.946, 4.002, 4.175, 4.501, 4.625, 2.4],
  374: [3.948, 4.006, 4.18, 4.508, 4.63, 2.4],
  375: [3.95, 4.01, 4.185, 4.515, 4.635, 2.4],
  376: [3.952, 4.014, 4.19, 4.522, 4.64, 2.4],
  377: [3.954, 4.018, 4.195, 4.529, 4.645, 2.4],
  378: [3.956, 4.022, 4.2, 4.536, 4.65, 2.4],
  379: [3.958, 4.026, 4.205, 4.543, 4.655, 2.4],
  380: [3.96, 4.03, 4.21, 4.55, 4.66, 2.4],
  381: [3.9615, 4.034, 4.2145, 4.557, 4.6645, 2.4],
  382: [3.963, 4.038, 4.219, 4.564, 4.669, 2.4],
  383: [3.9645, 4.042, 4.2235, 4.571, 4.6735, 2.4],
  384: [3.966, 4.046, 4.228, 4.578, 4.678, 2.4],
  385: [3.9675, 4.05, 4.2325, 4.585, 4.6825, 2.4],
  386: [3.969, 4.054, 4.237, 4.592, 4.687, 2.4],
  387: [3.9705, 4.058, 4.2415, 4.599, 4.6915, 2.4],
  388: [3.972, 4.062, 4.246, 4.606, 4.696, 2.4],
  389: [3.9735, 4.066, 4.2505, 4.613, 4.7005, 2.4],
  390: [3.975, 4.07, 4.255, 4.62, 4.705, 2.4],
  391: [3.9765, 4.074, 4.2595, 4.627, 4.7095, 2.4],
  392: [3.978, 4.078, 4.264, 4.634, 4.714, 2.4],
  393: [3.9795, 4.082, 4.2685, 4.641, 4.7185, 2.4],
  394: [3.981, 4.086, 4.273, 4.648, 4.723, 2.4],
  395: [3.9825, 4.09, 4.2775, 4.655, 4.7275, 2.4],
  396: [3.984, 4.094, 4.282, 4.662, 4.732, 2.4],
  397: [3.9855, 4.098, 4.2865, 4.669, 4.7365, 2.4],
  398: [3.987, 4.102, 4.291, 4.676, 4.741, 2.4],
  399: [3.9885, 4.106, 4.2955, 4.683, 4.7455, 2.4],
  400: [3.99, 4.11, 4.3, 4.69, 4.75, 2.4],
  401: [3.9915, 4.112, 4.302, 4.692, 4.752, 2.4],
  402: [3.993, 4.114, 4.304, 4.694, 4.754, 2.4],
  403: [3.9945, 4.116, 4.306, 4.696, 4.756, 2.4],
  404: [3.996, 4.118, 4.308, 4.698, 4.758, 2.4],
  405: [3.9975, 4.12, 4.31, 4.7, 4.76, 2.4],
  406: [3.999, 4.122, 4.312, 4.702, 4.762, 2.4],
  407: [4.0005, 4.124, 4.314, 4.704, 4.764, 2.4],
  408: [4.002, 4.126, 4.316, 4.706, 4.766, 2.4],
  409: [4.0035, 4.128, 4.318, 4.708, 4.768, 2.4],
  410: [4.005, 4.13, 4.32, 4.71, 4.77, 2.4],
  411: [4.0065, 4.132, 4.322, 4.712, 4.772, 2.4],
  412: [4.008, 4.134, 4.324, 4.714, 4.774, 2.4],
  413: [4.0095, 4.136, 4.326, 4.716, 4.776, 2.4],
  414: [4.011, 4.138, 4.328, 4.718, 4.778, 2.4],
  415: [4.0125, 4.14, 4.33, 4.72, 4.78, 2.4],
  416: [4.014, 4.142, 4.332, 4.722, 4.782, 2.4],
  417: [4.0155, 4.144, 4.334, 4.724, 4.784, 2.4],
  418: [4.017, 4.146, 4.336, 4.726, 4.786, 2.4],
  419: [4.0185, 4.148, 4.338, 4.728, 4.788, 2.4],
  420: [4.02, 4.15, 4.34, 4.73, 4.79, 2.4],
  421: [4.023, 4.1525, 4.3425, 4.7325, 4.7925, 2.4],
  422: [4.026, 4.155, 4.345, 4.735, 4.795, 2.4],
  423: [4.029, 4.1575, 4.3475, 4.7375, 4.7975, 2.4],
  424: [4.032, 4.16, 4.35, 4.74, 4.8, 2.4],
  425: [4.035, 4.1625, 4.3525, 4.7425, 4.8025, 2.4],
  426: [4.038, 4.165, 4.355, 4.745, 4.805, 2.4],
  427: [4.041, 4.1675, 4.3575, 4.7475, 4.8075, 2.4],
  428: [4.044, 4.17, 4.36, 4.75, 4.81, 2.4],
  429: [4.047, 4.1725, 4.3625, 4.7525, 4.8125, 2.4],
  430: [4.05, 4.175, 4.365, 4.755, 4.815, 2.4],
  431: [4.053, 4.1775, 4.3675, 4.7575, 4.8175, 2.4],
  432: [4.056, 4.18, 4.37, 4.76, 4.82, 2.4],
  433: [4.059, 4.1825, 4.3725, 4.7625, 4.8225, 2.4],
  434: [4.062, 4.185, 4.375, 4.765, 4.825, 2.4],
  435: [4.065, 4.1875, 4.3775, 4.7675, 4.8275, 2.4],
  436: [4.068, 4.19, 4.38, 4.77, 4.83, 2.4],
  437: [4.071, 4.1925, 4.3825, 4.7725, 4.8325, 2.4],
  438: [4.074, 4.195, 4.385, 4.775, 4.835, 2.4],
  439: [4.077, 4.1975, 4.3875, 4.7775, 4.8375, 2.4],
  440: [4.08, 4.2, 4.39, 4.78, 4.84, 2.4],
  441: [4.0845, 4.2045, 4.3945, 4.7845, 4.8445, 2.4],
  442: [4.089, 4.209, 4.399, 4.789, 4.849, 2.4],
  443: [4.0935, 4.2135, 4.4035, 4.7935, 4.8535, 2.4],
  444: [4.098, 4.218, 4.408, 4.798, 4.858, 2.4],
  445: [4.1025, 4.2225, 4.4125, 4.8025, 4.8625, 2.4],
  446: [4.107, 4.227, 4.417, 4.807, 4.867, 2.4],
  447: [4.1115, 4.2315, 4.4215, 4.8115, 4.8715, 2.4],
  448: [4.116, 4.236, 4.426, 4.816, 4.876, 2.4],
  449: [4.1205, 4.2405, 4.4305, 4.8205, 4.8805, 2.4],
  450: [4.125, 4.245, 4.435, 4.825, 4.885, 2.4],
  451: [4.1295, 4.2495, 4.4395, 4.8295, 4.8895, 2.4],
  452: [4.134, 4.254, 4.444, 4.834, 4.894, 2.4],
  453: [4.1385, 4.2585, 4.4485, 4.8385, 4.8985, 2.4],
  454: [4.143, 4.263, 4.453, 4.843, 4.903, 2.4],
  455: [4.1475, 4.2675, 4.4575, 4.8475, 4.9075, 2.4],
  456: [4.152, 4.272, 4.462, 4.852, 4.912, 2.4],
  457: [4.1565, 4.2765, 4.4665, 4.8565, 4.9165, 2.4],
  458: [4.161, 4.281, 4.471, 4.861, 4.921, 2.4],
  459: [4.1655, 4.2855, 4.4755, 4.8655, 4.9255, 2.4],
  460: [4.17, 4.29, 4.48, 4.87, 4.93, 2.4],
  461: [4.1755, 4.2955, 4.4855, 4.8755, 4.9355, 2.4],
  462: [4.181, 4.301, 4.491, 4.881, 4.941, 2.4],
  463: [4.1865, 4.3065, 4.4965, 4.8865, 4.9465, 2.4],
  464: [4.192, 4.312, 4.502, 4.892, 4.952, 2.4],
  465: [4.1975, 4.3175, 4.5075, 4.8975, 4.9575, 2.4],
  466: [4.203, 4.323, 4.513, 4.903, 4.963, 2.4],
  467: [4.2085, 4.3285, 4.5185, 4.9085, 4.9685, 2.4],
  468: [4.214, 4.334, 4.524, 4.914, 4.974, 2.4],
  469: [4.2195, 4.3395, 4.5295, 4.9195, 4.9795, 2.4],
  470: [4.225, 4.345, 4.535, 4.925, 4.985, 2.4],
  471: [4.2305, 4.3505, 4.5405, 4.9305, 4.9905, 2.4],
  472: [4.236, 4.356, 4.546, 4.936, 4.996, 2.4],
  473: [4.2415, 4.3615, 4.5515, 4.9415, 5.0015, 2.4],
  474: [4.247, 4.367, 4.557, 4.947, 5.007, 2.4],
  475: [4.2525, 4.3725, 4.5625, 4.9525, 5.0125, 2.4],
  476: [4.258, 4.378, 4.568, 4.958, 5.018, 2.4],
  477: [4.2635, 4.3835, 4.5735, 4.9635, 5.0235, 2.4],
  478: [4.269, 4.389, 4.579, 4.969, 5.029, 2.4],
  479: [4.2745, 4.3945, 4.5845, 4.9745, 5.0345, 2.4],
  480: [4.28, 4.4, 4.59, 4.98, 5.04, 2.4],
  481: [4.2865, 4.4065, 4.5965, 4.9865, 5.0465, 2.4],
  482: [4.293, 4.413, 4.603, 4.993, 5.053, 2.4],
  483: [4.2995, 4.4195, 4.6095, 4.9995, 5.0595, 2.4],
  484: [4.306, 4.426, 4.616, 5.006, 5.066, 2.4],
  485: [4.3125, 4.4325, 4.6225, 5.0125, 5.0725, 2.4],
  486: [4.319, 4.439, 4.629, 5.019, 5.079, 2.4],
  487: [4.3255, 4.4455, 4.6355, 5.0255, 5.0855, 2.4],
  488: [4.332, 4.452, 4.642, 5.032, 5.092, 2.4],
  489: [4.3385, 4.4585, 4.6485, 5.0385, 5.0985, 2.4],
  490: [4.345, 4.465, 4.655, 5.045, 5.105, 2.4],
  491: [4.3515, 4.4715, 4.6615, 5.0515, 5.1115, 2.4],
  492: [4.358, 4.478, 4.668, 5.058, 5.118, 2.4],
  493: [4.3645, 4.4845, 4.6745, 5.0645, 5.1245, 2.4],
  494: [4.371, 4.491, 4.681, 5.071, 5.131, 2.4],
  495: [4.3775, 4.4975, 4.6875, 5.0775, 5.1375, 2.4],
  496: [4.384, 4.504, 4.694, 5.084, 5.144, 2.4],
  497: [4.3905, 4.5105, 4.7005, 5.0905, 5.1505, 2.4],
  498: [4.397, 4.517, 4.707, 5.097, 5.157, 2.4],
  499: [4.4035, 4.5235, 4.7135, 5.1035, 5.1635, 2.4],
  500: [4.41, 4.53, 4.72, 5.11, 5.17, 2.4],
  501: [4.4165, 4.5365, 4.7265, 5.1165, 5.1765, 2.4],
  502: [4.423, 4.543, 4.733, 5.123, 5.183, 2.4],
  503: [4.4295, 4.5495, 4.7395, 5.1295, 5.1895, 2.4],
  504: [4.436, 4.556, 4.746, 5.136, 5.196, 2.4],
  505: [4.4425, 4.5625, 4.7525, 5.1425, 5.2025, 2.4],
  506: [4.449, 4.569, 4.759, 5.149, 5.209, 2.4],
  507: [4.4555, 4.5755, 4.7655, 5.1555, 5.2155, 2.4],
  508: [4.462, 4.582, 4.772, 5.162, 5.222, 2.4],
  509: [4.4685, 4.5885, 4.7785, 5.1685, 5.2285, 2.4],
  510: [4.475, 4.595, 4.785, 5.175, 5.235, 2.4],
  511: [4.4815, 4.6015, 4.7915, 5.1815, 5.2415, 2.4],
  512: [4.488, 4.608, 4.798, 5.188, 5.248, 2.4],
  513: [4.4945, 4.6145, 4.8045, 5.1945, 5.2545, 2.4],
  514: [4.501, 4.621, 4.811, 5.201, 5.261, 2.4],
  515: [4.5075, 4.6275, 4.8175, 5.2075, 5.2675, 2.4],
  516: [4.514, 4.634, 4.824, 5.214, 5.274, 2.4],
  517: [4.5205, 4.6405, 4.8305, 5.2205, 5.2805, 2.4],
  518: [4.527, 4.647, 4.837, 5.227, 5.287, 2.4],
  519: [4.5335, 4.6535, 4.8435, 5.2335, 5.2935, 2.4],
  520: [4.54, 4.66, 4.85, 5.24, 5.3, 2.4],
  521: [4.5455, 4.666, 4.856, 5.246, 5.306, 2.4],
  522: [4.551, 4.672, 4.862, 5.252, 5.312, 2.4],
  523: [4.5565, 4.678, 4.868, 5.258, 5.318, 2.4],
  524: [4.562, 4.684, 4.874, 5.264, 5.324, 2.4],
  525: [4.5675, 4.69, 4.88, 5.27, 5.33, 2.4],
  526: [4.573, 4.696, 4.886, 5.276, 5.336, 2.4],
  527: [4.5785, 4.702, 4.892, 5.282, 5.342, 2.4],
  528: [4.584, 4.708, 4.898, 5.288, 5.348, 2.4],
  529: [4.5895, 4.714, 4.904, 5.294, 5.354, 2.4],
  530: [4.595, 4.72, 4.91, 5.3, 5.36, 2.4],
  531: [4.6005, 4.726, 4.916, 5.306, 5.366, 2.4],
  532: [4.606, 4.732, 4.922, 5.312, 5.372, 2.4],
  533: [4.6115, 4.738, 4.928, 5.318, 5.378, 2.4],
  534: [4.617, 4.744, 4.934, 5.324, 5.384, 2.4],
  535: [4.6225, 4.75, 4.94, 5.33, 5.39, 2.4],
  536: [4.628, 4.756, 4.946, 5.336, 5.396, 2.4],
  537: [4.6335, 4.762, 4.952, 5.342, 5.402, 2.4],
  538: [4.639, 4.768, 4.958, 5.348, 5.408, 2.4],
  539: [4.6445, 4.774, 4.964, 5.354, 5.414, 2.4],
  540: [4.65, 4.78, 4.97, 5.36, 5.42, 2.4],
  541: [4.6545, 4.784, 4.974, 5.364, 5.424, 2.4],
  542: [4.659, 4.788, 4.978, 5.368, 5.428, 2.4],
  543: [4.6635, 4.792, 4.982, 5.372, 5.432, 2.4],
  544: [4.668, 4.796, 4.986, 5.376, 5.436, 2.4],
  545: [4.6725, 4.8, 4.99, 5.38, 5.44, 2.4],
  546: [4.677, 4.804, 4.994, 5.384, 5.444, 2.4],
  547: [4.6815, 4.808, 4.998, 5.388, 5.448, 2.4],
  548: [4.686, 4.812, 5.002, 5.392, 5.452, 2.4],
  549: [4.6905, 4.816, 5.006, 5.396, 5.456, 2.4],
  550: [4.695, 4.82, 5.01, 5.4, 5.46, 2.4],
  551: [4.6995, 4.824, 5.014, 5.404, 5.464, 2.4],
  552: [4.704, 4.828, 5.018, 5.408, 5.468, 2.4],
  553: [4.7085, 4.832, 5.022, 5.412, 5.472, 2.4],
  554: [4.713, 4.836, 5.026, 5.416, 5.476, 2.4],
  555: [4.7175, 4.84, 5.03, 5.42, 5.48, 2.4],
  556: [4.722, 4.844, 5.034, 5.424, 5.484, 2.4],
  557: [4.7265, 4.848, 5.038, 5.428, 5.488, 2.4],
  558: [4.731, 4.852, 5.042, 5.432, 5.492, 2.4],
  559: [4.7355, 4.856, 5.046, 5.436, 5.496, 2.4],
  560: [4.74, 4.86, 5.05, 5.44, 5.5, 2.4],
  561: [4.7425, 4.8625, 5.0525, 5.4425, 5.5025, 2.4],
  562: [4.745, 4.865, 5.055, 5.445, 5.505, 2.4],
  563: [4.7475, 4.8675, 5.0575, 5.4475, 5.5075, 2.4],
  564: [4.75, 4.87, 5.06, 5.45, 5.51, 2.4],
  565: [4.7525, 4.8725, 5.0625, 5.4525, 5.5125, 2.4],
  566: [4.755, 4.875, 5.065, 5.455, 5.515, 2.4],
  567: [4.7575, 4.8775, 5.0675, 5.4575, 5.5175, 2.4],
  568: [4.76, 4.88, 5.07, 5.46, 5.52, 2.4],
  569: [4.7625, 4.8825, 5.0725, 5.4625, 5.5225, 2.4],
  570: [4.765, 4.885, 5.075, 5.465, 5.525, 2.4],
  571: [4.7675, 4.8875, 5.0775, 5.4675, 5.5275, 2.4],
  572: [4.77, 4.89, 5.08, 5.47, 5.53, 2.4],
  573: [4.7725, 4.8925, 5.0825, 5.4725, 5.5325, 2.4],
  574: [4.775, 4.895, 5.085, 5.475, 5.535, 2.4],
  575: [4.7775, 4.8975, 5.0875, 5.4775, 5.5375, 2.4],
  576: [4.78, 4.9, 5.09, 5.48, 5.54, 2.4],
  577: [4.7825, 4.9025, 5.0925, 5.4825, 5.5425, 2.4],
  578: [4.785, 4.905, 5.095, 5.485, 5.545, 2.4],
  579: [4.7875, 4.9075, 5.0975, 5.4875, 5.5475, 2.4],
  580: [4.79, 4.91, 5.1, 5.49, 5.55, 2.4],
  581: [4.7915, 4.9115, 5.1015, 5.4915, 5.5515, 2.4],
  582: [4.793, 4.913, 5.103, 5.493, 5.553, 2.4],
  583: [4.7945, 4.9145, 5.1045, 5.4945, 5.5545, 2.4],
  584: [4.796, 4.916, 5.106, 5.496, 5.556, 2.4],
  585: [4.7975, 4.9175, 5.1075, 5.4975, 5.5575, 2.4],
  586: [4.799, 4.919, 5.109, 5.499, 5.559, 2.4],
  587: [4.8005, 4.9205, 5.1105, 5.5005, 5.5605, 2.4],
  588: [4.802, 4.922, 5.112, 5.502, 5.562, 2.4],
  589: [4.8035, 4.9235, 5.1135, 5.5035, 5.5635, 2.4],
  590: [4.805, 4.925, 5.115, 5.505, 5.565, 2.4],
  591: [4.8065, 4.9265, 5.1165, 5.5065, 5.5665, 2.4],
  592: [4.808, 4.928, 5.118, 5.508, 5.568, 2.4],
  593: [4.8095, 4.9295, 5.1195, 5.5095, 5.5695, 2.4],
  594: [4.811, 4.931, 5.121, 5.511, 5.571, 2.4],
  595: [4.8125, 4.9325, 5.1225, 5.5125, 5.5725, 2.4],
  596: [4.814, 4.934, 5.124, 5.514, 5.574, 2.4],
  597: [4.8155, 4.9355, 5.1255, 5.5155, 5.5755, 2.4],
  598: [4.817, 4.937, 5.127, 5.517, 5.577, 2.4],
  599: [4.8185, 4.9385, 5.1285, 5.5185, 5.5785, 2.4],
  600: [4.82, 4.94, 5.13, 5.52, 5.58, 2.4],
}
