export default [
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/spider/general/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/spider/general/Profile.vue'),
    meta: {
      resource: 'coach',
      action: 'read',
    },
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('@/views/spider/general/Settings.vue'),
    meta: {
      resource: 'coach',
      action: 'read',
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
  {
    path: '/pages/miscellaneous/not-authorized',
    name: 'misc-not-authorized',
    component: () => import('@/views/spider/general/NotAuthorized.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },

  {
    path: '/add-student',
    name: 'add-student',
    component: () => import('@/views/spider/general/AddStudent.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
  {
    path: '/redeem-codes',
    name: 'redeem-codes',
    component: () => import('@/views/spider/general/RedeemCodes.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
]
